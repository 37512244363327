import { PageLimitPaginationParams } from "@taxbit-dashboard/router";

const getOffsetFromPage = ({
  page,
  limit,
}: PageLimitPaginationParams): number => {
  const normalizedPage = Math.max(page - 1, 0);
  return normalizedPage * limit;
};

export default getOffsetFromPage;
