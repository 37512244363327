import { useNavigate, useSearch } from "@tanstack/react-router";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import { z, ZodSchema } from "zod";

import { PageLimitPaginationParams } from "../params/shared/pageLimitPaginationParamsSchema";
import { DEFAULT_PAGE_NUM } from "../params/shared/pageNumberSchema";
import { UrlParams } from "../types/params";
import { RouteId } from "../types/RouteId";

type Updater<TParams> = TParams | ((current: TParams) => TParams);

const isUpdaterFn = <TParams>(
  updater: Updater<TParams>
): updater is (current: TParams) => TParams => {
  return typeof updater === "function";
};

export type UseUrlParamsReturn<TParams> = {
  /**
   * The current URL params for the route. If `shouldThrow` is set to `false`, this may be undefined.
   */
  urlParams: TParams | undefined;
  /**
   * Updates the url params on the route. This updates the URL params with the new values, but does not
   * override existing values. To completely override the current params with the given set, see `setUrlParams`.
   */
  updateUrlParams: (updater: Updater<Partial<TParams>>) => Promise<void>;
  /**
   * Updates the url params on the route and resets the page number to 1. This updates the URL params with the new
   * values, but does not override existing values. To completely override the current params with the given set,
   * see `setUrlParams`.
   */
  updateFilterParams: (
    updater: Updater<Partial<Omit<TParams, keyof PageLimitPaginationParams>>>
  ) => Promise<void>;
  /**
   * Overrides the url params for the current route with the given set. To update params without overriding existing
   * values, see `updateUrlParams` and `updateFilterParams`.
   */
  setUrlParams: (params: TParams) => Promise<void>;
};

/**
 * An abstraction on the Tanstack Router search params hook that allows for easy
 * overriding of URL params for a specific route. By default, this hook will always return
 * a defined params object and will throw an error if used on a route that does not match the
 * given routeId. To override this behavior when using this hook on unknown pages or multiple
 * pages from different areas of the route tree, set `shouldThrow` to `false`.
 *
 * Some routes require param defaults that cannot be set statically in our routing code, i.e.
 * defaults that come from feature flags or API calls. For these, use the `overrideParams` config
 * to manually parse and apply default values to the URL params.
 *
 * @returns An object representing the current URL params for a particular route as
 * well as setters for URL params on that route.
 */
function useUrlParams<
  TRouteId extends RouteId,
  TSchema extends ZodSchema | undefined = undefined,
  TParams = TSchema extends ZodSchema ? z.infer<TSchema> : UrlParams<TRouteId>,
>(params: {
  routeId: TRouteId;
  shouldThrow?: true;
  overrideSchema?: TSchema;
}): UseUrlParamsReturn<TParams> & {
  urlParams: TSchema extends ZodSchema ? z.infer<TSchema> : UrlParams<TRouteId>;
};
function useUrlParams<
  TRouteId extends RouteId,
  TSchema extends ZodSchema | undefined = undefined,
  TParams = TSchema extends ZodSchema ? z.infer<TSchema> : UrlParams<TRouteId>,
>(params: {
  routeId: TRouteId;
  shouldThrow: false;
  overrideSchema?: TSchema;
}): UseUrlParamsReturn<TParams>;
function useUrlParams<
  TRouteId extends RouteId,
  TSchema extends ZodSchema | undefined = undefined,
  TParams = TSchema extends ZodSchema ? z.infer<TSchema> : UrlParams<TRouteId>,
>({
  routeId,
  shouldThrow = true,
  overrideSchema = undefined,
}: {
  routeId: TRouteId;
  shouldThrow?: boolean;
  overrideSchema?: TSchema;
}): UseUrlParamsReturn<TParams> {
  const navigate = useNavigate();

  const urlParams = useSearch({
    from: routeId,
    shouldThrow,
  });

  const parsedParams = overrideSchema
    ? (overrideSchema.parse(urlParams) as TParams)
    : urlParams;

  /**
   * To mimic the Tanstack Router's behavior of updating the actual URL params with defaults caught by
   * validation, we need to update the URL params when the parsed params are different from the URL params.
   */
  useEffect(() => {
    if (!_.isEqual(urlParams, parsedParams)) {
      void navigate({
        // @ts-expect-error Routes that used override schemas will not have the correct types at the routing
        // layer anyway, so we are not worried about this type error.
        search: parsedParams,
      });
    }
  }, [navigate, parsedParams, urlParams]);

  const getNewParams = useCallback(
    <T>(updater: Updater<T>, prevParams: T): T => {
      if (isUpdaterFn(updater)) {
        return updater(prevParams);
      } else {
        return updater;
      }
    },
    []
  );

  const updateUrlParams: UseUrlParamsReturn<TParams>["updateUrlParams"] =
    useCallback(
      async (updater) => {
        return navigate({
          // @ts-expect-error @tanstack/router is not a fan of our usage of generics, but the inferred types
          // when this helper is actually used are correct and so we are not worried about this type error.
          search: (prevParams: TParams) => {
            const newParams = getNewParams(updater, prevParams);

            return {
              ...prevParams,
              ...newParams,
            };
          },
        });
      },
      [getNewParams, navigate]
    );

  const updateFilterParams: UseUrlParamsReturn<TParams>["updateFilterParams"] =
    useCallback(
      async (updater) => {
        return navigate({
          // @ts-expect-error @tanstack/router is not a fan of our usage of generics, but the inferred types
          // when this helper is actually used are correct and so we are not worried about this type error.
          search: (prevParams: TParams) => {
            const newParams = getNewParams(updater, prevParams);

            return {
              ...prevParams,
              ...newParams,
              page: DEFAULT_PAGE_NUM,
            };
          },
        });
      },
      [getNewParams, navigate]
    );

  const setUrlParams: UseUrlParamsReturn<TParams>["setUrlParams"] = useCallback(
    async (params) => {
      return navigate({
        // @ts-expect-error @tanstack/router is not a fan of our usage of generics, but the inferred types
        // when this helper is actually used are correct and so we are not worried about this type error.
        search: params,
      });
    },
    [navigate]
  );

  return {
    urlParams: parsedParams as TParams,
    setUrlParams,
    updateFilterParams,
    updateUrlParams,
  };
}

export default useUrlParams;
