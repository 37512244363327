import { routerMap as irwRouterMap } from "@taxbit-dashboard/irw";
import { router, RouteId, RouterProvider } from "@taxbit-dashboard/router";
import { routerMap as settingsRouterMap } from "@taxbit-dashboard/settings";
import React from "react";

import AuthedRoute from "./AuthedRoute";
import { AuthedRouteWrapper } from "./AuthedRouteWrapper";
import RootComponent from "./RootComponent";
import Callback from "../../callback/Callback";
import NotificationsPage from "../../notifications/page/Notifications";

const routerMap = {
  /**
   * Root component. This component wraps the entire application, and is responsible for the
   * setup of global providers and styles that are required for the app to function correctly.
   */
  [RouteId.Root]: RootComponent,

  /**
   * The callback page used for Auth0 authentication. This is hardcoded as the return route
   * for the Auth0 authentication process, and is not intended to be accessed directly.
   */
  [RouteId.Callback]: Callback,

  /**
   * Authenticated route wrappers. These ensure that navigating to any route (besides /callback)
   * logs the user into an appropriate organization and redirects them to the correct landing page
   * if they are not hitting a direct page link.
   */
  [RouteId.Index]: () => (
    <AuthedRouteWrapper>
      <AuthedRoute />
    </AuthedRouteWrapper>
  ),
  [RouteId.AuthedLayout]: () => (
    <AuthedRouteWrapper>
      <AuthedRoute />
    </AuthedRouteWrapper>
  ),
  [RouteId.AuthedOrgIndex]: () => (
    <AuthedRouteWrapper>
      <AuthedRoute />
    </AuthedRouteWrapper>
  ),

  /**
   * Feature pages set up in app/dashboard.
   */
  [RouteId.Notifications]: NotificationsPage,

  /**
   * Feature pages imported from other monorepo packages
   */
  ...settingsRouterMap,
  ...irwRouterMap,
} as const satisfies Record<RouteId, React.FC>;

for (const [path, component] of Object.entries(routerMap)) {
  const foundRoute = router.routesById[path as RouteId];

  foundRoute.update({
    component,
  });
}

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
