import { EligibilityByType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback } from "react";

import useLegacyFormFeatureFlagData from "../../pages/information-reporting/shared/form-year-dropdowns/useLegacyFormFeatureFlagData";

const useFilterResponseDataByFeatureFlag = () => {
  const { hasYearForForm } = useLegacyFormFeatureFlagData({
    shouldUseFormItemsForms: false,
  });

  return useCallback(
    (data: EligibilityByType[], year: FourDigitYear) => {
      const filteredData = data.filter((item) =>
        hasYearForForm(item.documentType, year)
      );

      const total = filteredData.reduce((acc, item) => {
        return acc + item.totalEligibleAccounts;
      }, 0);

      return {
        filteredData,
        filteredTotal: total,
      };
    },
    [hasYearForForm]
  );
};

export default useFilterResponseDataByFeatureFlag;
