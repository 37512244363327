import { useChildMatches } from "@tanstack/react-router";
import { useMemo } from "react";

/**
 * Returns whether the current route is the index route, i.e. there are no child matches
 * that need to be displayed. This can be used to determine when the user should be manually
 * navigated to a child route.
 */
const useIsIndexRoute = () => {
  const childMatches = useChildMatches();
  return useMemo(() => childMatches.length === 0, [childMatches]);
};

export default useIsIndexRoute;
