import {
  transactionSubtypeLabelMap,
  transactionTypeLabelMap,
  TransactionsTableRow,
} from "@taxbit-dashboard/rest";
import { RouteId, useAuthenticatedNavigation } from "@taxbit-dashboard/router";
import { Button, TableColumns } from "@taxbit-private/cosmic";
import {
  isCosmicSupportedCurrency,
  useCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { AssetCode } from "@taxbit-private/data-models-v2";
import { useCallback, useMemo } from "react";

import useNavigateToAccountDetails from "../../accounts/account-details/useNavigateToAccountDetails";

export const TRANSACTIONS_TABLE_ROW_LABELS = {
  accountId: "Account ID",
  dateTime: "Date",
  transactionId: "Transaction ID",
  type: "Type",
  subtype: "Subtype",
  sent: "Sent",
  sentAsset: "Sent Asset",
  received: "Received",
  receivedAsset: "Received Asset",
  fee: "Fee",
  feeAsset: "Fee Asset",
  transactionValue: "Transaction Value",
  feeValue: "Fee Value",
} as const;

export enum TransactionsTableTrackingId {
  CosmicTable = "transactions-table",
  ExportFailureToast = "transactions-export-failure-toast",
  ExportSuccessToast = "transactions-export-success-toast",
  TableContent = "transactions-table-content",
  TransactionDetailsLink = "transaction-details-link",
  AccountDetailsLink = "account-details-link",
}

export const useTransactionsTableColumns = () => {
  const { formatDateTime, formatNumber, formatTokenQuantity } =
    useCosmicLocalizationContext();

  const { authenticatedNavigate } = useAuthenticatedNavigation();
  const navigateToAccountDetails = useNavigateToAccountDetails();

  const renderAsset = useCallback(
    (assetCode?: AssetCode, amount?: string) => {
      if (isCosmicSupportedCurrency(assetCode)) {
        return formatNumber({
          value: amount,
          format: "FiatCurrency",
          currency: assetCode,
        });
      } else {
        return formatTokenQuantity(amount, assetCode);
      }
    },
    [formatNumber, formatTokenQuantity]
  );

  const columns = useMemo<TableColumns<TransactionsTableRow>>(
    () => [
      {
        key: "dateTime",
        label: TRANSACTIONS_TABLE_ROW_LABELS.dateTime,
        renderCell: (date) => {
          return formatDateTime({ date, format: "DateTime" });
        },
        isContentFullWidth: true,
      },
      {
        key: "sourceTransactionId",
        label: TRANSACTIONS_TABLE_ROW_LABELS.transactionId,
        isContentFullWidth: true,
        renderCell: (value, row) => (
          <Button
            onClick={() => {
              void authenticatedNavigate({
                to: RouteId.TransactionDetails,
                params: {
                  accountId: row.accountId,
                  transactionId: row.transactionUuid,
                },
              });
            }}
            variant="anchor-primary-inline"
            trackingId={TransactionsTableTrackingId.TransactionDetailsLink}
            label={value}
          />
        ),
      },
      {
        key: "accountExternalId",
        label: TRANSACTIONS_TABLE_ROW_LABELS.accountId,
        isContentFullWidth: true,
        renderCell: (value, row) => (
          <Button
            onClick={() => {
              navigateToAccountDetails(row.accountId);
            }}
            variant="anchor-primary-inline"
            trackingId={TransactionsTableTrackingId.AccountDetailsLink}
            label={value}
          />
        ),
      },
      {
        key: "type",
        label: TRANSACTIONS_TABLE_ROW_LABELS.type,
        isContentFullWidth: true,
        renderCell: (value) => transactionTypeLabelMap[value],
      },
      {
        key: "subtype",
        label: TRANSACTIONS_TABLE_ROW_LABELS.subtype,
        isContentFullWidth: true,
        renderCell: (value) => value && transactionSubtypeLabelMap[value],
      },
      {
        key: "sentQuantity",
        label: TRANSACTIONS_TABLE_ROW_LABELS.sent,
        textAlign: "right",
        renderCell: (value, transaction) =>
          renderAsset(transaction.sentAsset, value),
        isContentFullWidth: true,
      },
      {
        key: "receivedQuantity",
        label: TRANSACTIONS_TABLE_ROW_LABELS.received,
        textAlign: "right",
        renderCell: (value, transaction) =>
          renderAsset(transaction.receivedAsset, value),
        isContentFullWidth: true,
      },
      {
        key: "feeQuantity",
        label: TRANSACTIONS_TABLE_ROW_LABELS.fee,
        textAlign: "right",
        renderCell: (value, transaction) =>
          renderAsset(transaction.feeAsset, value),
        isContentFullWidth: true,
      },
      {
        key: "feeValue",
        label: TRANSACTIONS_TABLE_ROW_LABELS.feeValue,
        renderCell: (value, transaction) =>
          renderAsset(transaction.feeRateAsset, value),
        textAlign: "right",
        isContentFullWidth: true,
      },
      {
        key: "transactionValue",
        label: TRANSACTIONS_TABLE_ROW_LABELS.transactionValue,
        renderCell: (value, transaction) =>
          renderAsset(transaction.transactionValueAsset, value),
        textAlign: "right",
        isContentFullWidth: true,
      },
    ],
    [
      authenticatedNavigate,
      formatDateTime,
      renderAsset,
      navigateToAccountDetails,
    ]
  );

  return columns;
};
