import { DashboardEligibilityFormStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { dashboardEligibilityFormStatusLabelMap } from "../../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export const getEligibilityFormStatusChipTrackingId = (
  formStatus: DashboardEligibilityFormStatus
) => {
  return `ir-eligibility-filters-${formStatus}-filter-chip`;
};

const useEligibilityFormStatusChip = () => {
  const { urlParams, updateFilterParams } = useEligibilityDataContext();

  return useMemo(() => {
    return (urlParams.formStatuses ?? []).map((formStatus) => {
      const label = dashboardEligibilityFormStatusLabelMap[formStatus];

      return {
        label: `Form Status: ${label}`,
        removeButtonProps: {
          "onClick": () => {
            updateFilterParams({
              formStatuses: urlParams.formStatuses?.filter(
                (s) => s !== formStatus
              ),
            });
          },
          "aria-label": `Remove filter for ${label}`,
        },
        trackingId: getEligibilityFormStatusChipTrackingId(formStatus),
      };
    });
  }, [urlParams, updateFilterParams]);
};

export default useEligibilityFormStatusChip;
