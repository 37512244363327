import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";
import { Body } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import FormsTableExportButton from "./export/FormsTableExportButton";
import { FORMS_DATA_MAX_LIMIT } from "../../../../api/information-reporting/forms/formsApi";
import { useIrFormsContext } from "../context/useIrForms";

export enum FormsTablePaginationTrackingId {
  Pagination = "ir-forms-table-pagination",
  ExportCsv = "ir-forms-table-export-csv",
}

const FormsTablePagination = () => {
  const {
    shouldDisableControls,
    totalCount,
    urlParams,
    updatePaginationParams,
  } = useIrFormsContext();
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const tooltipProps = {
    content: (
      <Body>
        Forms are limited to {formatWholeQuantity(FORMS_DATA_MAX_LIMIT)} rows.
        Please <FormsTableExportButton isAnchor={true} label="export a CSV" />{" "}
        of this table to see all forms.
      </Body>
    ),
    isInteractive: true,
  } as const;

  return (
    <UrlParamPaginationControls
      itemsLimit={FORMS_DATA_MAX_LIMIT}
      tooltipProps={tooltipProps}
      isDisabled={shouldDisableControls}
      totalCount={totalCount}
      trackingId={FormsTablePaginationTrackingId.Pagination}
      urlParams={urlParams}
      updateUrlParams={updatePaginationParams}
    />
  );
};

export default FormsTablePagination;
