import {
  AuthorizedView,
  DashboardNavigationItem,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { Outlet, RouteId, RouterMapForPrefix } from "@taxbit-dashboard/router";

import AccountDetailsTab from "./account-details/account-details-tab/AccountDetailsTab";
import AccountDetailsPage from "./account-details/AccountDetails.page";
import DetailsTab from "./account-details/details-tab/DetailsTab";
import TaxDocumentationTab from "./account-details/documentation-tab/TaxDocumentationTab";
import FormDataTab from "./account-details/form-data-tab/FormDataTab";
import InventoryTab from "./account-details/inventory-tab/InventoryTab";
import ReportingProfileTab from "./account-details/reporting-profile-tab/ReportingProfileTab";
import TaxFormsTab from "./account-details/tax-forms-tab/TaxFormsTab";
import TransactionDetails from "./account-details/transactions-tab/transactions-details/TransactionDetails";
import TransactionsTab from "./account-details/transactions-tab/TransactionsTab";
import Accounts from "./accounts/Accounts";

export const accountsNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.AccountsLayout,
  iconName: "user",
  label: "Accounts",
  trackingId: "sidebar-navigation-accounts-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadAccounts],
} as const;

const AccountsPage = () => {
  return (
    <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
      <Accounts />
    </AuthorizedView>
  );
};

export const accountsRouterMap = {
  // This blank layout route ensures that we have a parent to check against to
  // display the active tab in the left navigation sidebar.
  [RouteId.AccountsLayout]: Outlet,
  [RouteId.Accounts]: AccountsPage,
  [RouteId.AccountDetails]: AccountDetailsPage,
  [RouteId.AccountDetailsDetailsTab]: DetailsTab,
  [RouteId.AccountDetailsAccountDetailsTab]: AccountDetailsTab,
  [RouteId.AccountDetailsReportingProfileTab]: ReportingProfileTab,
  [RouteId.AccountDetailsTaxDocumentationTab]: TaxDocumentationTab,
  [RouteId.AccountDetailsTaxFormsTab]: TaxFormsTab,
  [RouteId.AccountDetailsInventoryTab]: InventoryTab,
  [RouteId.AccountDetailsFormDataTab]: FormDataTab,
  [RouteId.AccountDetailsTransactionsTab]: TransactionsTab,
  [RouteId.AccountDetailsTransactionDetails]: TransactionDetails,
} as const satisfies RouterMapForPrefix<"/_authed/o/$organizationId/_irwLayout/accounts/">;
