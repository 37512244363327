import { FileAction } from "@taxbit-dashboard/rest";

import { useDeleteFileUploaderContext } from "./useDeleteFileUploader";
import { useIngestFileUploaderContext } from "./useIngestFileUploader";

const useFileUploaderContext = (action: FileAction) => {
  const ingestContext = useIngestFileUploaderContext();
  const deleteContext = useDeleteFileUploaderContext();

  return action === FileAction.Ingest ? ingestContext : deleteContext;
};

export default useFileUploaderContext;
