import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  Invitation,
  InvitationResponse,
  InviteUser,
  Role,
} from "@taxbit-dashboard/rest";

import { useGetRoles } from "./rolesApi";

const transformInvitationDates = (invitationResponse: InvitationResponse) => {
  return {
    ...invitationResponse,
    expiresAt: new Date(invitationResponse.expiresAt),
    createdAt: new Date(invitationResponse.createdAt),
  };
};

export const useGetInvitations = (organizationId: string) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.Invitations],
    () => restSdk.organizations.invitations.get(organizationId),
    createQueryMetaObject(
      restSdk.organizations.invitations.buildPath(organizationId)
    )
  );

  const unwrappedQuery = unwrapPublicApiWrappedQuery(query);

  return {
    ...unwrappedQuery,
    data: unwrappedQuery.data?.map(transformInvitationDates),
  };
};

const getInvitationsWithRoles = (invitations: Invitation[], roles: Role[]) => {
  return invitations.map(({ roles: roleIds, ...otherData }) => ({
    ...otherData,
    roles: roles.filter(({ id }) => roleIds.includes(id)),
  }));
};

type InvitationsWithRoles = ReturnType<typeof getInvitationsWithRoles>;

export type InvitationWithRoles = InvitationsWithRoles[number];

export const useGetInvitationsWithRoles = (organizationId: string) => {
  const {
    data: roles,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
  } = useGetRoles();
  const {
    data: invitations,
    isLoading: isLoadingInvitations,
    isError: isErrorInvitations,
  } = useGetInvitations(organizationId);

  return {
    isLoading: isLoadingRoles || isLoadingInvitations,
    isError: isErrorRoles || isErrorInvitations,
    data:
      invitations && roles
        ? getInvitationsWithRoles(invitations, roles)
        : undefined,
  };
};

export const useInviteUserToOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();
  const restSdk = useTaxBitRest();
  return useMutation(
    (data: InviteUser) =>
      restSdk.organizations.invitations.post(organizationId, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [DashboardQueryKey.Invitations],
        });
      },
    }
  );
};
