import {
  createFileRoute,
  redirect,
  retainSearchParams,
} from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { EligibilityStatus } from "@taxbit-dashboard/rest";

import irEligibilityUrlParamsSchema from "../../params/irw/eligibility/irEligibilityUrlParamsSchema";
import { RouteId } from "../../types/RouteId";
import getPathForId from "../../utils/getPathForId";

export const Route = createFileRoute(RouteId.IrEligibility)({
  validateSearch: zodValidator(irEligibilityUrlParamsSchema),
  search: {
    middlewares: [retainSearchParams(["formType", "formYear"])],
  },
  /**
   * Product has requested that the eligibility table have a default view at first page load of
   * all eligible users. To distinguish between an unset `statuses` filter and an intentionally
   * empty one, we set intentionally empty statuses to the value `None` in the filter drawer and
   * chips. So, we know that it is safe to throw a redirect and add the default statuses when
   * `statuses` is not set at all.
   */
  beforeLoad: ({ search }) => {
    if (!search.statuses) {
      redirect({
        to: getPathForId(RouteId.IrEligibility),
        search: {
          statuses: [EligibilityStatus.Eligible, EligibilityStatus.Added],
        },
        throw: true,
      });
    }
  },
});
