import {
  AuthorizedView,
  DashboardNavigationItemChild,
  MissingDashboardConfigEmptyState,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import { IrFormsContextProvider } from "./context/useIrForms";
import Forms from "./Forms";
import useFormFeatureFlagData from "../shared/form-year-dropdowns/useFormFeatureFlagData";

export const irFormsNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.IrForms,
  label: "Forms",
  trackingId: "sidebar-navigation-ir-forms-anchor",
  key: "forms",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasIrFormsPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasIrFormsPageAccess,
} as const;

const IrFormsPage = () => {
  const { hasFeatureFlagData } = useFormFeatureFlagData({
    featureFlag: "formsPageYearsByFormType",
  });

  return (
    <AuthorizedView
      permissions={[UserPermission.ReadAccountDocuments]}
      flags={["hasIrFormsPageAccess"]}
    >
      {hasFeatureFlagData ? (
        <IrFormsContextProvider>
          <Forms />
        </IrFormsContextProvider>
      ) : (
        <MissingDashboardConfigEmptyState />
      )}
    </AuthorizedView>
  );
};

export default IrFormsPage;
