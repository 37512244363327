import { useRouterState } from "@tanstack/react-router";
import { useCallback } from "react";

import { RouteId } from "../types/RouteId";

const useIsRouteIdActive = () => {
  const routerState = useRouterState();

  return useCallback(
    (routeId: RouteId) => {
      return routerState.matches.some((match) => match.routeId === routeId);
    },
    [routerState.matches]
  );
};

export default useIsRouteIdActive;
