import { IrFormTypeApiParams } from "@taxbit-dashboard/rest";

import {
  IrFormTypeDateParams,
  mapDashboardFormTypeToApiFormType,
} from "./irApiTypes";

const getIrParams = ({
  formYear,
  formType,
}: IrFormTypeDateParams): IrFormTypeApiParams => {
  return {
    "filters[document_date]": formYear,
    "filters[document_type]": mapDashboardFormTypeToApiFormType(formType),
  };
};

export default getIrParams;
