import { useMatch } from "@tanstack/react-router";

import { RouteId } from "../types/RouteId";

const useRouteOrganizationId = () => {
  const match = useMatch({
    from: RouteId.AuthedOrgIndex,
    shouldThrow: false,
  });

  return match?.params.organizationId;
};

export default useRouteOrganizationId;
