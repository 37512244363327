import {
  Clickable,
  ClickableProps,
  Flex,
  H2,
  H4,
  TrackingProps,
} from "@taxbit-private/cosmic";
import styled from "styled-components";
import { O } from "ts-toolbelt";

import FallbackViewAsset from "./FallbackViewAsset.svg?react";

type Props = {
  title?: string;
  ctaProps?: O.Omit<ClickableProps, "variant" | "trackingId">;
  subtitle: string;
} & TrackingProps;

/**
 * The default clickable is an anchor rather a button with a Tanstack Router navigation,
 * so that we trigger a fresh load without any potential error params
 */
const defaultCtaProps = {
  label: "Go Home",
  href: "/",
  target: "_self",
};

const FallbackView: React.FC<Props> = ({
  ctaProps = defaultCtaProps,
  subtitle,
  title = "Uh-Oh!",
  trackingId,
}) => {
  return (
    <Wrapper
      alignItems="center"
      justifyContent="center"
      trackingId={trackingId}
    >
      <Flex direction="column" alignItems="center" gap="s">
        <SizedFallbackViewAsset />
        <H2 as="div">{title}</H2>
        <HelpText forwardedAs="div">{subtitle}</HelpText>
        <Flex padding="m none none none">
          <Clickable
            {...ctaProps}
            variant="button-primary"
            trackingId={`${trackingId}-cta-button`}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default FallbackView;

const Wrapper = styled(Flex)(
  ({ theme }) => `
  height: 100%;
  background-color: ${theme.color.white};
`
);

const HelpText = styled(H4)(
  ({ theme }) => `
  max-width: ${theme.measure.xxl};
  text-align: center;
`
);

const SizedFallbackViewAsset = styled(FallbackViewAsset)(
  ({ theme }) => `
  width: ${theme.measure.m};
  padding-bottom: ${theme.spacing.m};
`
);
