import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";
import { Dropdown } from "@taxbit-private/cosmic";

import { VIEW_LABELS } from "./accountsView";
import { AccountsTable } from "./table/AccountsTable";
import { useAccountsView } from "./useAccountsView";

export enum AccountsTrackingId {
  AccountsViewDropdown = "accounts-view-dropdown",
}

const Accounts: React.FC = () => {
  const { view, allViews, setView } = useAccountsView();

  return (
    <DashboardPrimaryTemplate
      title="Accounts"
      utilityElement={
        allViews.length > 1 ? (
          <Dropdown
            label="Accounts View"
            trackingId={AccountsTrackingId.AccountsViewDropdown}
            isLabelHidden={true}
            options={allViews}
            value={view.type}
            getOptionKey={(value) => value}
            getOptionLabel={(value) => VIEW_LABELS[value]}
            onChange={(value) => {
              void setView(value);
            }}
          />
        ) : undefined
      }
    >
      <AccountsTable />
    </DashboardPrimaryTemplate>
  );
};

export default Accounts;
