import {
  AuthorizedView,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import IrSummary from "./IrSummary";
import { IrSummaryDataContextProvider } from "./useIrSummaryData";
import { irEligibilityNavigationItem } from "../eligible-users/IrEligibility.page";
import { irFormsNavigationItem } from "../forms/IrForms.page";

export const irSummaryNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.IrSummary,
  label: "Summary",
  trackingId: "sidebar-navigation-ir-summary-anchor",
  key: "summary",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasIrSummaryPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasIrSummaryPageAccess,
} as const;

/**
 * This parent item will only live here temporarily, as the refreshed
 * navigation will have only top-level navigation items.
 */
export const irNavigationItem: DashboardNavigationItemWithChildren = {
  iconName: "file-text",
  label: "Information Reporting",
  trackingId: "sidebar-navigation-ir-anchor",
  children: [
    irSummaryNavigationItem,
    irEligibilityNavigationItem,
    irFormsNavigationItem,
  ],
} as const;

const IrSummaryPage = () => {
  return (
    <AuthorizedView
      flags={["hasIrSummaryPageAccess"]}
      permissions={[UserPermission.ReadAccountDocuments]}
    >
      <IrSummaryDataContextProvider>
        <IrSummary />
      </IrSummaryDataContextProvider>
    </AuthorizedView>
  );
};

export default IrSummaryPage;
