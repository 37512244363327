import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";

import useRouteOrganizationId from "./useRouteOrganizationId";
import { pageWrapperScrollAreaRef } from "../router";
import { AuthedRouteId } from "../types/AuthedRouteId";
import { DashboardPath } from "../types/dashboardPathSchema";
import isRouteId from "../types/isRouteId";
import { PathParams, UrlParams } from "../types/params";
import { RouteId } from "../types/RouteId";
import getOrganizationPrefixedRoute from "../utils/getOrganizationPrefixedRoute";
import getPathForId from "../utils/getPathForId";

/**
 * An outstanding issue in Tanstack Router causes intermittent issues with scroll
 * restoration on pages with redirects. To ensure that scroll restoration works
 * consistently, we need to navigate to the intended subroute directly instead of relying
 * on the redirect and so don't allow base routes as a navigation option.
 *
 * Hint: To navigate to the account details route, see the `useNavigateToAccountDetails` hook.
 */
export type DirectRouteId = Exclude<
  AuthedRouteId,
  | typeof RouteId.AuthedOrgIndex
  | typeof RouteId.AuthedLayout
  | typeof RouteId.IrwLayout
  | typeof RouteId.AccountDetails
>;

export type AuthenticatedNavigationOptions<TRouteId extends DirectRouteId> = {
  /**
   * The RouteId or known DashboardPath string to navigate to.
   */
  to: TRouteId | DashboardPath;
  /**
   * The params required for the given route. This will be merged with the
   * organizationId param for the current organization.
   */
  params?: TRouteId extends RouteId
    ? Omit<PathParams<TRouteId>, "organizationId">
    : never;
  /**
   * The desired search params for the given route.
   */
  search?: TRouteId extends RouteId ? UrlParams<TRouteId> : never;
  /**
   * Whether to reset the scroll position to the top of the page when navigating.
   * When `false`, the scroll position will be restored to the last scroll position on
   * the destination path in the Router cache. When `true`, the scrollable area will be
   * reset to the top of the page after navigation. Default is `true`.
   */
  shouldScrollToTop?: boolean;
};

/**
 * Returns a function that can be used to navigate to an authenticated route (i.e. any rounds that live under the
 * `/o/$organizationId` prefix) without having to manually include the organization id in the params object.
 */
const useAuthenticatedNavigation = <TRouteId extends DirectRouteId>({
  organizationId,
}: {
  organizationId?: string;
} = {}) => {
  const navigate = useNavigate();
  const authOrganizationId = useRouteOrganizationId() ?? organizationId;

  const getPath = useCallback(
    (to: AuthenticatedNavigationOptions<TRouteId>["to"]) => {
      if (isRouteId(to)) {
        return getPathForId(to);
      } else if (authOrganizationId) {
        return getOrganizationPrefixedRoute(authOrganizationId, to);
      } else {
        throw new Error(
          "Attempted to navigate to an authenticated route without an organization."
        );
      }
    },
    [authOrganizationId]
  );

  const navigateToLaunch = useCallback(() => {
    void navigate({
      to: getPathForId(RouteId.AuthedOrgIndex),
      params: {
        organizationId: authOrganizationId,
      },
    });
  }, [authOrganizationId, navigate]);

  const authenticatedNavigate = useCallback(
    async ({
      to,
      params,
      search,
      shouldScrollToTop = true,
    }: AuthenticatedNavigationOptions<TRouteId>) => {
      await navigate({
        to: getPath(to),
        params: {
          ...params,
          // @ts-expect-error Tanstack Router is not a fan of the generic path/search params.
          organizationId: authOrganizationId,
        },
        // @ts-expect-error Tanstack Router is not a fan of the generic path/search params.
        search,
      });

      /**
       * The Tanstack Router's scroll restoration behavior automatically maintains scroll positions between pages we
       * are visiting back to back, but we've decided that all navigations but those to/from details pages should actually
       * reset the page to the top. So, we need to manually reset the scroll position within our main scrollable area for
       * most navigations.
       */
      if (shouldScrollToTop) {
        pageWrapperScrollAreaRef.current?.scrollTo({ top: 0, left: 0 });
      }
    },
    [authOrganizationId, getPath, navigate]
  );

  const getAuthenticatedNavigateProps = useCallback(
    ({ to, params, search }: AuthenticatedNavigationOptions<TRouteId>) => {
      return {
        to: getPath(to),
        params: {
          organizationId: authOrganizationId,
          ...params,
        },
        search,
      } as const;
    },
    [authOrganizationId, getPath]
  );

  return {
    authenticatedNavigate,
    getAuthenticatedNavigateProps,
    navigateToLaunch,
  };
};

export default useAuthenticatedNavigation;
