import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { isSameDay as isSameBrowserDay } from "date-fns";
import { useCallback } from "react";

import isSameUtcDay from "./date/isSameUtcDay";

const useGetDateRangeChipLabel = () => {
  const { formatDateRange, formatDateTime } = useCosmicLocalizationContext();
  return useCallback(
    ({
      startDate,
      endDate,
      isBrowserTime = false,
    }: {
      startDate?: string;
      endDate?: string;
      isBrowserTime?: boolean;
    }) => {
      const isSameDay = (() => {
        if (!startDate || !endDate) {
          return false;
        } else {
          return isBrowserTime
            ? isSameBrowserDay(new Date(startDate), new Date(endDate))
            : isSameUtcDay(startDate, endDate);
        }
      })();

      return isSameDay
        ? formatDateTime({
            date: startDate,
            tz: isBrowserTime ? "Browser" : "UTC",
          })
        : formatDateRange({
            startDate,
            endDate,
            tz: isBrowserTime ? "Browser" : "UTC",
          });
    },
    [formatDateRange, formatDateTime]
  );
};

export default useGetDateRangeChipLabel;
