import { useCallback } from "react";

import {
  FormsSearchParamKey,
  formsSearchParamKeyLabelMap,
  formsSearchParamKeys,
} from "../../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../../context/useIrForms";

export enum FormsSearchChipsTrackingId {
  Chip = "ir-form-search-chip",
}

const useSearchFilterChips = () => {
  const { urlParams, updateFilterParams } = useIrFormsContext();

  const getSearchChip = useCallback(
    (key: FormsSearchParamKey, value: string) => {
      if (value) {
        const label = `${formsSearchParamKeyLabelMap[key]}: "${value}"`;
        return {
          label,
          removeButtonProps: {
            "onClick": () =>
              updateFilterParams({
                [key]: undefined,
              }),
            "aria-label": `Remove filter for ${label}`,
          },
          trackingId: `${FormsSearchChipsTrackingId.Chip}${formsSearchParamKeyLabelMap[key]}`,
        };
      } else {
        return undefined;
      }
    },
    [updateFilterParams]
  );

  const searchChipEntries = Object.entries(urlParams).filter(
    (entry): entry is [FormsSearchParamKey, string] =>
      formsSearchParamKeys.includes(entry[0] as FormsSearchParamKey) &&
      !!entry[1]
  );

  return searchChipEntries.map(([key, value]) => getSearchChip(key, value));
};

export default useSearchFilterChips;
