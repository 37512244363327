import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  RouteId,
  RouterProvider,
  router,
  ErrorComponent,
} from "@taxbit-dashboard/router";
import { theme } from "@taxbit-private/cosmic";
import { CosmicLocalizationContextProvider } from "@taxbit-private/cosmic-localization";
import { render } from "@testing-library/react";
import { ThemeProvider } from "styled-components";

import { NavigationHistoryContextProvider } from "../navigation/hooks/useNavigationHistory";

export const testQueryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

/**
 * Wraps the given elements in the necessary providers for a correct
 * component testing environment and calls RTL `render` on the wrapped element.
 *
 * To use the rendered element in a test, import `screen` from @testing-library/react:
 * ```
 * import { screen } from "@testing-library/react";
 *
 * it("renders", () => {
 *  renderWithProviders(<div>Some text</div>);
 *  expect(screen.getByText("Some text")).toBeInTheDocument();
 * })
 * ```
 */
export const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <QueryClientProvider client={testQueryClient}>
    <ThemeProvider theme={theme}>
      <CosmicLocalizationContextProvider
        initialData={{ locale: "en-US", currency: "USD" }}
      >
        <NavigationHistoryContextProvider>
          {children}
        </NavigationHistoryContextProvider>
      </CosmicLocalizationContextProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

const updateRouterWithWrappedChildren = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  router.routesById[RouteId.Root].update({
    component: () => <Wrapper>{children}</Wrapper>,
    // Set the Tanstack Router error component for tests because it nicely serializes error messages into the UI.
    errorComponent: ErrorComponent,
  });
};

export type RenderWithProvidersOptions = {
  /**
   * Any additional providers that should wrap the component being tested.
   * For example, page specific context providers.
   */
  providers?: React.FC<React.PropsWithChildren>[];
};

/**
 * Wraps the given elements in the necessary providers for a correct
 * component testing environment and calls RTL `render` on the wrapped element.
 *
 * To use the rendered element in a test, import `screen` from @testing-library/react:
 *
 * ```
 * import { screen } from "@testing-library/react";
 *
 * it("renders", () => {
 *  renderWithProviders(<div>Some text</div>);
 *  expect(screen.getByText("Some text")).toBeInTheDocument();
 * })
 * ```
 */
const renderWithProviders = (
  children: React.ReactElement,
  { providers }: RenderWithProvidersOptions = {}
) => {
  if (providers) {
    updateRouterWithWrappedChildren({
      children: providers.reduce(
        (content, Provider) => <Provider>{content}</Provider>,
        children
      ),
    });

    render(<RouterProvider router={router} />);
  } else {
    updateRouterWithWrappedChildren({ children });

    render(<RouterProvider router={router} />);
  }
};

export default renderWithProviders;
