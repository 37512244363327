import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  SelfCertQuestionnaire,
  activeNonFinancialEntityTypeMap,
  financialInstitutionTypeMap,
  selfCertificationAccountTypeMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import KycBadge from "../../KycBadge";

const useSelfCertQuestionnaireRows = ({
  selfCertQuestionnaire,
}: {
  selfCertQuestionnaire?: SelfCertQuestionnaire;
}) => {
  const { formatDateTime } = useCosmicLocalizationContext();
  return useMemo(() => {
    if (!selfCertQuestionnaire) {
      return undefined;
    }

    const classification = (() => {
      if (selfCertQuestionnaire.isIndividual) {
        return "Individual";
      }

      if (selfCertQuestionnaire.accountType) {
        if (
          selfCertQuestionnaire.accountType === "FINANCIAL_INSTITUTION" &&
          selfCertQuestionnaire.financialInstitutionType ===
            "INVESTMENT_ENTITY" &&
          selfCertQuestionnaire.investmentEntityManaged
        ) {
          return `Managed ${
            financialInstitutionTypeMap[
              selfCertQuestionnaire.financialInstitutionType
            ]
          }`;
        }

        if (
          selfCertQuestionnaire.accountType === "ACTIVE_NON_FINANCIAL_ENTITY" &&
          selfCertQuestionnaire.activeNonFinancialEntityType === "OTHER"
        ) {
          return `${selfCertificationAccountTypeMap[selfCertQuestionnaire.accountType]} (${activeNonFinancialEntityTypeMap[selfCertQuestionnaire.activeNonFinancialEntityType]})`;
        }

        return selfCertificationAccountTypeMap[
          selfCertQuestionnaire.accountType
        ];
      }

      return undefined;
    })();

    const rows = {
      key: "rows",
      rows: [
        {
          label: "Documentation Status",
          content: <KycBadge taxStatus="VALID" />,
          key: "documentation-status",
        },
        {
          label: "Name",
          content: selfCertQuestionnaire.name,
          key: "name",
        },
        {
          label: "Submission Date",
          content: formatDateTime({
            date: selfCertQuestionnaire.submissionDate,
          }),
          key: "submission-date",
        },
        {
          label: "Classification",
          content: classification,
          key: "classification",
        },
      ],
    };

    return [rows];
  }, [selfCertQuestionnaire, formatDateTime]);
};

export default useSelfCertQuestionnaireRows;
