import { AccountOwnerDetailsTaxDocumentationIssueType } from "@taxbit-dashboard/rest";

const warningTypes = new Set([
  AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch,
  AccountOwnerDetailsTaxDocumentationIssueType.WithholdingDocumentation,
]);

export const isTaxDocumentationWarning = (
  issueType: AccountOwnerDetailsTaxDocumentationIssueType
) => warningTypes.has(issueType);
