import { Anchor, Body, H4 } from "@taxbit-private/cosmic";

import {
  HelpArticleLinkTrackingId,
  treatyCountryMismatchCuringDocumentationLink,
  withholdingDocumentationCuringDocumentationLink,
} from "./helpArticleLinks";

export enum ResolutionTextsTrackingId {
  CuringTextLink = "curing-text-link",
}

export const getCuringDocumentationObtainedText = ({
  href,
  trackingId,
}: {
  href: string;
  trackingId: string;
}) => (
  <>
    <H4>Curing Documentation Obtained</H4>
    <Body>
      It was previously indicated that curing documentation has been obtained to
      resolve this issue. If this is incorrect, click below to re-open the
      issue.
    </Body>
    <Body>
      See <Anchor label="here" href={href} trackingId={trackingId} /> for a
      checklist on what to look for before accepting curing documentation.
    </Body>
  </>
);

export const treatyCountryMismatchDocumentationObtainedText = (
  <>
    <H4>Information/Documentation Obtained</H4>
    <Body>
      It was previously indicated that additional information and/or
      documentation has been obtained to resolve this warning. If this is
      incorrect, click below to re-open the warning.
    </Body>
    <Body>
      See{" "}
      <Anchor
        label="here"
        href={treatyCountryMismatchCuringDocumentationLink}
        trackingId={
          HelpArticleLinkTrackingId.TreatyCountryMismatchCuringDocumentation
        }
      />{" "}
      for additional details on this warning.
    </Body>
  </>
);

export const getNewTaxDocumentationSubmittedText = (options?: {
  isWarning: boolean;
}) => (
  <>
    <H4>New Tax Documentation Submitted</H4>
    <Body>
      {`This ${options?.isWarning ? "warning" : "issue"} has been resolved by the account owner re-submitting new tax
      documentation.`}
    </Body>
  </>
);

export const withholdingDocumentationSubmittedText = (
  <>
    <H4>Withholding Documentation Obtained</H4>
    <Body>
      It was previously indicated that additional withholding documentation has
      been obtained to resolve this warning (e.g., withholding statements,
      withholding certificates for each underlying beneficial owner, etc.). If
      this is incorrect, click below to re-open the warning.
    </Body>
    <Body>
      See{" "}
      <Anchor
        label="here"
        href={withholdingDocumentationCuringDocumentationLink}
        trackingId={
          HelpArticleLinkTrackingId.WithholdingDocumentationCuringDocumentationLink
        }
      />{" "}
      for a checklist on what to look for before accepting withholding
      documentation.
    </Body>
  </>
);
