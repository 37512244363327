import { EligibilityStatus } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { getEligibilityStatusLabelMap } from "../../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export const getEligibilityStatusChipTrackingId = (
  status: EligibilityStatus
) => {
  return `irEligibilityFilters-${status}-filter-chip`;
};

const useEligibilityStatusChip = () => {
  const { urlParams, updateFilterParams } = useEligibilityDataContext();

  return useMemo(() => {
    const statuses =
      urlParams.statuses === "None" || !urlParams.statuses
        ? []
        : urlParams.statuses;

    return statuses.map((status) => {
      const documentType = urlParams.formType;
      const statusLabel = getEligibilityStatusLabelMap(documentType)[status];

      return {
        label: `Status: ${statusLabel}`,
        removeButtonProps: {
          "onClick": () => {
            /**
             * We represent an intentionally empty statuses filter as "None" so that we can
             * safely default statuses on page load. To account for this, we need to manually
             * set the filter to "None" when the last status chip is cleared.
             */
            if (statuses.length > 1) {
              updateFilterParams({
                statuses: statuses.filter((s) => s !== status),
              });
            } else {
              updateFilterParams({
                statuses: "None",
              });
            }
          },
          "aria-label": `Remove filter for ${statusLabel}`,
        },
        trackingId: getEligibilityStatusChipTrackingId(status),
      };
    });
  }, [urlParams, updateFilterParams]);
};

export default useEligibilityStatusChip;
