import { selfCertificationAccountTypeMap } from "../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";

export const getCarfClassificationDisplayValue = (classification: string) => {
  switch (classification) {
    case "INDIVIDUAL": {
      return "Individual";
    }
    case "FINANCIAL_INSTITUTION":
    case "ACTIVE_NON_FINANCIAL_ENTITY":
    case "PASSIVE_NON_FINANCIAL_ENTITY": {
      return selfCertificationAccountTypeMap[classification];
    }
    default: {
      return "Unknown";
    }
  }
};
