import { doesResponseErrorMatchStatusCode } from "@taxbit-dashboard/commons";
import { Box, ContentErrorEmptyState } from "@taxbit-private/cosmic";

import EntityNotFound from "../../../EntityNotFound";

type ErrorType = {
  error: unknown;
};
const TransactionErrorContent: React.FC<ErrorType> = ({ error }) => {
  const isAccountNotFoundError = doesResponseErrorMatchStatusCode(error, 401);
  const isTransactionNotFoundError = doesResponseErrorMatchStatusCode(
    error,
    404
  );

  if (isTransactionNotFoundError) {
    return <EntityNotFound entity="Transaction" />;
  }

  if (isAccountNotFoundError) {
    return <EntityNotFound entity="Account" />;
  }

  return (
    <Box>
      <ContentErrorEmptyState entity="transaction data" />
    </Box>
  );
};

export default TransactionErrorContent;
