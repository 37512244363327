import { AuthorizedView, UserPermission } from "@taxbit-dashboard/commons";
import { AssetAmount } from "@taxbit-dashboard/rest";
import {
  Box,
  BoxedEntityEmptyState,
  BoxedErrorEmptyState,
  ContentSpinner,
  Table,
  TableColumns,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useGetInventory from "./useGetInventory";
import EntityNotFound from "../../EntityNotFound";

export enum InventoryTabTrackingId {
  InventoryTable = "inventory-table",
  InventoryTabSpinner = "inventory-tab-spinner",
}

const InventoryTab = () => {
  const { isLoading, inventoryItems, isError, isAccountNotFoundError } =
    useGetInventory();

  const { formatQuantity } = useCosmicLocalizationContext();

  const columns = useMemo(
    (): TableColumns<AssetAmount> => [
      {
        key: "asset",
        label: "Asset",
        renderCell: (value) => value.name,
        sortMethod: (a, b) => (a.name ?? "").localeCompare(b.name ?? ""),
      },
      {
        key: "amount",
        label: "Quantity",
        textAlign: "right",
        renderCell: (value) => formatQuantity(value),
      },
    ],
    [formatQuantity]
  );

  return (
    <AuthorizedView
      permissions={[UserPermission.ReadAccountAssets]}
      flags={["hasAccountInventoryAccess"]}
    >
      {isLoading ? (
        <ContentSpinner
          trackingId={InventoryTabTrackingId.InventoryTabSpinner}
        />
      ) : isAccountNotFoundError ? (
        <Box>
          <EntityNotFound entity="Account" />
        </Box>
      ) : isError ? (
        <BoxedErrorEmptyState entity="assets" />
      ) : inventoryItems?.length ? (
        <Box>
          <TrimmedBoxContent trim="all">
            <Table
              trackingId={InventoryTabTrackingId.InventoryTable}
              columns={columns}
              getRowKey={(row) => row.asset.code}
              rows={inventoryItems}
              isSortable={true}
              initialSortColumnKey="amount"
              isInitialSortDescending={true}
            />
          </TrimmedBoxContent>
        </Box>
      ) : (
        <BoxedEntityEmptyState entity="assets" />
      )}
    </AuthorizedView>
  );
};

export default InventoryTab;
