import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import { GetEligibilityDataParams } from "@taxbit-dashboard/rest";
import { IrEligibilityUrlParams } from "@taxbit-dashboard/router";

import { mapDashboardFormTypeToApiFormType } from "../irApiTypes";

const getEligibilityParams = ({
  limit,
  page,
  formYear,
  formType,
  statuses,
  formStatuses,
  accountValidationsEligibilityStatuses,
  externalId,
  name,
  alternateExternalId,
}: IrEligibilityUrlParams): GetEligibilityDataParams => {
  return {
    "filters[document_date]": formYear,
    "filters[document_type]": mapDashboardFormTypeToApiFormType(formType),
    "filters[status]": statuses === "None" ? undefined : statuses,
    "filters[form_status]": formStatuses,
    "filters[account_validations_eligibility_status]":
      accountValidationsEligibilityStatuses,
    "filters[external_id]": externalId,
    "filters[name]": name,
    "filters[alternate_external_id]": alternateExternalId,
    ...getPageLimitApiParams({ page, limit }),
  };
};

export default getEligibilityParams;
