import { createRouter } from "@tanstack/react-router";
import { ErrorBoundaryFallback } from "@taxbit-private/cosmic";
import qs from "qs";
import { createRef } from "react";

import FallbackView from "./components/fallback-view/FallbackView";
import { routeTree } from "./routeTree.gen";

export const PAGE_WRAPPER_SCROLL_AREA_ID = "page-wrapper-scroll-area";
export const pageWrapperScrollAreaRef = createRef<HTMLDivElement>();

export const router = createRouter({
  routeTree,
  /**
   * We use the qs library to parse and stringify search params because it
   * aligns with our backend and includes some useful features like omitting
   * params with empty values (ex: an empty array or empty object).
   *
   * https://github.com/ljharb/qs
   */
  parseSearch: (params) => qs.parse(params, { ignoreQueryPrefix: true }),
  stringifySearch: (params) => {
    const paramString = qs.stringify(params);
    return paramString ? `?${paramString}` : "";
  },
  /**
   * This fallback view will be shown when a route is not found. In the event that a route is not found but its immediate
   * parent has a default route that it navigates to at the index layer (see `AuthedRoute`), then the default route will be
   * navigated to instead of showing this fallback view.
   *
   * Ex: `dashboard.taxbit.com/some-fake-route` --> Shows fallback view, as there is no default route for the root.
   * Ex: `dashboard.taxbit.com/o/some-id/some-fake-route` --> Redirects to `dashboard.taxbit.com/o/some-id/overview` via the
   * redirect in `AuthedRoute.tsx`.
   */
  defaultNotFoundComponent: () => (
    <FallbackView
      subtitle="The page you are looking for doesn't exist. Check your URL or hit the back button in your browser to try again."
      trackingId="not-found-fallback-view"
    />
  ),
  /**
   * This error view will be shown if the encounters an error while trying to render a route.
   */
  defaultErrorComponent: () => {
    return <ErrorBoundaryFallback trackingId="error-fallback-view" />;
  },
  scrollRestoration: true,
  scrollToTopSelectors: [`#${PAGE_WRAPPER_SCROLL_AREA_ID}`],
  getScrollRestorationKey: (location) => location.pathname,
});
