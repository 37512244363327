import {
  caProvinceCodes,
  usStateCodes,
  countryCodes,
} from "@taxbit-private/irw-address";
import {
  exemptFatcaCodes,
  exemptPayeeCodes,
  usTaxIdTypes,
  w8BenETaxClassificationEnum,
  w9TaxClassificationEnum,
} from "@taxbit-private/irw-tax-documentation";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

export enum KycTaxDocumentType {
  W8Ben = "W-8BEN",
  W9 = "W-9",
  W8BenE = "W-8BEN-E",
  W8Imy = "W-8IMY",
  Dps = "DPS",
  SelfCertification = "SELF_CERTIFICATION",
}

export enum KycTaxDocumentStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  Undocumented = "UNDOCUMENTED",
}

export enum KycTinVerificationStatus {
  Pending = "PENDING",
  InvalidData = "INVALID_DATA",
  TinMismatch = "TIN_MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  TinTypeMismatch = "TIN_TYPE_MISMATCH",
  TinMismatchForeignIndicia = "TIN_MISMATCH_FOREIGN_INDICIA",
  ForeignIndicia = "FOREIGN_INDICIA",
  Valid = "VALID",
  CountryCodeMismatch = "COUNTRY_CODE_MISMATCH",
}

export enum KycTaxDocumentationIssueType {
  ExpiredForm = "EXPIRED_FORM",
  UsMailingAddress = "US_MAILING_ADDRESS",
  UsPermanentAddress = "US_PERMANENT_ADDRESS",
  CareOf = "CARE_OF_PERMANENT_ADDRESS",
  PoBoxPermanentAddress = "PO_BOX_PERMANENT_ADDRESS",
}

export const kycTaxDocumentationIssueTypeSchema = z.nativeEnum(
  KycTaxDocumentationIssueType
);

export enum KycTaxDocumentationIssueStatus {
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycCuringStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycResubmissionStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycTaxDocumentationSubmissionStatus {
  Submitted = "SUBMITTED",
  NotSubmitted = "NOT_SUBMITTED",
}

export enum KycTaxDocumentationDac7Status {
  Complete = "COMPLETE",
  Incomplete = "INCOMPLETE",
}

export enum KycTaxdocumentationVatStatus {
  Pending = "PENDING",
  Valid = "VALID",
  Invalid = "INVALID",
  InsufficientData = "INSUFFICIENT_DATA",
  NotRequired = "NOT_REQUIRED",
  NonEu = "NON_EU",
}

export enum EuCountryCodes {
  Austria = "AT",
  Belgium = "BE",
  Bulgaria = "BG",
  Croatia = "HR",
  RepublicOfCyprus = "CY",
  CzechRepublic = "CZ",
  Denmark = "DK",
  Estonia = "EE",
  Finland = "FI",
  France = "FR",
  Germany = "DE",
  Greece = "GR",
  Hungary = "HU",
  Ireland = "IE",
  Italy = "IT",
  Latvia = "LV",
  Lithuania = "LT",
  Luxembourg = "LU",
  Malta = "MT",
  Netherlands = "NL",
  Poland = "PL",
  Portugal = "PT",
  Romania = "RO",
  Slovakia = "SK",
  Slovenia = "SI",
  Spain = "ES",
  Sweden = "SE",
}

export const unrefinedAddressSchema = z
  .object({
    firstLine: z.string().optional(),
    secondLine: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z
      .union([z.enum(caProvinceCodes), z.enum(usStateCodes), z.string()])
      .optional(),
    postalCode: z.string().optional(),
    country: z.enum(countryCodes).optional(),
  })
  .describe("unrefinedAddressSchema");

export const unrefinedW9TaxDocumentationSchema = z.object({
  name: z.string(),
  dbaName: z.string().optional(),
  otherTaxClassification: z.string().optional(),
  exemptPayeeCode: z.enum(exemptPayeeCodes).optional(),
  exemptFatcaCode: z.enum(exemptFatcaCodes).optional(),
  taxClassification: z.enum(w9TaxClassificationEnum),
  address: unrefinedAddressSchema,
  tin: z.string(),
  tinType: z.enum(usTaxIdTypes),
  documentType: z.literal(KycTaxDocumentType.W9),
  hasCertified: z.boolean(),
  isNotSubjectBackupWithholding: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenTaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.literal("INDIVIDUAL"),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  dateOfBirth: z.string(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8Ben),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenETaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.enum(w8BenETaxClassificationEnum),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8BenE),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const TAX_RESIDENCE_TIN_NOT_REQUIRED_REASON = [
  "NOT_ISSUED",
  "NOT_REQUIRED",
  "OTHER",
] as const;

export const taxResidenceTinNotRequiredReasonSchema = z.enum(
  TAX_RESIDENCE_TIN_NOT_REQUIRED_REASON
);

export type TaxResidenceTinNotRequiredReason = z.infer<
  typeof taxResidenceTinNotRequiredReasonSchema
>;

const taxResidenceSchema = z.object({
  country: z.string().optional(),
  tin: z.string().optional(),
  tinNotRequired: z.boolean().optional(),
  tinNotRequiredReason: taxResidenceTinNotRequiredReasonSchema.optional(),
  tinNotRequiredReasonOther: z.string().optional(),
});

export type TaxResidence = z.infer<typeof taxResidenceSchema>;

export const dpsSchema = z.object({
  name: z.string(),
  address: unrefinedAddressSchema.optional(),
  taxResidences: taxResidenceSchema.array().optional(),
  vatin: z.string().optional(),
  vatinCountry: z.string().optional(),
  vatinNotRequired: z.boolean().optional(),
  financialAccountIdentifier: z.string().optional(),
  financialAccountName: z.string().optional(),
  isConfirmedCorrectComplete: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.Dps),
  dateOfBirth: z.string().optional(),
  cityOfBirth: z.string().optional(),
  countryOfBirth: z.string().optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
  isIndividual: z.boolean(),
});

export const SELF_CERTIFICATION_ACCOUNT_TYPES = [
  "FINANCIAL_INSTITUTION",
  "PASSIVE_NON_FINANCIAL_ENTITY",
  "ACTIVE_NON_FINANCIAL_ENTITY",
] as const;

const selfCertificationAccountTypeSchema = z.enum(
  SELF_CERTIFICATION_ACCOUNT_TYPES
);

export type SelfCertificationAccountType = z.infer<
  typeof selfCertificationAccountTypeSchema
>;

export const FINANCIAL_INSTITUTION_TYPE = [
  "DEPOSITORY_INSTITUTION",
  "CUSTODIAL_INSTITUTION",
  "INVESTMENT_ENTITY",
  "INSURANCE_COMPANY",
] as const;

const financialInstitutionTypeSchema = z.enum(FINANCIAL_INSTITUTION_TYPE);

export type FinancialInstitutionType = z.infer<
  typeof financialInstitutionTypeSchema
>;

export const ACTIVE_NON_FINANCIAL_ENTITY_TYPE = [
  "GOVERNMENT_ENTITY",
  "INTERNATIONAL_ORGANIZATION",
  "CENTRAL_BANK",
  "PUBLIC_CORPORATION",
  "OTHER",
] as const;

const activeNonFinancialEntityTypeSchema = z.enum(
  ACTIVE_NON_FINANCIAL_ENTITY_TYPE
);

export type ActiveNonFinancialEntityType = z.infer<
  typeof activeNonFinancialEntityTypeSchema
>;

export const ENTITY_TYPE = ["TRUST", "SIMILAR_TO_TRUST", "OTHER"] as const;

export const entityTypeSchema = z.enum(ENTITY_TYPE);

export type EntityType = z.infer<typeof entityTypeSchema>;

const CONTROLLING_PERSON_ROLE = [
  "SETTLOR",
  "TRUSTEE",
  "PROTECTOR",
  "BENEFICIARY",
  "OTHER",
  "SETTLOR_EQUIVALENT",
  "TRUSTEE_EQUIVALENT",
  "PROTECTOR_EQUIVALENT",
  "BENEFICIARY_EQUIVALENT",
  "OTHER_EQUIVALENT",
  "OWNER",
  "OTHER_MEANS",
  "SENIOR_MANAGING_OFFICIAL",
] as const;

const controllingPersonRoleSchema = z.enum(CONTROLLING_PERSON_ROLE);

export type ControllingPersonRole = z.infer<typeof controllingPersonRoleSchema>;

// in camel case
export const controllingPersonSchema = z.object({
  name: z.string().optional(),
  role: controllingPersonRoleSchema.optional(),
  roleOther: z.string().optional(),
  ownershipPercentage: z.number().optional(),
  countryOfCitizenship: z.enum(countryCodes).optional(),
  dateOfBirth: z.string().optional(),
  cityOfBirth: z.string().optional(),
  countryOfBirth: z.enum(countryCodes).optional(),
  address: unrefinedAddressSchema.optional(),
  mailingAddressIsDifferent: z.boolean().optional(),
  mailingAddress: unrefinedAddressSchema.optional(),
  taxResidences: taxResidenceSchema.array().optional(),
});

export type ControllingPerson = z.infer<typeof controllingPersonSchema>;

export const SIGNATURE_CAPACITY = [
  "OFFICER",
  "EXECUTOR",
  "OTHER_CAPACITY",
] as const;

const signatureCapacitySchema = z.enum(SIGNATURE_CAPACITY);

export type SignatureCapacity = z.infer<typeof signatureCapacitySchema>;

// in camel case
export const selfCertificationSchema = z.object({
  documentType: z.literal(KycTaxDocumentType.SelfCertification),
  name: z.string().optional(),
  address: unrefinedAddressSchema.optional(),
  mailingAddressIsDifferent: z.boolean().optional(),
  mailingAddress: unrefinedAddressSchema.optional(),
  isIndividual: z.boolean().optional(),
  taxResidences: taxResidenceSchema.array().optional(),
  signature: z.string().optional(),
  signatureDate: z.string().optional(),
  hasCertified: z.boolean().optional(),

  // individual specific fields
  dateOfBirth: z.string().optional(),
  cityOfBirth: z.string().optional(),
  countryOfBirth: z.enum(countryCodes).optional(),

  // entity specific fields
  countryOfCitizenship: z.enum(countryCodes).optional(),
  selfCertificationAccountType: selfCertificationAccountTypeSchema.optional(),
  financialInstitutionType: financialInstitutionTypeSchema.optional(),
  activeNonFinancialEntityType: activeNonFinancialEntityTypeSchema.optional(),
  investmentEntityManaged: z.boolean().optional(),
  entityType: entityTypeSchema.optional(),
  securitiesMarket: z.string().optional(),
  relatedCorporation: z.string().optional(),
  controllingPersons: controllingPersonSchema.array().optional(),
  signatureCapacity: signatureCapacitySchema.optional(),
  signatureCapacityOther: z.string().optional(),
});

export const selfCertificationWithUuidSchema = selfCertificationSchema.extend({
  id: uuidSchema,
  createdAt: z.string(),
});

export type KycTaxDocumentationAddress = z.infer<typeof unrefinedAddressSchema>;

const w9TaxDocumentationWithUuidSchema =
  unrefinedW9TaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

export const TYPE_OF_INCOME = [
  "ROYALTIES_OTHER",
  "SERVICES",
  "BUSINESS_PROFITS",
] as const;

export const LIMITATION_ON_BENEFITS = [
  "GOVERNMENT",
  "TAX_EXEMPT_PENSION",
  "OTHER_TAX_EXEMPT_ORGANIZATION",
  "PUBLICLY_TRADED_CORPORATION",
  "SUBSIDIARY",
  "COMPANY_MEETS_EROSION_TEST",
  "COMPANY_MEETS_DERIVATIVE_TEST",
  "COMPANY_MEETS_BUSINESS_TEST",
  "FAVORABLE_DETERMINATION",
  "NO_LOB_ARTICLE",
  "OTHER_ARTICLE_PARAGRAPH",
] as const;

const typeOfIncomeSchema = z.enum(TYPE_OF_INCOME);

export type TypeOfIncome = z.infer<typeof typeOfIncomeSchema>;

const limitationOnBenefitsSchema = z.enum(LIMITATION_ON_BENEFITS);

export type LimitationOnBenefits = z.infer<typeof limitationOnBenefitsSchema>;

const w8BenTaxDocumentationWithUuidSchema =
  unrefinedW8BenTaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
    treatyClaimIsEligible: z.boolean().optional(),
    treatyClaimCountry: z.string().optional(),
    treatyClaimICertifyResident: z.literal(true).optional(),
    treatyClaimICertifyRequirements: z.literal(true).optional(),
    treatyClaimTypeOfIncome: typeOfIncomeSchema.optional(),
    treatyClaimRateOfWithholding: z.string().optional(),
    treatyClaimArticleParagraph: z.string().optional(),
    treatyClaimHasAdditionalConditions: z.boolean().optional(),
  });

const w8BenETaxDocumentationWithUuidSchema =
  unrefinedW8BenETaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
    treatyClaimIsEligible: z.boolean().optional(),
    treatyClaimCountry: z.string().optional(),
    treatyClaimICertifyResident: z.literal(true).optional(),
    treatyClaimICertifyRequirements: z.literal(true).optional(),
    treatyClaimLimitationOnBenefits: limitationOnBenefitsSchema.optional(),
    treatyClaimLimitationOnBenefitsOtherArticleParagraph: z.string().optional(),
    treatyClaimTypeOfIncome: typeOfIncomeSchema.optional(),
    treatyClaimRateOfWithholding: z.string().optional(),
    treatyClaimArticleParagraph: z.string().optional(),
    treatyClaimHasAdditionalConditions: z.boolean().optional(),
  });

export const W8IMY_TAX_CLASSIFICATIONS = [
  "QUALIFIED_INTERMEDIARY",
  "NONQUALIFIED_INTERMEDIARY",
  "TERRITORY_FINANCIAL_INSTITUTION",
  "US_BRANCH",
  "WITHHOLDING_FOREIGN_PARTNERSHIP",
  "WITHHOLDING_FOREIGN_TRUST",
  "NONWITHHOLDING_FOREIGN_PARTNERSHIP",
  "NONWITHHOLDING_FOREIGN_SIMPLE_TRUST",
  "NONWITHHOLDING_FOREIGN_GRANTOR_TRUST",
] as const;

export const QDD_CLASSIFICATION = [
  "CORPORATION",
  "PARTNERSHIP",
  "DISREGARDED_ENTITY",
] as const;

const w8ImySchema = unrefinedW8BenETaxDocumentationSchema.extend({
  accountOwnerType: z.literal("INTERMEDIARY").optional(),
  taxClassification: z.enum(W8IMY_TAX_CLASSIFICATIONS).optional(),
  mailingAddressIsDifferent: z.boolean().optional(),
  documentType: z.literal(KycTaxDocumentType.W8Imy),
  isQdd: z.boolean().optional(),
  qddClassification: z.enum(QDD_CLASSIFICATION).optional(),
  assumesChapters3And4Responsibility: z.boolean().optional(),
  assumes1099Responsibility: z.boolean().optional(),
  providesAdditionalDocumentation: z.boolean().optional(),
  usPersonElection: z.boolean().optional(),
  isQiCertified: z.boolean().optional(),
  assumesChapters3And4ResponsibilityCertified: z.boolean().optional(),
  assumes1099ResponsibilityCertified: z.boolean().optional(),
  notAssume1099ResponsibilityCertified: z.boolean().optional(),
  meetsQiRegulationsRequirementsCertified: z.boolean().optional(),
  obtainsDocumentationCertified: z.boolean().optional(),
  meetsQddRequirementsCertified: z.boolean().optional(),
  notActingAsQiCertified: z.boolean().optional(),
  transmitsNqiDocumentationCertified: z.boolean().optional(),
  meetsNqiRegulationsRequirementsCertified: z.boolean().optional(),
  representsWithholdingStatementsCertified: z.boolean().optional(),
  isFinancialInstitutionCertified: z.boolean().optional(),
  tfiUsPersonElectionCertified: z.boolean().optional(),
  transmitsTfiDocumentationCertified: z.boolean().optional(),
  usbPaymentsCertified: z.boolean().optional(),
  usbUsPersonElectionCertified: z.boolean().optional(),
  transmitsUsbDocumentationCertified: z.boolean().optional(),
  isCompliantCertified: z.boolean().optional(),
  entityPaymentsCertified: z.boolean().optional(),
  providesWithholdingStatementCertified: z.boolean().optional(),
});

const w8ImyTaxDocumentationWithUuidSchema = w8ImySchema.extend({
  id: uuidSchema,
  createdAt: z.string(),
});

const dpsWithUuidSchema = dpsSchema.extend({
  id: uuidSchema,
  createdAt: z.string(),
});

export const taxDocumentationWithUuidSchema = z.discriminatedUnion(
  "documentType",
  [
    w9TaxDocumentationWithUuidSchema,
    w8BenTaxDocumentationWithUuidSchema,
    w8BenETaxDocumentationWithUuidSchema,
    w8ImyTaxDocumentationWithUuidSchema,
    dpsWithUuidSchema,
    selfCertificationWithUuidSchema,
  ]
);

export type W9TaxDocumentation = z.infer<
  typeof w9TaxDocumentationWithUuidSchema
>;

export type W8BenTaxDocumentation = z.infer<
  typeof w8BenTaxDocumentationWithUuidSchema
>;

export type W8BenETaxDocumentation = z.infer<
  typeof w8BenETaxDocumentationWithUuidSchema
>;

export type W8ImyTaxDocumentation = z.infer<
  typeof w8ImyTaxDocumentationWithUuidSchema
>;

export type KycTaxDocumentation = z.infer<
  typeof taxDocumentationWithUuidSchema
>;
