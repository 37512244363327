import { isTestEnv } from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";
import { z } from "zod";

import newlineTextBasedFilterToArray from "./newlineTextBasedFilterToArray";

export const getTextAreaFilterMaxItems = () =>
  // We limit the number of text based filter items to 3 in test environment to avoid performance issues
  // Typing in 10k+ text based filter items in the filter will be slow
  isTestEnv() ? 3 : 10_000;

export const getTextAreaFilterMaxCharLength = () =>
  // We limit the number of text area filter items to 3 in test environment to avoid performance issues
  // Typing in 500k chars in the filter will be slow
  // Per ticket, limiting total chars to 500,000
  // https://taxbit.atlassian.net/browse/TAX-41578
  isTestEnv() ? 3 : 500_000;

export const useCreateTextAreaFilterSchema = (label: string): z.ZodSchema => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return useMemo(() => {
    return z.string().superRefine((textFilter, ctx) => {
      const textFilterArray = newlineTextBasedFilterToArray(textFilter);

      if (textFilterArray.length > getTextAreaFilterMaxItems()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Count of ${label} cannot exceed ${formatWholeQuantity(getTextAreaFilterMaxItems())}. Please shorten your input and try again.`,
        });
      }

      const charCount = textFilterArray.reduce(
        (acc, curr) => acc + curr.length,
        0
      );

      if (charCount > getTextAreaFilterMaxCharLength()) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Characters cannot exceed the ${formatWholeQuantity(getTextAreaFilterMaxCharLength())} character limit. Please shorten your input and try again.`,
        });
      }
    });
  }, [label, formatWholeQuantity]);
};
