import {
  KycTaxDocumentType,
  KycTaxDocumentation,
  W8BenETaxDocumentation,
  W8BenTaxDocumentation,
  W8ImyTaxDocumentation,
  W9TaxDocumentation,
} from "@taxbit-dashboard/rest";

export const isWForm = (
  doc?: KycTaxDocumentation
): doc is
  | W9TaxDocumentation
  | W8BenTaxDocumentation
  | W8BenETaxDocumentation
  | W8ImyTaxDocumentation =>
  doc?.documentType === KycTaxDocumentType.W9 ||
  doc?.documentType === KycTaxDocumentType.W8Ben ||
  doc?.documentType === KycTaxDocumentType.W8BenE ||
  doc?.documentType === KycTaxDocumentType.W8Imy;
