import {
  AuthorizedView,
  DashboardNavigationItem,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import FilerSetup from "./FilerSetup";
import { FilerSetupModalProvider } from "./useFilerSetupModalContext";

export const filerSetupNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.FilerSettings,
  iconName: "tool",
  label: "Filer Setup",
  key: "filer-setup",
  trackingId: "sidebar-navigation-filer-setup",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadPayers] && flags.hasFilerSetupAccess,
  shouldDisableRoute: ({ flags }) => !flags.hasFilerSetupAccess,
} as const;

const FilerSetupPage = () => {
  return (
    <AuthorizedView flags={["hasFilerSetupAccess"]}>
      <FilerSetupModalProvider>
        <FilerSetup />
      </FilerSetupModalProvider>
    </AuthorizedView>
  );
};

export default FilerSetupPage;
