import { dashboardFormTypeSchema } from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { O } from "ts-toolbelt";
import { z } from "zod";

const formTypeAndDateParamsSchema = z.object({
  formType: dashboardFormTypeSchema.optional(),
  formYear: fourDigitYearSchema.optional(),
});

export type FormTypeAndDateParams = z.infer<typeof formTypeAndDateParamsSchema>;

export type ParamsWithDefinedFormTypeAndDate<T extends FormTypeAndDateParams> =
  O.Required<T, "formType" | "formYear">;

export default formTypeAndDateParamsSchema;
