import { RouteId } from "@taxbit-dashboard/router";

import { useGetFormItems } from "../../api/form-items/formItemsApi";
import useLegacyFormFeatureFlagData from "../information-reporting/shared/form-year-dropdowns/useLegacyFormFeatureFlagData";
import useUrlParamsWithFormTypeAndYear from "../information-reporting/shared/form-year-dropdowns/useUrlParamsWithFormTypeAndYear";

const useFormItems = () => {
  const featureFlagData = useLegacyFormFeatureFlagData({
    shouldUseFormItemsForms: true,
  });

  const { urlParams, updateUrlParams, ...formDropdownData } =
    useUrlParamsWithFormTypeAndYear({
      featureFlagData,
      shouldUseFormItemsForms: true,
      routeId: RouteId.AccountDetailsFormDataTab,
    });

  const query = useGetFormItems(urlParams);

  return {
    ...formDropdownData,
    ...query,
    formItems: query.data?.formItems,
    isNewDataLoading: query.isPreviousData,
    updateUrlParams,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
    urlParams,
  };
};

export default useFormItems;
