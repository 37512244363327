import { FallbackView } from "@taxbit-dashboard/router";

import useCallbackAuth0Error from "./useCallbackAuth0Error";
import AppLevelSpinner from "../app/navigation/AppLevelSpinner";

/**
 * This component receives callback state from Auth0 and displays appropriate error UI
 * if we hit an Auth0 failure. If no error is present, this component just displays a
 * spinner until the Auth0 redirect (set up in `AuthProvider.tsx`) completes.
 */
const Callback: React.FC = () => {
  const errorFallbackUiProps = useCallbackAuth0Error();

  return errorFallbackUiProps ? (
    <FallbackView {...errorFallbackUiProps} />
  ) : (
    <AppLevelSpinner trackingId="callback-spinner" />
  );
};

export default Callback;
