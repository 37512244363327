import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  createSingleInstanceHookContext,
  readonlyIncludes,
  useDashboardStore,
  useLocalPageBanner,
} from "@taxbit-dashboard/commons";
import { EligibilityDataItem, EligibilityStatus } from "@taxbit-dashboard/rest";
import {
  RouteId,
  IrEligibilityUrlParams,
  PageLimitPaginationParams,
} from "@taxbit-dashboard/router";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo, useState } from "react";
import { O } from "ts-toolbelt";

import {
  OverrideMutationResponse,
  useCreateEligibilityQaPackage,
  useGetEligibilityAlerts,
  useGetEligibilityCounts,
  useGetEligibilityData,
  useOverrideAccountsEligibility,
} from "../../../../api/information-reporting/eligible-users/eligibilityApi";
import {
  OverrideEligibilityAction,
  eligibilityEnumParamKeys,
} from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useGenerateTaxForms } from "../../../../api/information-reporting/forms/formsApi";
import useLegacyFormFeatureFlagData from "../../shared/form-year-dropdowns/useLegacyFormFeatureFlagData";
import useUrlParamsWithFormTypeAndYear from "../../shared/form-year-dropdowns/useUrlParamsWithFormTypeAndYear";
import getMissingAccountsToastContent from "../../shared/getMissingAccountsToastContent";
import {
  actionToBannerVerbMap,
  actionToPrepositionMap,
  getOverrideErroredToastContent,
  getOverrideSucceededToastContent,
} from "../remove-from-scope/overrideEligibilityNotifications";
import useEligibilityTableColumns from "../table/useEligibilityTableColumns";

enum EligibilityDataTableTrackingId {
  CosmicTable = "eligibility-data-table",
}

const useEligibilityData = () => {
  const [selectedRows, setSelectedRows] = useState<EligibilityDataItem[]>([]);
  const [shouldShowMissingAccountWarning, setShouldShowMissingAccountWarning] =
    useState(true);

  // We allow up to 10k account IDs in this filter
  // Therefore we can't put it directly in the URL and we need to keep in in a state
  const [accountExternalIdsFilter, setAccountExternalIdsFilter] =
    useState<string>("");

  const addToast = useDashboardStore((state) => state.addToast);

  const clearAccountExternalIdsFilter = useCallback(() => {
    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter]);

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const featureFlagData = useLegacyFormFeatureFlagData();

  const {
    urlParams,
    updateUrlParams,
    updateFilterParams: updateFilterParamsOriginal,
    setUrlParams,
    ...formYearDropdownProps
  } = useUrlParamsWithFormTypeAndYear({
    featureFlagData,
    routeId: RouteId.IrEligibility,
    onFormChangeCallback: clearAccountExternalIdsFilter,
    onYearChangeCallback: clearAccountExternalIdsFilter,
  });

  const { formYear, formType } = urlParams;

  const updateFilterParams = useCallback(
    (
      params: Partial<
        O.Exclude<IrEligibilityUrlParams, PageLimitPaginationParams>
      >
    ) => {
      setShouldShowMissingAccountWarning(true);
      void updateFilterParamsOriginal(params);
    },
    [updateFilterParamsOriginal]
  );

  const updatePaginationParams = useCallback(
    async (params: Partial<PageLimitPaginationParams>) => {
      // We don't want to show warning on pagination change
      setShouldShowMissingAccountWarning(false);
      return updateUrlParams(params);
    },
    [updateUrlParams]
  );

  const clearAllFilters = useCallback(async () => {
    const { formYear, formType, page, limit } = urlParams;

    await setUrlParams({
      formYear,
      formType,
      page,
      limit,
      /**
       * We represent an intentionally empty statuses filter as "None" so that we
       * can safely default this search param on a fresh page load (i.e. when the
       * value is `undefined`).
       */
      statuses: "None",
    });

    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter, setUrlParams, urlParams]);

  const filterDrawerFilterCount = useMemo(() => {
    const urlFilterCount = Object.keys(urlParams).reduce((count, key) => {
      if (key === "statuses" && urlParams[key] !== "None") {
        const statusCount = urlParams[key]?.length ?? 0;
        return count + statusCount;
      } else if (
        readonlyIncludes(eligibilityEnumParamKeys, key) &&
        urlParams[key]
      ) {
        const selectedOptionCount = urlParams[key]?.length ?? 0;
        return count + selectedOptionCount;
      } else {
        return count;
      }
    }, 0);

    if (accountExternalIdsFilter.length > 0) {
      return urlFilterCount + 1;
    }

    return urlFilterCount;
  }, [urlParams, accountExternalIdsFilter]);

  const {
    isLoading: isEligibilityDataLoading,
    data: eligibilityData,
    meta: eligibilityDataMeta,
    isPreviousData: isEligibilityPreviousData,
    isError: isEligibilityDataError,
  } = useGetEligibilityData({
    ...urlParams,
    accountExternalIdsFilter,
    onMissingAccounts: (requestIdsLength, totalAccountsNotFound) => {
      if (shouldShowMissingAccountWarning) {
        addToast(
          getMissingAccountsToastContent(
            formatWholeQuantity(totalAccountsNotFound),
            formatWholeQuantity(requestIdsLength)
          )
        );
      }
    },
  });

  const { data: eligibilityAlerts, isError: isEligibilityAlertsError } =
    useGetEligibilityAlerts({
      formYear,
      formType,
    });

  const {
    data: eligibilityCounts,
    isLoading: isEligibilityCountsLoading,
    isError: isEligibilityCountsError,
  } = useGetEligibilityCounts({
    formYear,
    formType,
  });

  const isLoading = isEligibilityCountsLoading || isEligibilityDataLoading;

  const items = useMemo(
    () => eligibilityData?.items ?? [],
    [eligibilityData?.items]
  );

  const onOverrideSettle = useCallback(
    (action: OverrideEligibilityAction) =>
      (data?: OverrideMutationResponse) => {
        const errorCount = data?.errorCount ?? 0;
        const totalCount = data?.totalCount ?? 0;
        if (errorCount > 0) {
          addToast(
            getOverrideErroredToastContent(errorCount, totalCount, action)
          );
        } else {
          addToast(getOverrideSucceededToastContent(action));
        }
      },
    [addToast]
  );

  const { mutate: handleAddAccountsToScope } = useOverrideAccountsEligibility({
    formYear,
    formType,
    onSettled: onOverrideSettle(OverrideEligibilityAction.Add),
    action: OverrideEligibilityAction.Add,
  });

  const { mutate: handleRemoveAccountsFromScope } =
    useOverrideAccountsEligibility({
      formYear,
      formType,
      onSettled: onOverrideSettle(OverrideEligibilityAction.Remove),
      action: OverrideEligibilityAction.Remove,
    });

  const isAddingAccountsToScope =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityOverride,
        { formYear, formType, action: OverrideEligibilityAction.Add },
      ],
    }) > 0;

  const isRemovingAccountsFromScope =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityOverride,
        { formYear, formType, action: OverrideEligibilityAction.Remove },
      ],
    }) > 0;

  const { mutate: createEligibilityPackage, isLoading: isCreatingQaPackage } =
    useCreateEligibilityQaPackage({
      formYear,
      formType,
    });

  const { mutate: generateTaxForms } = useGenerateTaxForms(urlParams);

  const isGeneratingTaxForms =
    useIsMutating({
      mutationKey: [DashboardMutationKey.IrFormsBatch, { formYear, formType }],
    }) > 0;

  const shouldDisableTableControls =
    isEligibilityPreviousData || isEligibilityDataLoading;

  const shouldDisableControls =
    isRemovingAccountsFromScope ||
    isAddingAccountsToScope ||
    isCreatingQaPackage ||
    isGeneratingTaxForms ||
    shouldDisableTableControls;

  const columns = useEligibilityTableColumns({ formType });
  const memoizedGetRowKey = useCallback(
    ({ accountId }: EligibilityDataItem) => accountId,
    []
  );

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const irEligibilityTableProps = useCosmicTable({
    trackingId: EligibilityDataTableTrackingId.CosmicTable,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    rows: items,
    columns,
    onRowSelect: setSelectedRows,
    isLoading: shouldDisableTableControls,
    getIsRowSelectionDisabled: (row) =>
      row.status === EligibilityStatus.Pending,
    emptyStateProps: isEligibilityDataError
      ? getEnUsErrorEmptyStateProps({ entity: "eligibility data" })
      : searchEmptyStateProps,
  });

  useLocalPageBanner({
    shouldShow: isAddingAccountsToScope || isRemovingAccountsFromScope,
    bannerConfig: {
      shouldShowSpinner: true,
      message: (() => {
        const action = isAddingAccountsToScope
          ? OverrideEligibilityAction.Add
          : OverrideEligibilityAction.Remove;

        return `We are ${actionToBannerVerbMap[action]} accounts ${actionToPrepositionMap[action]} scope. Please wait a few moments.`;
      })(),
    },
  });
  useLocalPageBanner({
    shouldShow: isGeneratingTaxForms,
    bannerConfig: {
      shouldShowSpinner: true,
      message:
        "We are preparing to generate the tax forms. Please wait a few moments.",
    },
  });

  return {
    ...formYearDropdownProps,
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
    totalCount: eligibilityDataMeta?.page?.totalCount ?? 0,
    currentForm: formType,
    currentYear: formYear,
    urlParams,
    updateFilterParams,
    updatePaginationParams,
    clearAllFilters,
    filterDrawerFilterCount,
    eligibilityAlerts,
    eligibilityCounts,
    isLoading,
    isEligibilityAlertsError,
    isEligibilityCountsError,
    eligibilityDataItems: items,
    isEligibilityDataError,
    isEligibilityDataLoading,
    shouldDisableControls,
    handleAddAccountsToScope,
    handleRemoveAccountsFromScope,
    selectedItems: selectedRows,
    irEligibilityTableProps,
    toggleAllSelectedItems:
      irEligibilityTableProps.tableInstance.toggleAllRowsSelected,
    createEligibilityPackage,
    generateTaxForms,
  };
};

export const {
  useContextHook: useEligibilityDataContext,
  Context: EligibilityDataContext,
  Provider: EligibilityDataContextProvider,
} = createSingleInstanceHookContext(useEligibilityData, "useEligibilityData");
