import {
  DirectRouteId,
  useAuthenticatedNavigation,
  useIsRouteIdActive,
} from "@taxbit-dashboard/router";
import {
  NavigationSidebarSectionProps,
  NavigationSidebar,
  NavigationItemProps,
  NavigationItemChildProps,
  NavigationItemWithChildrenProps,
} from "@taxbit-private/cosmic";
import React, { useMemo } from "react";
import { O } from "ts-toolbelt";

import { DashboardFeatureFlagSet } from "../feature-flags/dashboardFeatureFlagSetTypes";
import useDashboardFeatureFlags from "../feature-flags/useDashboardFeatureFlags";
import { UserPermissions } from "../store/auth/authSliceModels";
import useDashboardStore from "../store/useDashboardStore";

export type DashboardNavigationItem = O.Omit<
  NavigationItemProps,
  "isActive" | "href"
> & {
  routeId: DirectRouteId;
  shouldShowRoute?: (args: {
    flags: DashboardFeatureFlagSet;
    permissions: UserPermissions;
  }) => boolean;
  shouldDisableRoute?: (args: { flags: DashboardFeatureFlagSet }) => boolean;
};

export type DashboardNavigationItemChild = O.Omit<
  NavigationItemChildProps,
  "isActive" | "href"
> & {
  routeId: DirectRouteId;
  shouldShowRoute?: (args: {
    flags: DashboardFeatureFlagSet;
    permissions: UserPermissions;
  }) => boolean;
  shouldDisableRoute?: (args: { flags: DashboardFeatureFlagSet }) => boolean;
};

export type DashboardNavigationItemWithChildren = O.Omit<
  NavigationItemWithChildrenProps,
  "children"
> & {
  children: readonly DashboardNavigationItemChild[];
  shouldShowRoute?: (args: {
    flags: DashboardFeatureFlagSet;
    permissions: UserPermissions;
  }) => boolean;
};

const isItemWithChildren = (
  item: DashboardNavigationItem | DashboardNavigationItemWithChildren
): item is DashboardNavigationItemWithChildren => !!item.children;

const disabledRouteBadgeProps = {
  label: "Coming Soon",
  variant: "secondary",
} as const;

export type NavigationSidebarSection = O.Update<
  NavigationSidebarSectionProps,
  "items",
  readonly (DashboardNavigationItem | DashboardNavigationItemWithChildren)[]
>;

type Props = {
  sections: readonly NavigationSidebarSection[];
};

const LeftNavigation: React.FC<Props> = ({ sections }) => {
  const { authenticatedNavigate } = useAuthenticatedNavigation();
  const isRouteIdActive = useIsRouteIdActive();
  const flags = useDashboardFeatureFlags();
  const permissions = useDashboardStore((store) => store.userPermissions);

  // This navigation component is meant to be used only in authenticated views,
  // so we shouldn't run across a situation where permissions are undefined.
  // We are (mostly) just doing this to appease Typescript.
  if (!permissions) {
    throw new Error("User permissions have not yet been set.");
  }

  const completeNavigationSections = useMemo(() => {
    const mapToNavigationItem = ({
      routeId,
      onClick,
      ...item
    }: DashboardNavigationItem | DashboardNavigationItemChild) => ({
      isActive: isRouteIdActive(routeId),
      onClick:
        onClick ??
        (() => {
          void authenticatedNavigate({ to: routeId, shouldScrollToTop: true });
        }),
      ...item,
    });

    const transformedSections = sections.map(({ items, ...rest }) => {
      // Filter out individual items that we shouldn't show.
      const completeItems = items
        .filter(({ shouldShowRoute = () => true }) =>
          shouldShowRoute({ flags, permissions })
        )
        .map(({ shouldShowRoute, ...item }) => {
          if (isItemWithChildren(item)) {
            return {
              ...item,
              children: item.children
                // Filter out children that we shouldn't show.
                .filter(
                  ({ shouldShowRoute: shouldShowChildRoute = () => true }) =>
                    shouldShowChildRoute({ flags, permissions })
                )
                .map(
                  ({
                    shouldShowRoute: shouldShowChildRoute,
                    shouldDisableRoute: shouldDisableChildRoute = () => false,
                    ...child
                  }) => {
                    const isDisabled = shouldDisableChildRoute({ flags });

                    return mapToNavigationItem({
                      ...child,
                      isDisabled,
                      badgeProps: isDisabled
                        ? disabledRouteBadgeProps
                        : undefined,
                    } as DashboardNavigationItemChild);
                  }
                ),
            };
          } else {
            const { shouldDisableRoute = () => false, ...restProps } =
              item as DashboardNavigationItem;

            const isDisabled = shouldDisableRoute({ flags });

            return mapToNavigationItem({
              ...restProps,
              isDisabled,
              badgeProps: isDisabled ? disabledRouteBadgeProps : undefined,
            } as DashboardNavigationItem);
          }
        })
        // Filter out individual item that has no children after route filtering.
        // Since we can have routes with and without children, we need to filter out
        // based on the children array length. If children prop is present
        // we assume that at least one child should be present.
        .filter(
          (item) =>
            !item.children ||
            (Array.isArray(item.children) && item.children.length > 0)
        );

      return { ...rest, items: completeItems };
    });

    // Filter out any sections that now would show no items.
    return transformedSections.filter(({ items }) => items.length > 0);
  }, [flags, isRouteIdActive, permissions, authenticatedNavigate, sections]);

  return (
    <NavigationSidebar
      sections={completeNavigationSections as NavigationSidebarSectionProps[]}
    />
  );
};

export default LeftNavigation;
