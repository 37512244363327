import {
  useDeletePayer,
  useDashboardStore,
  useUpdatePayer,
  doesResponseErrorMatchStatusCode,
} from "@taxbit-dashboard/commons";
import { Payer } from "@taxbit-dashboard/rest";
import { ActionMenu } from "@taxbit-private/cosmic";
import { useCallback, useState } from "react";

import FilerSetupTableDeleteModal from "./FilerSetupTableDeleteModal";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import { useFilerSetupModalContext } from "../useFilerSetupModalContext";

export enum FilerSetupTableActionMenuTrackingId {
  Menu = "filer-setup-table-action-menu",
  EditButton = "filer-setup-table-action-menu-edit-button",
  SetAsDefaultButton = "filer-setup-table-action-menu-set-as-default-button",
  DeleteButton = "filer-setup-table-action-menu-delete-button",
  DeleteSuccessToast = "filer-setup-table-action-menu-delete-success-toast",
  DeleteConflictErrorToast = "filer-setup-table-action-menu-delete-conflict-error-toast",
  DeleteServerErrorToast = "filer-setup-table-action-menu-delete-server-error-toast",
  DefaultSuccessToast = "filer-setup-table-action-menu-default-success-toast",
  DefaultErrorToast = "filer-setup-table-action-menu-default-error-toast",
}

type Props = {
  payer: Payer;
};

const FilerSetupTableRowActionMenu: React.FC<Props> = ({ payer }) => {
  const { mutate: deletePayer, isLoading } = useDeletePayer();
  const { mutate: setDefaultPayer } = useUpdatePayer();
  const addToast = useDashboardStore((store) => store.addToast);
  const { handleModalOpen } = useFilerSetupModalContext();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDeleteClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleConfirmedDelete = useCallback(() => {
    const successMessage = `${payer.payerName} has been deleted successfully.`;
    const conflictErrorMessage =
      "Default Filers or Filers with associated accounts may not be deleted.";
    const serverErrorMessage =
      "An unexpected error occurred. Please try again later.";

    const toastTimeout = TOAST_TIMEOUT;
    const primaryVariant = "primary";
    const dangerVariant = "danger";

    deletePayer(payer.payerId, {
      onSuccess: () => {
        addToast({
          message: successMessage,
          variant: primaryVariant,
          trackingId: FilerSetupTableActionMenuTrackingId.DeleteSuccessToast,
          timeoutMs: toastTimeout,
        });
        setModalOpen(false);
      },
      onError: (error) => {
        const isConflictError = doesResponseErrorMatchStatusCode(error, 409);

        setModalOpen(false);

        if (isConflictError) {
          addToast({
            message: conflictErrorMessage,
            variant: dangerVariant,
            trackingId:
              FilerSetupTableActionMenuTrackingId.DeleteConflictErrorToast,
            timeoutMs: toastTimeout,
          });
        } else {
          addToast({
            message: serverErrorMessage,
            variant: dangerVariant,
            trackingId:
              FilerSetupTableActionMenuTrackingId.DeleteServerErrorToast,
            timeoutMs: toastTimeout,
          });
        }
      },
    });
  }, [addToast, deletePayer, payer]);

  const handleSetDefaultClick = useCallback(() => {
    setDefaultPayer(
      {
        payerId: payer.payerId,
        formInput: {
          data: {
            ...payer,
            isDefault: true,
          },
        },
      },
      {
        onSuccess: () => {
          addToast({
            message: `${payer.payerName} has been set as the Default Payer.`,
            variant: "primary",
            trackingId: FilerSetupTableActionMenuTrackingId.DefaultSuccessToast,
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onError: () => {
          addToast({
            message: "An unexpected error occurred. Please try again later.",
            variant: "danger",
            trackingId: FilerSetupTableActionMenuTrackingId.DefaultErrorToast,
            timeoutMs: TOAST_TIMEOUT,
          });
        },
      }
    );
  }, [addToast, setDefaultPayer, payer]);

  const items = [
    {
      onClick: () =>
        handleModalOpen({
          isOpen: true,
          payerToUpdate: payer,
        }),
      label: "Edit Filer",
      trackingId: FilerSetupTableActionMenuTrackingId.EditButton,
      iconName: "edit" as const,
    },
    {
      onClick: () => handleSetDefaultClick(),
      label: "Set as Default Filer",
      trackingId: FilerSetupTableActionMenuTrackingId.SetAsDefaultButton,
      iconName: "check-circle" as const,
    },
    {
      onClick: () => handleDeleteClick(),
      label: "Delete Filer",
      trackingId: FilerSetupTableActionMenuTrackingId.DeleteButton,
      iconName: "trash" as const,
    },
  ];

  return (
    <>
      <ActionMenu
        items={items}
        trackingId={`${FilerSetupTableActionMenuTrackingId.Menu}-${payer.payerId}`}
        shouldUsePortal={true}
      />
      <FilerSetupTableDeleteModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirmedDelete}
        payerName={payer.payerName}
        isLoading={isLoading}
      />
    </>
  );
};

export default FilerSetupTableRowActionMenu;
