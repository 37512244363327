import { AccountOwnerDetailsTaxDocumentationIssueType } from "@taxbit-dashboard/rest";

export type AccountOwnerDetailsCuringIssueType =
  | AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf
  | AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress
  | AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
  | AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch
  | AccountOwnerDetailsTaxDocumentationIssueType.WithholdingDocumentation;

const curingIssueTypes = new Set([
  AccountOwnerDetailsTaxDocumentationIssueType.W8beneCareOf,
  AccountOwnerDetailsTaxDocumentationIssueType.W8benePoBoxPermanentAddress,
  AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia,
  AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch,
  AccountOwnerDetailsTaxDocumentationIssueType.WithholdingDocumentation,
]);

export const isAccountOwnerDetailsCuringIssueType = (
  issueType: AccountOwnerDetailsTaxDocumentationIssueType
): issueType is AccountOwnerDetailsCuringIssueType =>
  curingIssueTypes.has(issueType as AccountOwnerDetailsCuringIssueType);
