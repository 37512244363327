import { Modal } from "@taxbit-private/cosmic";

import { useTransactionsTableContext } from "./useTransactionsTable";

export enum TransactionsExportWarningModalTrackingId {
  CloseButton = "transactions-export-warning-modal-close-button",
  CancelButton = "transactions-export-warning-modal-cancel-button",
  ConfirmButton = "transactions-export-warning-modal-confirm-button",
}

const TransactionsExportWarningModal: React.FC = () => {
  const {
    isWarningModalOpen,
    setIsWarningModalOpen,
    isCreatingExport,
    createTransactionsExport,
  } = useTransactionsTableContext();

  return (
    <Modal
      title="Export Size Warning"
      isOpen={isWarningModalOpen}
      onClose={() => setIsWarningModalOpen(false)}
      closeButtonTrackingId={
        TransactionsExportWarningModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        onClick: () => setIsWarningModalOpen(false),
        trackingId: TransactionsExportWarningModalTrackingId.CancelButton,
      }}
      primaryButtonProps={{
        label: "Confirm export",
        onClick: () => {
          setIsWarningModalOpen(false);
          createTransactionsExport();
        },
        isLoading: isCreatingExport,
        loadingText: "Exporting...",
        trackingId: TransactionsExportWarningModalTrackingId.ConfirmButton,
      }}
    >
      Your export will only contain the first 50 million rows. You can use
      filters to reduce the dataset size if needed.
    </Modal>
  );
};

export default TransactionsExportWarningModal;
