import { NotificationsTab } from "@taxbit-dashboard/router";
import {
  ContentSpinner,
  ContentErrorEmptyState,
  ContentEmptyState,
  Divider,
} from "@taxbit-private/cosmic";
import { useMemo, Fragment } from "react";

import NotificationsListItem from "./list-item/NotificationsListItem";
import useNotificationsContext, {
  NotificationsContext,
} from "./useNotificationsContext";

export enum NotificationsListContentsTrackingId {
  Spinner = "notifications-list-contents-spinner",
}

type Props = {
  context: NotificationsContext;
};

const NotificationsListContents: React.FC<Props> = ({ context }) => {
  const { currentTab, filteredNotifications, isLoading, isError } =
    useNotificationsContext(context);

  const emptyStateText = useMemo(() => {
    switch (currentTab) {
      case NotificationsTab.Read: {
        return "No read notifications right now";
      }
      case NotificationsTab.Unread: {
        return "No unread notifications right now";
      }
      default: {
        return "No notifications right now";
      }
    }
  }, [currentTab]);

  if (isLoading) {
    return (
      <ContentSpinner
        trackingId={NotificationsListContentsTrackingId.Spinner}
      />
    );
  } else if (isError) {
    return <ContentErrorEmptyState />;
  } else if (filteredNotifications.length === 0) {
    return (
      <ContentEmptyState
        title={emptyStateText}
        avatarProps={{
          iconName: "bell",
          variant: "secondary",
        }}
      />
    );
  } else {
    const truncatedNotifications =
      context === NotificationsContext.Center
        ? filteredNotifications.slice(0, 4)
        : filteredNotifications;

    return truncatedNotifications.map((notification, idx) => (
      <Fragment key={notification.notificationId}>
        <NotificationsListItem notification={notification} context={context} />
        {idx !== truncatedNotifications.length - 1 && <Divider />}
      </Fragment>
    ));
  }
};

export default NotificationsListContents;
