import {
  UsTaxIdType,
  AccountServiceTinType,
  ReportingProfileTinType,
} from "@taxbit-dashboard/rest";

const formatTaxId = (
  taxId?: string,
  taxIdType?: UsTaxIdType | AccountServiceTinType | ReportingProfileTinType
): string | undefined => {
  if (!taxId) {
    return undefined;
  }

  if (taxId.length !== 9) {
    return taxId;
  }

  const taxIdWithoutDashes = taxId.replaceAll("-", "");

  switch (taxIdType) {
    case AccountServiceTinType.UsEin:
    case "EIN": {
      return `${taxIdWithoutDashes.slice(0, 2)}-${taxIdWithoutDashes.slice(2)}`;
    }
    case AccountServiceTinType.UsSsn:
    case AccountServiceTinType.UsItin:
    case "SSN":
    case "ITIN": {
      return `${taxIdWithoutDashes.slice(0, 3)}-${taxIdWithoutDashes.slice(
        3,
        5
      )}-${taxIdWithoutDashes.slice(5)}`;
    }
    default: {
      return taxId;
    }
  }
};

export default formatTaxId;
