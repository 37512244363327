import { AccountId } from "@taxbit-dashboard/rest";
import { RouteId, useMatch } from "@taxbit-dashboard/router";

/**
 * Pulls the `accountId` param from the current account details route. If used
 * outside of an account details route, the `accountId` will be undefined.
 */
const useAccountId = () => {
  const match = useMatch({
    from: RouteId.AccountDetails,
    shouldThrow: false,
  });

  return match?.params.accountId as AccountId | undefined;
};

export default useAccountId;
