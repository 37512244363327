import { Dropdown } from "@taxbit-private/cosmic";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback } from "react";

import useLegacyFormFeatureFlagData from "../../information-reporting/shared/form-year-dropdowns/useLegacyFormFeatureFlagData";
import { useOverviewDataContext } from "../context/useOverviewData";

export enum FormsYearPickerTrackingId {
  DropdownToggle = "form-data-year-picker",
}

type Props = {
  paramKey: "eligibility" | "forms";
};

const FormsYearPicker: React.FC<Props> = ({ paramKey }) => {
  const { setParamsForKey, urlParams, formItemYears } =
    useOverviewDataContext();

  const { years } = useLegacyFormFeatureFlagData();

  const onChange = useCallback(
    (value: FourDigitYear) => {
      setParamsForKey(paramKey, { year: value });
    },
    [setParamsForKey, paramKey]
  );

  const yearOptions = paramKey === "eligibility" ? years : formItemYears;

  return (
    <Dropdown
      value={urlParams[paramKey].year}
      onChange={onChange}
      options={yearOptions}
      getOptionKey={(year) => year.toString()}
      getOptionLabel={(year) => year.toString()}
      trackingId={FormsYearPickerTrackingId.DropdownToggle}
      size="small"
      label="Form Year"
      isLabelHidden={true}
    />
  );
};

export default FormsYearPicker;
