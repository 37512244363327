import { TaxBitRest, getEnvironment } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import useGetAccessTokenAndUpdateAuthState from "./useGetAccessTokenAndUpdateAuthState";

const useTaxBitRest = () => {
  const { getAccessTokenAndUpdateAuthState } =
    useGetAccessTokenAndUpdateAuthState();

  return useMemo(
    () =>
      new TaxBitRest({
        getAccessToken: () => getAccessTokenAndUpdateAuthState(),
        environment: getEnvironment(),
      }),
    [getAccessTokenAndUpdateAuthState]
  );
};

export default useTaxBitRest;
