import {
  Box,
  Divider,
  Flex,
  Table,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";

import TransactionsFilterChips from "./filters/TransactionsFilterChips";
import TransactionsFilterDrawer from "./filters/TransactionsFilterDrawer";
import TransactionsTableSearchBar from "./filters/TransactionsTableSearchBar";
import TransactionsTableExportButton from "./TransactionsTableExportButton";
import TransactionsTablePagination from "./TransactionsTablePagination";
import { useTransactionsTableContext } from "./useTransactionsTable";
import { TransactionsTableTrackingId } from "./useTransactionsTableColumns";

export const TransactionsTable: React.FC = () => {
  const { tableProps, shouldDisableControls, totalCount } =
    useTransactionsTableContext();

  return (
    <Box trackingId={TransactionsTableTrackingId.TableContent}>
      <TrimmedBoxContent trim="all">
        <Flex padding="m" direction="column" gap="m">
          <Flex gap="s">
            <TransactionsFilterDrawer />
            <TransactionsTableSearchBar isDisabled={shouldDisableControls} />
            <TransactionsTableExportButton />
          </Flex>
          <TransactionsFilterChips />
        </Flex>
        <Divider />
        <Table {...tableProps} />
        <Divider />
        <TransactionsTablePagination
          isDisabled={shouldDisableControls}
          totalCount={totalCount}
        />
      </TrimmedBoxContent>
    </Box>
  );
};
