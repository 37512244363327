import { isTestEnv } from "@taxbit-dashboard/commons";
import { z } from "zod";

export const getAccountExternalIdsFilterMaxIdsLength = () =>
  // We limit the number of account IDs to 3 in test environment to avoid performance issues
  // Typing in 10k+ account IDs in the filter will be slow
  isTestEnv() ? 3 : 10_000;

export const getAccountExternalIdsFilterMaxIdsCharLength = () =>
  // We limit the number of account IDs to 3 in test environment to avoid performance issues
  // Typing in 500k chars in the filter will be slow
  // Per ticket, limiting total chars to 500,000
  // https://taxbit.atlassian.net/browse/TAX-41578
  isTestEnv() ? 3 : 500_000;

export const accountExternalIdsFilterSchema = z
  .string()
  .transform((val) => val.trim());
