import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  DashboardQueryKey,
  createQueryMetaObject,
  useDashboardStore,
  navigateToUrl,
  useGetAccessTokenAndUpdateAuthState,
  sleep,
} from "@taxbit-dashboard/commons";
import {
  camelCaseKeys,
  NotificationActionType,
  NotificationCategory,
  NotificationType,
  getCurrentWebsocketHost,
  notificationSchema,
} from "@taxbit-dashboard/rest";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "@taxbit-dashboard/router";
import { Uuid } from "@taxbit-private/uuids";
import { useCallback, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

import getNotificationsParams, {
  NotificationParams,
} from "./getNotificationsParams";
import notificationQueryMap from "./notificationQueryMap";

const useGetNotifications = (params: NotificationParams) => {
  const restSdk = useTaxBitRest();

  return unwrapPublicApiWrappedQuery(
    useQuery(
      [DashboardQueryKey.Notifications, { ...params }],
      () => restSdk.notifications.get(getNotificationsParams(params)),
      {
        ...createQueryMetaObject(restSdk.notifications.buildPath()),
      }
    )
  );
};

export const useGetNotificationsByCategory = (
  params: NotificationsPageParams
) => {
  const {
    data: allNotifications = [],
    meta: allMeta,
    isLoading: isLoadingAllNotifications,
    isError: isErrorAllNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.All,
  });

  const allCount = allMeta?.page?.totalCount ?? 0;

  const {
    data: unreadNotifications = [],
    meta: unreadMeta,
    isLoading: isLoadingUnreadNotifications,
    isError: isErrorUnreadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Unread,
  });

  const unreadCount = unreadMeta?.page?.totalCount ?? 0;

  const {
    data: readNotifications = [],
    meta: readMeta,
    isLoading: isLoadingReadNotifications,
    isError: isErrorReadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Read,
  });

  const readCount = readMeta?.page?.totalCount ?? 0;

  return {
    allNotifications,
    allCount,
    unreadNotifications,
    unreadCount,
    readNotifications,
    readCount,
    isLoading:
      isLoadingAllNotifications ||
      isLoadingUnreadNotifications ||
      isLoadingReadNotifications,
    isError:
      isErrorAllNotifications ||
      isErrorUnreadNotifications ||
      isErrorReadNotifications,
  };
};

export const useGetProgressNotifications = () => {
  const {
    data: activeJobs = [],
    isLoading,
    isError,
  } = useGetNotifications({
    type: NotificationType.Progress,
    page: 0,
    limit: 25,
  });

  return {
    activeJobs,
    isLoading,
    isError,
  };
};

export const useMarkAllNotificationsAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => restSdk.notifications.put(),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useMarkNotificationAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: Uuid) =>
      restSdk.notifications.read.post(notificationId),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useFetchNotificationDownloadUrl = (notificationId: Uuid) => {
  const restSdk = useTaxBitRest();
  const addToast = useDashboardStore((store) => store.addToast);

  const { refetch: fetchNotification, isFetching: isFetchingDownloadUrl } =
    useQuery(
      [DashboardQueryKey.Notification, { notificationId }],
      () => restSdk.notifications.notification.get(notificationId),
      {
        ...createQueryMetaObject(
          restSdk.notifications.notification.buildPath(notificationId)
        ),
        // We only want to fetch a fresh version of this notification on button click,
        // so we disable the query from running outside of the 'refetch' call.
        enabled: false,
      }
    );

  const fetchNotificationDownloadUrl = useCallback(async () => {
    const { data: notification } = unwrapPublicApiWrappedQuery(
      await fetchNotification()
    );

    const downloadAction = notification?.actions?.find(
      ({ type }) => type === NotificationActionType.Download
    );

    if (downloadAction?.actionUrl) {
      navigateToUrl(downloadAction.actionUrl);
    } else {
      addToast({
        variant: "danger",
        message: "Failed to download file. Please try again.",
        trackingId: "fetch-notification-download-url-error-toast",
      });
    }
  }, [addToast, fetchNotification]);

  return {
    fetchNotificationDownloadUrl,
    isFetchingDownloadUrl,
  };
};

const useInvalidateQueriesForNotificationCategory = () => {
  const queryClient = useQueryClient();

  const invalidateNotificationRelatedQueryKeys = useCallback(
    (queryKeys: DashboardQueryKey[] = []) => {
      // We use the notifications BE as the source of truth for all FE notifications
      // displays, so we refresh that query on every notification.
      const allQueries = [...queryKeys, DashboardQueryKey.Notifications];

      for (const queryKey of allQueries) {
        void queryClient.invalidateQueries([queryKey]);
      }
    },
    [queryClient]
  );

  const invalidateQueriesForNotificationCategory = useCallback(
    (category: NotificationCategory) => {
      const categorySpecificQueries = notificationQueryMap[category];
      if (
        [
          NotificationCategory.FormFilingComplete,
          NotificationCategory.FormFilingFailed,
        ].includes(category)
      ) {
        void (async () => {
          // Sleeping to ensure that the ES has enough time to update the data
          await sleep(3_500);

          invalidateNotificationRelatedQueryKeys(categorySpecificQueries);
        })();
      } else {
        invalidateNotificationRelatedQueryKeys(categorySpecificQueries);
      }
    },
    [invalidateNotificationRelatedQueryKeys]
  );

  return {
    invalidateQueriesForNotificationCategory,
  };
};

export const useNotificationsWebSocket = () => {
  const [isError, setIsError] = useState(false);

  const { getAccessTokenAndUpdateAuthState } =
    useGetAccessTokenAndUpdateAuthState();

  const { invalidateQueriesForNotificationCategory } =
    useInvalidateQueriesForNotificationCategory();

  const { readyState, sendMessage } = useWebSocket(
    `wss://${getCurrentWebsocketHost()}`,
    {
      onOpen: () => {
        getAccessTokenAndUpdateAuthState()
          .then((token) => {
            sendMessage(
              JSON.stringify({
                action: "auth",
                jwt: token,
              })
            );
          })
          .catch(() => setIsError(true));
      },
      onError: () => {
        setIsError(true);
      },
      onMessage: (message) => {
        /**
         * To avoid having to manually join together incoming data and the current query
         * response, we just use the web socket connection to signal when we should refresh
         * the query data for notifications.
         *
         * We also invalidate queries for other pages depending on the notification category
         * as data may have changed, instead of waiting for the next poll interval.
         */

        const { category } = notificationSchema.parse(
          camelCaseKeys(
            JSON.parse(message.data as string) as Record<string, unknown>
          )
        );
        invalidateQueriesForNotificationCategory(category);
      },
      shouldReconnect: () => true,
    }
  );

  return {
    isErrorWebSocket: isError,
    isConnectingWebSocket: readyState !== ReadyState.OPEN && !isError,
  };
};
