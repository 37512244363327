import { DashboardNavigationItem } from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import AccountSettings from "./AccountSettings";

export const accountSettingsNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.MyAccount,
  iconName: "user",
  label: "My Account",
  trackingId: "sidebar-navigation-my-account",
} as const;

const AccountSettingsPage = () => {
  return <AccountSettings />;
};

export default AccountSettingsPage;
