import {
  DashboardFeatureFlag,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback, useMemo } from "react";

import sortDashboardForms from "../sortDashboardForms";

export type YearsByFormFeatureFlag = Extract<
  DashboardFeatureFlag,
  | "ingestionModuleYearsByFormTemplate"
  | "formsPageYearsByFormType"
  | "generateFormsYearsByFormType"
>;

/**
 * This hook parses data from the independent, page-level feature flags that map each form year
 * available for that page to the years that it is available. This is used to determine which forms
 * and years may be selected in dropdown controls on that page.
 */
const useFormFeatureFlagData = ({
  featureFlag,
}: {
  featureFlag: YearsByFormFeatureFlag;
}) => {
  const yearsByFormType = useDashboardFeatureFlags()[featureFlag];

  const hasFeatureFlagData = useMemo(
    () => Object.values(yearsByFormType).length > 0,
    [yearsByFormType]
  );

  const forms = useMemo(() => {
    return sortDashboardForms(
      Object.keys(yearsByFormType) as DashboardFormType[]
    );
  }, [yearsByFormType]);

  const years = useMemo(
    () =>
      [...new Set(Object.values(yearsByFormType).flat())].sort((a, b) => b - a),
    [yearsByFormType]
  );

  const hasForms = useMemo(() => forms.length > 0, [forms]);

  const getYearsForForm = useCallback(
    (form?: DashboardFormType) => {
      const yearsForForm = form ? (yearsByFormType[form] ?? []) : [];
      return yearsForForm.sort((a, b) => b - a);
    },
    [yearsByFormType]
  );

  const hasYearForForm = useCallback(
    (form?: DashboardFormType, year?: FourDigitYear) => {
      if (form && yearsByFormType[form] && year) {
        return yearsByFormType[form]?.includes(year);
      } else {
        return false;
      }
    },
    [yearsByFormType]
  );

  return {
    forms,
    years,
    hasForms,
    getYearsForForm,
    hasYearForForm,
    yearsByFormType,
    hasFeatureFlagData,
  };
};

export type FormFeatureFlagData = ReturnType<typeof useFormFeatureFlagData>;

export default useFormFeatureFlagData;
