import { ToastConfig, useDashboardStore } from "@taxbit-dashboard/commons";
import { useState } from "react";

import { useGenerateFormsExport } from "../../../../../api/information-reporting/forms/formsApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useIrFormsContext } from "../../context/useIrForms";

export enum FormsTableExportTrackingIds {
  ErrorToast = "ir-forms-table-export-error-toast",
  SuccessToast = "ir-forms-table-export-success-toast",
  InfoToast = "ir-forms-table-export-info-toast",
  ExportButton = "ir-forms-table-export-button",
}

export const exportErroredToastContent: ToastConfig = {
  message: "Forms table failed to export. Please try again.",
  trackingId: FormsTableExportTrackingIds.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
};

export const exportStartedToastContent: ToastConfig = {
  message:
    "Your forms table is being exported. The CSV file will appear in the notifications tab when ready to download.",
  trackingId: FormsTableExportTrackingIds.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useFormsTableExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);

  const {
    accountExternalIdsFilter,
    urlParams,
    currentForm,
    currentYear,
    shouldDisableControls,
    isFormsError,
  } = useIrFormsContext();

  const { mutate: generateFormsExport } = useGenerateFormsExport();

  const handleSubmit = () => {
    setIsLoading(true);
    addToast(exportStartedToastContent);

    generateFormsExport(
      {
        ...urlParams,
        formYear: currentYear,
        formType: currentForm,
        accountExternalIdsFilter,
      },
      {
        onError: () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
        onSuccess: () => {
          setIsLoading(false);
        },
      }
    );
  };

  return {
    isLoading,
    isDisabled: isLoading || shouldDisableControls || isFormsError,
    createExport: handleSubmit,
  };
};

export default useFormsTableExport;
