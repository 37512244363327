import { createFileRoute, retainSearchParams } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";

import formDataTabParamsSchema from "../../../../params/irw/accounts/account-details/formDataTabParamsSchema";
import { RouteId } from "../../../../types/RouteId";

export const Route = createFileRoute(RouteId.AccountDetailsFormDataTab)({
  validateSearch: zodValidator(formDataTabParamsSchema),
  search: {
    middlewares: [retainSearchParams(["formType", "formYear"])],
  },
});
