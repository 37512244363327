import { useAuth0 } from "@auth0/auth0-react";
import {
  useDashboardStore,
  useGetAccessTokenAndUpdateAuthState,
  useLoginWithReturnTo,
} from "@taxbit-dashboard/commons";
import { useRouteOrganizationId } from "@taxbit-dashboard/router";
import { useEffect, useMemo } from "react";

import useHandleArrivalFromInvitation from "./useHandleArrivalFromInvitation";

const useRouteAuthentication = () => {
  const { getAccessTokenAndUpdateAuthState, hasLoadedAuthState } =
    useGetAccessTokenAndUpdateAuthState();

  const { error, isAuthenticated, isLoading } = useAuth0();
  const loginWithReturnTo = useLoginWithReturnTo();

  const authOrganizationId = useDashboardStore(
    (store) => store.authOrganizationId
  );
  const routeAuthOrganizationId = useRouteOrganizationId();

  const isRedirectingForInvitationAcceptance = useHandleArrivalFromInvitation();

  if (error) {
    throw error;
  }

  /**
   * After we have authenticated, fetch the access token from Auth0 and use it to update
   * the authentication information (permissions, organization ID, etc.) in the global store.
   */
  useEffect(() => {
    if (isAuthenticated) {
      void getAccessTokenAndUpdateAuthState();
    }
  }, [getAccessTokenAndUpdateAuthState, isAuthenticated]);

  const shouldAuthenticate = useMemo(() => {
    /**
     * Auth0 is fully loaded but we are either not signed in or we are in the process
     * of redirecting to the invitation flow.
     */
    const isUnauthenticated =
      !isLoading && !isAuthenticated && !isRedirectingForInvitationAcceptance;

    /**
     * We are fully authenticated into the incorrect organization. In this case, we need
     * to redirect through the login flow to get correctly authorized for the organization
     * the user is intending to visit with this URL.
     */
    const mustSwitchOrganizations =
      isAuthenticated &&
      authOrganizationId &&
      routeAuthOrganizationId &&
      authOrganizationId !== routeAuthOrganizationId;

    return mustSwitchOrganizations || isUnauthenticated;
  }, [
    authOrganizationId,
    isAuthenticated,
    isLoading,
    isRedirectingForInvitationAcceptance,
    routeAuthOrganizationId,
  ]);

  useEffect(() => {
    if (shouldAuthenticate) {
      void loginWithReturnTo({
        authorizationParams: { organization: routeAuthOrganizationId },
      });
    }
  }, [loginWithReturnTo, routeAuthOrganizationId, shouldAuthenticate]);

  // If we're currently loading the authentication status, show a spinner.
  if (isLoading) {
    return false;
  }

  // If we're in the process of redirecting to the invitation flow, show a spinner.
  if (isRedirectingForInvitationAcceptance) {
    return false;
  }

  // If we're in the process of logging in, show a spinner.
  if (shouldAuthenticate) {
    return false;
  }

  // If we're setting authentication info in the store, show a spinner.
  if (!hasLoadedAuthState) {
    return false;
  }

  return true;
};

export default useRouteAuthentication;
