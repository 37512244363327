import { Button } from "@taxbit-private/cosmic";

import TransactionsExportWarningModal from "./TransactionsExportWarningModal";
import { useTransactionsTableContext } from "./useTransactionsTable";

enum TransactionsTableExportButtonTrackingIds {
  ExportButton = "TransactionsTableExportButton",
}

const EXPORT_ROW_LIMIT = 50_000_000;

const TransactionsTableExportButton: React.FC = () => {
  const {
    totalCount,
    createTransactionsExport,
    isCreatingExport,
    setIsWarningModalOpen,
  } = useTransactionsTableContext();

  const handleClick = () => {
    if (totalCount > EXPORT_ROW_LIMIT) {
      setIsWarningModalOpen(true);
    } else {
      createTransactionsExport();
    }
  };

  return (
    <>
      <Button
        trackingId={TransactionsTableExportButtonTrackingIds.ExportButton}
        label="Export table"
        isLoading={isCreatingExport}
        onClick={handleClick}
        size="small"
        variant="button-tertiary"
        iconName="download"
        loadingText="Exporting..."
      />

      <TransactionsExportWarningModal />
    </>
  );
};

export default TransactionsTableExportButton;
