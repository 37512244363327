import { AccountId } from "@taxbit-dashboard/rest";
import { RouteId, useAuthenticatedNavigation } from "@taxbit-dashboard/router";
import { Table, TrimmedBoxContent } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import useTransactionsTableColumns, {
  TableTransaction,
  TransactionTableTrackingId,
} from "./useTransactionsTableColumns";

type TransactionsTableProps = {
  rows: TableTransaction[];
  accountId?: AccountId;
};

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  rows,
  accountId,
}) => {
  const { authenticatedNavigate } = useAuthenticatedNavigation();

  const columns = useTransactionsTableColumns();

  const getRowKey = useCallback((row: TableTransaction) => row.id, []);
  const onRowClick = useCallback(
    (row: TableTransaction) => {
      void authenticatedNavigate({
        to: RouteId.AccountDetailsTransactionDetails,
        params: { accountId, transactionId: row.id },
      });
    },
    [accountId, authenticatedNavigate]
  );

  return (
    <TrimmedBoxContent trim="all">
      <Table
        trackingId={TransactionTableTrackingId.CosmicTable}
        columns={columns}
        getRowKey={getRowKey}
        rows={rows}
        onRowClick={onRowClick}
      />
    </TrimmedBoxContent>
  );
};

export default TransactionsTable;
