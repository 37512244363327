import {
  AuthorizedView,
  DashboardNavigationItemChild,
  DashboardPrimaryTemplate,
  MissingDashboardConfigEmptyState,
} from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";
import { RouteId } from "@taxbit-dashboard/router";
import { Flex } from "@taxbit-private/cosmic";

import { useDeleteFileUploaderContext } from "./context/useDeleteFileUploader";
import FilesTableCard from "./file-uploader/files-table-card/FilesTableCard";
import FileUploaderFormCard from "./file-uploader/FileUploaderFormCard";

export const deleteDataNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.DeleteData,
  label: "Delete",
  trackingId: "sidebar-navigation-delete-file-uploader-anchor",
  key: "delete-file-uploader",
  shouldShowRoute: ({ flags }) => flags.hasIngestionModuleDeleteAccess,
} as const;

const DeleteData = () => {
  const { hasIngestionTemplates } = useDeleteFileUploaderContext();

  return (
    <AuthorizedView flags={["hasIngestionModuleDeleteAccess"]}>
      <DashboardPrimaryTemplate title="Delete Data">
        {hasIngestionTemplates ? (
          <Flex direction="column" gap="l">
            <FileUploaderFormCard action={FileAction.Delete} />
            <FilesTableCard action={FileAction.Delete} />
          </Flex>
        ) : (
          <MissingDashboardConfigEmptyState />
        )}
      </DashboardPrimaryTemplate>
    </AuthorizedView>
  );
};

export default DeleteData;
