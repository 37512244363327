import {
  GetFormsSort,
  GetFormsSortKey,
  getFormsSortSchema,
} from "@taxbit-dashboard/rest";
import {
  DEFAULT_FORMS_SORT,
  IrFormsUrlParams,
  UseUrlParamsReturn,
} from "@taxbit-dashboard/router";
import { useCallback } from "react";

import { FormsTableColumnKey } from "./useFormsTableColumns";

const formTableKeyToSortKeyMap: Partial<
  Record<FormsTableColumnKey, GetFormsSortKey>
> = {
  dateGenerated: "date_generated",
};

const formSortKeyToFormsTableKeyMap = Object.fromEntries(
  Object.entries(formTableKeyToSortKeyMap).map(([key, value]) => [value, key])
) as Record<GetFormsSortKey, FormsTableColumnKey>;

const isFormsSortKey = (key: string): key is GetFormsSortKey =>
  key in formSortKeyToFormsTableKeyMap;

const getSortAttributesFromSort = (sort: GetFormsSort) => {
  const slicedSortKey = sort.slice(1);

  if (isFormsSortKey(slicedSortKey)) {
    return {
      isInitialSortDescending: true,
      initialSortColumnKey: formSortKeyToFormsTableKeyMap[slicedSortKey],
    } as const;
  } else if (isFormsSortKey(sort)) {
    return {
      isInitialSortDescending: false,
      initialSortColumnKey: formSortKeyToFormsTableKeyMap[sort],
    };
  }

  return {
    isInitialSortDescending: false,
    initialSortColumnKey: formSortKeyToFormsTableKeyMap.date_generated,
  } as const;
};

const useFormsTableSort = ({
  updateFilterParams,
  sort,
}: {
  updateFilterParams: UseUrlParamsReturn<IrFormsUrlParams>["updateFilterParams"];
  sort: GetFormsSort;
}) => {
  const onSortChange = useCallback(
    (key: FormsTableColumnKey, isDescending = false) => {
      const newSort = (() => {
        const sortKey = formTableKeyToSortKeyMap[key];

        if (sortKey) {
          return isDescending ? (`-${sortKey}` as const) : sortKey;
        }

        return DEFAULT_FORMS_SORT;
      })();

      if (newSort !== sort) {
        void updateFilterParams({
          sort: newSort,
        });
      }
    },
    [sort, updateFilterParams]
  );

  const initialSortProps = getSortAttributesFromSort(
    getFormsSortSchema.safeParse(sort).success ? sort : DEFAULT_FORMS_SORT
  );

  return {
    onSortChange,
    ...initialSortProps,
  };
};

export default useFormsTableSort;
