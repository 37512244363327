import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";

import { useNotificationsPageDataContext } from "./useNotificationsPageData";

export enum NotificationsPagePaginationTrackingId {
  Pagination = "notifications-page-pagination",
}

const NotificationsPagePagination = () => {
  const { params, totalCount, updateUrlParams } =
    useNotificationsPageDataContext();

  return (
    params && (
      <UrlParamPaginationControls
        totalCount={totalCount}
        trackingId={NotificationsPagePaginationTrackingId.Pagination}
        urlParams={params}
        updateUrlParams={updateUrlParams}
      />
    )
  );
};

export default NotificationsPagePagination;
