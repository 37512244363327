import {
  DashboardPrimaryTemplate,
  isDefined,
  useDashboardFeatureFlags,
  useNavigationHistoryContext,
  UserPermission,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import {
  KycTaxDocumentType,
  ReportingProfile as ReportingProfileType,
} from "@taxbit-dashboard/rest";
import {
  Outlet,
  RouteId,
  useAuthenticatedNavigation,
  useIsIndexRoute,
  useIsRouteIdActive,
} from "@taxbit-dashboard/router";
import {
  Box,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useCallback, useEffect } from "react";

import { useAccountOwnerTaxDocumentContext } from "../../../hooks/useGetAccountOwnerTaxDocumentData";
import useAccountId from "../../../utils/useAccountId";
import EntityNotFound from "../EntityNotFound";

export enum AccountDetailsTabTrackingId {
  ReportingProfileTab = "reporting-profile-tab",
  AccountDetailsTab = "account-details-tab",
  DetailsTab = "details-tab",
  TaxDocumentationTab = "tax-documentation-tab",
  TaxFormsTab = "tax-forms-tab",
  InventoryTab = "inventory-tab",
  FormDataTab = "form-data-tab",
  TransactionsTab = "transactions-tab",
}

export enum AccountDetailsTrackingId {
  BackBtn = "account-details-back-button",
  LoadingSpinner = "account-details-loading-spinner",
}

const getNameByReportingProfileType = (
  profileType: ReportingProfileType["profileType"],
  reportingProfilesData?: ReportingProfileType[]
) =>
  reportingProfilesData?.find((profile) => profile.profileType === profileType)
    ?.profileData.name;

const AccountDetails: React.FC = () => {
  const {
    hasTaxDocumentationAccess,
    hasAccountInventoryAccess,
    showAccountDetailsReportingProfile,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
    hasReportingProfile,
  } = useDashboardFeatureFlags();

  const { authenticatedNavigate } = useAuthenticatedNavigation();
  const isAccountDetailsIndexRoute = useIsIndexRoute();
  const isRouteIdActive = useIsRouteIdActive();
  const accountId = useAccountId();
  const { getBackButtonProps } = useNavigationHistoryContext();

  const {
    account,
    accountOwner,
    isLoading,
    isError,
    isAccountNotFoundError,
    taxDocumentations,
    reportingProfiles,
  } = useAccountOwnerTaxDocumentContext();

  const canViewTaxForms = useUserPermission(
    UserPermission.ReadAccountDocuments
  );
  const canViewAssets = useUserPermission(UserPermission.ReadAccountAssets);

  const shouldShowReportingProfile =
    showAccountDetailsReportingProfile && hasReportingProfile;

  const tabItems = [
    ...(shouldShowReportingProfile
      ? [
          {
            label: "Reporting Profile",
            trackingId: AccountDetailsTabTrackingId.ReportingProfileTab,
            routeId: RouteId.AccountDetailsReportingProfileTab,
          },
        ]
      : []),
    ...(shouldShowReportingProfile
      ? [
          {
            label: "Details",
            trackingId: AccountDetailsTabTrackingId.AccountDetailsTab,
            routeId: RouteId.AccountDetailsAccountDetailsTab,
          },
        ]
      : [
          {
            label: "Details",
            trackingId: AccountDetailsTabTrackingId.DetailsTab,
            routeId: RouteId.AccountDetailsDetailsTab,
          },
        ]),
    ...(hasTaxDocumentationAccess
      ? [
          {
            label: "Tax Documentation",
            trackingId: AccountDetailsTabTrackingId.TaxDocumentationTab,
            routeId: RouteId.AccountDetailsTaxDocumentationTab,
          },
        ]
      : []),
    ...(canViewTaxForms
      ? [
          {
            label: "Tax Forms",
            trackingId: AccountDetailsTabTrackingId.TaxFormsTab,
            routeId: RouteId.AccountDetailsTaxFormsTab,
          },
        ]
      : []),
    ...(canViewAssets && hasAccountInventoryAccess
      ? [
          {
            label: "Inventory",
            trackingId: AccountDetailsTabTrackingId.InventoryTab,
            routeId: RouteId.AccountDetailsInventoryTab,
          },
        ]
      : []),
    ...(canViewTaxForms
      ? [
          {
            label: "Form Data",
            trackingId: AccountDetailsTabTrackingId.FormDataTab,
            routeId: RouteId.AccountDetailsFormDataTab,
          },
        ]
      : []),
    {
      label: "Transactions",
      trackingId: AccountDetailsTabTrackingId.TransactionsTab,
      routeId: RouteId.AccountDetailsTransactionsTab,
    },
  ];

  const accountName = useCallback(() => {
    if (shouldShowReportingProfile) {
      const usName = hasUsAccountsPageView
        ? getNameByReportingProfileType(
            "US",
            reportingProfiles?.reportingProfiles
          )
        : undefined;

      const globalName = hasGlobalAccountsPageView
        ? getNameByReportingProfileType(
            "GLOBAL",
            reportingProfiles?.reportingProfiles
          )
        : undefined;

      if (usName && globalName && usName !== globalName) {
        return `${usName} / ${globalName}`;
      }

      if (usName || globalName) {
        // we know that at least one of them is defined
        return (usName || globalName) as string;
      }
    }

    if (accountOwner?.pii.name) {
      return accountOwner.pii.name;
    }

    if (!shouldShowReportingProfile) {
      const wForm = taxDocumentations?.find(
        (doc) =>
          doc.documentType === KycTaxDocumentType.W9 ||
          doc.documentType === KycTaxDocumentType.W8Ben ||
          doc.documentType === KycTaxDocumentType.W8BenE
      );

      if (isDefined(wForm) && wForm.name) {
        return wForm.name;
      }

      const dps = taxDocumentations?.find(
        (doc) => doc.documentType === KycTaxDocumentType.Dps
      );

      if (isDefined(dps) && dps.name) {
        return dps.name;
      }
    }

    return "Name Not Available";
  }, [
    accountOwner,
    taxDocumentations,
    reportingProfiles,
    hasUsAccountsPageView,
    hasGlobalAccountsPageView,
    shouldShowReportingProfile,
  ])();

  /**
   * If we have landed on the root account details page, navigate to the default tab.
   */
  useEffect(() => {
    if (account && isAccountDetailsIndexRoute) {
      const defaultTabRoute = shouldShowReportingProfile
        ? RouteId.AccountDetailsReportingProfileTab
        : RouteId.AccountDetailsDetailsTab;

      void authenticatedNavigate({
        to: defaultTabRoute,
        params: { accountId: account.accountId },
      });
    }
  }, [
    account,
    authenticatedNavigate,
    isAccountDetailsIndexRoute,
    shouldShowReportingProfile,
  ]);

  return isLoading ? (
    <ContentSpinner trackingId={AccountDetailsTrackingId.LoadingSpinner} />
  ) : (
    <DashboardPrimaryTemplate
      title={accountName}
      {...getBackButtonProps({
        fallback: RouteId.Accounts,
      })}
      backButtonTrackingId={AccountDetailsTrackingId.BackBtn}
      subtitles={[account?.externalId || COSMIC_VALUE_PLACEHOLDER]}
      tabs={
        isAccountNotFoundError || isError
          ? undefined
          : tabItems.map(({ routeId, ...tab }) => ({
              ...tab,
              isActive: isRouteIdActive(routeId),
              onClick: () =>
                authenticatedNavigate({
                  to: routeId,
                  params: {
                    accountId,
                  },
                }),
            }))
      }
    >
      {isAccountNotFoundError ? (
        <EntityNotFound entity="Account" />
      ) : isError ? (
        <Box>
          <ContentErrorEmptyState entity="account" />
        </Box>
      ) : (
        <Outlet />
      )}
    </DashboardPrimaryTemplate>
  );
};

export default AccountDetails;
