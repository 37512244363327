import {
  TRACKING_EXCLUDE_CLASS,
  renderExcludedSpan,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { IrForm } from "@taxbit-dashboard/rest";
import {
  Badge,
  Body,
  Flex,
  TableColumns,
  ColumnKey,
  Button,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import {
  formTypeToLabelMap,
  formStatusToLabelMap,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import useNavigateToAccountDetails from "../../../accounts/account-details/useNavigateToAccountDetails";

export enum FormsTableTrackingId {
  AccountLink = "ir-forms-table-account-link",
  FormStatusHelpIcon = "ir-forms-table-form-status-help-icon",
}

const FORMS_STATUS_HELP_ICON_CONTENT = (
  <Flex direction="column" gap="xs">
    <Body>
      Generated: The Form has been generated and is available to be filed with
      the relevant tax authority.
    </Body>
    <Body>
      Filed: The final deliverable has been generated with this form&apos;s data
      and ready to be, or filed with the relevant tax authority.
    </Body>
  </Flex>
);
export type FormsTableColumnKey = ColumnKey<IrForm>;

const useFormsTableColumns = () => {
  const navigateToAccountDetails = useNavigateToAccountDetails();
  const { formatDateTime } = useCosmicLocalizationContext();
  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const onAccountLinkClick = useCallback(
    (accountId: IrForm["accountId"]) => {
      navigateToAccountDetails(accountId);
    },
    [navigateToAccountDetails]
  );

  return useMemo((): TableColumns<IrForm> => {
    const columns: TableColumns<IrForm> = [
      {
        key: "accountExternalId",
        label: "Account ID",
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: (accountExternalId, form) => (
          <Button
            onClick={() => onAccountLinkClick(form.accountId)}
            trackingId={FormsTableTrackingId.AccountLink}
            className={TRACKING_EXCLUDE_CLASS}
            label={accountExternalId ?? "Account Not Found"}
            variant="anchor-primary-inline"
          />
        ),
      },
      {
        key: "accountAlternateExternalId",
        label: "Alternate Account ID",
        isContentFullWidth: true,
        shouldDisableSorting: true,
      },
      {
        key: "name",
        label: "Name",
        shouldTruncate: true,
        minWidth: 128,
        shouldDisableSorting: true,
        renderCell: renderExcludedSpan,
      },
      {
        key: "type",
        label: "Type",
        shouldDisableSorting: true,
        renderCell: (type) => formTypeToLabelMap[type],
      },
      {
        key: "status",
        label: "Form Status",
        shouldDisableSorting: true,
        renderCell: (status) => (
          <Badge variant="success" label={formStatusToLabelMap[status]} />
        ),
        helpIconProps: {
          trackingId: FormsTableTrackingId.FormStatusHelpIcon,
          tooltipProps: {
            content: FORMS_STATUS_HELP_ICON_CONTENT,
            appendTo: "body",
            isInteractive: false,
          },
        },
      },
      {
        key: "dateGenerated",
        label: "Date Generated",
        isContentFullWidth: true,
        renderCell: (date) =>
          formatDateTime({
            date,
            format: "DateTime",
            tz: "Browser",
          }),
      },
    ];
    return columns.filter(
      (x) => showAlternateExternalId || x.key !== "accountAlternateExternalId"
    );
  }, [formatDateTime, onAccountLinkClick, showAlternateExternalId]);
};

export default useFormsTableColumns;
