import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import {
  NotificationsTab,
  RouteId,
  useUrlParams,
} from "@taxbit-dashboard/router";
import { useMemo } from "react";

import useNotifications, {
  notificationsTabTitleMap,
} from "../useNotifications";

const useNotificationsPageData = () => {
  const { urlParams, updateUrlParams, updateFilterParams } = useUrlParams({
    routeId: RouteId.Notifications,
    shouldThrow: false,
  });

  const { shouldShowUnreadNotifications, unreadCount, ...restContext } =
    useNotifications(urlParams);

  const tabConfigs = useMemo(() => {
    return Object.values(NotificationsTab).map((tab) => {
      const shouldShowUnread =
        shouldShowUnreadNotifications && tab !== NotificationsTab.Read;

      const title = notificationsTabTitleMap[tab];

      return {
        label: shouldShowUnread ? `${title} (${unreadCount})` : title,
        onClick: () => updateFilterParams({ tab }),
        isActive: tab === urlParams?.tab,
        trackingId: tab,
      };
    });
  }, [
    updateFilterParams,
    shouldShowUnreadNotifications,
    unreadCount,
    urlParams?.tab,
  ]);

  return {
    currentTab: urlParams?.tab,
    tabConfigs,
    params: urlParams,
    updateUrlParams,
    shouldShowUnreadNotifications,
    ...restContext,
  };
};

export const {
  Provider: NotificationsPageDataContextProvider,
  useContextHook: useNotificationsPageDataContext,
} = createSingleInstanceHookContext(
  useNotificationsPageData,
  "useNotificationsPageDataContext"
);
