import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import {
  GetNotificationsParams,
  NotificationType,
} from "@taxbit-dashboard/rest";
import { NotificationsTab } from "@taxbit-dashboard/router";
import { CosmicPageSize } from "@taxbit-private/cosmic";

/**
 * NotificationParams is not linked to NotificationsPageParams because the notifications API
 * can be used to query data for views that are not controlled by url page params
 */
export type NotificationParams = {
  limit: CosmicPageSize;
  page: number;
  tab?: NotificationsTab;
  type?: NotificationType;
};

const getNotificationsParams = ({
  limit,
  page,
  tab = NotificationsTab.All,
  type = NotificationType.Instant,
}: NotificationParams): GetNotificationsParams => {
  return {
    "filters[notification_type]": type,
    ...getPageLimitApiParams({ page, limit }),
    ...(type === NotificationType.Instant
      ? {
          "filters[is_read]":
            tab === NotificationsTab.All
              ? undefined
              : tab === NotificationsTab.Read,
        }
      : {}),
  };
};

export default getNotificationsParams;
