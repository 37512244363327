import {
  UserPermission,
  useDashboardStore,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { GetKycDocument, KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { useState } from "react";

import { useGetAccountV2 } from "../../../../../api/account/accountApi";
import {
  useGenerateKycDocument,
  useGetKycDocument,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApi";
import { documentTypeMap } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";

const getFormFailedToGenerateMessage = (formName: KycTaxDocumentType) =>
  `${documentTypeMap[formName]} form failed to generate. Please try again.`;

const getFormGenerationStartedMessage = (formName: KycTaxDocumentType) =>
  `${documentTypeMap[formName]} form generation started. This could take a few minutes.`;

const getFormGenerationSuccessMessage = (formName: KycTaxDocumentType) =>
  `Your ${documentTypeMap[formName]} is ready to download. `;

export enum GenerateTaxDocumentationTrackingId {
  TaxDocumentationFormErrorToast = "tax-documentation-form-error-toast",
  TaxDocumentationFormSuccessToast = "tax-documentation-form-success-toast",
  TaxDocumentationFormInfoToast = "tax-documentation-form-info-toast",
  TaxDocumentationFormDownloadLinkInToast = "tax-documentation-form-download-link-in-toast",
}

enum TaxDocumentationFormGenerationState {
  Idle = "idle",
  Processing = "processing",
  Finished = "finished",
}

const useGenerateTaxDocumentationForm = (documentType: KycTaxDocumentType) => {
  const [
    taxDocumentationFormGenerationState,
    setTaxDocumentationFormGenerationState,
  ] = useState<TaxDocumentationFormGenerationState>(
    TaxDocumentationFormGenerationState.Idle
  );

  const canReadTins = useUserPermission(UserPermission.ReadTins);
  const { data: account, isLoading: isAccountLoading } = useGetAccountV2();

  const addToast = useDashboardStore((store) => store.addToast);

  const onError = () => {
    setTaxDocumentationFormGenerationState(
      TaxDocumentationFormGenerationState.Idle
    );

    addToast({
      message: getFormFailedToGenerateMessage(documentType),
      trackingId:
        GenerateTaxDocumentationTrackingId.TaxDocumentationFormErrorToast,
      variant: "danger",
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  const onProccessing = () => {
    setTaxDocumentationFormGenerationState(
      TaxDocumentationFormGenerationState.Processing
    );

    addToast({
      message: getFormGenerationStartedMessage(documentType),
      trackingId:
        GenerateTaxDocumentationTrackingId.TaxDocumentationFormInfoToast,
      variant: "primary",
      timeoutMs: TOAST_TIMEOUT,
    });
  };

  const onSuccess = (documentData: GetKycDocument) => {
    if (documentData.data.status === "ERROR") {
      onError();
      return;
    }

    if (
      taxDocumentationFormGenerationState ===
        TaxDocumentationFormGenerationState.Finished ||
      !documentData.data.url
    )
      return;

    setTaxDocumentationFormGenerationState(
      TaxDocumentationFormGenerationState.Finished
    );

    addToast({
      message: getFormGenerationSuccessMessage(documentType),
      trackingId:
        GenerateTaxDocumentationTrackingId.TaxDocumentationFormSuccessToast,
      variant: "primary",
      timeoutMs: TOAST_TIMEOUT,
      ctaProps: {
        href: documentData.data.url,
        target: "_blank",
        label: `Download ${documentTypeMap[documentType]}`,
        trackingId:
          GenerateTaxDocumentationTrackingId.TaxDocumentationFormDownloadLinkInToast,
      },
    });
  };

  const {
    mutate: postKycTaxDocumentationForm,
    data: kycTaxDocumentationFormGenerationResponse,
  } = useGenerateKycDocument();

  const { data: kycTaxDocumentationForm } = useGetKycDocument({
    userId: account?.accountOwner.accountOwnerId,
    documentId: kycTaxDocumentationFormGenerationResponse?.data.id,
    onError,
    onSuccess,
    refetchInterval:
      taxDocumentationFormGenerationState ===
      TaxDocumentationFormGenerationState.Processing
        ? 5 * 1000
        : taxDocumentationFormGenerationState ===
            TaxDocumentationFormGenerationState.Finished
          ? 5 * 60 * 1000
          : undefined,
  });

  const handleKycTaxDocumentationFormGeneration = () => {
    if (account) {
      onProccessing();
      postKycTaxDocumentationForm(
        {
          userId: account.accountOwner.accountOwnerId,
          requestParams: {
            documentType,
          },
        },
        {
          onError,
        }
      );
    }
  };

  return {
    onGenerateButtonClick: handleKycTaxDocumentationFormGeneration,
    onDownloadButtonClick: () => {
      window.open(kycTaxDocumentationForm?.url, "_blank");
      setTaxDocumentationFormGenerationState(
        TaxDocumentationFormGenerationState.Idle
      );
    },
    shouldShowDownloadButton:
      taxDocumentationFormGenerationState ===
        TaxDocumentationFormGenerationState.Finished &&
      kycTaxDocumentationForm?.url,
    isLoading:
      taxDocumentationFormGenerationState ===
      TaxDocumentationFormGenerationState.Processing,
    isDisabled: isAccountLoading || !canReadTins,
  };
};

export default useGenerateTaxDocumentationForm;
