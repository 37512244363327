import {
  TransactionType,
  transactionTypeLabelMap,
} from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";

const options = [
  TransactionType.Buy,
  TransactionType.Sell,
  TransactionType.Trade,
  TransactionType.Income,
  TransactionType.TransferIn,
  TransactionType.TransferOut,
  TransactionType.Expense,
  TransactionType.Reward,
];

const TransactionTypeMultiselectFilter: React.FC = () => {
  return (
    <RhfMultiselect
      name="type"
      label="Transaction Type"
      options={options}
      placeholder="Select an option"
      getOptionValue={(option) => option}
      getOptionKey={(option) => option}
      getOptionLabel={(option) => transactionTypeLabelMap[option]}
      trackingId="transactions-type-filter"
    />
  );
};

export default TransactionTypeMultiselectFilter;
