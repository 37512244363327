import {
  UseMutationOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import {
  DashboardMutationKey,
  DashboardQueryKey,
  createQueryMetaObject,
  isDefined,
  isTestEnv,
  logError,
  sleep,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  useInvalidateQueries,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { DownloadIrFormsRequest, IrFormKeys } from "@taxbit-dashboard/rest";
import {
  IrFormsUrlParams,
  IrEligibilityUrlParams,
} from "@taxbit-dashboard/router";
import { O } from "ts-toolbelt";

import {
  toApiSearchIrFormsParams,
  SearchIrFormsParams,
  GenerateTaxFormsMutationParams,
  toGenerateTaxFormsRequest,
} from "./formsApiTypes";
import getFormsParams from "./getFormsParams";
import newlineTextBasedFilterToArray from "../../../utils/newlineTextBasedFilterToArray";
import { AccountExternalIdsFilter, IrFormTypeDateParams } from "../irApiTypes";

export const FORMS_DATA_MAX_LIMIT = 10_000;
export const FORMS_REFRESH_DELAY = isTestEnv() ? 100 : 3_500;

type RescindMutationResponse = {
  errorCount: number;
  totalCount: number;
};

export const useGetFormsAggregates = ({
  formYear,
  formType,
}: O.Optional<IrFormTypeDateParams>) => {
  const restSdk = useTaxBitRest();
  const apiParams =
    isDefined(formYear) && isDefined(formType)
      ? {
          "filters[document_date]": formYear,
          "filters[document_type]": formType,
        }
      : undefined;

  const query = useQuery(
    [DashboardQueryKey.IrFormsAggregates, { formYear, formType }],
    () => apiParams && restSdk.irForms.aggregates.get(apiParams),
    {
      ...createQueryMetaObject(restSdk.irForms.aggregates.buildPath()),
      enabled: isDefined(apiParams),
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetForms = ({
  onMissingAccounts,
  formYear,
  formType,
  ...restParams
}: SearchIrFormsParams & {
  onMissingAccounts: (
    requestIdsLength: number,
    totalAccountsNotFound: number
  ) => void;
}) => {
  const { hasReportingProfile } = useDashboardFeatureFlags();
  const restSdk = useTaxBitRest();

  const apiSearchParams =
    isDefined(formYear) && isDefined(formType)
      ? toApiSearchIrFormsParams({
          formYear,
          formType,
          useReportingProfile: hasReportingProfile,
          ...restParams,
        })
      : undefined;

  const query = useQuery(
    [DashboardQueryKey.IrForms, { formYear, formType, ...restParams }],
    () => apiSearchParams && restSdk.irForms.search.post(apiSearchParams),
    {
      ...createQueryMetaObject(restSdk.irForms.search.buildPath()),
      keepPreviousData: true,
      enabled: isDefined(apiSearchParams),
      onSuccess: (data) => {
        const totalAccountsNotFound = data?.meta?.totalAccountsNotFound;
        const requestIds = apiSearchParams?.filters.accountExternalIds;
        if (totalAccountsNotFound && totalAccountsNotFound > 0 && requestIds) {
          onMissingAccounts(requestIds.length, totalAccountsNotFound);
        }
      },
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateTaxForms = ({
  formType,
  formYear,
  ...params
}: IrEligibilityUrlParams) => {
  const restSdk = useTaxBitRest();
  const { shouldForm1099BIncludeCostBasis } = useDashboardFeatureFlags();

  return useMutation(
    (mutationParams: GenerateTaxFormsMutationParams) => {
      const requestData = toGenerateTaxFormsRequest({
        formYear,
        formType,
        shouldForm1099BIncludeCostBasis,
        ...params,
        ...mutationParams,
      });

      return restSdk.irForms.batch.post(requestData);
    },
    {
      ...createQueryMetaObject(restSdk.irForms.batch.buildPath()),
      mutationKey: [DashboardMutationKey.IrFormsBatch, { formType, formYear }],
    }
  );
};

export const useCreateTaxFormsFiling = ({
  formType,
  formYear,
}: Partial<IrFormTypeDateParams>) => {
  const restSdk = useTaxBitRest();

  return useMutation(
    () => {
      if (!isDefined(formYear) || !isDefined(formType)) {
        throw new Error("Form Date and Form Type are required");
      }

      return restSdk.irForms.filing.post({
        data: { documentType: formType, documentYear: formYear },
      });
    },
    {
      ...createQueryMetaObject(restSdk.irForms.filing.buildPath()),
      mutationKey: [DashboardMutationKey.IrFormsFiling, { formType, formYear }],
    }
  );
};

export const useGenerateFormsExport = () => {
  const restSdk = useTaxBitRest();
  const { showAlternateExternalId, hasReportingProfile } =
    useDashboardFeatureFlags();

  return useMutation(
    ({
      formYear,
      formType,
      accountExternalIdsFilter,
      ...restParams
    }: IrFormsUrlParams & AccountExternalIdsFilter) => {
      if (!isDefined(formYear) || !isDefined(formType)) {
        throw new Error("Form Date and Form Type are required");
      }

      const {
        "page[offset]": _offset,
        "page[limit]": _limit,
        ...filterParams
      } = getFormsParams({ formYear, formType, ...restParams });

      const accountExternalIds = newlineTextBasedFilterToArray(
        accountExternalIdsFilter
      );
      const requestData = {
        accountExternalIds:
          accountExternalIds.length > 0 ? accountExternalIds : undefined,
        includeColumns: showAlternateExternalId
          ? ["accountAlternateExternalId"]
          : undefined,
        useReportingProfile: hasReportingProfile,
      };

      return restSdk.irForms.exports.post(filterParams, requestData);
    },
    createQueryMetaObject(restSdk.irForms.exports.buildPath())
  );
};

export const useRescindIrForms = ({
  formYear,
  formType,
  onSettled,
}: O.Optional<IrFormTypeDateParams> & {
  onSettled?: UseMutationOptions<
    RescindMutationResponse,
    Error,
    IrFormKeys[]
  >["onSettled"];
}) => {
  const restSdk = useTaxBitRest();
  const invalidateQueries = useInvalidateQueries();

  return useMutation<RescindMutationResponse, Error, IrFormKeys[]>(
    async (formKeys: IrFormKeys[]) => {
      try {
        if (formKeys.length === 0) {
          return { errorCount: 0, totalCount: 0 };
        }

        if (!isDefined(formYear) || !isDefined(formType)) {
          throw new Error("Form Date and Form Type are required");
        }

        const res = await restSdk.irForms.rescind.patch({
          data: { taxForms: formKeys },
        });
        await sleep(FORMS_REFRESH_DELAY);

        await invalidateQueries([
          // Invalidate queries for the form that is currently being edited
          [DashboardQueryKey.IrForms, { formYear, formType }],
          [DashboardQueryKey.IrFormsAggregates, { formYear, formType }],

          // Invalidate the query for the entire Account Details --> Tax Forms tab
          [DashboardQueryKey.TaxForms],
        ]);

        return {
          errorCount: res.data.failed.length,
          totalCount: formKeys.length,
        };
      } catch (e) {
        logError({ message: "Failed to rescind tax forms", error: e });
        return { errorCount: formKeys.length, totalCount: formKeys.length };
      }
    },
    {
      ...createQueryMetaObject(restSdk.irForms.rescind.buildPath()),
      mutationKey: [
        DashboardMutationKey.IrFormsRescind,
        { formType, formYear },
      ],
      onSettled,
    }
  );
};

/**
 * Hook to download IR forms. We're passing `onError` and `onSuccess` callbacks
 * here instead of `mutate` because we want to show toasts globally
 * and not just in the component where the mutation is called.
 */
export const useDownloadIrForms = ({
  onError,
  onSuccess,
}: {
  onError?: UseMutationOptions<
    void,
    Error,
    Partial<DownloadIrFormsRequest["data"]>
  >["onError"];
  onSuccess?: UseMutationOptions<
    void,
    Error,
    Partial<DownloadIrFormsRequest["data"]>
  >["onSuccess"];
}) => {
  const restSdk = useTaxBitRest();

  return useMutation<void, Error, Partial<DownloadIrFormsRequest["data"]>>(
    ({
      taxFormKeys,
      documentType,
      documentYear,
      filters,
    }: Partial<DownloadIrFormsRequest["data"]>) => {
      if (!isDefined(documentYear) || !isDefined(documentType)) {
        throw new Error("Form Date and Form Type are required");
      }

      return restSdk.irForms.downloads.post({
        data: {
          documentYear,
          documentType,
          taxFormKeys,
          filters,
        },
      });
    },
    {
      ...createQueryMetaObject(restSdk.irForms.downloads.buildPath()),
      onSuccess,
      onError,
    }
  );
};
