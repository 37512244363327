import {
  DashboardNavigationItem,
  UserPermission,
  AuthorizedView,
} from "@taxbit-dashboard/commons";
import { Outlet, RouteId, RouterMapForPrefix } from "@taxbit-dashboard/router";

import Transactions from "./Transactions";
import TransactionDetails from "../accounts/account-details/transactions-tab/transactions-details/TransactionDetails";

export const transactionsNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.TransactionsLayout,
  iconName: "book-open",
  label: "Transactions",
  trackingId: "sidebar-navigation-transactions-anchor",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccounts] && flags.hasTransactionsPageAccess,
  shouldDisableRoute: ({ flags }) => !flags.hasTransactionsPageAccess,
} as const;

const TransactionsPage = () => {
  return (
    <AuthorizedView permissions={[UserPermission.ReadAccountTransactions]}>
      <Transactions />
    </AuthorizedView>
  );
};

export const transactionsRouterMap = {
  // This blank layout route ensures that we have a parent to check against to
  // display the active tab in the left navigation sidebar.
  [RouteId.TransactionsLayout]: Outlet,
  [RouteId.Transactions]: TransactionsPage,
  [RouteId.TransactionDetails]: TransactionDetails,
} as const satisfies RouterMapForPrefix<"/_authed/o/$organizationId/_irwLayout/transactions/">;

export default TransactionsPage;
