import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import { form1099BAggregateValuesSchema } from "./form1099BAggregateValues";
import { form1099IntAggregateValuesSchema } from "./form1099IntAggregateValues";
import { form1099KAggregateValuesSchema } from "./form1099KAggregateValues";
import { form1099MiscAggregateValuesSchema } from "./form1099MiscAggregateValues";
import { form1099NecAggregateValuesSchema } from "./form1099NecAggregateValues";
import {
  formGainLossPdfAggregateValuesSchema,
  formGainLossSummaryAggregateValuesSchema,
} from "./formGainLossAggregateValues";
import { formItem1099BAggregateValuesSchema } from "./formItemForm1099BAggregateValues";
import { formItem1099DivAggregateValuesSchema } from "./formItemForm1099DivAggregateValues";
import { formItem1099IntAggregateValuesSchema } from "./formItemForm1099IntAggregateValues";
import { formItem1099KAggregateValuesSchema } from "./formItemForm1099KAggregateValues";
import { formItem1099MiscAggregateValuesSchema } from "./formItemForm1099MiscAggregateValues";
import { formItem1099NecAggregateValuesSchema } from "./formItemForm1099NecAggregateValues";
import { formItem1099RAggregateValuesSchema } from "./formItemForm109RAggregateValues";
import { formItem5498AggregateValuesSchema } from "./formItemForm5498AggregateValues";
import {
  formItemGainLossPdfValuesSchema,
  formItemGainLossSummaryAggregateValuesSchema,
} from "./formItemFormGainLossAggregateValues";
import { formItemRmdStatementAggregateValuesSchema } from "./formItemFormRmdStatementAggregateValues";
import getPublicApiSuccessSchema from "../../getPublicApiSuccessSchema";
import { IrFormTypeApiParams } from "../irRestApiTypes";
import { dashboardFormTypeSchema } from "../shared/dashboardFormType";
import { accountIdSchema, pageSchema } from "../sharedApiTypes";

export enum EligibilityOverrideStatus {
  "AddToScope" = "ADD_TO_SCOPE",
  "RemoveFromScope" = "REMOVE_FROM_SCOPE",
  "None" = "NONE",
}
export const overrideStatusSchema = z.nativeEnum(EligibilityOverrideStatus);

export enum AccountValidationType {
  Tin = "TIN",
  Name = "NAME",
  Address = "ADDRESS",
  Dob = "DOB",
  Ira = "IRA",
  Fmv = "FMV",
  EstDate = "EST_DATE",
}

export enum EligibilityStatus {
  Eligible = "ELIGIBLE",
  Added = "ADDED",
  Exempt = "EXEMPT",
  NonValidTaxCountry = "NON_VALID_TAX_COUNTRY",
  NotReportable = "NOT_REPORTABLE",
  Removed = "REMOVED",
  Pending = "PENDING",
}
export const eligibilityStatusSchema = z.nativeEnum(EligibilityStatus);

export enum EligibilityFormStatus {
  Generated = "GENERATED",
  GeneratedRescinded = "GENERATED_RESCINDED",
  Delivered = "DELIVERED",
  DeliveredRescinded = "DELIVERED_RESCINDED",
  Filed = "FILED",
}
export const eligibilityFormStatusSchema = z.nativeEnum(EligibilityFormStatus);

const eligibilityCountsSchema = z.object({
  documentType: dashboardFormTypeSchema,
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  organizationId: uuidSchema,
  eligible: z.object({
    completeAccountData: z.number(),
    incompleteAccountData: z.number(),
    completeAccountDataWithFormGenerated: z.number(),
    incompleteAccountDataWithFormGenerated: z.number(),
  }),
  ineligible: z.object({
    exempt: z.number(),
    nonValidTaxCountryCode: z.number(),
    noReportableTransactions: z.number(),
    removed: z.number(),
    hasFormDataForNonValidTaxCountryCode: z.number(),
    hasFormGenerated: z.number(),
  }),
  total: z.number(),
});

export type EligibilityCounts = z.infer<typeof eligibilityCountsSchema>;

export const getEligibilityCountsResponseSchema = getPublicApiSuccessSchema(
  eligibilityCountsSchema
);
export type GetEligibilityCountsResponse = z.infer<
  typeof getEligibilityCountsResponseSchema
>;

export enum DashboardEligibilityFormStatus {
  NotGenerated = "NOT_GENERATED",
  Generated = "GENERATED",
  Filed = "FILED",
}
export const dashboardEligibilityFormStatusSchema = z.nativeEnum(
  DashboardEligibilityFormStatus
);

export enum AccountValidationsEligibilityStatus {
  Complete = "COMPLETE",
  CompleteWithWarnings = "COMPLETE_WITH_WARNINGS",
  Incomplete = "INCOMPLETE",
}
export const accountValidationsEligibilityStatusSchema = z.nativeEnum(
  AccountValidationsEligibilityStatus
);

export type GetEligibilityDataParams = IrFormTypeApiParams & {
  "page[limit]"?: number;
  "page[offset]"?: number;
  "filters[name]"?: string;
  "filters[external_id]"?: string;
  "filters[alternate_external_id]"?: string;
  "filters[status]"?: EligibilityStatus[];
  "filters[form_status]"?: DashboardEligibilityFormStatus[];
  "filters[account_validations_eligibility_status]"?: AccountValidationsEligibilityStatus[];
  "filters[name_valid]"?: boolean[];
  "filters[tin_valid]"?: boolean[];
  "filters[address_valid]"?: boolean[];
  "filters[dob_valid]"?: boolean[];
  "filters[ira_valid]"?: boolean[];
  "filters[fmv_valid]"?: boolean[];
  "filters[est_date_valid]"?: boolean[];
};

const accountValidationSchema = z.object({
  name: z.nativeEnum(AccountValidationType),
  value: z.boolean(),
});
export type AccountValidation = z.infer<typeof accountValidationSchema>;

const eligibilityFormSpecificFieldsSchema = z.discriminatedUnion(
  "documentTypeDataSource",
  [
    form1099BAggregateValuesSchema,
    form1099IntAggregateValuesSchema,
    form1099KAggregateValuesSchema,
    form1099MiscAggregateValuesSchema,
    form1099NecAggregateValuesSchema,
    formGainLossSummaryAggregateValuesSchema,
    formGainLossPdfAggregateValuesSchema,
    formItem1099BAggregateValuesSchema,
    formItem1099DivAggregateValuesSchema,
    formItem1099IntAggregateValuesSchema,
    formItem1099KAggregateValuesSchema,
    formItem1099MiscAggregateValuesSchema,
    formItem1099NecAggregateValuesSchema,
    formItem1099RAggregateValuesSchema,
    formItem5498AggregateValuesSchema,
    formItemRmdStatementAggregateValuesSchema,
    formItemGainLossSummaryAggregateValuesSchema,
    formItemGainLossPdfValuesSchema,
  ]
);
export type EligibilityFormSpecificFields = z.infer<
  typeof eligibilityFormSpecificFieldsSchema
>;

export const eligibilityDataItemSchema = z.object({
  name: z.string().optional(),
  accountId: accountIdSchema,
  accountExternalId: z.string(),
  accountAlternateExternalId: z.string().optional(),
  fmvAmount: z.string().optional(),
  status: eligibilityStatusSchema,
  isFormData: z.boolean().optional(),
  hasFormGenerated: z.boolean().optional(),
  formStatus: eligibilityFormStatusSchema.optional(),
  accountValidationsEligibilityStatus:
    accountValidationsEligibilityStatusSchema.optional(),
  accountValidations: accountValidationSchema.array().optional(),
  taxYearTotalTaxableTransactionCount: z.number().optional(),
  historicalTotalTransactionCount: z.number().optional(),
  formSpecificFields: eligibilityFormSpecificFieldsSchema.optional(),
});
export type EligibilityDataItem = z.infer<typeof eligibilityDataItemSchema>;

const eligibilityDataSchema = z.object({
  documentType: dashboardFormTypeSchema,
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  items: eligibilityDataItemSchema.array(),
});

export const getEligibilityDataResponseSchema = getPublicApiSuccessSchema(
  eligibilityDataSchema
);

export enum EligibilityReportType {
  AccountDataReport = "account-data-report",
  DataValidationReport = "data-validation-report",
  FormDataReport = "form-data-report",
}

export const eligibilityReportTypeSchema = z.nativeEnum(EligibilityReportType);

export const createEligibilityExportBodyDtoSchema = z.object({
  reportTypes: eligibilityReportTypeSchema.array(),
  externalIds: z.string().array().optional(),
  includeColumns: z.string().array().optional(),
});

export type CreateEligibilityExportBodyDto = z.infer<
  typeof createEligibilityExportBodyDtoSchema
>;

export const createEligibilityExportResponseSchema = getPublicApiSuccessSchema(
  z.object({
    exportId: uuidSchema,
  })
);
export type CreateEligibilityExportResponse = z.infer<
  typeof createEligibilityExportResponseSchema
>;

export const createEligibilityOverrideSchema = z.object({
  accountId: accountIdSchema,
  documentType: dashboardFormTypeSchema,
  documentYear: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  overrideStatus: z.string(),
});
export type CreateEligibilityOverride = z.infer<
  typeof createEligibilityOverrideSchema
>;

export const createEligibilityBatchOverridesSchema = z.object({
  data: createEligibilityOverrideSchema.array(),
});
export type CreateEligibilityBatchOverrides = z.infer<
  typeof createEligibilityBatchOverridesSchema
>;

const eligibilityBatchOverridesResponseSchema = z.object({
  success: createEligibilityOverrideSchema.array(),
  unprocessed: createEligibilityOverrideSchema.array(),
});

export const createEligibilityBatchOverridesResponseSchema =
  getPublicApiSuccessSchema(eligibilityBatchOverridesResponseSchema);
export type CreateEligibilityBatchOverridesResponse = z.infer<
  typeof createEligibilityBatchOverridesResponseSchema
>;

const eligibilityAlertsResponseSchema = z.object({
  hasFormDataForNonValidTaxCountryCode: z.number(),
  hasFormDataUnderThreshold: z.number(),
});

export const getEligibilityAlertsResponseSchema = getPublicApiSuccessSchema(
  eligibilityAlertsResponseSchema
);

const createEligibilityQaPackageSchema = z.object({
  documentType: dashboardFormTypeSchema,
  documentYear: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  accountIds: z.string().array(),
  should1099bIncludeCostBasis: z.boolean().optional(),
});

export const createEligibilityQaPackageRequestSchema =
  getPublicApiSuccessSchema(createEligibilityQaPackageSchema);

export type CreateEligibilityQaPackageRequest = z.infer<
  typeof createEligibilityQaPackageRequestSchema
>;

export const eligibilityRecalcRequestSchema = z.object({
  year: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  formTypes: z.array(dashboardFormTypeSchema),
});
export type EligibilityRecalcRequest = z.infer<
  typeof eligibilityRecalcRequestSchema
>;

export const eligibilityFindAccountsRequestSchema = z.object({
  filters: z.object({
    accountValidationsEligibilityStatus:
      accountValidationsEligibilityStatusSchema.array().optional(),
    documentDate: fourDigitYearSchema,
    documentType: dashboardFormTypeSchema,
    externalId: z.string().optional(),
    externalIds: z.string().array().optional(),
    alternateExternalId: z.string().optional(),
    formStatus: dashboardEligibilityFormStatusSchema.array().optional(),
    name: z.string().optional(),
    status: eligibilityStatusSchema.array().optional(),
  }),
  page: pageSchema.optional(),
});
export type EligibilityFindAccountsRequest = z.infer<
  typeof eligibilityFindAccountsRequestSchema
>;
