import { getOffsetFromPage } from "@taxbit-dashboard/commons";
import {
  IrFormStatus,
  IrFormType,
  SearchIrFormsParams as ApiSearchIrFormsParams,
  GenerateTaxFormsRequest,
  EligibilityDataItem,
  DashboardFormType,
  BaseIrFormsFilters,
} from "@taxbit-dashboard/rest";
import {
  IrFormsUrlParams,
  IrEligibilityUrlParams,
} from "@taxbit-dashboard/router";

import newlineTextBasedFilterToArray from "../../../utils/newlineTextBasedFilterToArray";
import { AccountExternalIdsFilter } from "../irApiTypes";

export const formsSearchParamKeys = [
  "accountExternalId",
  "name",
  "accountAlternateExternalId",
] as const;

export type FormsSearchParamKey = (typeof formsSearchParamKeys)[number];

export const formsSearchParamKeyLabelMap: Record<FormsSearchParamKey, string> =
  {
    accountExternalId: "Account ID",
    name: "Name",
    accountAlternateExternalId: "Alternate Account ID",
  };

export const formsEnumParamKeys = ["statuses", "types"] as const;

export const formTypeToLabelMap: Record<IrFormType, string> = {
  [IrFormType.Original]: "Original",
  [IrFormType.Correction]: "Correction",
  [IrFormType.Void]: "Void",
};

export const formStatusToLabelMap: Record<IrFormStatus, string> = {
  [IrFormStatus.Generated]: "Generated",
  [IrFormStatus.Filed]: "Filed",
};

export type SearchIrFormsParams = IrFormsUrlParams & AccountExternalIdsFilter;

export const toBaseApiFormsFilters = ({
  accountExternalIdsFilter,
  accountExternalId,
  accountAlternateExternalId,
  startDate,
  endDate,
  statuses,
  types,
  name,
}: Omit<IrFormsUrlParams, "sort" | "page" | "limit"> &
  AccountExternalIdsFilter): BaseIrFormsFilters => {
  const accountExternalIds = newlineTextBasedFilterToArray(
    accountExternalIdsFilter
  );
  return {
    accountExternalId,
    accountExternalIds:
      accountExternalIds.length === 0 ? undefined : accountExternalIds,
    accountAlternateExternalId,
    dateGenerated:
      startDate || endDate
        ? {
            gte: startDate,
            lte: endDate,
          }
        : undefined,
    name,
    statuses,
    types,
  };
};

export const toApiSearchIrFormsParams = ({
  sort,
  page,
  limit,
  formYear,
  formType,
  statuses,
  types,
  startDate,
  endDate,
  accountExternalIdsFilter,
  accountExternalId,
  accountAlternateExternalId,
  name,
  useReportingProfile,
}: SearchIrFormsParams): ApiSearchIrFormsParams => {
  const baseFilters = toBaseApiFormsFilters({
    accountExternalIdsFilter,
    accountExternalId,
    accountAlternateExternalId,
    startDate,
    endDate,
    statuses,
    types,
    name,
    formYear,
    formType,
  });

  return {
    filters: {
      ...baseFilters,
      documentDate: formYear,
      documentType: formType,
    },
    sort,
    page: {
      limit,
      offset: getOffsetFromPage({ page, limit }),
    },
    useReportingProfile,
  };
};

export type GenerateTaxFormsMutationParams = {
  shouldIncludeFilters: boolean;
  shouldCompareData: boolean;
  eligibilityItems?: EligibilityDataItem[];
} & AccountExternalIdsFilter;

export const toGenerateTaxFormsRequest = ({
  accountExternalIdsFilter,
  accountValidationsEligibilityStatuses,
  eligibilityItems,
  externalId,
  formYear,
  formStatuses,
  formType,
  name,
  shouldCompareData,
  shouldForm1099BIncludeCostBasis,
  shouldIncludeFilters,
  statuses,
}: GenerateTaxFormsMutationParams &
  IrEligibilityUrlParams & {
    shouldForm1099BIncludeCostBasis: boolean;
  }): GenerateTaxFormsRequest => {
  const externalIds = newlineTextBasedFilterToArray(accountExternalIdsFilter);

  const filters = shouldIncludeFilters
    ? {
        accountValidationsEligibilityStatus:
          accountValidationsEligibilityStatuses,
        externalId,
        externalIds: externalIds.length > 0 ? externalIds : undefined,
        formStatus: formStatuses,
        name,
        status: statuses === "None" ? undefined : statuses,
      }
    : {};

  return {
    data: {
      accountIds: eligibilityItems?.map(({ accountId }) => accountId),
      documentDate: formYear,
      documentType: formType,
      should1099bIncludeCostBasis:
        formType === DashboardFormType.Irs1099B
          ? shouldForm1099BIncludeCostBasis
          : undefined,
      shouldCompareSnapshots: shouldCompareData,
      ...filters,
    },
  };
};
