import { utcDateStringSchema } from "@taxbit-private/cosmic";
import { boolean, z } from "zod";

import { assetAmountSchema, lineItemSchema } from "./assetApiTypes";
import { accountIdSchema } from "./sharedApiTypes";
import { transactionIdSchema } from "./transactionsRestApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const gainTypeSchema = z.enum([
  "short-term",
  "long-term",
  "uk-same-day",
  "uk-30-day",
  "uk-section-104",
]);

export type GainType = z.infer<typeof gainTypeSchema>;

export const gainItemSchema = z.object({
  userId: accountIdSchema,
  dispositionTransactionId: transactionIdSchema,
  acquisitionTransactionId: transactionIdSchema.optional(),
  amountSold: lineItemSchema.optional(),
  gain: lineItemSchema.optional(),
  cost: lineItemSchema.optional(),
  proceeds: lineItemSchema.optional(),
  gainType: gainTypeSchema.optional(),
  costBasisDate: utcDateStringSchema.optional(),
  accountOwnerEdited: boolean().optional(),
});

export type GainItem = z.infer<typeof gainItemSchema>;

export const getGainsSchema = getPublicApiSuccessSchema(gainItemSchema.array());
export type GetGains = z.infer<typeof getGainsSchema>;

const aggregatedGainSchema = z.object({
  count: z.number(),
  totalCost: assetAmountSchema.optional(),
  totalGain: assetAmountSchema.optional(),
  totalProceeds: assetAmountSchema.optional(),
});

export const getGainsSummaryResponseSchema = getPublicApiSuccessSchema(
  aggregatedGainSchema.array()
);
