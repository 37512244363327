import {
  useInfiniteQuery,
  QueryFunctionContext,
  useQuery,
  useMutation,
} from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  createQueryMetaObject,
  filterToDefinedValues,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  AccountId,
  TransactionId,
  CreateTransactionsExportRequest,
  GetTransactionsTableRequest,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";

export const useGetTransaction = (
  accountId: AccountId,
  transactionId: TransactionId
) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.Transactions, transactionId, accountId],
    () => restSdk.transaction.get({ accountId, transactionId }),
    createQueryMetaObject(restSdk.transaction.buildPath(accountId))
  );

  return unwrapPublicApiWrappedQuery(query);
};

const getTransactionsQueryKeys = (accountId?: AccountId, limit?: number) =>
  [{ key: DashboardQueryKey.Transactions, accountId, limit }] as const;

export const useGetTransactions = (
  accountUuid?: AccountId,
  transactionsLimit?: number
) => {
  const restSdk = useTaxBitRest();

  const fetchTransactions = ({
    queryKey: [{ accountId, limit }],
    pageParam,
  }: QueryFunctionContext<
    ReturnType<typeof getTransactionsQueryKeys>,
    string
  >) => {
    return accountId
      ? restSdk.transactions.get({
          accountId,
          next: pageParam,
          limit,
        })
      : undefined;
  };

  const query = useInfiniteQuery(
    getTransactionsQueryKeys(accountUuid, transactionsLimit),
    fetchTransactions,
    {
      ...createQueryMetaObject(restSdk.transactions.buildPath(accountUuid)),
      getNextPageParam: (lastPage) => lastPage?.meta?.page?.next,
    }
  );

  const data = useMemo(
    () =>
      filterToDefinedValues(query.data?.pages)?.flatMap((page) => page.data),
    [query]
  );

  return { ...query, data };
};

const getTransactionsCsvQueryKeys = (
  accountId?: AccountId,
  pageParam?: string
) =>
  [{ key: DashboardQueryKey.TransactionsCsv, accountId, pageParam }] as const;

export const useGetTransactionsForCsv = ({
  accountUuid,
  cursor,
  enabled,
}: {
  accountUuid?: AccountId;
  cursor?: string;
  enabled?: boolean;
}) => {
  const restSdk = useTaxBitRest();

  const fetchTransactions = ({
    queryKey: [{ accountId, pageParam }],
  }: QueryFunctionContext<
    ReturnType<typeof getTransactionsCsvQueryKeys>,
    string
  >) => {
    return accountId
      ? restSdk.transactions.get({
          accountId,
          next: pageParam,
        })
      : undefined;
  };

  return useQuery(
    getTransactionsCsvQueryKeys(accountUuid, cursor),
    fetchTransactions,
    {
      ...createQueryMetaObject(restSdk.transactions.buildPath(accountUuid)),
      enabled,
      // consolidating staleTime and cacheTime to 60 seconds
      // if stale time is different from cache time, it will cause a bug where
      // the page will freeze when the user clicks the download button the second time
      staleTime: 60 * 1000,
      cacheTime: 60 * 1000,
    }
  );
};

export const useGetTransactionsForTable = (
  requestData: GetTransactionsTableRequest
) => {
  const restSdk = useTaxBitRest();
  const { hasTransactionsPageAccess } = useDashboardFeatureFlags();

  const queryKey = useMemo(() => {
    return [DashboardQueryKey.TransactionsTable, ...Object.values(requestData)];
  }, [requestData]);

  const query = useQuery(
    queryKey,
    () => restSdk.transactionsTable.post(requestData),
    {
      ...createQueryMetaObject(restSdk.transactionsTable.buildPath()),
      enabled: hasTransactionsPageAccess,
      keepPreviousData: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useCreateTransactionsExport = () => {
  const restSdk = useTaxBitRest();
  return useMutation(
    (requestData: CreateTransactionsExportRequest) =>
      restSdk.transactionsTable.exports.post(requestData),
    {
      ...createQueryMetaObject(restSdk.eligibility.exports.buildPath()),
    }
  );
};
