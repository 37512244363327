import { useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { createStore } from "zustand/vanilla";

import createAuthSlice from "./auth/createAuthSlice";
import createDedupedStoreFromSlices from "./createDedupedStoreFromSlices";
import { DashboardStore } from "./dashboardStoreTypes";
import createNotificationSlice from "./notification/createNotificationSlice";

/**
 * The vanillaStore can be accessed directly via imports without
 * the use of the `useDashboardStore` React hook.
 *
 * https://github.com/pmndrs/zustand#using-zustand-without-react
 */
export const vanillaStore = createStore<DashboardStore>()(
  devtools((...a) =>
    createDedupedStoreFromSlices([
      createAuthSlice(...a),
      createNotificationSlice(...a),
    ])
  )
);

/**
 * The hook to access global state values and their setters across the dashboard.
 * The state setup adheres to the slices pattern described in the [Zustand docs](https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern),
 * with the exception of our own helper that validates the uniqueness of state keys.
 */
const useDashboardStore = <T extends unknown>(
  selector: (state: DashboardStore) => T
) => useStore(vanillaStore, selector);

export default useDashboardStore;
