import {
  DashboardFormType,
  dashboardFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { z } from "zod";

/**
 * This helper method is a temporary solution to map CESOP & DAC7 to 1099-K
 * so that we can have a somewhat realistic demo environment for those form
 * types. It is a hack and shouldn't be used when we have real implementations.
 */
export const mapDashboardFormTypeToApiFormType = (
  formType: DashboardFormType
): DashboardFormType => {
  if ([DashboardFormType.Cesop, DashboardFormType.Dac7].includes(formType)) {
    return DashboardFormType.Irs1099K;
  } else {
    return formType;
  }
};

export const irFormTypeDateParamsSchema = z.object({
  formType: dashboardFormTypeSchema,
  formYear: fourDigitYearSchema,
});
export type IrFormTypeDateParams = z.infer<typeof irFormTypeDateParamsSchema>;

export type AccountExternalIdsFilter = { accountExternalIdsFilter: string };

export type EligibilityExportFormatParams = {
  includeAlternateAccountId: boolean;
};
