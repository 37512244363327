import { createFileRoute, redirect } from "@tanstack/react-router";

import { RouteId } from "../types/RouteId";
import getOrganizationPrefixedSubroute from "../utils/getOrganizationPrefixedSubroute";
import getPathForId from "../utils/getPathForId";
import { isMigratedRoute, migratedRouteMap } from "../utils/migratedRoutes";

export const Route = createFileRoute(RouteId.AuthedLayout)({
  /**
   * This redirect ensures that navigations to a migrated route are redirected to
   * the correct location. It should be removed as soon as we are confident that all
   * Taxbit-provided links (ex: help articles) have been updated to the new routes
   *
   * TODO: https://linear.app/taxbit/issue/DASH-73/remove-backwards-compatibility-infrastructure-for-router-migration
   */
  beforeLoad: ({ location }) => {
    const subroute = getOrganizationPrefixedSubroute(location.pathname);
    if (subroute && isMigratedRoute(subroute)) {
      const path = getPathForId(migratedRouteMap[subroute]);
      redirect({ to: path, throw: true });
    }
  },
});
