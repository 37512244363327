import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";
import { FileAction, FilesApiFile } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
  Flex,
  Table,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import { useCallback, useState } from "react";

import EditFileDescriptionModal from "./edit-file-description-modal/EditFileDescriptionModal";
import FilesTableRowActionMenu from "./FilesTableRowActionMenu";
import FileTypeFilter from "./FileTypeFilter";
import RemoveUploadModal from "./remove-upload-modal/RemoveUploadModal";
import ReviewIngestionModal from "./review-ingestion-modal/ReviewIngestionModal";
import useGetFilesTableData from "./useFilesTableData";
import useFileUploaderContext from "../../context/useFileUploaderContext";

export enum FilesTableCardTrackingId {
  LoadingSpinner = "files-table-card-spinner",
  Pagination = "files-table-card-pagination",
  CosmicTable = "files-table",
}

type Props = {
  action: FileAction;
};

const FilesTableCard: React.FC<Props> = ({ action }) => {
  const [fileToEdit, setFileToEdit] = useState<FilesApiFile>();
  const [fileToRemove, setFileToRemove] = useState<FilesApiFile>();
  const [fileToReview, setFileToReview] = useState<FilesApiFile>();

  const { columns, rows, isLoading, isFetching, isError, totalCount } =
    useGetFilesTableData(action);
  const { urlParams, updateUrlParams } = useFileUploaderContext(action);

  const getRowUtilityElement = useCallback(
    (file: FilesApiFile) => {
      return (
        <FilesTableRowActionMenu
          action={action}
          file={file}
          onEditClick={() => setFileToEdit(file)}
          onRemoveClick={() => setFileToRemove(file)}
          onReviewClick={() => setFileToReview(file)}
        />
      );
    },
    [action]
  );

  return (
    <>
      {fileToEdit && (
        <EditFileDescriptionModal
          isOpen={!!fileToEdit}
          onClose={() => setFileToEdit(undefined)}
          file={fileToEdit}
        />
      )}
      {fileToRemove && (
        <RemoveUploadModal
          isOpen={!!fileToRemove}
          onClose={() => setFileToRemove(undefined)}
          file={fileToRemove}
        />
      )}
      {fileToReview && (
        <ReviewIngestionModal
          isOpen={!!fileToReview}
          onClose={() => setFileToReview(undefined)}
          action={action}
          file={fileToReview}
        />
      )}
      <Card title="Files" utilityElement={<FileTypeFilter action={action} />}>
        <TrimmedBoxContent trim="bottom">
          {isLoading ? (
            <ContentSpinner
              trackingId={FilesTableCardTrackingId.LoadingSpinner}
            />
          ) : isError ? (
            <ContentErrorEmptyState entity="files" />
          ) : rows.length === 0 ? (
            <ContentEmptyState
              avatarProps={{
                variant: "secondary",
                iconName: "file",
              }}
              title="No results"
              subtitleElement="No uploaded files."
            />
          ) : (
            <Flex direction="column">
              <Table
                trackingId={FilesTableCardTrackingId.CosmicTable}
                rows={rows}
                columns={columns}
                getRowKey={({ fileId }) => fileId}
                getRowUtilityElement={getRowUtilityElement}
                isLoading={isFetching}
              />
              <Divider />
              <UrlParamPaginationControls
                urlParams={urlParams}
                updateUrlParams={updateUrlParams}
                trackingId={FilesTableCardTrackingId.Pagination}
                totalCount={totalCount}
              />
            </Flex>
          )}
        </TrimmedBoxContent>
      </Card>
    </>
  );
};

export default FilesTableCard;
