import { PageLimitPaginationParams } from "@taxbit-dashboard/router";
import {
  CosmicPageSize,
  PaginationFooter,
  PaginationFooterProps,
} from "@taxbit-private/cosmic";
import { useCallback } from "react";
import { O } from "ts-toolbelt";

import calculatePageNumberForUpdatedPageSize from "../utils/calculatePageNumberForUpdatedPageSize";

export type UrlParamPaginationControlsProps = {
  urlParams: PageLimitPaginationParams;
  updateUrlParams: (
    newParams: Partial<PageLimitPaginationParams>
  ) => Promise<void>;
} & O.Omit<
  PaginationFooterProps,
  "currentPage" | "setCurrentPage" | "setPageSize" | "pageSize"
>;

const UrlParamPaginationControls: React.FC<UrlParamPaginationControlsProps> = ({
  urlParams,
  updateUrlParams,
  ...paginationFooterProps
}) => {
  const { page: currentPage, limit: currentPageSize } = urlParams;

  const setCurrentPage = useCallback(
    (nextPage: number) => updateUrlParams({ page: nextPage }),
    [updateUrlParams]
  );

  const setPageSize = useCallback(
    (nextPageSize: CosmicPageSize) => {
      const page = calculatePageNumberForUpdatedPageSize({
        currentPageSize,
        currentPage,
        nextPageSize,
      });

      void updateUrlParams({
        page,
        limit: nextPageSize,
      });
    },
    [currentPage, currentPageSize, updateUrlParams]
  );

  return (
    <PaginationFooter
      {...paginationFooterProps}
      currentPage={currentPage}
      setCurrentPage={(page) => {
        void setCurrentPage(page);
      }}
      setPageSize={setPageSize}
      pageSize={currentPageSize}
    />
  );
};

export default UrlParamPaginationControls;
