import { FileAction } from "@taxbit-dashboard/rest";
import { Dropdown } from "@taxbit-private/cosmic";

import {
  fileActionFileTypesMap,
  fileTypeLabelMap,
} from "../../../../api/files/filesApiTypes";
import useFileUploaderContext from "../../context/useFileUploaderContext";

const ALL_OPTION = {
  key: "ALL",
  label: "All",
} as const;

const FILE_TYPE_OPTIONS = (action: FileAction) =>
  Object.values(fileActionFileTypesMap[action]).map(
    (type) =>
      ({
        key: type,
        label: fileTypeLabelMap[type],
      }) as const
  );

const OPTIONS = (action: FileAction) =>
  [ALL_OPTION, ...FILE_TYPE_OPTIONS(action)] as const;

export const FILE_TYPE_FILTER_TRACKING_ID = "file-type-filter";

type Props = {
  action: FileAction;
};

const FileTypeFilter: React.FC<Props> = ({ action }) => {
  const { fileTypeFilter, setFileTypeFilter } = useFileUploaderContext(action);

  const value =
    fileTypeFilter.length === 1
      ? FILE_TYPE_OPTIONS(action).find(({ key }) => key === fileTypeFilter[0])
      : ALL_OPTION;

  return (
    <Dropdown
      trackingId={FILE_TYPE_FILTER_TRACKING_ID}
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      onChange={({ key }) => {
        void setFileTypeFilter(key === "ALL" ? undefined : key);
      }}
      options={OPTIONS(action)}
      value={value}
    />
  );
};

export default FileTypeFilter;
