import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { Dropdown, Flex } from "@taxbit-private/cosmic";

import {
  CESOP_STATE_MEMBERS,
  YEARS_WITH_QUARTER_VALUES,
  currentStateMember,
  currentYearWithQuarter,
} from "../cesopDemoValues";
import useUrlParamsWithFormTypeAndYear from "./useUrlParamsWithFormTypeAndYear";

export enum FormTypeAndYearDropdownsTrackingId {
  FormDropdown = "ir-form-dropdown",
  YearDropdown = "ir-year-dropdown",
  StateMemberDropdown = "ir-state-member-dropdown",
  YearQuarterDropdown = "ir-year-quarter-dropdown",
}

type Props = Pick<
  ReturnType<typeof useUrlParamsWithFormTypeAndYear>,
  "forms" | "years" | "onFormChange" | "onYearChange" | "urlParams"
> & {
  isDisabled: boolean;
  size?: "small" | "default";
};

const FormTypeAndYearDropdowns: React.FC<Props> = ({
  forms,
  years,
  onFormChange,
  onYearChange,
  isDisabled,
  size = "default",
  urlParams: { formYear, formType },
}) => {
  const additionalDropdowns = (() => {
    if (formType === DashboardFormType.Cesop) {
      return (
        <>
          <Dropdown
            options={CESOP_STATE_MEMBERS}
            value={currentStateMember}
            getOptionKey={(value) => value.toString()}
            getOptionLabel={(value) => value.toString()}
            onChange={() => undefined}
            trackingId={FormTypeAndYearDropdownsTrackingId.StateMemberDropdown}
            size={size}
            isDisabled={isDisabled}
          />
          <Dropdown
            options={YEARS_WITH_QUARTER_VALUES}
            value={currentYearWithQuarter}
            getOptionKey={(value) => value.toString()}
            getOptionLabel={(value) => value.toString()}
            onChange={() => undefined}
            trackingId={FormTypeAndYearDropdownsTrackingId.YearQuarterDropdown}
            size={size}
            isDisabled={isDisabled}
          />
        </>
      );
    }

    return (
      <Dropdown
        options={years}
        value={formYear}
        getOptionKey={(value) => value.toString()}
        getOptionLabel={(value) => value.toString()}
        onChange={(year) => {
          void onYearChange(year);
        }}
        trackingId={FormTypeAndYearDropdownsTrackingId.YearDropdown}
        size={size}
        isDisabled={isDisabled}
      />
    );
  })();

  return (
    <Flex gap="s">
      <Dropdown
        options={forms}
        value={formType}
        getOptionKey={(value) => value}
        getOptionLabel={(value) => irFormTypeToDisplayNameMap[value]}
        onChange={(form) => {
          void onFormChange(form);
        }}
        trackingId={FormTypeAndYearDropdownsTrackingId.FormDropdown}
        size={size}
        isDisabled={isDisabled}
      />
      {additionalDropdowns}
    </Flex>
  );
};

export default FormTypeAndYearDropdowns;
