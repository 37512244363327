import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { BodySmall } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { differenceInCalendarDays, startOfToday } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useMemo } from "react";

import getFormDeadlinesByFormTypeDate from "./getFormDeadlinesByFormTypeDate";
import { useIrSummaryDataContext } from "../useIrSummaryData";

const differenceBetweenNowAndDeadline = (deadline: Date, now: Date) => {
  return Math.max(differenceInCalendarDays(deadline, now), 0);
};

const getVariantBasedOnDaysRemaining = (daysRemaining = 0) =>
  daysRemaining > 0 && daysRemaining <= 7 ? "danger" : "primary";

const useDeadlineSlots = () => {
  const {
    urlParams: { formYear, formType },
  } = useIrSummaryDataContext();
  const {
    recipientCopyDate,
    recipientCopyExtendedDate,
    eFileDate,
    eFileExtendedDate,
  } = getFormDeadlinesByFormTypeDate(formYear, formType) ?? {};
  const { formatDateTime } = useCosmicLocalizationContext();

  return useMemo(() => {
    // Convert current date to UTC to compare deadlines
    // in the same timezone
    const nowInUtc = zonedTimeToUtc(
      startOfToday(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    return filterToDefinedValues([
      recipientCopyDate && {
        title: formatDateTime({
          date: recipientCopyDate,
          tz: "ET",
        }),
        subtitle: "Recipient Copy Deadline",
        content: (
          <BodySmall
            colorVariant={getVariantBasedOnDaysRemaining(
              differenceBetweenNowAndDeadline(recipientCopyDate, nowInUtc)
            )}
          >
            Days Remaining:{" "}
            {differenceBetweenNowAndDeadline(recipientCopyDate, nowInUtc)}
          </BodySmall>
        ),
        key: "recipientCopyDeadline",
      },
      recipientCopyExtendedDate && {
        title: formatDateTime({
          date: recipientCopyExtendedDate,
          tz: "ET",
        }),
        subtitle: "Recipient Copy Extension Deadline",
        content: (
          <BodySmall
            colorVariant={getVariantBasedOnDaysRemaining(
              differenceBetweenNowAndDeadline(
                recipientCopyExtendedDate,
                nowInUtc
              )
            )}
          >
            Days Remaining:{" "}
            {differenceBetweenNowAndDeadline(
              recipientCopyExtendedDate,
              nowInUtc
            )}
          </BodySmall>
        ),
        key: "recipientCopyExtensionDeadline",
      },
      eFileDate && {
        title: formatDateTime({ date: eFileDate, tz: "ET" }),
        subtitle: "eFile Deadline",
        content: (
          <BodySmall
            colorVariant={getVariantBasedOnDaysRemaining(
              differenceBetweenNowAndDeadline(eFileDate, nowInUtc)
            )}
          >
            Days Remaining:{" "}
            {differenceBetweenNowAndDeadline(eFileDate, nowInUtc)}
          </BodySmall>
        ),
        key: "efileDeadline",
      },
      eFileExtendedDate && {
        title: formatDateTime({
          date: eFileExtendedDate,
          tz: "ET",
        }),
        subtitle: "Filing Extension Deadline",
        content: (
          <BodySmall
            colorVariant={getVariantBasedOnDaysRemaining(
              differenceBetweenNowAndDeadline(eFileExtendedDate, nowInUtc)
            )}
          >
            Days Remaining:{" "}
            {differenceBetweenNowAndDeadline(eFileExtendedDate, nowInUtc)}
          </BodySmall>
        ),
        key: "filingExtensionDeadline",
      },
    ]);
  }, [
    recipientCopyDate,
    formatDateTime,
    recipientCopyExtendedDate,
    eFileDate,
    eFileExtendedDate,
  ]);
};

export default useDeadlineSlots;
