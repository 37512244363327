import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";

import { useTransactionsTableContext } from "./useTransactionsTable";

export enum TransactionsTablePaginationTrackingId {
  Pagination = "transactions-table-pagination",
}

type TransactionsTablePaginationProps = {
  isDisabled: boolean;
  totalCount: number;
};

const TransactionsTablePagination: React.FC<
  TransactionsTablePaginationProps
> = ({ isDisabled, totalCount }) => {
  const { urlParams, updateUrlParams } = useTransactionsTableContext();

  return (
    <UrlParamPaginationControls
      isDisabled={isDisabled}
      urlParams={urlParams}
      updateUrlParams={updateUrlParams}
      totalCount={totalCount}
      trackingId={TransactionsTablePaginationTrackingId.Pagination}
    />
  );
};

export default TransactionsTablePagination;
