import {
  ActionBarWrapper,
  UserPermission,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { BulkActionBar, IconName } from "@taxbit-private/cosmic";
import { useState } from "react";

import GenerateTaxFormsConfirmationModal from "./GenerateTaxFormsConfirmationModal";
import useEligibilityQaPackageDownload from "./useEligibilityQaPackageDownload";
import useFormFeatureFlagData from "../../../shared/form-year-dropdowns/useFormFeatureFlagData";
import { useEligibilityDataContext } from "../../context/useEligibilityData";
import AddToScopeConfirmationModal from "../../remove-from-scope/AddToScopeConfirmationModal";
import RemoveFromScopeConfirmationModal from "../../remove-from-scope/RemoveFromScopeConfirmationModal";

export enum IrEligibilityActionBarTrackingId {
  AddToScope = "ir-eligibility-add-to-scope-action-btn",
  RemoveFromScope = "ir-eligibility-remove-from-scope-action-btn",
  DownloadQaPackage = "ir-eligibility-download-qa-package-action-btn",
  GenerateForms = "ir-eligibility-generate-forms-action-btn",
  ActionBar = "ir-eligibility-action-bar",
}

const EligibilityActionBar = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const {
    currentForm,
    currentYear,
    selectedItems,
    shouldDisableControls,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();

  const { onDownloadQaPackage } = useEligibilityQaPackageDownload();

  const hasReadTinsPermission = useUserPermission(UserPermission.ReadTins);
  const hasCreateTaxFormsPermission = useUserPermission(
    UserPermission.CreateTaxForms
  );

  const { hasYearForForm } = useFormFeatureFlagData({
    featureFlag: "generateFormsYearsByFormType",
  });

  return (
    selectedItems.length > 0 &&
    !shouldDisableControls && (
      <>
        <ActionBarWrapper>
          <BulkActionBar
            selectedCount={selectedItems.length}
            trackingId={IrEligibilityActionBarTrackingId.ActionBar}
            onClear={() => toggleAllSelectedItems(false)}
            buttonsProps={[
              {
                label: "Add to Scope",
                iconName: "plus-circle",
                onClick: () => setIsAddModalOpen(true),
                trackingId: IrEligibilityActionBarTrackingId.AddToScope,
                key: "add",
              },
              {
                label: "Remove from Scope",
                iconName: "minus-circle",
                onClick: () => setIsRemoveModalOpen(true),
                trackingId: IrEligibilityActionBarTrackingId.RemoveFromScope,
                key: "remove",
              },
              ...(hasReadTinsPermission
                ? [
                    {
                      label: "Download QA Package",
                      iconName: "download" as IconName,
                      trackingId:
                        IrEligibilityActionBarTrackingId.DownloadQaPackage,
                      key: "download",
                      onClick: onDownloadQaPackage,
                    },
                  ]
                : []),
              ...(hasCreateTaxFormsPermission &&
              hasYearForForm(currentForm, currentYear)
                ? [
                    {
                      label: "Generate Forms",
                      iconName: "check-circle" as IconName,
                      trackingId:
                        IrEligibilityActionBarTrackingId.GenerateForms,
                      key: "generate",
                      onClick: () => setIsGenerateModalOpen(true),
                    },
                  ]
                : []),
            ]}
          />
        </ActionBarWrapper>
        {isAddModalOpen && (
          <AddToScopeConfirmationModal
            isOpen={isAddModalOpen}
            onClose={() => setIsAddModalOpen(false)}
            eligibilityItems={selectedItems}
          />
        )}
        {isRemoveModalOpen && (
          <RemoveFromScopeConfirmationModal
            isOpen={isRemoveModalOpen}
            onClose={() => setIsRemoveModalOpen(false)}
            eligibilityItems={selectedItems}
          />
        )}
        {isGenerateModalOpen && (
          <GenerateTaxFormsConfirmationModal
            shouldIgnoreSelectedItems={false}
            isOpen={isGenerateModalOpen}
            onClose={() => setIsGenerateModalOpen(false)}
          />
        )}
      </>
    )
  );
};

export default EligibilityActionBar;
