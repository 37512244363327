import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { MAX_SEARCH_VALUE_LENGTH } from "@taxbit-dashboard/router";
import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useMemo, useState } from "react";
import styled from "styled-components";

import {
  EligibilitySearchParamKey,
  eligibilitySearchParamKeyLabelMap,
} from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum EligibilityScopedSearchBarTrackingId {
  SearchBar = "irEligibilityScopedSearchBar",
  SearchButton = "irEligibilityScopedSearchButton",
}

type SearchKeyOption = {
  key: EligibilitySearchParamKey;
  label: string;
};

const DEFAULT_OPTIONS = [
  { key: "externalId", label: eligibilitySearchParamKeyLabelMap.externalId },
  { key: "name", label: eligibilitySearchParamKeyLabelMap.name },
] as const;

const ALT_ID_OPTION: SearchKeyOption = {
  key: "alternateExternalId",
  label: eligibilitySearchParamKeyLabelMap.alternateExternalId,
};

const EligibilityScopedSearchBar = () => {
  const { updateFilterParams, shouldDisableControls, urlParams } =
    useEligibilityDataContext();

  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const [searchKey, setSearchKey] = useState<SearchKeyOption>(
    DEFAULT_OPTIONS[0]
  );

  const [searchValue, setSearchValue] = useState("");

  const trimmedValue = searchValue.trim();
  const isValueEmpty = !trimmedValue;
  const isValueTooLong = trimmedValue.length > MAX_SEARCH_VALUE_LENGTH;
  const hasAlreadyAddedFilter = !!urlParams[searchKey.key];

  const isSubmitDisabled =
    isValueEmpty ||
    hasAlreadyAddedFilter ||
    shouldDisableControls ||
    isValueTooLong;

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    updateFilterParams({
      [searchKey.key]: trimmedValue,
    });

    setSearchValue("");
  };

  const options = useMemo(
    () =>
      showAlternateExternalId
        ? [...DEFAULT_OPTIONS, ALT_ID_OPTION]
        : DEFAULT_OPTIONS,
    [showAlternateExternalId]
  );

  const tooltipProps = (() => {
    const label = eligibilitySearchParamKeyLabelMap[searchKey.key];
    const casedKey = (label[0]?.toLocaleLowerCase() ?? "") + label.slice(1);

    if (hasAlreadyAddedFilter) {
      return {
        content: `You can only search for one ${casedKey} at a time. Please clear your existing search to search by another ${casedKey}.`,
      };
    } else if (isValueEmpty) {
      return {
        content: `The ${casedKey} should not be empty.`,
      };
    } else if (isValueTooLong) {
      return {
        content: `The ${casedKey} should not exceed 100 characters.`,
      };
    } else {
      return undefined;
    }
  })();

  return (
    <FlexForm
      onSubmit={handleSearchSubmit}
      aria-label="eligibility-search-form"
    >
      <FullWidthScopedSearchBar
        trackingId={EligibilityScopedSearchBarTrackingId.SearchBar}
        label="Search by name or external ID"
        isLabelHidden={true}
        size="small"
        dropdownProps={{
          options,
          onChange: setSearchKey,
          value: searchKey,
          getOptionKey: ({ key }: SearchKeyOption) => key,
          getOptionLabel: ({ label }: SearchKeyOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={shouldDisableControls}
      />
      <Button
        size="small"
        trackingId={EligibilityScopedSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.s};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchKeyOption>)`
  flex-grow: 1;
`;

export default EligibilityScopedSearchBar;
