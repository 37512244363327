import { ToastConfig } from "@taxbit-dashboard/commons";

import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";

const getMissingAccountsToastContent = (
  totalAccountsNotFound: string,
  requestIdsLength: string
): ToastConfig => {
  return {
    message: `${totalAccountsNotFound}/${requestIdsLength} accounts were not found.`,
    variant: "danger",
    timeoutMs: TOAST_TIMEOUT,
    trackingId: "shared-ir-missing-accounts-toast",
  };
};

export default getMissingAccountsToastContent;
