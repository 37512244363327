import { z } from "zod";

export const MAX_SEARCH_VALUE_LENGTH = 100;

const searchStringValueSchema = z
  .string()
  .min(1)
  .max(MAX_SEARCH_VALUE_LENGTH)
  .optional()
  .catch(undefined);

export default searchStringValueSchema;
