import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { Body } from "@taxbit-private/cosmic";

export const usIndiciaDescriptionText = (
  <Body>
    The account owner provided information that indicates tax residency in the
    United States (e.g., address, country of birth, previously submitted Form
    W-9), but has completed a Form W-8.
  </Body>
);

export const expiredFormDescriptionText = (
  <Body>
    A Form W-8 expires on December 31 of the third calendar year following the
    date of signature.
  </Body>
);

export const addressResubmissionOrCuringDescriptionText = (
  <Body>
    A Form W-8BEN-E must include a physical permanent address for the account
    owner, located outside of the United States, unless the address provided is
    the registered address for the account owner.
  </Body>
);

export const getAddressDescriptionText = (
  taxDocumentType: KycTaxDocumentType
) => (
  <Body>
    A Form {taxDocumentType} must include a physical permanent address for the
    account owner, located outside of the United States.
  </Body>
);

export const treatyCountryMismatchDescriptionText = (
  <Body>
    The account owner provided a permanent and/or mailing address in a country
    that differs from the country in which they are claiming treaty benefits.
  </Body>
);

export const WithholdingDocumentationDescriptionText = (
  <Body>
    The account owner provided information that indicates they are an
    intermediary, which requires additional information to be provided.
  </Body>
);
