import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { RhfTextArea } from "@taxbit-private/cosmic-react-hook-form";

import { getTextAreaFilterMaxItems } from "../../../../utils/useCreateTextAreaFilterSchema";

enum TransactionAssetCodeTextFilterTrackingId {
  HelpIcon = "assetCode-help-icon",
  TextArea = "assetCode-filter",
}

const TransactionAssetCodeTextFilter: React.FC = () => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <RhfTextArea
      name="assetCodes"
      label="Filter by Asset Code"
      trackingId={TransactionAssetCodeTextFilterTrackingId.TextArea}
      helpIconProps={{
        tooltipProps: {
          content: `Manually enter up to ${formatWholeQuantity(getTextAreaFilterMaxItems())} assets, with each asset on a separate line.`,
        },
        trackingId: TransactionAssetCodeTextFilterTrackingId.HelpIcon,
      }}
      height="xxxs"
    />
  );
};

export default TransactionAssetCodeTextFilter;
