import {
  createSingleInstanceHookContext,
  useOrganizationId,
} from "@taxbit-dashboard/commons";
import { RouteId, useUrlParams } from "@taxbit-dashboard/router";
import { useMemo } from "react";

import { useGetOrganizationMembersWithRoles } from "../../../../api/organizationMembersApi";

const useCompanyUserManagementData = () => {
  const { urlParams, updateUrlParams } = useUrlParams({
    routeId: RouteId.UserManagement,
  });

  const organizationId = useOrganizationId();
  const { data, ...query } = useGetOrganizationMembersWithRoles({
    organizationId,
    ...urlParams,
  });

  const organizationMembers = useMemo(() => data ?? [], [data]);

  return {
    ...query,
    organizationMembers,
    urlParams,
    updateUrlParams,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
  };
};

export const {
  useContextHook: useCompanyUserManagementDataContext,
  Context: CompanyUserManagementDataContext,
  Provider: CompanyUserManagementDataProvider,
} = createSingleInstanceHookContext(
  useCompanyUserManagementData,
  "useCompanyUserManagementData"
);
