import {
  AuthorizedView,
  DashboardNavigationItemChild,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import { EligibilityDataContextProvider } from "./context/useEligibilityData";
import EligibleUsers from "./EligibleUsers";

export const irEligibilityNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.IrEligibility,
  label: "Eligibility",
  trackingId: "sidebar-navigation-ir-eligibility-anchor",
  key: "eligibility",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadAccountDocuments] &&
    (flags.hasEligibilityPageAccess || flags.hasIrItemsPreviewAccess),
  shouldDisableRoute: ({ flags }) => !flags.hasEligibilityPageAccess,
} as const;

const IrEligibilityPage = () => {
  return (
    <AuthorizedView
      flags={["hasEligibilityPageAccess", "hasIrItemsPreviewAccess"]}
      permissions={[UserPermission.ReadAccountDocuments]}
    >
      <EligibilityDataContextProvider>
        <EligibleUsers />
      </EligibilityDataContextProvider>
    </AuthorizedView>
  );
};

export default IrEligibilityPage;
