import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  createQueryMetaObject,
} from "@taxbit-dashboard/commons";
import {
  UpdateCurrentCompanyUserName,
  ResetMfaForCompanyUser,
} from "@taxbit-dashboard/rest";

export const useGetCompanyUsers = (companyId: string) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.CompanyUsers, companyId],
    () => restSdk.companies.users.get({ companyId }),
    {
      ...createQueryMetaObject(restSdk.companies.users.buildPath(companyId)),
      enabled: !!companyId,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useUpdateCurrentCompanyUserName = () => {
  const queryClient = useQueryClient();
  const restSdk = useTaxBitRest();
  return useMutation(
    (data: UpdateCurrentCompanyUserName) =>
      restSdk.currentCompanyUser.name.patch(data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [DashboardQueryKey.CurrentCompanyUser],
        });
        void queryClient.invalidateQueries({
          queryKey: [DashboardQueryKey.OrganizationMembers],
        });
      },
    }
  );
};

export const useChangeCurrentCompanyPassword = () => {
  const restSdk = useTaxBitRest();
  return useMutation(() => restSdk.currentCompanyUser.passwordChange.post());
};

export const useResetMfaForCompanyUser = () => {
  const restSdk = useTaxBitRest();
  return useMutation((data: ResetMfaForCompanyUser) =>
    restSdk.companyUsers.post(data)
  );
};
