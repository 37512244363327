/**
 * This is the source of truth for all of the routes within the tax dashboard's router. We store these
 * in an object so that navigation helpers can leverage the type-safety of Tanstack Router without
 * needing to use the full prefixed route. This must be a constant declaration rather than an enum because
 * the router's helpers require route ids that are string literals for type inference to work properly.
 */
export const RouteId = {
  /**
   * The root route. This is the only route not directly passed as an argument to a route creation
   * helper, because the `createRootRoute` function does not take a route id param.
   */
  Root: "__root__",

  /**
   * Routes used in the authentication flow.
   */
  Callback: "/callback",
  Index: "/",
  AuthedLayout: "/_authed",
  AuthedOrgIndex: "/_authed/o/$organizationId",

  /**
   * Routes for the apps/dashboard monorepo package.
   */
  Notifications: "/_authed/o/$organizationId/notifications",

  /**
   * Routes for the @taxbit-dashboard/irw monorepo package.
   */
  IrwLayout: "/_authed/o/$organizationId/_irwLayout",
  Overview: "/_authed/o/$organizationId/_irwLayout/overview",
  AccountsLayout: "/_authed/o/$organizationId/_irwLayout/accounts",
  Accounts: "/_authed/o/$organizationId/_irwLayout/accounts/",
  AccountDetails: "/_authed/o/$organizationId/_irwLayout/accounts/$accountId",
  AccountDetailsDetailsTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details",
  AccountDetailsAccountDetailsTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details",
  AccountDetailsReportingProfileTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile",
  AccountDetailsTaxDocumentationTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation",
  AccountDetailsTaxFormsTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms",
  AccountDetailsInventoryTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory",
  AccountDetailsFormDataTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data",
  AccountDetailsTransactionsTab:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions",
  AccountDetailsTransactionDetails:
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId",
  IngestData: "/_authed/o/$organizationId/_irwLayout/ingest-data",
  DeleteData: "/_authed/o/$organizationId/_irwLayout/delete-data",
  IrSummary: "/_authed/o/$organizationId/_irwLayout/ir-summary",
  IrEligibility: "/_authed/o/$organizationId/_irwLayout/ir-eligibility",
  IrForms: "/_authed/o/$organizationId/_irwLayout/ir-forms",
  Reports: "/_authed/o/$organizationId/_irwLayout/reports",
  TransactionsLayout: "/_authed/o/$organizationId/_irwLayout/transactions",
  Transactions: "/_authed/o/$organizationId/_irwLayout/transactions/",
  TransactionDetails:
    "/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId",

  /**
   * Routes for the @taxbit-dashboard/settings monorepo package.
   */
  Settings: "/_authed/o/$organizationId/settings",
  DeveloperSettings: "/_authed/o/$organizationId/settings/developer-settings",
  FilerSettings: "/_authed/o/$organizationId/settings/filer-settings",
  InvitationManagement:
    "/_authed/o/$organizationId/settings/invitation-management",
  MyAccount: "/_authed/o/$organizationId/settings/my-account",
  UserManagement: "/_authed/o/$organizationId/settings/user-management",
} as const;

/**
 * This manual type declaration allows the constant to be used as a type just like a standard enum.
 */
export type RouteId = (typeof RouteId)[keyof typeof RouteId];
