import { createFileRoute, retainSearchParams } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";

import formTypeAndDateParamsSchema from "../../params/shared/formTypeAndDateParamsSchema";
import { RouteId } from "../../types/RouteId";

export const Route = createFileRoute(RouteId.IrSummary)({
  validateSearch: zodValidator(formTypeAndDateParamsSchema),
  search: {
    middlewares: [retainSearchParams(["formType", "formYear"])],
  },
});
