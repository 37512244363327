import { QueryClientProvider } from "@tanstack/react-query";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { dashboardQueryClient, logError } from "@taxbit-dashboard/commons";
import { Outlet } from "@taxbit-dashboard/router";
import {
  GlobalStyles,
  theme as cosmicTheme,
  ErrorBoundary,
} from "@taxbit-private/cosmic";
import { CosmicLocalizationContextProvider } from "@taxbit-private/cosmic-localization";
import { StrictMode } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";

import AuthProvider from "../AuthProvider";
import initializeAppVendors from "../initializeAppVendors";

/**
 * Attach the app version to the window object so it can be accessed in the dev console for easy debugging.
 */
window.appVersion = APP_VERSION;

/**
 * Sets up the Cosmic CSS. All Cosmic components depend on the basic CSS overrides in this file.
 */
import "@taxbit-private/cosmic/dist/style.css";

/**
 * Inject the global background color we want for the dashboard in addition
 * to the other global styles that Cosmic offers. This has to live at the top
 * to avoid a Typescript error about declaration ordering.
 */
const DashboardGlobalStyles = createGlobalStyle(
  ({ theme }) => `
  html, body, #root {
    height: 100%;

  }

  body {
    background-color: ${theme.color.gray0};
  }
`
);

/**
 * Load the vendor provider. This just resolves to an empty fragment while vendors are being loaded.
 */
const LaunchDarklyProvider = await (async () => await initializeAppVendors())();

/**
 * The entry point for the application. All top-level HOCs and vendor providers should be incorporated into this
 * component. Any provider added to `RootComponent` must be able to be safely rendered without authentication
 * or user context, as this is taken care of in the `AuthedRoute` component that wraps the majority of the app.
 *
 * For more information on the routing structure of the app, see the `Router` component.
 */
const RootComponent = () => {
  return (
    <StrictMode>
      <LaunchDarklyProvider>
        <ThemeProvider theme={cosmicTheme}>
          <GlobalStyles />
          <DashboardGlobalStyles />
          <ErrorBoundary logError={logError} trackingId="root-error-boundary">
            <QueryClientProvider client={dashboardQueryClient}>
              <AuthProvider>
                <CosmicLocalizationContextProvider
                  initialData={{ locale: "en-US", currency: "USD" }}
                >
                  <Outlet />
                </CosmicLocalizationContextProvider>
              </AuthProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </LaunchDarklyProvider>
      <TanStackRouterDevtools position="bottom-right" />
    </StrictMode>
  );
};

export default RootComponent;
