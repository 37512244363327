import {
  FileAction,
  FileProcessingStatus,
  FilesApiFile,
} from "@taxbit-dashboard/rest";
import { ActionMenu, ActionMenuClickable } from "@taxbit-private/cosmic";

import useDownloadErrorReports from "./useDownloadErrorReports";
import useDownloadFile from "./useDownloadFile";
import useFileUploaderContext from "../../context/useFileUploaderContext";

export enum FilesTableCardActionMenuTrackingId {
  Menu = "files-table-card-action-menu",
  ReviewIngestion = "files-table-card-review-ingestion",
  EditDescription = "files-table-card-edit-description",
  RemoveUpload = "files-table-card-remove-upload",
  DownloadFile = "files-table-card-download-file",
  DownloadErrorReports = "files-table-card-download-error-reports",
}

type Props = {
  action: FileAction;
  file: FilesApiFile;
  onEditClick: (file: FilesApiFile) => void;
  onRemoveClick: (file: FilesApiFile) => void;
  onReviewClick: (file: FilesApiFile) => void;
};

const FilesTableRowActionMenu: React.FC<Props> = ({
  action,
  file,
  onEditClick,
  onRemoveClick,
  onReviewClick,
}) => {
  const { isStaleUpload } = useFileUploaderContext(action);
  const downloadFile = useDownloadFile(file);
  const downloadErrorReports = useDownloadErrorReports(file);

  const shouldShowDownloadFileAction =
    new Date(file.dateUploaded).getTime() >= new Date("2024-03-06").getTime() &&
    file.status !== FileProcessingStatus.Uploading &&
    file.status !== FileProcessingStatus.ContactSupport;

  const shouldShowRemoveUploadAction =
    file.status === FileProcessingStatus.Invalid ||
    file.status === FileProcessingStatus.Rejected ||
    isStaleUpload(file);

  const items: ActionMenuClickable[] = [
    ...(file.status === FileProcessingStatus.PendingApproval
      ? [
          {
            onClick: () => onReviewClick(file),
            label: "Review File",
            trackingId: `${FilesTableCardActionMenuTrackingId.ReviewIngestion}-${file.fileName}`,
            iconName: "check-circle",
          } as const,
        ]
      : []),
    ...(shouldShowDownloadFileAction
      ? ([
          {
            onClick: downloadFile,
            label: `Download File`,
            trackingId: `${FilesTableCardActionMenuTrackingId.DownloadFile}-${file.fileName}`,
            iconName: "download",
          },
        ] as const)
      : []),
    ...(file.hasErrorReport
      ? ([
          {
            onClick: downloadErrorReports,
            label: "Download Summary Reports",
            trackingId: `${FilesTableCardActionMenuTrackingId.DownloadErrorReports}-${file.fileName}`,
            iconName: "download",
          },
        ] as const)
      : []),
    {
      onClick: () => onEditClick(file),
      label: "Edit Description",
      trackingId: `${FilesTableCardActionMenuTrackingId.EditDescription}-${file.fileName}`,
      iconName: "edit",
    },
    ...(shouldShowRemoveUploadAction
      ? [
          {
            onClick: () => onRemoveClick(file),
            label: "Remove",
            trackingId: `${FilesTableCardActionMenuTrackingId.RemoveUpload}-${file.fileName}`,
            iconName: "trash",
          } as const,
        ]
      : []),
  ];

  return (
    <ActionMenu
      items={items}
      trackingId={`${FilesTableCardActionMenuTrackingId.Menu}-${file.fileName}`}
      shouldUsePortal={true}
    />
  );
};

export default FilesTableRowActionMenu;
