import {
  AuthorizedView,
  DashboardNavigationItemChild,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import DeveloperSettings from "./DeveloperSettings";

export const developerSettingsNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.DeveloperSettings,
  label: "Developer Settings",
  trackingId: "sidebar-navigation-developer-settings",
  key: "developer-settings",
  shouldShowRoute: ({ permissions, flags }) =>
    flags.shouldShowDeveloperSettings && permissions[UserPermission.ReadTins],
} as const;

const DeveloperSettingsPage = () => {
  return (
    <AuthorizedView
      permissions={[UserPermission.ReadTins]}
      flags={["shouldShowDeveloperSettings"]}
    >
      <DeveloperSettings />
    </AuthorizedView>
  );
};

export default DeveloperSettingsPage;
