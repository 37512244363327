import { AccountsUrlParams } from "@taxbit-dashboard/router";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo, useState } from "react";

import { FilterDrawerFields } from "./filterDrawerFields";
import { ACCOUNTS_TABLE_ROW_LABELS } from "../../accountsTableRow";

export enum MultiselectFilterTrackingId {
  Multiselect = "MultiselectFilter",
}

type MultiselectUrlParams = Pick<
  AccountsUrlParams,
  | "tinStatuses"
  | "vatStatuses"
  | "taxClassifications"
  | "taxDocumentationTypes"
  | "taxCountryCodes"
  | "filerIds"
>;

type Option<T> = {
  value: T;
  label: string;
};

type MultiselectFilterProps<
  T extends keyof MultiselectUrlParams,
  R extends FilterDrawerFields[T][0],
> = {
  options: Option<R>[];
  name: keyof MultiselectUrlParams;
  hasSearchBar?: boolean;
};

export const LABELS: Record<keyof MultiselectUrlParams, string> = {
  tinStatuses: ACCOUNTS_TABLE_ROW_LABELS.full.tinStatus,
  vatStatuses: ACCOUNTS_TABLE_ROW_LABELS.full.vatStatus,
  taxClassifications: ACCOUNTS_TABLE_ROW_LABELS.full.taxClassification,
  taxDocumentationTypes:
    ACCOUNTS_TABLE_ROW_LABELS.full.usProfileTaxDocumentationType,
  taxCountryCodes: ACCOUNTS_TABLE_ROW_LABELS.full.taxCountryCodes,
  filerIds: ACCOUNTS_TABLE_ROW_LABELS.full.filer,
};

const MultiselectFilter = <
  T extends keyof MultiselectUrlParams,
  R extends Required<FilterDrawerFields>[T][0],
>({
  options,
  name,
  hasSearchBar,
}: MultiselectFilterProps<T, R>) => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredOptions = useMemo(
    () =>
      options
        .filter(({ label }) =>
          label.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a.label.localeCompare(b.label)),
    [options, searchTerm]
  );

  return (
    <RhfMultiselect<
      Required<FilterDrawerFields>[T][0],
      Pick<MultiselectUrlParams, typeof name>,
      typeof name,
      (typeof options)[number]
    >
      getOptionKey={({ value }) => value}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ value }) => value}
      name={name}
      options={filteredOptions}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label={LABELS[name]}
      placeholder="All"
      trackingId={MultiselectFilterTrackingId.Multiselect}
      searchBarProps={
        hasSearchBar
          ? { value: searchTerm, onTextChange: setSearchTerm }
          : undefined
      }
    />
  );
};

export default MultiselectFilter;
