import { TransactionType } from "@taxbit-dashboard/rest";
import { cosmicDateRangePickerValueSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import pageLimitPaginationParamsSchema from "../../shared/pageLimitPaginationParamsSchema";

const transactionsUrlParamsSchema = z
  .object({
    type: z.array(z.nativeEnum(TransactionType)).optional().catch(undefined),
  })
  .merge(pageLimitPaginationParamsSchema)
  .merge(cosmicDateRangePickerValueSchema);

export type TransactionsUrlParams = z.infer<typeof transactionsUrlParamsSchema>;

export default transactionsUrlParamsSchema;
