import { deleteFileTypes } from "@taxbit-dashboard/rest";
import { z } from "zod";

import pageLimitPaginationParamsSchema from "../../shared/pageLimitPaginationParamsSchema";

const deleteDataUrlParamsSchema = pageLimitPaginationParamsSchema.merge(
  z.object({
    fileType: z.array(z.enum(deleteFileTypes)).optional().catch(undefined),
  })
);

export type DeleteDataUrlParams = z.infer<typeof deleteDataUrlParamsSchema>;

export default deleteDataUrlParamsSchema;
