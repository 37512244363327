import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import { GetIrFormsParams } from "@taxbit-dashboard/rest";
import { IrFormsUrlParams } from "@taxbit-dashboard/router";

const getFormsParams = ({
  limit,
  page,
  formYear,
  formType,
  sort,
  statuses,
  types,
  startDate,
  endDate,
  accountAlternateExternalId,
  accountExternalId,
  name,
}: IrFormsUrlParams): GetIrFormsParams => {
  return {
    "sort": sort,
    "filters[document_date]": formYear,
    "filters[document_type]": formType,
    "filters[status]": statuses,
    "filters[type]": types,
    "filters[date_generated][$gte]": startDate,
    "filters[date_generated][$lte]": endDate,
    "filters[account_alternate_external_id]": accountAlternateExternalId,
    "filters[account_external_id]": accountExternalId,
    "filters[name]": name,
    ...getPageLimitApiParams({ page, limit }),
  };
};

export default getFormsParams;
