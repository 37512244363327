import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  UserPermission,
  createSingleInstanceHookContext,
  readonlyIncludes,
  useDashboardFeatureFlags,
  useDashboardStore,
  useLocalPageBanner,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  IrForm,
  UnexpectedResponseError,
} from "@taxbit-dashboard/rest";
import {
  IrFormsUrlParams,
  PageLimitPaginationParams,
  RouteId,
} from "@taxbit-dashboard/router";
import {
  useCosmicTable,
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo, useState } from "react";
import { O } from "ts-toolbelt";

import {
  useCreateTaxFormsFiling,
  useGetFormsAggregates,
  useRescindIrForms,
  useGetForms,
} from "../../../../api/information-reporting/forms/formsApi";
import { formsEnumParamKeys } from "../../../../api/information-reporting/forms/formsApiTypes";
import useFormFeatureFlagData from "../../shared/form-year-dropdowns/useFormFeatureFlagData";
import useUrlParamsWithFormTypeAndYear from "../../shared/form-year-dropdowns/useUrlParamsWithFormTypeAndYear";
import getMissingAccountsToastContent from "../../shared/getMissingAccountsToastContent";
import {
  getDeleteErrorToastContent,
  getErrorFilingToastContent,
  successDeleteToastContent,
  successFilingToastContent,
} from "../table/actions/toastContents";
import useFormsTableColumns from "../table/useFormsTableColumns";
import useFormsTableSort from "../table/useFormsTableSort";

enum IrFormsTrackingId {
  CosmicTable = "ir-forms-table",
}

const supportedFormsForFiling = new Set([
  DashboardFormType.Irs1099B,
  DashboardFormType.Irs1099Misc,
  DashboardFormType.Irs1099K,
  DashboardFormType.Irs1099R,
  DashboardFormType.Irs5498,
  DashboardFormType.Irs1099Nec,
  DashboardFormType.Irs1099Int,
  DashboardFormType.Irs1099Div,
  DashboardFormType.Irs1042S,
]);

const useIrForms = () => {
  const [selectedRows, setSelectedRows] = useState<IrForm[]>([]);

  // We allow up to 10k account IDs in this filter
  // Therefore we can't put it directly in the URL and we need to keep in in a state
  const [accountExternalIdsFilter, setAccountExternalIdsFilter] =
    useState<string>("");
  const [shouldShowMissingAccountWarning, setShouldShowMissingAccountWarning] =
    useState(true);

  const clearAccountExternalIdsFilter = useCallback(() => {
    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter]);

  const featureFlagData = useFormFeatureFlagData({
    featureFlag: "formsPageYearsByFormType",
  });

  const {
    urlParams,
    updateUrlParams,
    updateFilterParams: updateFilterParamsOriginal,
    setUrlParams,
    ...formYearDropdownProps
  } = useUrlParamsWithFormTypeAndYear({
    featureFlagData,
    routeId: RouteId.IrForms,
    onFormChangeCallback: clearAccountExternalIdsFilter,
    onYearChangeCallback: clearAccountExternalIdsFilter,
  });

  const { formYear, formType, sort } = urlParams;

  const addToast = useDashboardStore((state) => state.addToast);
  const { hasTaxFormsFilingAccess } = useDashboardFeatureFlags();

  const canCreateTaxForms = useUserPermission(UserPermission.CreateTaxForms);
  const shouldShowFilingActions =
    hasTaxFormsFilingAccess &&
    canCreateTaxForms &&
    supportedFormsForFiling.has(formType);

  const { mutate: createTaxFormsFiling } = useCreateTaxFormsFiling({
    formType,
    formYear,
  });

  const isCreatingFiling =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.IrFormsFiling,
        {
          formType,
          formYear,
        },
      ],
    }) > 0;

  const handleSubmitFiling = useCallback(() => {
    createTaxFormsFiling(undefined, {
      onSuccess: () => {
        addToast(successFilingToastContent);
      },
      onError: (error) => {
        if (
          error instanceof UnexpectedResponseError &&
          error.detailedErrors?.[0]
        ) {
          addToast(getErrorFilingToastContent(error.detailedErrors[0].detail));
        } else {
          addToast(getErrorFilingToastContent());
        }
      },
    });
  }, [addToast, createTaxFormsFiling]);

  const {
    data: aggregates,
    isError: isErrorAggregates,
    isLoading: isLoadingAggregates,
  } = useGetFormsAggregates({
    formType,
    formYear,
  });

  const totalFormsGenerated =
    (aggregates?.generated.original ?? 0) +
    (aggregates?.generated.correction ?? 0) +
    (aggregates?.generated.voided ?? 0);

  const { mutate: rescindForms } = useRescindIrForms({
    formType,
    formYear,
    onSettled: (data, error) => {
      const errorCount = data?.errorCount ?? 0;
      const totalCount = data?.totalCount ?? 0;

      if (error || errorCount > 0) {
        addToast(
          getDeleteErrorToastContent({
            errorCount,
            totalCount,
          })
        );
      } else {
        addToast(successDeleteToastContent);
      }
    },
  });

  const isRescindingForms =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.IrFormsRescind,
        {
          formType,
          formYear,
        },
      ],
    }) > 0;

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const {
    isLoading: isFormsLoading,
    isError: isFormsError,
    isPreviousData: isFormsPreviousData,
    data: irForms,
    meta: irFormsMeta,
  } = useGetForms({
    ...urlParams,
    accountExternalIdsFilter,
    onMissingAccounts: (requestIdsLength, totalAccountsNotFound) => {
      if (shouldShowMissingAccountWarning) {
        addToast(
          getMissingAccountsToastContent(
            formatWholeQuantity(totalAccountsNotFound),
            formatWholeQuantity(requestIdsLength)
          )
        );
      }
    },
  });

  const updateFilterParams = useCallback(
    (
      params: Partial<O.Exclude<IrFormsUrlParams, PageLimitPaginationParams>>
    ) => {
      setShouldShowMissingAccountWarning(true);
      return updateFilterParamsOriginal(params);
    },
    [updateFilterParamsOriginal]
  );

  const updatePaginationParams = useCallback(
    async (params: Partial<PageLimitPaginationParams>) => {
      // We don't want to show warning on pagination change
      setShouldShowMissingAccountWarning(false);
      return updateUrlParams(params);
    },
    [updateUrlParams]
  );

  const clearAllFilters = useCallback(async () => {
    const { formYear, formType, page, limit, sort } = urlParams;

    await setUrlParams({
      formYear,
      formType,
      page,
      limit,
      sort,
    });

    setAccountExternalIdsFilter("");
  }, [setAccountExternalIdsFilter, setUrlParams, urlParams]);

  const filterDrawerFilterCount = useMemo(() => {
    const urlFilterCount = Object.keys(urlParams).reduce((count, key) => {
      if (key === "startDate" && urlParams.startDate) {
        return count + 1;
      } else if (readonlyIncludes(formsEnumParamKeys, key) && urlParams[key]) {
        return count + (urlParams[key]?.length ?? 0);
      }

      return count;
    }, 0);

    if (accountExternalIdsFilter.length > 0) {
      return urlFilterCount + 1;
    }

    return urlFilterCount;
  }, [urlParams, accountExternalIdsFilter]);

  const shouldDisableControls = useMemo(
    () => isRescindingForms || isFormsLoading || isFormsPreviousData,
    [isFormsLoading, isFormsPreviousData, isRescindingForms]
  );

  const irFormsTableSortProps = useFormsTableSort({
    sort,
    updateFilterParams: updateFilterParamsOriginal,
  });

  const irFormsRows = useMemo(() => irForms ?? [], [irForms]);
  const columns = useFormsTableColumns();
  const memoizedGetRowKey = useCallback(({ id }: IrForm) => id, []);

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const irFormsTableProps = useCosmicTable({
    trackingId: IrFormsTrackingId.CosmicTable,
    isManualSortable: true,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    rows: irFormsRows,
    columns,
    onRowSelect: setSelectedRows,
    isLoading: shouldDisableControls,
    emptyStateProps: isFormsError
      ? getEnUsErrorEmptyStateProps({ entity: "forms" })
      : searchEmptyStateProps,
    ...irFormsTableSortProps,
  });

  useLocalPageBanner({
    shouldShow: isRescindingForms,
    bannerConfig: {
      shouldShowSpinner: true,
      message: "We are deleting forms. Please wait a few moments.",
    },
  });

  const shouldDisableFiling =
    isLoadingAggregates ||
    shouldDisableControls ||
    totalFormsGenerated <= 0 ||
    isCreatingFiling;

  return {
    ...formYearDropdownProps,
    urlParams,
    updateFilterParams,
    updatePaginationParams,
    clearAllFilters,
    filterDrawerFilterCount,
    totalCount: irFormsMeta?.page?.totalCount ?? 0,
    currentForm: formType,
    currentYear: formYear,
    accountExternalIdsFilter,
    aggregates,
    handleSubmitFiling,
    irFormsTableProps,
    isErrorAggregates,
    isFormsError,
    isLoading: isLoadingAggregates || isFormsLoading,
    isLoadingAggregates,
    rescindForms,
    selectedForms: selectedRows,
    setAccountExternalIdsFilter,
    shouldDisableControls,
    shouldDisableFiling,
    shouldShowFilingActions,
    toggleAllSelectedForms:
      irFormsTableProps.tableInstance.toggleAllRowsSelected,
    totalFormsGenerated,
  };
};

export const {
  useContextHook: useIrFormsContext,
  Context: IrFormsContext,
  Provider: IrFormsContextProvider,
} = createSingleInstanceHookContext(useIrForms, "useIrForms");
