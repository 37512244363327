import { useCallback } from "react";

import {
  EligibilitySearchParamKey,
  eligibilitySearchParamKeyLabelMap,
  eligibilitySearchParamKeys,
} from "../../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum EligibilitySearchChipsTrackingId {
  Chip = "irEligibilitySearchChip",
}

const useSearchFilterChips = () => {
  const { urlParams, updateFilterParams } = useEligibilityDataContext();

  const getSearchChip = useCallback(
    (key: EligibilitySearchParamKey, value: string) => {
      if (value) {
        const label = `${eligibilitySearchParamKeyLabelMap[key]}: "${value}"`;
        return {
          label,
          removeButtonProps: {
            "onClick": () =>
              updateFilterParams({
                [key]: undefined,
              }),
            "aria-label": `Remove filter for ${label}`,
          },
          trackingId: `${EligibilitySearchChipsTrackingId.Chip}${eligibilitySearchParamKeyLabelMap[key]}`,
        };
      } else {
        return undefined;
      }
    },
    [updateFilterParams]
  );

  const searchChipEntries = Object.entries(urlParams).filter(
    (entry): entry is [EligibilitySearchParamKey, string] =>
      eligibilitySearchParamKeys.includes(
        entry[0] as EligibilitySearchParamKey
      ) && !!entry[1]
  );

  return searchChipEntries.map(([key, value]) => getSearchChip(key, value));
};

export default useSearchFilterChips;
