/**
 * Formats the given organization ID and route into our standard prefixed route format.
 */
const getOrganizationPrefixedRoute = (
  organizationId: string,
  subroute?: string
) => {
  return `/o/${organizationId}${subroute ? `/${subroute}` : "/"}`;
};

export default getOrganizationPrefixedRoute;
