import { DashboardPrimaryTemplate } from "@taxbit-dashboard/commons";

import { TransactionsTable } from "./table/TransactionsTable";
import { TransactionsTableProvider } from "./table/useTransactionsTable";

const Transactions: React.FC = () => {
  return (
    <DashboardPrimaryTemplate title="Transactions" shouldHaveMaxWidth={false}>
      <TransactionsTableProvider>
        <TransactionsTable />
      </TransactionsTableProvider>
    </DashboardPrimaryTemplate>
  );
};

export default Transactions;
