import {
  UsTaxIdType,
  AccountServiceTinType,
  ReportingProfileTinType,
} from "@taxbit-dashboard/rest";

const maskTaxIdByLength = (taxId: string): string => {
  if (taxId.length === 1) {
    return "*";
  }

  if (taxId.length === 2) {
    return `*${taxId.slice(-1)}`;
  }

  if (taxId.length < 6) {
    return `${"*".repeat(taxId.length - 2)}${taxId.slice(-2)}`;
  }

  return `${"*".repeat(taxId.length - 4)}${taxId.slice(-4)}`;
};

const maskTaxId = (
  taxId?: string,
  taxIdType?: UsTaxIdType | AccountServiceTinType | ReportingProfileTinType
): string | undefined => {
  if (!taxId) {
    return undefined;
  }

  if (taxId.length !== 9) {
    return maskTaxIdByLength(taxId);
  }

  switch (taxIdType) {
    case "EIN":
    case AccountServiceTinType.UsEin: {
      return `**-***${taxId.slice(-4)}`;
    }
    case "SSN":
    case "ITIN":
    case AccountServiceTinType.UsSsn:
    case AccountServiceTinType.UsItin: {
      return `***-**-${taxId.slice(-4)}`;
    }
    default: {
      return maskTaxIdByLength(taxId);
    }
  }
};

export default maskTaxId;
