import useRouteAuthentication from "./useRouteAuthentication";
import AppLevelSpinner from "../navigation/AppLevelSpinner";

export const AuthedRouteWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isAuthenticated = useRouteAuthentication();

  return isAuthenticated ? (
    children
  ) : (
    <AppLevelSpinner trackingId="authed-route-wrapper" />
  );
};
