import {
  Auth0Provider,
  Auth0ProviderOptions,
  AppState,
} from "@auth0/auth0-react";
import { serializeScopeClaim, UserPermission } from "@taxbit-dashboard/commons";
import { isProdEnvironment } from "@taxbit-dashboard/rest";
import { useNavigate } from "@taxbit-dashboard/router";
import { useCallback } from "react";

/**
 * This is a scope string passed to Auth0 when retrieving an access token. It contains
 * all scopes (user permissions) for all API requests the app would like to make on
 * behalf of the user. The scope string contained within the returned access token
 * represents the subset of these requested permissions that the user has been assigned.
 * https://auth0.com/docs/get-started/apis/scopes
 */
const scope = serializeScopeClaim(Object.values(UserPermission));

const options: Auth0ProviderOptions = {
  domain: isProdEnvironment()
    ? "auth-enterprise.taxbit.com"
    : "auth-enterprise-staging.taxbit.com",
  clientId: isProdEnvironment()
    ? "h8OqsAjsGiNYFWHTJZpvki7NgsnP3K3u"
    : "JJI49MKogCrHHc38TUpZcRD6NAYlBvoB",
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/callback`,
    scope,
  },
};

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      void navigate({ to: appState?.returnTo ?? "/" });
    },
    [navigate]
  );

  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...options}>
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
