import { z } from "zod";

export enum AccountsViewType {
  Us = "us",
  Global = "global",
}

export const accountsViewTypeSchema = z
  .nativeEnum(AccountsViewType)
  .catch(AccountsViewType.Us);
