import { z } from "zod";

import pageLimitPaginationParamsSchema from "../shared/pageLimitPaginationParamsSchema";

export enum NotificationsTab {
  All = "all",
  Unread = "unread",
  Read = "read",
}

export const notificationsPageParamsSchema =
  pageLimitPaginationParamsSchema.extend({
    tab: z.nativeEnum(NotificationsTab).catch(NotificationsTab.All),
  });

export type NotificationsPageParams = z.infer<
  typeof notificationsPageParamsSchema
>;
