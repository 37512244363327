import { createFileRoute, retainSearchParams } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";

import irFormsUrlParamsSchema from "../../params/irw/forms/irFormsUrlParamsSchema";
import { RouteId } from "../../types/RouteId";

export const Route = createFileRoute(RouteId.IrForms)({
  validateSearch: zodValidator(irFormsUrlParamsSchema),
  search: {
    middlewares: [retainSearchParams(["formType", "formYear"])],
  },
});
