import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import {
  RemoveUser,
  OrganizationMember,
  Role,
  UpdateOrganizationMemberRoles,
} from "@taxbit-dashboard/rest";
import { PageLimitPaginationParams } from "@taxbit-dashboard/router";

import { useGetRoles } from "./rolesApi";

const getOrganizationMemberWithRoles = (
  users: OrganizationMember[],
  roles: Role[]
) =>
  users.map(({ companyUser, roleIds }) => ({
    ...companyUser,
    roles: roles.filter(({ id }) => roleIds.includes(id)),
  }));

type OrganizationMembersWithRoles = ReturnType<
  typeof getOrganizationMemberWithRoles
>;

export type OrganizationMemberWithRoles = OrganizationMembersWithRoles[number];

export type GetOrganizationMembersParams = {
  organizationId: string;
} & Partial<PageLimitPaginationParams>;

export const useGetOrganizationMembers = (
  params: GetOrganizationMembersParams
) => {
  const restSdk = useTaxBitRest();

  return unwrapPublicApiWrappedQuery(
    useQuery(
      [DashboardQueryKey.OrganizationMembers, { ...params }],
      () => restSdk.organizations.members.get(params),
      {
        ...createQueryMetaObject(
          restSdk.organizations.members.buildPath(params.organizationId)
        ),
        enabled: !!params.organizationId,
        keepPreviousData: true,
      }
    )
  );
};

export const useGetOrganizationMembersWithRoles = (
  params: GetOrganizationMembersParams
) => {
  const {
    data: roles,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
  } = useGetRoles();
  const {
    data: organizationMembers,
    meta,
    isLoading: isLoadingOrganizationMembers,
    isError: isErrorOrganizationMembers,
    isPreviousData,
  } = useGetOrganizationMembers(params);

  return {
    data:
      organizationMembers && roles
        ? getOrganizationMemberWithRoles(organizationMembers, roles)
        : undefined,
    meta,
    isLoading: isLoadingRoles || isLoadingOrganizationMembers,
    isError: isErrorRoles || isErrorOrganizationMembers,
    isPreviousData,
  };
};

export const useRemoveUserFromOrganization = (organizationId: string) => {
  const queryClient = useQueryClient();
  const restSdk = useTaxBitRest();
  return useMutation(
    (data: RemoveUser) =>
      restSdk.organizations.members.patch(organizationId, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [DashboardQueryKey.OrganizationMembers],
        });
      },
    }
  );
};

export const useUpdateUserRoles = (
  organizationId: string,
  memberId: string
) => {
  const queryClient = useQueryClient();
  const restSdk = useTaxBitRest();
  return useMutation(
    (data: UpdateOrganizationMemberRoles) =>
      restSdk.organizations.members.roles.patch(organizationId, memberId, data),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries({
          queryKey: [DashboardQueryKey.OrganizationMembers],
        });
      },
    }
  );
};
