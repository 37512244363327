import {
  useAuthenticatedNavigation,
  RouteId,
  AuthenticatedNavigationOptions,
  useMatches,
} from "@taxbit-dashboard/router";
import _ from "lodash";
import { useState, useEffect, useCallback, useMemo } from "react";

import createSingleInstanceHookContext from "../../utils/createSingleInstanceHookContext";
import readonlyIncludes from "../../utils/readonlyIncludes";

/**
 * All of the account details tab routes have the same set of permitted
 * back routes, so we just save them for convenience in the map below.
 */
const accountDetailsBackRoutes = [
  RouteId.Accounts,
  RouteId.Transactions,
  RouteId.IrEligibility,
  RouteId.IrForms,
  RouteId.Overview,
] as const;

/**
 * This map is the source of truth for what routes may be navigated back to from a
 * particular page. It must be adjusted any time we add a new navigation to a page
 * with a back button or a new page with a back button.
 */
const routeToPermittedBackRoutesMap = {
  [RouteId.TransactionDetails]: [
    RouteId.AccountDetailsTransactionsTab,
    RouteId.Transactions,
  ],
  [RouteId.AccountDetails]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsTransactionDetails]: [
    RouteId.AccountDetailsTransactionsTab,
  ],
  [RouteId.AccountDetailsDetailsTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsAccountDetailsTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsReportingProfileTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsTaxDocumentationTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsTaxFormsTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsInventoryTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsFormDataTab]: accountDetailsBackRoutes,
  [RouteId.AccountDetailsTransactionsTab]: accountDetailsBackRoutes,
} as const;

type RouteWithBackNavigation = keyof typeof routeToPermittedBackRoutesMap;

const isRouteWithBackNavigation = (
  routeId: RouteId
): routeId is RouteWithBackNavigation => {
  return routeId in routeToPermittedBackRoutesMap;
};

type BackNavigationRoute =
  (typeof routeToPermittedBackRoutesMap)[RouteWithBackNavigation][number];

const isBackNavigationRoute = (
  routeId?: RouteId
): routeId is BackNavigationRoute => {
  return (
    !!routeId &&
    readonlyIncludes(
      Object.values(routeToPermittedBackRoutesMap).flat(),
      routeId
    )
  );
};

/**
 * A map of routes that can be navigated back to from a page with a back button
 * to the appropriate label to show on the back button.
 */
const backNavigationRouteLabelMap: Record<BackNavigationRoute, string> = {
  [RouteId.Accounts]: "Accounts",
  [RouteId.Transactions]: "Transactions",
  [RouteId.IrEligibility]: "Eligibility",
  [RouteId.IrForms]: "Forms",
  [RouteId.AccountDetailsTransactionsTab]: "Account Details",
  [RouteId.Overview]: "Overview",
};

const useNavigationHistory = () => {
  const { authenticatedNavigate } = useAuthenticatedNavigation();
  const matches = useMatches();

  /**
   * The last match in the router state is the current page. We take the route id and
   * params associated with the most specific match to save them in history.
   */
  const currentMatch = useMemo(() => {
    const mostSpecificMatch = matches.at(-1);

    if (mostSpecificMatch) {
      // Strip out org id from the params because we use our authenticated
      // navigation helper and therefore don't need it saved for back navigation.
      // The 'no-unnecessary-condition' rule is disabled here because the params
      // are incorrectly marked as required in these types.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const { organizationId, ...params } = (mostSpecificMatch.params ??
        {}) as { organizationId?: string };

      return {
        routeId: mostSpecificMatch.routeId,
        search: mostSpecificMatch.search,
        params,
      };
    }
  }, [matches]);

  const [history, setHistory] = useState(currentMatch ? [currentMatch] : []);

  /**
   * When the current match changes, add it to the routing history if it is a different
   * routeId and/or params than the last match. This prevents adding duplicate routeId
   * and path params combos when the search params change.
   */
  useEffect(() => {
    setHistory((prevHistory) => {
      const lastSavedMatch = prevHistory[0];

      if (currentMatch && !_.isEqual(currentMatch, lastSavedMatch)) {
        return [currentMatch, ...prevHistory];
      } else {
        return prevHistory;
      }
    });
  }, [currentMatch]);

  /**
   * Finds the first match in the routing history that is a permitted back navigation route
   * from the current page.
   */
  const previousRoute = useMemo(() => {
    const potentialBackRoutes =
      currentMatch?.routeId && isRouteWithBackNavigation(currentMatch.routeId)
        ? routeToPermittedBackRoutesMap[currentMatch.routeId]
        : [];

    return history.find(({ routeId: potentialRouteId }) => {
      if (!currentMatch) {
        return false;
      }

      return new Set<RouteId>(potentialBackRoutes).has(potentialRouteId);
    });
  }, [history, currentMatch]);

  /**
   * Returns props for a back button that leverages the navigation history to determine
   * the correct route to navigate back to. If we cannot find a valid route to navigate back
   * to in the history (ex: we have fully refreshed a page with a back button), it will fall
   * back to the provided fallback route and params.
   */
  const getBackButtonProps = useCallback(
    <TRoute extends BackNavigationRoute>({
      fallback,
      fallbackParams,
    }: {
      fallback: TRoute;
      fallbackParams?: AuthenticatedNavigationOptions<TRoute>["params"];
    }) => {
      const params = {
        to: (previousRoute?.routeId ?? fallback) as BackNavigationRoute,
        params: previousRoute?.params ?? fallbackParams,
        search: previousRoute?.search,
        // When navigating back, we want to restore the scroll position to the
        // last position for the path we are navigating to.
        shouldScrollToTop: false,
      };

      const backRouteId = params.to;

      const backButtonLabel = isBackNavigationRoute(backRouteId)
        ? `Back to ${backNavigationRouteLabelMap[backRouteId]}`
        : "Back";

      return {
        onBack: () => authenticatedNavigate(params),
        backButtonLabel,
      };
    },
    [authenticatedNavigate, previousRoute]
  );

  return { getBackButtonProps };
};

export const {
  useContextHook: useNavigationHistoryContext,
  Provider: NavigationHistoryContextProvider,
} = createSingleInstanceHookContext(
  useNavigationHistory,
  "useNavigationHistory"
);
