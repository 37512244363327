export const CESOP_STATE_MEMBERS = [
  "All Member States",
  "Belgium",
  "Spain",
  "Germany",
] as const;
type CesopStateMember = (typeof CESOP_STATE_MEMBERS)[number];

export const currentStateMember: CesopStateMember = "Belgium";

export const YEARS_WITH_QUARTER_VALUES = [
  "2023 - Q4",
  "2024 - Q1",
  "2024 - Q2",
  "2024 - Q3",
  "2024 - Q4",
] as const;
type YearWithQuarter = (typeof YEARS_WITH_QUARTER_VALUES)[number];

export const currentYearWithQuarter: YearWithQuarter = "2023 - Q4";
