import { getOffsetFromPage } from "@taxbit-dashboard/commons";
import {
  DashboardFormType,
  EligibilityStatus,
  EligibilityFormStatus,
  DashboardEligibilityFormStatus,
  AccountValidationsEligibilityStatus,
  CreateEligibilityExportBodyDto,
  EligibilityReportType,
  EligibilityFindAccountsRequest,
} from "@taxbit-dashboard/rest";
import { IrEligibilityUrlParams } from "@taxbit-dashboard/router";

import { ExportEligibilityTableModalForm } from "../../../pages/information-reporting/eligible-users/context/exportEligibilityTableModalFormTypes";
import newlineTextBasedFilterToArray from "../../../utils/newlineTextBasedFilterToArray";
import {
  AccountExternalIdsFilter,
  EligibilityExportFormatParams,
  mapDashboardFormTypeToApiFormType,
} from "../irApiTypes";

export const UK_DOCUMENT_TYPES = new Set([DashboardFormType.UkGainLossSummary]);
export const NOT_REQUIRED_COMPLETE_DATA_DOCUMENT_TYPES = new Set([
  DashboardFormType.GainLossPdf,
  DashboardFormType.GainLossSummary,
  DashboardFormType.UkGainLossSummary,
]);

export const getEligibilityStatusLabelMap = (
  documentType: DashboardFormType
): Record<EligibilityStatus, string> => {
  const countryCodeStatus = (() => {
    if (UK_DOCUMENT_TYPES.has(documentType)) {
      return "Non-GB";
    }

    // for CESOP/DAC7 demo purposes only
    // should be removed when the feature is implemented
    if (
      [DashboardFormType.Cesop, DashboardFormType.Dac7].includes(documentType)
    ) {
      return "Transactions Under Threshold";
    }

    return "Non-US";
  })();

  return {
    [EligibilityStatus.Added]: "Eligible - Added",
    [EligibilityStatus.Eligible]: "Eligible",
    [EligibilityStatus.Removed]: "Removed From Scope",
    [EligibilityStatus.Exempt]:
      documentType === DashboardFormType.Dac7 ? "Non-EU" : "Exempt",
    [EligibilityStatus.NonValidTaxCountry]: countryCodeStatus,
    [EligibilityStatus.NotReportable]: [
      DashboardFormType.Cesop,
      DashboardFormType.Dac7,
    ].includes(documentType)
      ? "No Reportable Transactions"
      : "Not Reportable",
    [EligibilityStatus.Pending]: "Pending",
  };
};

export const toDashboardEligibilityFormStatus = (
  formStatus?: EligibilityFormStatus
): DashboardEligibilityFormStatus => {
  switch (formStatus) {
    case EligibilityFormStatus.Generated:
    case EligibilityFormStatus.Delivered: {
      return DashboardEligibilityFormStatus.Generated;
    }
    case EligibilityFormStatus.Filed: {
      return DashboardEligibilityFormStatus.Filed;
    }
    default: {
      return DashboardEligibilityFormStatus.NotGenerated;
    }
  }
};

export const dashboardEligibilityFormStatusLabelMap: Record<
  DashboardEligibilityFormStatus,
  string
> = {
  [DashboardEligibilityFormStatus.NotGenerated]: "Not Generated",
  [DashboardEligibilityFormStatus.Generated]: "Generated",
  [DashboardEligibilityFormStatus.Filed]: "Filed",
};

export const dashboardEligibilityFormStatusVariantMap: Record<
  DashboardEligibilityFormStatus,
  "success" | "secondary" | "danger"
> = {
  [DashboardEligibilityFormStatus.NotGenerated]: "danger",
  [DashboardEligibilityFormStatus.Generated]: "success",
  [DashboardEligibilityFormStatus.Filed]: "success",
};

export const accountValidationsEligibilityStatusLabelMap: Record<
  AccountValidationsEligibilityStatus,
  string
> = {
  [AccountValidationsEligibilityStatus.Complete]: "Complete - No Warnings",
  [AccountValidationsEligibilityStatus.CompleteWithWarnings]:
    "Complete - With Warnings",
  [AccountValidationsEligibilityStatus.Incomplete]: "Incomplete",
};

export const eligibilitySearchParamKeys = [
  "externalId",
  "name",
  "alternateExternalId",
] as const;

export type EligibilitySearchParamKey =
  (typeof eligibilitySearchParamKeys)[number];

export const eligibilitySearchParamKeyLabelMap: Record<
  EligibilitySearchParamKey,
  string
> = {
  externalId: "Account ID",
  name: "Name",
  alternateExternalId: "Alternate Account ID",
};

export const eligibilityEnumParamKeys = [
  "accountValidationsEligibilityStatuses",
  "formStatuses",
] as const;

export enum OverrideEligibilityAction {
  Add = "add",
  Remove = "remove",
}

export const getEligibilityExportRequest = ({
  accountExternalIdsFilter,
  shouldIncludeDataValidationReport,
  shouldIncludeFormDataReport,
  shouldIncludeAccountDataReport,
  includeAlternateAccountId,
}: ExportEligibilityTableModalForm &
  AccountExternalIdsFilter &
  EligibilityExportFormatParams): CreateEligibilityExportBodyDto => {
  const reportTypes: EligibilityReportType[] = [];

  if (shouldIncludeDataValidationReport) {
    reportTypes.push(EligibilityReportType.DataValidationReport);
  }
  if (shouldIncludeFormDataReport) {
    reportTypes.push(EligibilityReportType.FormDataReport);
  }
  if (shouldIncludeAccountDataReport) {
    reportTypes.push(EligibilityReportType.AccountDataReport);
  }

  const accountExternalIds = newlineTextBasedFilterToArray(
    accountExternalIdsFilter
  );

  return {
    reportTypes,
    externalIds: accountExternalIds.length > 0 ? accountExternalIds : undefined,
    includeColumns: includeAlternateAccountId ? ["alternate_account_id"] : [],
  };
};

export const toEligibilityFindAccountsRequest = ({
  limit,
  page,
  formYear,
  formType,
  formStatuses,
  accountExternalIdsFilter,
  accountValidationsEligibilityStatuses,
  statuses,
  name,
  externalId,
  alternateExternalId,
}: IrEligibilityUrlParams &
  AccountExternalIdsFilter): EligibilityFindAccountsRequest => {
  const accountExternalIds = newlineTextBasedFilterToArray(
    accountExternalIdsFilter
  );

  return {
    filters: {
      accountValidationsEligibilityStatus:
        accountValidationsEligibilityStatuses,
      documentDate: formYear,
      documentType: mapDashboardFormTypeToApiFormType(formType),
      externalId,
      alternateExternalId,
      externalIds:
        accountExternalIds.length === 0 ? undefined : accountExternalIds,
      formStatus: formStatuses,
      name,
      status: statuses === "None" ? undefined : statuses,
    },
    page: {
      limit,
      offset: getOffsetFromPage({ page, limit }),
    },
  };
};
