import { CompanyUser } from "@taxbit-dashboard/rest";
import { useLocation } from "@taxbit-dashboard/router";
import { useEffect } from "react";

export const initializeZendesk = () => {
  if (window.zE) {
    // We don't want to show the widget until we know if the current
    // user has access to view it, so our "initialization" is really just
    // hiding the widget until we know more.
    window.zE("webWidget", "hide");
  }
};

export const updateZendeskUser = (
  user: CompanyUser,
  hasZendeskWidgetAccess: boolean
) => {
  if (window.zE && hasZendeskWidgetAccess) {
    window.zE("webWidget", "show");
    window.zE("webWidget", "identify", {
      name: user.name,
      email: user.email,
    });
  }
};

/**
 * The Zendesk widget doesn't listen to page URL changes by default, so we need to
 * manually update the URL in the widget when the user navigates to a new page for
 * the help center content suggestions to be accurate.
 */
export const useUpdateZendeskUrl = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.zE) {
      window.zE("webWidget", "helpCenter:setSuggestions", {
        url: true,
      });
    }
  }, [location.pathname]);
};
