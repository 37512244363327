import { getOffsetFromPage } from "@taxbit-dashboard/commons";
import { GetTransactionsTableRequest } from "@taxbit-dashboard/rest";
import { RouteId, useUrlParams } from "@taxbit-dashboard/router";
import { useCallback, useMemo, useState } from "react";

import newlineTextBasedFilterToArray from "../../utils/newlineTextBasedFilterToArray";

export const useTransactionsParams = () => {
  const { urlParams, updateUrlParams, updateFilterParams, setUrlParams } =
    useUrlParams({
      routeId: RouteId.Transactions,
    });

  // These filters can get long and break the URL, so we store them in state
  const [stateFilters, setStateFilters] = useState({
    assetCodes: "",
    transactionIds: "",
    accountIds: "",
  });

  const requestParams = useMemo<GetTransactionsTableRequest>(() => {
    const stateFiltersAsArrays = Object.fromEntries(
      Object.entries(stateFilters).map(([key, value]) => [
        key,
        newlineTextBasedFilterToArray(value),
      ])
    );

    const { page, limit, ...urlParamFilters } = urlParams;
    const { assetCodes, ...arrayFilters } = stateFiltersAsArrays;

    return {
      page: {
        offset: getOffsetFromPage({
          page,
          limit,
        }),
        limit,
      },
      filters: {
        assetCode: assetCodes,
        ...arrayFilters,
        ...urlParamFilters,
      },
    };
  }, [urlParams, stateFilters]);

  const updateStateFilters = useCallback(
    (newFilters: Partial<typeof stateFilters>) => {
      setStateFilters((prev) => ({
        ...prev,
        ...newFilters,
      }));
    },
    []
  );

  const clearAllFilters = useCallback(() => {
    // Clear local filters
    updateStateFilters({
      assetCodes: "",
      transactionIds: "",
      accountIds: "",
    });

    // Clear all URL parameters besides pagination and sorting
    const { page, limit } = urlParams;
    return setUrlParams({ page, limit });
  }, [setUrlParams, updateStateFilters, urlParams]);

  return {
    urlParams,
    requestParams,
    updateUrlParams,
    stateFilters,
    updateStateFilters,
    updateFilterParams,
    clearAllFilters,
  };
};
