import {
  createSingleInstanceHookContext,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { RouteId, useUrlParams } from "@taxbit-dashboard/router";
import { useCallback, useMemo } from "react";

import useFormItemYears from "./useFormItemYears";
import { useGetTotalAccountsCount } from "../../../api/hydrated-accounts/hydratedAccountsApi";
import getOverviewApiUrlParams from "../../../api/overview/getOverviewApiUrlParams";
import {
  useGetEligibilityOverview,
  useGetFormItemsOverview,
  useGetTransactionsOverview,
} from "../../../api/overview/overviewApi";
import {
  OverviewUrlParams,
  useOverviewUrlParamsSchema,
} from "../../../api/overview/useOverviewUrlParamsSchema";

const useOverviewData = () => {
  const { hasEligibilityPageAccess } = useDashboardFeatureFlags();

  const urlParamSchema = useOverviewUrlParamsSchema();

  const { urlParams, updateUrlParams } = useUrlParams({
    routeId: RouteId.Overview,
    overrideSchema: urlParamSchema,
  });

  const apiParams = useMemo(
    () => getOverviewApiUrlParams(urlParams),
    [urlParams]
  );

  const setParamsForKey = useCallback(
    <TKey extends keyof OverviewUrlParams>(
      key: TKey,
      params: Partial<OverviewUrlParams[TKey]>
    ) => {
      void updateUrlParams({
        [key]: {
          ...urlParams[key],
          ...params,
        },
      });
    },
    [updateUrlParams, urlParams]
  );

  const eligibilityQuery = useGetEligibilityOverview(apiParams.eligibility);
  const formItemsQuery = useGetFormItemsOverview(apiParams.forms);
  const totalAccountsCountQuery = useGetTotalAccountsCount();
  const transactionsQuery = useGetTransactionsOverview(apiParams.transactions);

  const { isLoading: formItemsYearsLoading, ...formItemYearsData } =
    useFormItemYears();

  const areInitialQueriesLoading = useMemo(
    () =>
      (eligibilityQuery.isLoading && hasEligibilityPageAccess) ||
      formItemsYearsLoading ||
      formItemsQuery.isLoading ||
      totalAccountsCountQuery.isLoading ||
      transactionsQuery.isLoading,
    [
      eligibilityQuery.isLoading,
      hasEligibilityPageAccess,
      formItemsQuery.isLoading,
      formItemsYearsLoading,
      totalAccountsCountQuery.isLoading,
      transactionsQuery.isLoading,
    ]
  );

  return {
    ...formItemYearsData,
    areInitialQueriesLoading,
    eligibilityQuery,
    formItemsQuery,
    setParamsForKey,
    totalAccountsCountQuery,
    transactionsQuery,
    urlParams,
  };
};

export const {
  Provider: OverviewDataContextProvider,
  useContextHook: useOverviewDataContext,
} = createSingleInstanceHookContext(useOverviewData, "useOverviewDataContext");
