import { UrlParamPaginationControls } from "@taxbit-dashboard/commons";
import { Body } from "@taxbit-private/cosmic";

import { useEligibilityDataContext } from "./context/useEligibilityData";
import EligibilityTableExportButton from "./export/EligibilityTableExportButton";
import { ELIGIBILITY_DATA_MAX_LIMIT } from "../../../api/information-reporting/eligible-users/eligibilityApi";

export enum EligibilityTablePaginationTrackingId {
  Pagination = "ir-eligibility-table-pagination",
}

const EligibilityTablePagination = () => {
  const {
    updatePaginationParams,
    urlParams,
    shouldDisableControls,
    totalCount,
  } = useEligibilityDataContext();

  const tooltipProps = {
    content: (
      <Body>
        Eligible users are limited to 10,000 rows. Please{" "}
        <EligibilityTableExportButton isAnchor={true} label="export a CSV" /> of
        this table to see all eligible users.
      </Body>
    ),
    isInteractive: true,
  } as const;

  return (
    <UrlParamPaginationControls
      itemsLimit={ELIGIBILITY_DATA_MAX_LIMIT}
      tooltipProps={tooltipProps}
      isDisabled={shouldDisableControls}
      totalCount={totalCount}
      trackingId={EligibilityTablePaginationTrackingId.Pagination}
      urlParams={urlParams}
      updateUrlParams={updatePaginationParams}
    />
  );
};

export default EligibilityTablePagination;
