import { Divider, Flex, H3 } from "@taxbit-private/cosmic";
import styled from "styled-components";

import ActiveJobsListContents from "./ActiveJobsListContents";

const ActiveJobsContents: React.FC = () => {
  return (
    <Flex direction="column">
      <Flex padding="l" alignItems="center">
        <H3 as="div">Active Jobs</H3>
      </Flex>
      <Divider />
      <ItemsWrapper>
        <ActiveJobsListContents />
      </ItemsWrapper>
    </Flex>
  );
};

export default ActiveJobsContents;

const ItemsWrapper = styled.div(
  ({ theme }) => `
  width: ${theme.measure.xl};
  overflow-y: auto;
`
);
