import { AppOutlet } from "@taxbit-dashboard/commons";
import { RouteId, RouterMapForPrefix } from "@taxbit-dashboard/router";

import {
  accountsNavigationItem,
  accountsRouterMap,
} from "./src/pages/accounts/Accounts.page";
import IrEligibilityPage from "./src/pages/information-reporting/eligible-users/IrEligibility.page";
import IrFormsPage from "./src/pages/information-reporting/forms/IrForms.page";
import IrSummaryPage, {
  irNavigationItem,
} from "./src/pages/information-reporting/summary/IrSummary.page";
import DeleteDataPage from "./src/pages/ingestion/DeleteData.page";
import IngestDataPage, {
  manageDataNavigationItem,
} from "./src/pages/ingestion/IngestData.page";
import OverviewPage, {
  overviewNavigationItem,
} from "./src/pages/overview/Overview.page";
import ReportsPage, {
  reportsNavigationItem,
} from "./src/pages/reports/Reports.page";
import {
  transactionsNavigationItem,
  transactionsRouterMap,
} from "./src/pages/transactions/Transactions.page";

export const routerMap = {
  [RouteId.IrwLayout]: () => (
    <AppOutlet
      sections={[
        {
          items: [
            overviewNavigationItem,
            accountsNavigationItem,
            transactionsNavigationItem,
            manageDataNavigationItem,
            irNavigationItem,
            reportsNavigationItem,
          ],
        },
      ]}
    />
  ),
  [RouteId.Overview]: OverviewPage,
  [RouteId.IngestData]: IngestDataPage,
  [RouteId.DeleteData]: DeleteDataPage,
  [RouteId.IrSummary]: IrSummaryPage,
  [RouteId.IrEligibility]: IrEligibilityPage,
  [RouteId.IrForms]: IrFormsPage,
  [RouteId.Reports]: ReportsPage,
  ...accountsRouterMap,
  ...transactionsRouterMap,
} as const satisfies RouterMapForPrefix<"/_authed/o/$organizationId/_irwLayout">;

export { DeleteFileUploaderContextProvider } from "./src/pages/ingestion/context/useDeleteFileUploader";
export { IngestFileUploaderContextProvider } from "./src/pages/ingestion/context/useIngestFileUploader";
