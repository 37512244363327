import { RouteId } from "../types/RouteId";

export const migratedRouteMap = {
  "/manage-data/ingest": RouteId.IngestData,
  "/manage-data/delete": RouteId.DeleteData,
  "/ir/eligibility": RouteId.IrEligibility,
  "/ir/summary": RouteId.IrSummary,
  "/ir/forms": RouteId.Reports,
} as const;

type MigratedRoute = keyof typeof migratedRouteMap;

const migratedRoutes = new Set(Object.keys(migratedRouteMap));

export const isMigratedRoute = (path: string): path is MigratedRoute => {
  return migratedRoutes.has(path);
};
