import { UtcDateString } from "@taxbit-private/cosmic";
import { isSameDay } from "date-fns";

import getBrowserDateFromUtcDateString from "./getBrowserDateFromUtcDateString";

/**
 * Converts two UTC date strings into browser dates and uses the date-fns
 * `isSameDay` method to determine if they are the same day.
 */
const isSameUtcDay = (
  dateA?: UtcDateString,
  dateB?: UtcDateString
): boolean => {
  const browserDateA = getBrowserDateFromUtcDateString(dateA);
  const browserDateB = getBrowserDateFromUtcDateString(dateB);

  return (
    !!browserDateA && !!browserDateB && isSameDay(browserDateA, browserDateB)
  );
};

export default isSameUtcDay;
