import { RouteId, useAuthenticatedNavigation } from "@taxbit-dashboard/router";
import { ActionSnippet, Anchor, Button } from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import useDeveloperSettings from "./useDeveloperSettings";

const TAX_API_DOCS_URL = "https://apidocs.taxbit.com/docs/getting-started";

export enum GeneralDeveloperSettingsTrackingId {
  OrganizationIdCopyBtn = "organization-id-copy-btn",
  ClientIdCopyBtn = "client-id-copy-btn",
  ClientSecretMaskedData = "client-secret-masked-data",
  FilerDetailsBtn = "filer-details-btn",
  S3BucketCopyBtn = "s3-bucket-copy-btn",
  AccessKeyCopyBtn = "access-key-copy-btn",
  SecretKeyMaskedData = "secret-key-masked-data",
  ApiDocumentationLink = "api-documentation-link",
}

const useGeneralDeveloperSettingsRows = () => {
  const { currentOrganization, currentOrganizationId, devSettings } =
    useDeveloperSettings();
  const { authenticatedNavigate } = useAuthenticatedNavigation();
  return useMemo(() => {
    const generalSettingsSections = [
      {
        key: "General",
        title: "General",
        rows: [
          {
            label: "Company Name",
            key: "companyName",
            content:
              currentOrganization?.companyName || COSMIC_VALUE_PLACEHOLDER,
          },
          {
            label: "Organization Name",
            key: "organizationName",
            content: currentOrganization?.name || COSMIC_VALUE_PLACEHOLDER,
          },
          {
            label: "Organization ID",
            key: "organizationId",
            content: (
              <ActionSnippet
                contents={currentOrganizationId}
                trackingId={
                  GeneralDeveloperSettingsTrackingId.OrganizationIdCopyBtn
                }
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Filer Details",
            key: "filerDetails",
            content: (
              <Button
                trackingId={GeneralDeveloperSettingsTrackingId.FilerDetailsBtn}
                label="Filer Setup"
                onClick={() => {
                  void authenticatedNavigate({ to: RouteId.FilerSettings });
                }}
                variant="anchor-primary-inline"
              />
            ),
          },
        ],
      },
      {
        key: "API",
        title: "API",
        rows: [
          {
            label: "Client ID",
            key: "clientId",
            content: (
              <ActionSnippet
                contents={devSettings?.auth0ClientId}
                trackingId={GeneralDeveloperSettingsTrackingId.ClientIdCopyBtn}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "Client Secret",
            key: "clientSecret",
            content: (
              <ActionSnippet
                contents={devSettings?.auth0ClientSecret}
                trackingId={
                  GeneralDeveloperSettingsTrackingId.ClientSecretMaskedData
                }
                shouldMask={true}
                shouldCopy={true}
              />
            ),
          },
          {
            label: "API Documentation",
            key: "apiDocumentation",
            content: (
              <Anchor
                trackingId={
                  GeneralDeveloperSettingsTrackingId.ApiDocumentationLink
                }
                label={TAX_API_DOCS_URL}
                href={TAX_API_DOCS_URL}
                variant="anchor-primary-inline"
              />
            ),
          },
        ],
      },
    ];
    const awsS3Section = {
      key: "AwsS3",
      title: "AWS S3",
      rows: [
        {
          label: "S3 Bucket",
          key: "s3Bucket",
          content: (
            <ActionSnippet
              contents={devSettings?.s3DropzoneBucket}
              trackingId={GeneralDeveloperSettingsTrackingId.S3BucketCopyBtn}
              shouldCopy={true}
            />
          ),
        },
        {
          label: "Access Key",
          key: "accessKey",
          content: (
            <ActionSnippet
              contents={devSettings?.s3DropzoneBucketAccessKey}
              trackingId={GeneralDeveloperSettingsTrackingId.AccessKeyCopyBtn}
              shouldCopy={true}
            />
          ),
        },
        {
          label: "Secret Key",
          key: "secretKey",
          content: (
            <ActionSnippet
              contents={devSettings?.s3DropzoneBucketSecretKey}
              trackingId={
                GeneralDeveloperSettingsTrackingId.SecretKeyMaskedData
              }
              shouldMask={true}
              shouldCopy={true}
            />
          ),
        },
      ],
    };
    return devSettings?.deliveryToS3
      ? [...generalSettingsSections, awsS3Section]
      : generalSettingsSections;
  }, [
    authenticatedNavigate,
    currentOrganization?.companyName,
    currentOrganization?.name,
    currentOrganizationId,
    devSettings?.auth0ClientId,
    devSettings?.auth0ClientSecret,
    devSettings?.deliveryToS3,
    devSettings?.s3DropzoneBucket,
    devSettings?.s3DropzoneBucketAccessKey,
    devSettings?.s3DropzoneBucketSecretKey,
  ]);
};

export default useGeneralDeveloperSettingsRows;
