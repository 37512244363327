import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { useTransactionsTableContext } from "../useTransactionsTable";
import {
  useTransactionsFilterFormSchema,
  TransactionsFilterFormValues,
} from "./transactionsFilterDrawerFormTypes";

export const useTransactionsFilterDrawerForm = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    urlParams,
    updateFilterParams,
    stateFilters,
    updateStateFilters,
    clearAllFilters,
    filterCount,
  } = useTransactionsTableContext();

  const formValuesFromUrlParams = useMemo((): TransactionsFilterFormValues => {
    return {
      datetime: {
        startDate: urlParams.startDate,
        endDate: urlParams.endDate,
      },
      type: urlParams.type ?? [],
      assetCodes: stateFilters.assetCodes,
      transactionIds: stateFilters.transactionIds,
      accountIds: stateFilters.accountIds,
    };
  }, [urlParams, stateFilters]);

  const transactionsFilterFormSchema = useTransactionsFilterFormSchema();

  const formMethods = useForm<TransactionsFilterFormValues>({
    defaultValues: formValuesFromUrlParams,
    resolver: zodResolver(transactionsFilterFormSchema),
  });

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(formValuesFromUrlParams);
  }, [formValuesFromUrlParams, formMethods]);

  const closeAndReset = useCallback(() => {
    setIsOpen(false);
    formMethods.reset(formValuesFromUrlParams);
  }, [formValuesFromUrlParams, formMethods]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit((values) => {
        const { type, datetime, ...stateFilters } = values;

        void updateFilterParams({
          type,
          startDate: datetime.startDate,
          endDate: datetime.endDate,
        });
        updateStateFilters(stateFilters);
        closeAndReset();
      })(e);
    },
    [formMethods, updateFilterParams, updateStateFilters, closeAndReset]
  );

  const clearAllFormFilters = useCallback(() => {
    void clearAllFilters();
    setIsOpen(false);
  }, [clearAllFilters]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
    isOpen,
    setIsOpen,
    closeAndReset,
    filterCount,
  };
};
