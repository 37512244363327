import { AuthorizedView, UserPermission } from "@taxbit-dashboard/commons";
import {
  BoxedEntityEmptyState,
  BoxedErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import DownloadTaxForms from "./download-tax-forms/DownloadTaxForms";
import YearDropdown from "./download-tax-forms/YearDropdown";
import TaxFormDetails from "./tax-form-details/TaxFormDetails";
import useGetTaxFormsTab from "./useGetTaxFormsTab";

export enum TaxFormsTabTrackingId {
  TaxFormsTabSpinner = "tax-forms-tab-spinner",
}

const TaxFormsTab = () => {
  const {
    years,
    currentYear,
    isLoading,
    isError,
    setCurrentYear,
    currentForms,
  } = useGetTaxFormsTab();

  return (
    <AuthorizedView permissions={[UserPermission.ReadAccountDocuments]}>
      {isLoading ? (
        <ContentSpinner trackingId={TaxFormsTabTrackingId.TaxFormsTabSpinner} />
      ) : isError ? (
        <BoxedErrorEmptyState entity="tax forms" />
      ) : years.length > 0 && currentYear && currentForms ? (
        <>
          <YearDropdown
            years={years}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
          <DownloadTaxForms currentForms={currentForms} />
          <TaxFormDetails currentForms={currentForms} />
        </>
      ) : (
        <BoxedEntityEmptyState entity="tax forms" />
      )}
    </AuthorizedView>
  );
};

export default TaxFormsTab;
