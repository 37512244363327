import { isDefined } from "@taxbit-dashboard/commons";
import {
  AccountOwner,
  AccountOwnerDetailsTaxDocumentationIssueType,
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentation,
} from "@taxbit-dashboard/rest";

import { TaxDocDetails } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { isTaxDocumentationWarning } from "../../../../../utils/isTaxDocumentationWarning";
import { isWForm } from "../../../../../utils/isWForm";

const getFormDetailsName = (name: string, dbaName?: string) =>
  `${name}${dbaName ? ` (${dbaName})` : ""}`;

export const getIssues = (
  issues?: AccountOwnerIssue[],
  latestTaxDocumentationGroupNumber?: number,
  latestTaxDocumentation?: KycTaxDocumentation
) => {
  if (!issues) {
    return {
      issues: [],
      warnings: [],
    };
  }

  const latestGroupIssues = issues.filter(
    (issue) =>
      issue.groupNumber === latestTaxDocumentationGroupNumber ||
      issue.groupNumber === 0
  );

  const unresolvedIssues = latestGroupIssues.filter(
    (issue) => !issue.resolvedAt
  );

  const existingIssueTypes = new Set(
    unresolvedIssues.map((issue) => issue.issueType)
  );

  const taxDocUsIndiciaIssue = (() => {
    if (
      !existingIssueTypes.has(
        AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
      )
    ) {
      const usIndiciaIssue = [...issues]
        .reverse()
        .find(
          (issue) =>
            issue.issueType ===
              AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia &&
            issue.accountOwnerCuring
        );

      if (isDefined(usIndiciaIssue)) {
        return [usIndiciaIssue];
      }
    }

    return [];
  })();

  existingIssueTypes.add(
    AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
  );

  const taxDocRelatedIssues = [...latestGroupIssues]
    .reverse()
    .filter((issue) => {
      const isResolvedByLatestTaxDocumentation =
        issue.resolvedAt && latestTaxDocumentation
          ? issue.resolvedAt >= latestTaxDocumentation.createdAt
          : false;

      if (
        !existingIssueTypes.has(issue.issueType) &&
        // we don't show resolved Expired Form issues
        issue.issueType !==
          AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm &&
        (isResolvedByLatestTaxDocumentation || issue.accountOwnerCuring)
      ) {
        existingIssueTypes.add(issue.issueType);

        return true;
      }

      return false;
    });

  const resolvedIssues = [...taxDocRelatedIssues, ...taxDocUsIndiciaIssue].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const finalIssues = [...unresolvedIssues, ...resolvedIssues];
  const filteredIssues = finalIssues.filter(
    (issue) => !isTaxDocumentationWarning(issue.issueType)
  );

  const warning = finalIssues.find((issue) =>
    isTaxDocumentationWarning(issue.issueType)
  );

  return {
    issues: filteredIssues,
    warnings: warning ? [warning] : [],
  };
};

const getExpirationDate = (signatureTimestamp: string) => {
  const expirationYear = new Date(signatureTimestamp).getUTCFullYear() + 3;
  return new Date(`${expirationYear}-12-31T23:59:59.999Z`).toISOString();
};

const getWForm = (taxDocumentations?: KycTaxDocumentation[]) =>
  taxDocumentations?.find((doc) => isWForm(doc));

const getFormDetails = (
  issues?: AccountOwnerIssue[],
  taxDocumentations?: KycTaxDocumentation[],
  accountOwner?: AccountOwner,
  latestTaxDocumentationGroupNumber?: number
): TaxDocDetails | undefined => {
  const wForm = getWForm(taxDocumentations);
  if (!isWForm(wForm)) {
    return undefined;
  }

  const baseData = {
    documentType: wForm.documentType,
    taxClassification: wForm.taxClassification,
    submissionDate: wForm.signatureTimestamp,
    documentStatus:
      accountOwner?.validation.taxDocumentStatus?.validationResult ??
      "UNDOCUMENTED",
  };

  if (wForm.documentType === KycTaxDocumentType.W9) {
    return {
      ...baseData,
      name: getFormDetailsName(wForm.name, wForm.dbaName),
      subjectToBackupWithholding: !wForm.isNotSubjectBackupWithholding,
    };
  }

  const { issues: currentIssues, warnings } = getIssues(
    issues,
    latestTaxDocumentationGroupNumber,
    wForm
  );

  const treatyClaimStatus =
    baseData.documentStatus === "INVALID" ||
    warnings.some(
      (issue) =>
        issue.issueType ===
          AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch &&
        !issue.resolvedAt
    )
      ? "INVALID"
      : "VALID";

  if (wForm.documentType === KycTaxDocumentType.W8Imy) {
    const withholdingDocumentationStatus = warnings.some(
      (issue) =>
        issue.issueType ===
          AccountOwnerDetailsTaxDocumentationIssueType.WithholdingDocumentation &&
        !issue.resolvedAt
    )
      ? "INVALID"
      : "VALID";

    return {
      ...baseData,
      withholdingDocumentationStatus,
      name: getFormDetailsName(wForm.name),
      expirationDate: getExpirationDate(wForm.signatureTimestamp),
      issues: currentIssues,
      warnings,
      permanentAddress: wForm.permanentAddress,
    };
  }

  return {
    ...baseData,
    treatyClaimStatus: wForm.treatyClaimIsEligible
      ? treatyClaimStatus
      : undefined,
    name: getFormDetailsName(wForm.name),
    expirationDate: getExpirationDate(wForm.signatureTimestamp),
    issues: currentIssues,
    warnings,
    permanentAddress: wForm.permanentAddress,
    treatyCountry: wForm.treatyClaimCountry,
  };
};

const useGetFormDetails = () => {
  const {
    isLoading,
    taxDocumentations,
    issues,
    accountOwner,
    latestTaxDocumentationGroupNumber,
    shouldUnmaskValue,
    taxDocumentationStatus,
  } = useAccountOwnerTaxDocumentContext();

  return {
    isLoading,
    formDetails: getFormDetails(
      issues,
      taxDocumentations,
      accountOwner,
      latestTaxDocumentationGroupNumber
    ),
    wForm: getWForm(taxDocumentations),
    shouldUnmaskValue,
    taxDocumentationStatus,
  };
};

export default useGetFormDetails;
