import { dashboardFeatureFlagSetSchema } from "./dashboardFeatureFlagSetTypes";

/**
 * This object reflects all current flags default values under the LaunchDarkly "TaxBit Dashboard" project.
 */
const defaultDashboardFeatureFlagSet = dashboardFeatureFlagSetSchema.parse({});

/**
 * Creates a version of the feature flag set where all simple boolean flags are set
 * to `true` while the complex flags are left with their default values. This is useful
 * for setting up test infrastructure that assumes all relevant flags are enabled.
 */
export const testDefaultDashboardFeatureFlagSet = (() => {
  const featureFlagSetWithTrueValues = Object.fromEntries(
    Object.entries(defaultDashboardFeatureFlagSet).map(([key]) => [key, true])
  );
  return dashboardFeatureFlagSetSchema.parse(featureFlagSetWithTrueValues);
})();

export default defaultDashboardFeatureFlagSet;
