import { TransactionType } from "@taxbit-dashboard/rest";
import { cosmicDateRangePickerValueSchema } from "@taxbit-private/cosmic";
import { useMemo } from "react";
import { z } from "zod";

import { useCreateTextAreaFilterSchema } from "../../../../utils/useCreateTextAreaFilterSchema";

export const useTransactionsFilterFormSchema = () => {
  const assetCodesTextAreaFilterSchema =
    useCreateTextAreaFilterSchema("Asset Codes");
  const transactionIdsTextAreaFilterSchema =
    useCreateTextAreaFilterSchema("Transaction IDs");
  const accountIdsTextAreaFilterSchema =
    useCreateTextAreaFilterSchema("Account IDs");

  return useMemo(() => {
    return z.object({
      datetime: cosmicDateRangePickerValueSchema,
      type: z.array(z.nativeEnum(TransactionType)),
      assetCodes: assetCodesTextAreaFilterSchema,
      transactionIds: transactionIdsTextAreaFilterSchema,
      accountIds: accountIdsTextAreaFilterSchema,
    });
  }, [
    assetCodesTextAreaFilterSchema,
    transactionIdsTextAreaFilterSchema,
    accountIdsTextAreaFilterSchema,
  ]);
};

export type TransactionsFilterFormValues = z.infer<
  ReturnType<typeof useTransactionsFilterFormSchema>
>;
