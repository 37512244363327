import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { IrFormsUrlParams } from "@taxbit-dashboard/router";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import useFormsFiltersFormFieldSchema, {
  FormsFiltersFormFields,
} from "./useFormsFiltersFormFieldSchema";
import { useIrFormsContext } from "../context/useIrForms";

const getFormDataFromUrlParams = ({
  statuses,
  types,
  startDate,
  endDate,
}: IrFormsUrlParams): Omit<
  FormsFiltersFormFields,
  "accountExternalIdsFilter"
> => ({
  statuses: statuses ?? [],
  types: types ?? [],
  dateRange: [
    startDate ? new Date(startDate) : undefined,
    endDate ? new Date(endDate) : undefined,
  ],
});

const formDataEmptyValues: FormsFiltersFormFields = {
  statuses: [],
  types: [],
  dateRange: [undefined, undefined],
  accountExternalIdsFilter: "",
};

const useFormsFiltersDrawerForm = () => {
  const {
    urlParams,
    updateFilterParams,
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
  } = useIrFormsContext();

  const defaultValues = useMemo(
    () => ({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    }),
    [urlParams, accountExternalIdsFilter]
  );

  const formsFiltersFormFieldSchema = useFormsFiltersFormFieldSchema();

  const formMethods = useForm<FormsFiltersFormFields>({
    resolver: zodResolver(formsFiltersFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
      void formMethods.handleSubmit(
        ({
          accountExternalIdsFilter: newAccountExternalIdsFilter,
          dateRange: [startDate, endDate],
          statuses,
          types,
        }) => {
          void updateFilterParams({
            statuses,
            types,
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          });
          setAccountExternalIdsFilter(newAccountExternalIdsFilter);
          callback();
        }
      )(e);
    },
    [formMethods, updateFilterParams, setAccountExternalIdsFilter]
  );

  useEffect(() => {
    formMethods.reset({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    });
  }, [urlParams, formMethods, accountExternalIdsFilter]);

  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof FormsFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useFormsFiltersDrawerForm;
