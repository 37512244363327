import { getPageLimitApiParams } from "@taxbit-dashboard/commons";
import { GetFilesParams } from "@taxbit-dashboard/rest";
import {
  DeleteDataUrlParams,
  IngestDataUrlParams,
} from "@taxbit-dashboard/router";

const getFilesParams = ({
  fileType,
  page,
  limit,
}: IngestDataUrlParams | DeleteDataUrlParams): GetFilesParams => {
  return {
    "filters[type]": fileType,
    ...getPageLimitApiParams({ page, limit }),
  };
};

export default getFilesParams;
