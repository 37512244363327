import {
  accountValidationsEligibilityStatusSchema,
  dashboardEligibilityFormStatusSchema,
  eligibilityStatusSchema,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

import formTypeAndDateParamsSchema, {
  ParamsWithDefinedFormTypeAndDate,
} from "../../shared/formTypeAndDateParamsSchema";
import pageLimitPaginationParamsSchema from "../../shared/pageLimitPaginationParamsSchema";
import searchStringValueSchema from "../../shared/searchStringValueSchema";

const irEligibilityUrlParamsSchema = z
  .object({
    statuses: z
      .array(eligibilityStatusSchema)
      .or(z.literal("None"))
      .optional()
      .catch(undefined),
    accountValidationsEligibilityStatuses: z
      .array(accountValidationsEligibilityStatusSchema)
      .optional()
      .catch(undefined),
    formStatuses: z
      .array(dashboardEligibilityFormStatusSchema)
      .optional()
      .catch(undefined),
    externalId: searchStringValueSchema,
    name: searchStringValueSchema,
    alternateExternalId: searchStringValueSchema,
  })
  .merge(pageLimitPaginationParamsSchema)
  .merge(formTypeAndDateParamsSchema);

/**
 * The URL params for the IRW eligibility page are run through the `useUrlParamsWithFormTypeAndDate` hook
 * to ensure that the formType and formYear are always present. This is just a convenience type that includes
 * this defaulting.
 */
export type IrEligibilityUrlParams = ParamsWithDefinedFormTypeAndDate<
  z.infer<typeof irEligibilityUrlParamsSchema>
>;

export default irEligibilityUrlParamsSchema;
