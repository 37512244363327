import { renderExcludedSpan } from "@taxbit-dashboard/commons";
import {
  HydratedAccount,
  HydratedAccountTaxDocumentationType,
} from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  VAT_STATUS_LABELS,
  TIN_STATUS_LABELS,
  TAX_CLASSIFICATION_LABELS,
  TAX_DOCUMENTATION_TYPE_LABELS,
} from "./accountsLabels";
import {
  ACCOUNTS_TABLE_ROW_LABELS,
  AccountsTableRow,
} from "./accountsTableRow";
import { assertUnreachable } from "../../../../utils/assertUnreachable";
import { AccountsView } from "../accountsView";
import { useAccountsView } from "../useAccountsView";

const renderBadge = (
  label: string,
  variant: "success" | "danger" | "warning" | "secondary"
) => <Badge shouldTransformLabel={false} variant={variant} label={label} />;

const renderStringArray = (data?: string[]) => data?.sort().join(", ");

const renderVatStatus = (vatStatus: HydratedAccount["vatStatus"]) => {
  if (!vatStatus) return vatStatus;

  const label = VAT_STATUS_LABELS[vatStatus];

  switch (vatStatus) {
    case "VALID": {
      return renderBadge(label, "success");
    }
    case "PENDING": {
      return renderBadge(label, "warning");
    }
    case "NOT_REQUIRED": {
      return renderBadge(label, "secondary");
    }
    case "INSUFFICIENT_DATA":
    case "ERROR":
    case "INVALID": {
      return renderBadge(label, "danger");
    }
    case "UNHANDLED": {
      return undefined;
    }
    default: {
      return assertUnreachable(vatStatus);
    }
  }
};

const renderTinStatus = (tinStatus: HydratedAccount["tinStatus"]) => {
  if (!tinStatus) return tinStatus;

  const key =
    tinStatus === "VALID_EIN_MATCH" ||
    tinStatus === "VALID_SSN_MATCH" ||
    tinStatus === "VALID_SSN_EIN_MATCH"
      ? "VALID_MATCH"
      : tinStatus;
  const label = TIN_STATUS_LABELS[key];

  switch (key) {
    case "VALID_MATCH": {
      return renderBadge(label, "success");
    }
    case "PENDING": {
      return renderBadge(label, "warning");
    }
    case "FOREIGN": {
      return renderBadge(label, "secondary");
    }
    case "INVALID_DATA":
    case "MISMATCH":
    case "ERROR":
    case "TIN_NOT_ISSUED": {
      return renderBadge(label, "danger");
    }
    case "UNHANDLED": {
      return undefined;
    }
    default: {
      return assertUnreachable(key);
    }
  }
};

const renderTaxClassification = (
  taxClassification: HydratedAccount["type"]
) => {
  if (!taxClassification) return taxClassification;
  return TAX_CLASSIFICATION_LABELS[taxClassification];
};

const renderTaxDocumentationType = (
  taxDocumentationType: HydratedAccountTaxDocumentationType | undefined
) => {
  if (!taxDocumentationType) return taxDocumentationType;
  return TAX_DOCUMENTATION_TYPE_LABELS[taxDocumentationType];
};

export const useAccountsTableColumns = () => {
  const { view } = useAccountsView();
  const { formatDateTime } = useCosmicLocalizationContext();

  const columns = useMemo(() => {
    const allColumns: TableColumns<AccountsTableRow> = [
      {
        key: "externalId",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.externalId,
        shouldDisableSorting: true,
        shouldTruncate: true,
        // 261px = approximately 33 chars, the average length.
        maxWidth: 261,
        renderCell: renderExcludedSpan,
      },
      {
        key: "alternateExternalId",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.alternateExternalId,
        shouldDisableSorting: true,
        shouldTruncate: true,
        maxWidth: 261,
        renderCell: renderExcludedSpan,
      },
      {
        key: "name",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.name,
        shouldTruncate: true,
        minWidth: 128,
        renderCell: renderExcludedSpan,
      },
      {
        key: "usProfileName",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.usProfileName,
        shouldTruncate: true,
        minWidth: 128,
        renderCell: renderExcludedSpan,
      },
      {
        key: "globalProfileName",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.globalProfileName,
        shouldTruncate: true,
        minWidth: 128,
        renderCell: renderExcludedSpan,
      },
      {
        key: "dateCreated",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.dateCreated,
        renderCell: (date) =>
          formatDateTime({
            date,
          }),
      },
      {
        key: "email",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.email,
        shouldDisableSorting: true,
        shouldTruncate: true,
        minWidth: 180,
        renderCell: renderExcludedSpan,
      },
      {
        key: "taxClassification",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.taxClassification,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxClassification,
      },
      {
        key: "usProfileTaxClassification",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.usProfileTaxClassification,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxClassification,
      },
      {
        key: "globalProfileTaxClassification",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.globalProfileTaxClassification,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxClassification,
      },
      {
        key: "taxCountryCodes",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.taxCountryCodes,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderStringArray,
      },
      {
        key: "usProfileTaxCountryCodes",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.usProfileTaxCountryCodes,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderStringArray,
      },
      {
        key: "globalProfileTaxCountryCodes",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.globalProfileTaxCountryCodes,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderStringArray,
      },
      {
        key: "vatStatus",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.vatStatus,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderVatStatus,
      },
      {
        key: "globalProfileVatStatus",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.globalProfileVatStatus,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderVatStatus,
      },
      {
        key: "tinStatus",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.tinStatus,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTinStatus,
      },
      {
        key: "usProfileTinStatus",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.usProfileTinStatus,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTinStatus,
      },
      {
        key: "usProfileTaxDocumentationType",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.usProfileTaxDocumentationType,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxDocumentationType,
      },
      {
        key: "globalProfileTaxDocumentationType",
        label:
          ACCOUNTS_TABLE_ROW_LABELS.short.globalProfileTaxDocumentationType,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderTaxDocumentationType,
      },
      {
        key: "filer",
        label: ACCOUNTS_TABLE_ROW_LABELS.short.filer,
        isContentFullWidth: true,
        shouldDisableSorting: true,
        renderCell: renderExcludedSpan,
      },
    ];

    const filteredColumns = allColumns.filter((c) =>
      AccountsView.includes(c.key, view)
    );

    return filteredColumns;
  }, [formatDateTime, view]);

  return columns;
};
