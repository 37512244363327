import { Chip, ChipGroup, Button } from "@taxbit-private/cosmic";

import useFormsFilterChips from "./useFormsFilterChips";
import { useIrFormsContext } from "../../context/useIrForms";

export enum FormsFilterChipsTrackingId {
  ClearAllButton = "ir-forms-chips-clear-all-btn",
}

const FormsFilterChips = () => {
  const { clearAllFilters } = useIrFormsContext();
  const filterChipProps = useFormsFilterChips();

  return filterChipProps.length > 0 ? (
    <ChipGroup>
      {filterChipProps.map((props) => (
        <Chip key={props.trackingId} {...props} />
      ))}
      <Button
        onClick={() => {
          void clearAllFilters();
        }}
        label="Clear All"
        trackingId={FormsFilterChipsTrackingId.ClearAllButton}
        variant="anchor-primary-inline"
      />
    </ChipGroup>
  ) : undefined;
};

export default FormsFilterChips;
