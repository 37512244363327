import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import {
  getOrganizationPrefixedRoute,
  getOrganizationPrefixedSubroute,
} from "@taxbit-dashboard/router";
import { useCallback } from "react";

/**
 * Returns a function that will trigger an Auth0 login with a redirect back to the current
 * page (but on the new organization, if provided) after the user has authenticated.
 */
const useLoginWithReturnTo = () => {
  const { loginWithRedirect } = useAuth0();

  return useCallback(
    (opts: RedirectLoginOptions = {}) => {
      const orgId = opts.authorizationParams?.organization;

      return loginWithRedirect({
        ...opts,
        appState: {
          returnTo:
            orgId &&
            getOrganizationPrefixedRoute(
              orgId,
              getOrganizationPrefixedSubroute(window.location.pathname)
            ),
        },
      });
    },
    [loginWithRedirect]
  );
};

export default useLoginWithReturnTo;
