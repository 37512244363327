import {
  useDashboardFeatureFlags,
  useDashboardStore,
  useGetCurrentCompanyUser,
} from "@taxbit-dashboard/commons";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { updateDatadogUser } from "../vendors/datadog";
import { updateFullStoryUser } from "../vendors/fullStory";
import { updateLaunchDarklyUser } from "../vendors/launchDarkly";
import { updatePendoUser } from "../vendors/pendo/pendo";
import { updateZendeskUser } from "../vendors/zendesk";

/**
 * After a user is successfully logged in and the global store has been updated with all of the
 * appropriate user information, we can identify the user in all of our third-party vendors.
 *
 * This hook must only be called inside the body of a component fully protected by authentication logic.
 */
const useIdentifyUserInVendors = () => {
  const ldClient = useLDClient();
  const { data: currentCompanyUser } = useGetCurrentCompanyUser({
    useErrorBoundary: true,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const { hasZendeskWidgetAccess } = useDashboardFeatureFlags();

  const organizationId = useDashboardStore((store) => store.organizationId);
  const organizationName = useDashboardStore((store) => store.organizationName);

  useEffect(() => {
    void (async () => {
      if (
        currentCompanyUser &&
        ldClient &&
        organizationId &&
        organizationName
      ) {
        updateFullStoryUser(
          currentCompanyUser,
          organizationId,
          organizationName
        );
        updatePendoUser(currentCompanyUser, organizationId, organizationName);
        updateDatadogUser(currentCompanyUser, organizationId, organizationName);
        await updateLaunchDarklyUser(
          currentCompanyUser,
          organizationId,
          organizationName,
          ldClient
        );
        updateZendeskUser(currentCompanyUser, hasZendeskWidgetAccess);
        setIsInitialized(true);
      }
    })();
  }, [
    currentCompanyUser,
    hasZendeskWidgetAccess,
    ldClient,
    organizationId,
    organizationName,
  ]);

  return isInitialized;
};

export default useIdentifyUserInVendors;
