import {
  AuthorizedView,
  DashboardNavigationItemChild,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import InvitationManagement from "./user-invitation-table/InvitationManagement";

export const invitationManagementNavigationItem: DashboardNavigationItemChild =
  {
    routeId: RouteId.InvitationManagement,
    label: "Invitation Management",
    trackingId: "sidebar-navigation-invitation-management",
    key: "invitations",
    shouldShowRoute: ({ permissions }) =>
      permissions[UserPermission.ReadOrganizationInvitations],
  } as const;

const InvitationManagementPage = () => {
  return (
    <AuthorizedView permissions={[UserPermission.ReadOrganizationInvitations]}>
      <InvitationManagement />
    </AuthorizedView>
  );
};

export default InvitationManagementPage;
