import { renderStringOrPlaceholder } from "@taxbit-dashboard/commons";
import {
  FileAction,
  FileProcessingStatus,
  FilesApiFile,
} from "@taxbit-dashboard/rest";
import { Badge, TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import {
  fileProcessingStatusLabelMap,
  fileTypeLabelMap,
} from "../../../../api/files/filesApiTypes";
import {
  getFileMetadataFailed,
  getFileMetadataSucceeded,
  getFileMetadataTotal,
  getFileMetadataWarnings,
} from "../../../../api/files/filesApiUtils";
import useFileUploaderContext from "../../context/useFileUploaderContext";

const renderStatusBadge = (
  status: FileProcessingStatus,
  isStaleUpload: boolean
) => {
  if (isStaleUpload) {
    return <Badge variant="danger" label="Upload Failed" />;
  }

  const variant = (() => {
    switch (status) {
      case FileProcessingStatus.Completed: {
        return "success";
      }
      case FileProcessingStatus.PendingApproval: {
        return "warning";
      }
      case FileProcessingStatus.Rejected:
      case FileProcessingStatus.Invalid:
      case FileProcessingStatus.ContactSupport: {
        return "danger";
      }
      default: {
        return "secondary";
      }
    }
  })();

  return (
    <Badge variant={variant} label={fileProcessingStatusLabelMap[status]} />
  );
};

export type FilesTableRow = FilesApiFile & {
  total?: number;
  succeeded?: number;
  failed?: number;
  warnings?: number;
};

const useFilesTableData = (action: FileAction) => {
  const {
    isStaleUpload,
    filteredFiles = [],
    filteredQuery,
    totalFilteredFilesCount,
  } = useFileUploaderContext(action);

  const { formatDateTime, formatWholeQuantity } =
    useCosmicLocalizationContext();

  const rows: FilesTableRow[] = filteredFiles.map((file) => {
    return {
      ...file,
      total: getFileMetadataTotal(file),
      failed: getFileMetadataFailed(file),
      succeeded: getFileMetadataSucceeded(file),
      warnings: getFileMetadataWarnings(file),
    };
  });

  /** We want to lock all count-related columns to the same fixed width */
  const COUNT_ROW_WIDTH = 88;

  const warningsColumn: TableColumns<FilesTableRow> = useMemo(
    () => [
      {
        key: "warnings",
        label: "Warnings",
        renderCell: (warnings) => formatWholeQuantity(warnings),
        minWidth: COUNT_ROW_WIDTH,
        maxWidth: COUNT_ROW_WIDTH,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
        textAlign: "right",
      },
    ],
    [formatWholeQuantity]
  );

  const columns: TableColumns<FilesTableRow> = useMemo(
    () => [
      {
        key: "fileName",
        label: "File Name",
        shouldTruncate: true,
        shouldShowHoverTitle: true,
        minWidth: 350,
        maxWidth: 350,
      },
      {
        key: "fileType",
        label: "Type",
        renderCell: (type) => fileTypeLabelMap[type],
        isContentFullWidth: true,
      },
      {
        key: "status",
        label: "Status",
        renderCell: (status, file) =>
          renderStatusBadge(status, isStaleUpload(file)),
        isContentFullWidth: true,
      },
      {
        key: "total",
        label: "Rows",
        renderCell: (total) => formatWholeQuantity(total),
        minWidth: COUNT_ROW_WIDTH,
        maxWidth: COUNT_ROW_WIDTH,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
        textAlign: "right",
      },
      {
        key: "succeeded",
        label: "Valid",
        renderCell: (succeeded) => formatWholeQuantity(succeeded),
        minWidth: COUNT_ROW_WIDTH,
        maxWidth: COUNT_ROW_WIDTH,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
        textAlign: "right",
      },
      {
        key: "failed",
        label: "Invalid",
        renderCell: (failed) => formatWholeQuantity(failed),
        minWidth: COUNT_ROW_WIDTH,
        maxWidth: COUNT_ROW_WIDTH,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
        textAlign: "right",
      },
      ...(action === FileAction.Ingest ? warningsColumn : []),
      {
        key: "dateUploaded",
        label: "Date",
        renderCell: (date) => {
          return formatDateTime({
            date,
            format: "DateTime",
            tz: "Browser",
          });
        },
        isContentFullWidth: true,
      },
      {
        key: "description",
        label: "Description",
        renderCell: (description) => renderStringOrPlaceholder(description),
        minWidth: 200,
        maxWidth: 292,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
      },
      {
        key: "username",
        label: "Author",
        minWidth: 120,
        maxWidth: 160,
        shouldTruncate: true,
        shouldShowHoverTitle: true,
      },
    ],
    [action, warningsColumn, isStaleUpload, formatDateTime, formatWholeQuantity]
  );

  return {
    rows,
    columns,
    totalCount: totalFilteredFilesCount,
    ...filteredQuery,
  };
};

export default useFilesTableData;
