import { transactionTypeLabelMap } from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import newlineTextBasedFilterToArray from "../../../../utils/newlineTextBasedFilterToArray";
import { useTransactionsTableContext } from "../useTransactionsTable";

export enum TransactionsFilterChipsTrackingId {
  FilterChip = "transactions-filter-chip",
  ClearAllButton = "transactions-filter-chips-clear-all-button",
}

const getTrackingId = (key: string) =>
  `${key}-${TransactionsFilterChipsTrackingId.FilterChip}`;

const getAriaLabel = (key: string, value: string) =>
  `Remove ${key} filter for ${value}`;

const getTextFilterAriaLabel = (key: string) => `Remove ${key} filter`;

export const useTransactionsFilterChips = () => {
  const { urlParams, stateFilters, updateStateFilters, updateFilterParams } =
    useTransactionsTableContext();
  const { formatDateTime } = useCosmicLocalizationContext();

  const startDateChipProps = useMemo(
    () =>
      urlParams.startDate
        ? {
            label: `Start Date: ${formatDateTime({
              date: urlParams.startDate,
            })}`,
            trackingId: getTrackingId("start-date"),
            removeButtonProps: {
              "onClick": () => {
                void updateFilterParams({ startDate: undefined });
              },
              "aria-label": getAriaLabel("start date", urlParams.startDate),
              "trackingId": getTrackingId("start-date-remove-button"),
            },
          }
        : undefined,
    [urlParams, formatDateTime, updateFilterParams]
  );

  const endDateChipProps = useMemo(
    () =>
      urlParams.endDate
        ? {
            label: `End Date: ${formatDateTime({
              date: urlParams.endDate,
            })}`,
            trackingId: getTrackingId("end-date"),
            removeButtonProps: {
              "onClick": () => updateFilterParams({ endDate: undefined }),
              "aria-label": getAriaLabel("end date", urlParams.endDate),
              "trackingId": getTrackingId("end-date-remove-button"),
            },
          }
        : undefined,
    [urlParams, formatDateTime, updateFilterParams]
  );

  const typeFilterChipProps = useMemo(
    () =>
      urlParams.type?.map((value) => ({
        label: `Type: ${transactionTypeLabelMap[value]}`,
        trackingId: getTrackingId(`type-${value}`),
        removeButtonProps: {
          "trackingId": getTrackingId(`type-${value}-remove-button`),
          "onClick": () =>
            updateFilterParams({
              type: urlParams.type?.filter((v) => v !== value),
            }),
          "aria-label": getAriaLabel("type", value),
        },
      })),
    [urlParams, updateFilterParams]
  );

  const assetCodeFilterChipProps = useMemo(() => {
    const assetCodes = newlineTextBasedFilterToArray(stateFilters.assetCodes);

    if (assetCodes.length === 0) return undefined;

    return {
      label: `Asset Codes: ${assetCodes.length}`,
      trackingId: getTrackingId("asset-code"),
      removeButtonProps: {
        "onClick": () =>
          updateStateFilters({
            assetCodes: "",
          }),
        "aria-label": getTextFilterAriaLabel("asset code"),
        "trackingId": getTrackingId(`asset-code-remove-button`),
      },
    };
  }, [stateFilters, updateStateFilters]);

  const transactionIdsFilterChipProps = useMemo(() => {
    const transactionIds = newlineTextBasedFilterToArray(
      stateFilters.transactionIds
    );

    if (transactionIds.length === 0) return undefined;

    return {
      label: `Transaction IDs: ${transactionIds.length}`,
      trackingId: getTrackingId("transaction-id"),
      removeButtonProps: {
        "onClick": () =>
          updateStateFilters({
            transactionIds: "",
          }),
        "aria-label": getTextFilterAriaLabel("transaction ID"),
        "trackingId": getTrackingId(`transaction-id-remove-button`),
      },
    };
  }, [stateFilters, updateStateFilters]);

  const accountIdsFilterChipProps = useMemo(() => {
    const accountIds = newlineTextBasedFilterToArray(stateFilters.accountIds);
    if (accountIds.length === 0) return undefined;

    return {
      label: `Account IDs: ${accountIds.length}`,
      trackingId: getTrackingId("account-id"),
      removeButtonProps: {
        "onClick": () =>
          updateStateFilters({
            accountIds: "",
          }),
        "aria-label": getTextFilterAriaLabel("account ID"),
        "trackingId": getTrackingId(`account-id-remove-button`),
      },
    };
  }, [stateFilters, updateStateFilters]);

  return [
    startDateChipProps,
    endDateChipProps,
    typeFilterChipProps,
    assetCodeFilterChipProps,
    transactionIdsFilterChipProps,
    accountIdsFilterChipProps,
  ].flat();
};
