import { ingestFileTypes } from "@taxbit-dashboard/rest";
import { z } from "zod";

import pageLimitPaginationParamsSchema from "../../shared/pageLimitPaginationParamsSchema";

const ingestDataUrlParamsSchema = pageLimitPaginationParamsSchema.merge(
  z.object({
    fileType: z.array(z.enum(ingestFileTypes)).optional().catch(undefined),
  })
);

export type IngestDataUrlParams = z.infer<typeof ingestDataUrlParamsSchema>;

export default ingestDataUrlParamsSchema;
