import {
  Outlet,
  PAGE_WRAPPER_SCROLL_AREA_ID,
  pageWrapperScrollAreaRef,
} from "@taxbit-dashboard/router";
import { Flex, getTopNavigationHeight } from "@taxbit-private/cosmic";
import styled from "styled-components";

import { AppWrapper } from "./AppWrapper";
import LeftNavigation, { NavigationSidebarSection } from "./LeftNavigation";
import AppNotifications from "../notifications/AppNotifications";

type Props = {
  sections: readonly NavigationSidebarSection[];
};

const AppOutlet: React.FC<Props> = ({ sections }) => {
  return (
    <AppWrapper>
      <LeftNavigation sections={sections} />
      <Flex direction="column">
        <AppNotifications />
        <PageWrapper
          id={PAGE_WRAPPER_SCROLL_AREA_ID}
          ref={pageWrapperScrollAreaRef}
        >
          <Outlet />
        </PageWrapper>
      </Flex>
    </AppWrapper>
  );
};

export default AppOutlet;

const PageWrapper = styled.div(
  ({ theme }) => `
  height: calc(100vh - ${getTopNavigationHeight(theme)});
  overflow: auto;
`
);
