import { AuthorizedView } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  DenseRowGroup,
} from "@taxbit-private/cosmic";

import { useGetReportingProfile } from "./useGetReportingProfile";
import { useReportingProfileRows } from "./useReportingProfileRows";

export const US_REPORTING_PROFILE_CARD_TOOLTIP_COPY =
  "This data is consolidated for year-end tax reporting to the IRS under U.S. information reporting requirements.";

export const GLOBAL_REPORTING_PROFILE_CARD_TOOLTIP_COPY =
  "This data is consolidated for year-end tax reporting under international regimes like MRDP and CESOP.";

export enum ReportingProfileTrackingId {
  ReportingProfileSpinner = "reporting-profile-spinner",
  ReportingProfileUsCardTooltip = "reporting-profile-us-card-tooltip",
  ReportingProfileGlobalCardTooltip = "reporting-profile-global-card-tooltip",
}

const ReportingProfileTab: React.FC = () => {
  const {
    isLoading,
    usReportingProfile,
    globalReportingProfile,
    hasGlobalAccountsPageView,
    hasUsAccountsPageView,
    shouldUnmaskValue,
    isReportingProfilesNotFound,
    reportingProfilesLoaded,
  } = useGetReportingProfile();

  const { usProfile, globalProfile } = useReportingProfileRows({
    shouldUnmaskValue,
    usReportingProfile,
    globalReportingProfile,
  });

  const usProfileNotFound =
    isReportingProfilesNotFound || (reportingProfilesLoaded && !usProfile);

  const globalProfileNotFound =
    isReportingProfilesNotFound || (reportingProfilesLoaded && !globalProfile);

  return (
    <AuthorizedView
      flags={["hasReportingProfile", "showAccountDetailsReportingProfile"]}
    >
      {hasUsAccountsPageView && (
        <Card
          title="US Reporting"
          helpIconProps={{
            tooltipProps: {
              content: US_REPORTING_PROFILE_CARD_TOOLTIP_COPY,
            },
            trackingId:
              ReportingProfileTrackingId.ReportingProfileUsCardTooltip,
          }}
        >
          {isLoading ? (
            <ContentSpinner
              trackingId={ReportingProfileTrackingId.ReportingProfileSpinner}
            />
          ) : usProfileNotFound ? (
            <ContentEntityEmptyState entity="US reporting profile" />
          ) : usProfile ? (
            <DenseRowGroup sections={usProfile} />
          ) : (
            <ContentErrorEmptyState entity="US reporting profile" />
          )}
        </Card>
      )}
      {hasGlobalAccountsPageView && (
        <Card
          title="Global Reporting"
          helpIconProps={{
            tooltipProps: {
              content: GLOBAL_REPORTING_PROFILE_CARD_TOOLTIP_COPY,
            },
            trackingId:
              ReportingProfileTrackingId.ReportingProfileGlobalCardTooltip,
          }}
        >
          {isLoading ? (
            <ContentSpinner
              trackingId={ReportingProfileTrackingId.ReportingProfileSpinner}
            />
          ) : globalProfileNotFound ? (
            <ContentEntityEmptyState entity="Global reporting profile" />
          ) : globalProfile ? (
            <DenseRowGroup sections={globalProfile} />
          ) : (
            <ContentErrorEmptyState entity="Global reporting profile" />
          )}
        </Card>
      )}
    </AuthorizedView>
  );
};

export default ReportingProfileTab;
