import { Button, Drawer, NumericalBadge, Flex } from "@taxbit-private/cosmic";
import { RhfUtcDateRangePicker } from "@taxbit-private/cosmic-react-hook-form";
import { FormProvider } from "react-hook-form";

import TransactionAssetCodeTextFilter from "./TransactionAssetCodeTextFilter";
import TransactionIdsTextFilter from "./TransactionIdsTextFilter";
import TransactionTypeMultiselectFilter from "./TransactionTypeMultiselectFilter";
import { useTransactionsFilterDrawerForm } from "./useTransactionsFilterDrawerForm";

export enum TransactionsFilterDrawerTrackingId {
  ToggleButton = "transactions-filter-drawer-toggle-button",
  CountBadge = "transactions-filter-drawer-count-badge",
  CloseButton = "transactions-filter-drawer-close-button",
  ApplyButton = "transactions-filter-drawer-apply-button",
  CancelButton = "transactions-filter-drawer-cancel-button",
  ClearAllFiltersButton = "transactions-filter-drawer-clear-all-filters-button",
}

const FILTER_DRAWER_FORM_ID = "transactions-filters-drawer-form";

const TransactionsFilterDrawer: React.FC = () => {
  const {
    clearAllFormFilters,
    formMethods,
    handleSubmit,
    hasFiltersApplied,
    isFormDirty,
    filterCount,
    isOpen,
    setIsOpen,
    closeAndReset,
  } = useTransactionsFilterDrawerForm();

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        size="small"
        trackingId={TransactionsFilterDrawerTrackingId.ToggleButton}
        onClick={() => setIsOpen(true)}
        utilityElement={
          filterCount > 0 ? (
            <NumericalBadge
              trackingId={TransactionsFilterDrawerTrackingId.CountBadge}
              value={filterCount}
            />
          ) : undefined
        }
      />
      <Drawer
        onClose={closeAndReset}
        isOpen={isOpen}
        title="Filters"
        closeButtonTrackingId={TransactionsFilterDrawerTrackingId.CloseButton}
        primaryButtonProps={{
          label: "Apply",
          trackingId: TransactionsFilterDrawerTrackingId.ApplyButton,
          isDisabled: !isFormDirty,
          type: "submit",
          form: FILTER_DRAWER_FORM_ID,
        }}
        secondaryButtonProps={{
          label: "Cancel",
          onClick: closeAndReset,
          trackingId: TransactionsFilterDrawerTrackingId.CancelButton,
        }}
      >
        <FormProvider {...formMethods}>
          <Flex
            as="form"
            id={FILTER_DRAWER_FORM_ID}
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e);
            }}
            direction="column"
            gap="m"
          >
            <RhfUtcDateRangePicker
              name="datetime"
              label="Date Range"
              trackingId="transactions-date-range-filter"
              placeholder="Choose Date Range"
            />
            <TransactionTypeMultiselectFilter />
            <TransactionIdsTextFilter
              name="transactionIds"
              label="Filter by Transaction ID"
            />
            <TransactionIdsTextFilter
              name="accountIds"
              label="Filter by Account ID"
            />
            <TransactionAssetCodeTextFilter />
            {hasFiltersApplied && (
              <Button
                onClick={clearAllFormFilters}
                label="Clear all filters"
                variant="anchor-primary-inline"
                trackingId={
                  TransactionsFilterDrawerTrackingId.ClearAllFiltersButton
                }
              />
            )}
          </Flex>
        </FormProvider>
      </Drawer>
    </>
  );
};

export default TransactionsFilterDrawer;
