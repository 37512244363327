import { accountIdSchema } from "@taxbit-dashboard/rest";
import { RouteId, useMatch } from "@taxbit-dashboard/router";
import { transactionIdSchema } from "@taxbit-private/data-models-v2";
import { z } from "zod";

const paramsSchema = z.object({
  accountId: accountIdSchema,
  transactionId: transactionIdSchema,
});

const useTransactionDetailsParams = () => {
  const accountDetailsMatch = useMatch({
    from: RouteId.AccountDetailsTransactionDetails,
    shouldThrow: false,
  });

  const transactionsMatch = useMatch({
    from: RouteId.TransactionDetails,
    shouldThrow: false,
  });

  return paramsSchema.parse(
    accountDetailsMatch?.params ?? transactionsMatch?.params
  );
};

export default useTransactionDetailsParams;
