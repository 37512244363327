import {
  accountValidationsEligibilityStatusSchema,
  dashboardEligibilityFormStatusSchema,
  eligibilityStatusSchema,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";
import { z } from "zod";

import { useCreateTextAreaFilterSchema } from "../../../../utils/useCreateTextAreaFilterSchema";
import { accountExternalIdsFilterSchema } from "../../shared/accountExternalIdsFilterSchema";

const eligibilityFiltersFormFieldsSchema = z.object({
  statuses: z.array(eligibilityStatusSchema),
  accountValidationsEligibilityStatuses: z.array(
    accountValidationsEligibilityStatusSchema
  ),
  formStatuses: z.array(dashboardEligibilityFormStatusSchema),
  accountExternalIdsFilter: accountExternalIdsFilterSchema,
});

export type EligibilityFiltersFormFields = z.infer<
  typeof eligibilityFiltersFormFieldsSchema
>;

const useEligibilityFiltersFormFieldSchema = () => {
  const textAreaFilterSchema = useCreateTextAreaFilterSchema("Account IDs");
  return useMemo(
    () =>
      eligibilityFiltersFormFieldsSchema.extend({
        accountExternalIdsFilter: textAreaFilterSchema,
      }),
    [textAreaFilterSchema]
  );
};

export default useEligibilityFiltersFormFieldSchema;
