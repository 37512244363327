import { filterToDefinedValues } from "@taxbit-dashboard/commons";
import { ExportHydratedAccountsParams } from "@taxbit-dashboard/rest";
import { AccountsViewType } from "@taxbit-dashboard/router";

import {
  ACCOUNTS_TABLE_ROW_LABELS,
  AccountsTableRow,
} from "./table/accountsTableRow";
import { assertUnreachable } from "../../../utils/assertUnreachable";

export type AccountsView = {
  type: AccountsViewType;
  columns: (keyof AccountsTableRow)[];
};

const includes = (column: keyof AccountsTableRow, view: AccountsView) =>
  view.columns.includes(column);

const includesAny = (
  column:
    | "name"
    | "tinStatus"
    | "vatStatus"
    | "taxClassification"
    | "taxCountryCodes"
    | "taxDocumentationType",
  view: AccountsView
) => {
  switch (column) {
    case "name": {
      return (
        includes("name", view) ||
        includes("usProfileName", view) ||
        includes("globalProfileName", view)
      );
    }
    case "tinStatus": {
      return (
        includes("tinStatus", view) || includes("usProfileTinStatus", view)
      );
    }
    case "vatStatus": {
      return (
        includes("vatStatus", view) || includes("globalProfileVatStatus", view)
      );
    }
    case "taxClassification": {
      return (
        includes("taxClassification", view) ||
        includes("usProfileTaxClassification", view) ||
        includes("globalProfileTaxClassification", view)
      );
    }
    case "taxCountryCodes": {
      return (
        includes("taxCountryCodes", view) ||
        includes("usProfileTaxCountryCodes", view) ||
        includes("globalProfileTaxCountryCodes", view)
      );
    }
    case "taxDocumentationType": {
      return (
        includes("usProfileTaxDocumentationType", view) ||
        includes("globalProfileTaxDocumentationType", view)
      );
    }
    default: {
      return assertUnreachable(column);
    }
  }
};

const columnToMapping = (
  column: AccountsView["columns"][0]
): ExportHydratedAccountsParams["mappings"][0] | undefined => {
  switch (column) {
    case "externalId": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.externalId,
        path: "external_id",
      };
    }
    case "alternateExternalId": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.alternateExternalId,
        path: "alternate_external_id",
      };
    }
    case "name": {
      return { header: ACCOUNTS_TABLE_ROW_LABELS.full.name, path: "name" };
    }
    case "dateCreated": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.dateCreated,
        path: "date_created",
      };
    }
    case "email": {
      return { header: ACCOUNTS_TABLE_ROW_LABELS.full.email, path: "email" };
    }
    case "taxClassification": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.taxClassification,
        path: "type",
      };
    }
    case "usProfileName": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.usProfileName,
        path: "profile.us.name",
      };
    }
    case "usProfileTaxClassification": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.usProfileTaxClassification,
        path: "profile.us.tax_classification",
      };
    }
    case "globalProfileName": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.globalProfileName,
        path: "profile.global.name",
      };
    }
    case "globalProfileTaxClassification": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.globalProfileTaxClassification,
        path: "profile.global.tax_classification",
      };
    }
    case "taxCountryCodes": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.taxCountryCodes,
        path: "tax_country_codes",
      };
    }
    case "usProfileTaxCountryCodes": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.usProfileTaxCountryCodes,
        path: "profile.us.tax_country_codes",
      };
    }
    case "globalProfileTaxCountryCodes": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.globalProfileTaxCountryCodes,
        path: "profile.global.tax_country_codes",
      };
    }
    case "vatStatus": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.vatStatus,
        path: "vat_status",
      };
    }
    case "globalProfileVatStatus": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.globalProfileVatStatus,
        path: "profile.global.vat_status",
      };
    }
    case "tinStatus": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.tinStatus,
        path: "tin_status",
      };
    }
    case "usProfileTinStatus": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.usProfileTinStatus,
        path: "profile.us.tin_status",
      };
    }
    case "usProfileTaxDocumentationType": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.usProfileTaxDocumentationType,
        path: "profile.us.tax_documentation_type",
      };
    }
    case "globalProfileTaxDocumentationType": {
      return {
        header:
          ACCOUNTS_TABLE_ROW_LABELS.full.globalProfileTaxDocumentationType,
        path: "profile.global.tax_documentation_type",
      };
    }
    case "filer": {
      return {
        header: ACCOUNTS_TABLE_ROW_LABELS.full.filer,
        path: "filer_id",
      };
    }
    default: {
      return undefined;
    }
  }
};

const toExportMappings = (
  view: AccountsView
): ExportHydratedAccountsParams["mappings"] => {
  return filterToDefinedValues(view.columns.map((c) => columnToMapping(c)));
};

export const AccountsView = {
  includes,
  includesAny,
  toExportMappings,
};

export const VIEWS: Record<AccountsViewType, AccountsView> = {
  us: {
    type: AccountsViewType.Us,
    columns: [
      "externalId",
      "dateCreated",
      "email",
      "usProfileName",
      "usProfileTaxClassification",
      "usProfileTaxCountryCodes",
      "usProfileTinStatus",
      "usProfileTaxDocumentationType",
      "filer",
    ],
  },
  global: {
    type: AccountsViewType.Global,
    columns: [
      "externalId",
      "dateCreated",
      "email",
      "globalProfileName",
      "globalProfileTaxClassification",
      "globalProfileTaxCountryCodes",
      "globalProfileVatStatus",
      "globalProfileTaxDocumentationType",
      "filer",
    ],
  },
};

export const VIEWS_WITHOUT_RP: Record<AccountsViewType, AccountsView> = {
  us: {
    type: AccountsViewType.Us,
    columns: [
      "externalId",
      "name",
      "dateCreated",
      "email",
      "taxClassification",
      "taxCountryCodes",
      "tinStatus",
      "filer",
    ],
  },
  global: {
    type: AccountsViewType.Global,
    columns: [
      "externalId",
      "name",
      "dateCreated",
      "email",
      "taxClassification",
      "taxCountryCodes",
      "vatStatus",
      "filer",
    ],
  },
};

export const ALTERNATE_EXTERNAL_ID_VIEWS: Record<
  AccountsViewType,
  AccountsView
> = {
  us: {
    type: AccountsViewType.Us,
    columns: [
      "externalId",
      "alternateExternalId",
      "dateCreated",
      "email",
      "usProfileName",
      "usProfileTaxClassification",
      "usProfileTaxCountryCodes",
      "usProfileTinStatus",
      "usProfileTaxDocumentationType",
      "filer",
    ],
  },
  global: {
    type: AccountsViewType.Global,
    columns: [
      "externalId",
      "dateCreated",
      "email",
      "globalProfileName",
      "globalProfileTaxClassification",
      "globalProfileTaxCountryCodes",
      "globalProfileVatStatus",
      "globalProfileTaxDocumentationType",
      "filer",
    ],
  },
};

export const ALTERNATE_EXTERNAL_ID_VIEWS_WITHOUT_RP: Record<
  AccountsViewType,
  AccountsView
> = {
  us: {
    type: AccountsViewType.Us,
    columns: [
      "externalId",
      "alternateExternalId",
      "name",
      "dateCreated",
      "email",
      "taxClassification",
      "taxCountryCodes",
      "tinStatus",
      "filer",
    ],
  },
  global: {
    type: AccountsViewType.Global,
    columns: [
      "externalId",
      "alternateExternalId",
      "name",
      "dateCreated",
      "email",
      "taxClassification",
      "taxCountryCodes",
      "vatStatus",
      "filer",
    ],
  },
};

export const VIEW_LABELS: Record<AccountsViewType, string> = {
  us: "Profile: US",
  global: "Profile: Global",
} as const;
