import { AccountsUrlParams } from "@taxbit-dashboard/router";

type ArrayParam<T extends keyof AccountsUrlParams> =
  Required<AccountsUrlParams>[T] extends unknown[]
    ? Required<AccountsUrlParams>[T][number]
    : never;

export const VAT_STATUS_LABELS: Record<ArrayParam<"vatStatuses">, string> = {
  VALID: "Valid",
  PENDING: "Pending",
  INSUFFICIENT_DATA: "Insufficient Data",
  ERROR: "Error",
  INVALID: "Invalid",
  NOT_REQUIRED: "Not Required",
  UNHANDLED: "Unhandled",
};

export const TIN_STATUS_LABELS: Record<ArrayParam<"tinStatuses">, string> = {
  PENDING: "Pending",
  VALID_MATCH: "Valid Match",
  FOREIGN: "Foreign",
  INVALID_DATA: "Invalid Data",
  MISMATCH: "Mismatch",
  TIN_NOT_ISSUED: "TIN Not Issued",
  ERROR: "Error",
  UNHANDLED: "Unhandled",
};

export const TAX_CLASSIFICATION_LABELS: Record<
  ArrayParam<"taxClassifications">,
  string
> = {
  US_IRA_ROTH: "US IRA Roth",
  US_IRA_TRADITIONAL: "US IRA Traditional",
  US_IRA_SEP: "US IRA SEP",
  US_IRA_SIMPLE: "US IRA Simple",
  INDIVIDUAL: "Individual",
  ENTITY: "Entity",
  LLC_S: "LLC S",
  S_CORPORATION: "S Corporation",
  LLC_P: "LLC P",
  CORPORATION: "Corporation",
  LLC_C: "LLC C",
  C_CORPORATION: "C Corporation",
  OTHER: "Other",
  TRUST_ESTATE: "Trust Estate",
  PARTNERSHIP: "Partnership",
  INTERNATIONAL_ORGANIZATION: "International Organization",
  SOLE_PROPRIETOR: "Sole Proprietor",
  SIMPLE_TRUST: "Simple Trust",
  PRIVATE_FOUNDATION: "Private Foundation",
  COMPLEX_TRUST: "Complex Trust",
  ESTATE: "Estate",
  TAX_EXEMPT_ORGANIZATION: "Tax Exempt Organization",
  FOREIGN_GOVERNMENT_CONTROLLED_ENTITY: "Foreign Government Controlled Entity",
  FOREIGN_GOVERNMENT_INTEGRAL_PART: "Foreign Government Integral Part",
  CENTRAL_BANK_OF_ISSUE: "Central Bank of Issue",
  GRANTOR_TRUST: "Grantor Trust",
  UNHANDLED: "Unhandled",
};

export const TAX_DOCUMENTATION_TYPE_LABELS: Record<
  ArrayParam<"taxDocumentationTypes">,
  string
> = {
  "W-9": "W-9",
  "W-8BEN": "W-8BEN",
  "W-8BEN-E": "W-8BEN-E",
  "W-8IMY": "W-8IMY",
  "DPS": "DPS",
  "SELF_CERTIFICATION": "Self-Certification",
  "UNHANDLED": "Unhandled",
};
