import {
  GetFormsSort,
  getFormsSortSchema,
  irFormStatusSchema,
  irFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { cosmicDateRangePickerValueSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import formTypeAndDateParamsSchema, {
  ParamsWithDefinedFormTypeAndDate,
} from "../../shared/formTypeAndDateParamsSchema";
import pageLimitPaginationParamsSchema from "../../shared/pageLimitPaginationParamsSchema";
import searchStringValueSchema from "../../shared/searchStringValueSchema";

export const DEFAULT_FORMS_SORT: GetFormsSort = "-date_generated";

const irFormsUrlParamsSchema = z
  .object({
    sort: getFormsSortSchema.catch(DEFAULT_FORMS_SORT),
    accountExternalId: searchStringValueSchema,
    name: searchStringValueSchema,
    accountAlternateExternalId: searchStringValueSchema,
    useReportingProfile: z.boolean().optional().catch(undefined),
    statuses: z.array(irFormStatusSchema).optional().catch(undefined),
    types: z.array(irFormTypeSchema).optional().catch(undefined),
  })
  .merge(cosmicDateRangePickerValueSchema)
  .merge(pageLimitPaginationParamsSchema)
  .merge(formTypeAndDateParamsSchema);

/**
 * The URL params for the IR Forms page are run through the `useUrlParamsWithFormTypeAndDate` hook
 * to ensure that the formType and formYear are always present. This is just a convenience type that includes
 * this defaulting.
 */
export type IrFormsUrlParams = ParamsWithDefinedFormTypeAndDate<
  z.infer<typeof irFormsUrlParamsSchema>
>;

export default irFormsUrlParamsSchema;
