import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  Button,
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  DenseRowGroup,
} from "@taxbit-private/cosmic";
import { useState } from "react";

import useGetSelfCertQuestionnaire from "./useGetSelfCertQuestionnaire";
import useSelfCertQuestionnaireRows from "./useSelfCertQuestionnaireRows";
import { documentTypeMap } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import TaxDocumentationDetailsModal from "../TaxDocumentationDetailsModal";

export const CARD_TITLE = documentTypeMap.SELF_CERTIFICATION;

export enum SelfCertQuestionnaireTrackingId {
  SelfCertQuestionnaireSpinner = "self-cert-questionnaire-spinner",
  ViewDetailsBtn = "self-cert-questionnaire-view-details-btn",
}

const SelfCertQuestionnaire: React.FC = () => {
  const { showAccountDetailsReportingProfile } = useDashboardFeatureFlags();

  const { isLoading, selfCertQuestionnaire, selfCert, shouldUnmaskValue } =
    useGetSelfCertQuestionnaire();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const selfCertQuestionnaireRows = useSelfCertQuestionnaireRows({
    selfCertQuestionnaire,
  });

  const cardContent = (() => {
    if (isLoading) {
      return (
        <ContentSpinner
          trackingId={
            SelfCertQuestionnaireTrackingId.SelfCertQuestionnaireSpinner
          }
        />
      );
    }

    if (selfCertQuestionnaireRows) {
      return <DenseRowGroup sections={selfCertQuestionnaireRows} />;
    }

    return <ContentErrorEmptyState entity={CARD_TITLE} />;
  })();

  return (
    <>
      <Card
        title={CARD_TITLE}
        utilityElement={
          showAccountDetailsReportingProfile && (
            <Button
              variant="button-secondary"
              label="View details"
              trackingId={SelfCertQuestionnaireTrackingId.ViewDetailsBtn}
              isDisabled={!selfCert}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          )
        }
      >
        {cardContent}
      </Card>
      {selfCert && (
        <TaxDocumentationDetailsModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={selfCert}
          shouldUnmaskValue={shouldUnmaskValue}
        />
      )}
    </>
  );
};

export default SelfCertQuestionnaire;
