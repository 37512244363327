import { useMemo } from "react";

import { formStatusToLabelMap } from "../../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormStatusChipTrackingId {
  Chip = "ir-forms-status-chip",
}

const useFormsStatusChips = () => {
  const { urlParams, updateFilterParams } = useIrFormsContext();

  return useMemo(() => {
    return (urlParams.statuses ?? []).map((status) => {
      const statusLabel = formStatusToLabelMap[status];

      return {
        label: `Status: ${statusLabel}`,
        removeButtonProps: {
          "onClick": () => {
            void updateFilterParams({
              statuses: urlParams.statuses?.filter((s) => s !== status),
            });
          },
          "aria-label": `Remove filter for ${statusLabel}`,
        },
        trackingId: `${IrFormStatusChipTrackingId.Chip}-${status}`,
      };
    });
  }, [urlParams.statuses, updateFilterParams]);
};

export default useFormsStatusChips;
