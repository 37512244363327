import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear, fourDigitYearSchema } from "@taxbit-private/datetime";
import { useState, useMemo, useCallback } from "react";

import sortDashboardForms from "../sortDashboardForms";
import { FormFeatureFlagData } from "./useFormFeatureFlagData";
import { calculateFormTypeToYearsMap } from "../../../../utils/calculateFormTypeToYearsMap";

type SharedFormDropdownDataOptions = {
  /**
   * If `true`, filters dropdown data to only forms (and their respective years) that
   * are supported by ingesting data through file upload or API.
   */
  shouldUseFormItemsForms?: boolean;
};

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `useFormFeatureFlagData` instead.
 */
const useLegacyFormFeatureFlagData = ({
  shouldUseFormItemsForms = false,
}: SharedFormDropdownDataOptions = {}): FormFeatureFlagData => {
  const { irFormTypesByYear } = useDashboardFeatureFlags();

  const [yearsByFormType] = useState(
    calculateFormTypeToYearsMap({
      irFormTypesByYear,
      shouldUseFormItemsForms,
    })
  );

  const hasFeatureFlagData = useMemo(
    () => Object.values(irFormTypesByYear).length > 0,
    [irFormTypesByYear]
  );

  const years = useMemo(
    () =>
      [...new Set(Object.keys(irFormTypesByYear))]
        .map((f) => fourDigitYearSchema.parse(f))
        .sort((a, b) => b - a),
    [irFormTypesByYear]
  );

  const forms = useMemo(() => {
    return sortDashboardForms(
      Object.keys(yearsByFormType) as DashboardFormType[]
    );
  }, [yearsByFormType]);

  const hasForms = useMemo(() => forms.length > 0, [forms]);

  const hasYearForForm = useCallback(
    (form?: DashboardFormType, year?: FourDigitYear) => {
      if (form && yearsByFormType[form] && year) {
        return yearsByFormType[form]?.includes(year);
      } else {
        return false;
      }
    },
    [yearsByFormType]
  );

  const getYearsForForm = useCallback(
    (form?: DashboardFormType) => {
      const yearsForForm = form ? (yearsByFormType[form] ?? []) : [];
      return yearsForForm.sort((a, b) => b - a);
    },
    [yearsByFormType]
  );

  return {
    forms,
    years,
    hasForms,
    getYearsForForm,
    hasYearForForm,
    yearsByFormType,
    hasFeatureFlagData,
  };
};

export default useLegacyFormFeatureFlagData;
