import {
  AccountsTemplateType,
  DashboardFormType,
  FileType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";

import formData1042STemplateContents from "../../assets/csv-templates/TaxBit_1042-S_Ingest_Template.csv?raw";
import formData1042STemplate from "../../assets/csv-templates/TaxBit_1042-S_Ingest_Template.csv?url";
import formData1099BTemplateContents from "../../assets/csv-templates/TaxBit_1099-B_Ingest_Template.csv?raw";
import formData1099BTemplate from "../../assets/csv-templates/TaxBit_1099-B_Ingest_Template.csv?url";
import formData1099DivTemplateContents from "../../assets/csv-templates/TaxBit_1099-DIV_Ingest_Template.csv?raw";
import formData1099DivTemplate from "../../assets/csv-templates/TaxBit_1099-DIV_Ingest_Template.csv?url";
import formData1099IntTemplateContents from "../../assets/csv-templates/TaxBit_1099-INT_Ingest_Template.csv?raw";
import formData1099IntTemplate from "../../assets/csv-templates/TaxBit_1099-INT_Ingest_Template.csv?url";
import formData1099KTemplateContents from "../../assets/csv-templates/TaxBit_1099-K_Ingest_Template.csv?raw";
import formData1099KTemplate from "../../assets/csv-templates/TaxBit_1099-K_Ingest_Template.csv?url";
import formData1099MiscTemplateContents from "../../assets/csv-templates/TaxBit_1099-MISC_Ingest_Template.csv?raw";
import formData1099MiscTemplate from "../../assets/csv-templates/TaxBit_1099-MISC_Ingest_Template.csv?url";
import formData1099NecTemplateContents from "../../assets/csv-templates/TaxBit_1099-NEC_Ingest_Template.csv?raw";
import formData1099NecTemplate from "../../assets/csv-templates/TaxBit_1099-NEC_Ingest_Template.csv?url";
import formData1099RTemplateContents from "../../assets/csv-templates/TaxBit_1099-R_Ingest_Template.csv?raw";
import formData1099RTemplate from "../../assets/csv-templates/TaxBit_1099-R_Ingest_Template.csv?url";
import formData5498TemplateContents from "../../assets/csv-templates/TaxBit_5498_Ingest_Template.csv?raw";
import formData5498Template from "../../assets/csv-templates/TaxBit_5498_Ingest_Template.csv?url";
import accountDeleteTemplateContents from "../../assets/csv-templates/TaxBit_Account_Delete_Template.csv?raw";
import accountDeleteTemplate from "../../assets/csv-templates/TaxBit_Account_Delete_Template.csv?url";
import accountOwnerDeleteTemplateContents from "../../assets/csv-templates/TaxBit_Account_Owner_Delete_Template.csv?raw";
import accountOwnerDeleteTemplate from "../../assets/csv-templates/TaxBit_Account_Owner_Delete_Template.csv?url";
import accountOwnerTemplateContents from "../../assets/csv-templates/TaxBit_Account_Owner_Ingest_Template.csv?raw";
import accountOwnerTemplate from "../../assets/csv-templates/TaxBit_Account_Owner_Ingest_Template.csv?url";
import accountOwnerWithAltIdTemplateContents from "../../assets/csv-templates/TaxBit_Account_Owner_Ingest_With_Alt_Id_Template.csv?raw";
import accountOwnerWithAltIdTemplate from "../../assets/csv-templates/TaxBit_Account_Owner_Ingest_With_Alt_Id_Template.csv?url";
import cesopTemplateContents from "../../assets/csv-templates/TaxBit_CESOP_Ingest_Template.csv?raw";
import cesopTemplate from "../../assets/csv-templates/TaxBit_CESOP_Ingest_Template.csv?url";
import transactionsCesopTemplateContents from "../../assets/csv-templates/TaxBit_CESOP_Transaction_Ingest_Template.csv?raw";
import transactionsCesopTemplate from "../../assets/csv-templates/TaxBit_CESOP_Transaction_Ingest_Template.csv?url";
import formDataDeleteTemplateContents from "../../assets/csv-templates/TaxBit_Form_Data_Delete_Template.csv?raw";
import formDataDeleteTemplate from "../../assets/csv-templates/TaxBit_Form_Data_Delete_Template.csv?url";
import formDataGainLossSummaryTemplateContents from "../../assets/csv-templates/TaxBit_Gain_Loss_Summary_Ingest_Template.csv?raw";
import formDataGainLossSummaryTemplate from "../../assets/csv-templates/TaxBit_Gain_Loss_Summary_Ingest_Template.csv?url";
import globalAccountOwnerTemplateContents from "../../assets/csv-templates/TaxBit_Global_Account_Owner_Ingest_Template.csv?raw";
import globalAccountOwnerTemplate from "../../assets/csv-templates/TaxBit_Global_Account_Owner_Ingest_Template.csv?url";
import iraAccountOwnerTemplateContents from "../../assets/csv-templates/TaxBit_IRA_Account_Owner_Ingest_Template.csv?raw";
import iraAccountOwnerTemplate from "../../assets/csv-templates/TaxBit_IRA_Account_Owner_Ingest_Template.csv?url";
import formDataMrdpTemplateContents from "../../assets/csv-templates/TaxBit_MRDP_Ingest_Template.csv?raw";
import formDataMrdpTemplate from "../../assets/csv-templates/TaxBit_MRDP_Ingest_Template.csv?url";
import transactionsPaymentsTemplateContents from "../../assets/csv-templates/TaxBit_Payments_Transactions_Ingest_Template.csv?raw";
import transactionsPaymentsTemplate from "../../assets/csv-templates/TaxBit_Payments_Transactions_Ingest_Template.csv?url";
import formDataRmdStatementTemplateContents from "../../assets/csv-templates/TaxBit_RMD_Statement_Ingest_Template.csv?raw";
import formDataRmdStatementTemplate from "../../assets/csv-templates/TaxBit_RMD_Statement_Ingest_Template.csv?url";
import transactionDeleteTemplateContents from "../../assets/csv-templates/TaxBit_Transaction_Delete_Template.csv?raw";
import transactionDeleteTemplate from "../../assets/csv-templates/TaxBit_Transaction_Delete_Template.csv?url";
import transactionsTemplateContents from "../../assets/csv-templates/TaxBit_Transaction_Ingest_Template.csv?raw";
import transactionsTemplateWithCounterpartyIdContents from "../../assets/csv-templates/TaxBit_Transaction_Ingest_With_Counterparty_Id_Template.csv?raw";
import transactionsTemplateWithCounterpartyId from "../../assets/csv-templates/TaxBit_Transaction_Ingest_With_Counterparty_Id_Template.csv?url";
import transactionsAdjustmentsTemplateContents from "../../assets/csv-templates/TaxBit_US_Transactions_Adjustments_Ingest_Template.csv?raw";
import transactionsAdjustmentsTemplate from "../../assets/csv-templates/TaxBit_US_Transactions_Adjustments_Ingest_Template.csv?url";

export const getCsvTemplateDownloadUrl = (
  {
    fileType,
    templateType,
  }: {
    fileType?: FileType;
    templateType?:
      | AccountsTemplateType
      | DashboardFormType
      | TransactionsTemplateType;
  },
  showAltIdOption: boolean = false
): string | undefined => {
  const csvTemplateDownloadUrlMapByFileType = {
    [FileType.Accounts]: {
      [AccountsTemplateType.Us]: showAltIdOption
        ? accountOwnerWithAltIdTemplate
        : accountOwnerTemplate,
      [AccountsTemplateType.UsIra]: iraAccountOwnerTemplate,
      [AccountsTemplateType.GlobalSingle]: globalAccountOwnerTemplate,
    },
    [FileType.Forms]: {
      [DashboardFormType.Cesop]: cesopTemplate,
      [DashboardFormType.GainLossSummary]: formDataGainLossSummaryTemplate,
      [DashboardFormType.Irs1042S]: formData1042STemplate,
      [DashboardFormType.Irs1099B]: formData1099BTemplate,
      [DashboardFormType.Irs1099Div]: formData1099DivTemplate,
      [DashboardFormType.Irs1099Int]: formData1099IntTemplate,
      [DashboardFormType.Irs1099K]: formData1099KTemplate,
      [DashboardFormType.Irs1099Misc]: formData1099MiscTemplate,
      [DashboardFormType.Irs1099Nec]: formData1099NecTemplate,
      [DashboardFormType.Irs1099R]: formData1099RTemplate,
      [DashboardFormType.Irs5498]: formData5498Template,
      [DashboardFormType.RmdStatement]: formDataRmdStatementTemplate,
      [DashboardFormType.Dac7]: formDataMrdpTemplate,
    },
    [FileType.Transactions]: {
      [TransactionsTemplateType.Adjustments]: transactionsAdjustmentsTemplate,
      [TransactionsTemplateType.Cesop]: transactionsCesopTemplate,
      [TransactionsTemplateType.Dac7]: transactionsPaymentsTemplate,
      [TransactionsTemplateType.Default]:
        transactionsTemplateWithCounterpartyId,
    },
    [FileType.DeleteAccountOwners]: accountOwnerDeleteTemplate,
    [FileType.DeleteAccounts]: accountDeleteTemplate,
    [FileType.DeleteForms]: formDataDeleteTemplate,
    [FileType.DeleteTransactions]: transactionDeleteTemplate,
  };

  switch (fileType) {
    case FileType.Accounts:
    case FileType.Transactions:
    case FileType.Forms: {
      const mapForFileType = csvTemplateDownloadUrlMapByFileType[fileType];
      return mapForFileType[templateType as keyof typeof mapForFileType];
    }
    default: {
      return fileType && csvTemplateDownloadUrlMapByFileType[fileType];
    }
  }
};

export const getCsvTemplateHeaders = ({
  fileType,
  templateType,
}: {
  fileType: FileType;
  templateType?:
    | AccountsTemplateType
    | DashboardFormType
    | TransactionsTemplateType;
}): string[][] | undefined => {
  const csvHeadersMapByFileType = {
    [FileType.Accounts]: {
      [AccountsTemplateType.Us]: [
        accountOwnerWithAltIdTemplateContents,
        accountOwnerTemplateContents,
      ],
      [AccountsTemplateType.UsIra]: [iraAccountOwnerTemplateContents],
      [AccountsTemplateType.GlobalSingle]: [globalAccountOwnerTemplateContents],
    },
    [FileType.Forms]: {
      [DashboardFormType.Cesop]: [cesopTemplateContents],
      [DashboardFormType.GainLossSummary]: [
        formDataGainLossSummaryTemplateContents,
      ],
      [DashboardFormType.Irs1042S]: [formData1042STemplateContents],
      [DashboardFormType.Irs1099B]: [formData1099BTemplateContents],
      [DashboardFormType.Irs1099Div]: [formData1099DivTemplateContents],
      [DashboardFormType.Irs1099Int]: [formData1099IntTemplateContents],
      [DashboardFormType.Irs1099K]: [formData1099KTemplateContents],
      [DashboardFormType.Irs1099Misc]: [formData1099MiscTemplateContents],
      [DashboardFormType.Irs1099Nec]: [formData1099NecTemplateContents],
      [DashboardFormType.Irs1099R]: [formData1099RTemplateContents],
      [DashboardFormType.Irs5498]: [formData5498TemplateContents],
      [DashboardFormType.RmdStatement]: [formDataRmdStatementTemplateContents],
      [DashboardFormType.Dac7]: [formDataMrdpTemplateContents],
    },
    [FileType.Transactions]: {
      [TransactionsTemplateType.Adjustments]: [
        transactionsAdjustmentsTemplateContents,
      ],
      [TransactionsTemplateType.Cesop]: [transactionsCesopTemplateContents],
      [TransactionsTemplateType.Dac7]: [transactionsPaymentsTemplateContents],
      [TransactionsTemplateType.Default]: [
        transactionsTemplateWithCounterpartyIdContents,
        transactionsTemplateContents,
      ],
    },
    [FileType.DeleteAccountOwners]: [accountOwnerDeleteTemplateContents],
    [FileType.DeleteAccounts]: [accountDeleteTemplateContents],
    [FileType.DeleteForms]: [formDataDeleteTemplateContents],
    [FileType.DeleteTransactions]: [transactionDeleteTemplateContents],
  };

  const templates = (() => {
    switch (fileType) {
      case FileType.Accounts:
      case FileType.Transactions:
      case FileType.Forms: {
        const mapForFileType = csvHeadersMapByFileType[fileType];
        return mapForFileType[templateType as keyof typeof mapForFileType];
      }
      default: {
        return csvHeadersMapByFileType[fileType];
      }
    }
  })() as string[] | undefined;

  if (templates && templates.length > 0) {
    const headers = templates.map((template) => template.split("\n")[0]);
    return headers
      .map((header) => header?.trim().split(","))
      .filter((header): header is string[] => header !== undefined);
  } else {
    return undefined;
  }
};
