import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  RowGroup,
} from "@taxbit-private/cosmic";
import { useMemo } from "react";

import DownloadTaxDocumentationFormButton from "./DownloadTaxDocumentationFormButton";
import { documentTypeMap } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import { isWForm } from "../../../../../utils/isWForm";

export enum DownloadTaxDocumentationTrackingId {
  DownloadTaxDocumentationSpinner = "download-tax-documentation-loading-spinner",
}

const DownloadTaxDocumentation: React.FC = () => {
  const { isLoading, taxDocumentations } = useAccountOwnerTaxDocumentContext();

  const kycTaxFormRows = useMemo(() => {
    const wForm = taxDocumentations?.find((doc) => isWForm(doc));
    const selfCert = taxDocumentations?.find(
      (doc) => doc.documentType === KycTaxDocumentType.SelfCertification
    );

    if (!wForm && !selfCert) {
      return undefined;
    }

    return [
      ...(wForm
        ? [
            {
              key: `${wForm.documentType}-form`,
              avatarProps: { iconName: "file-text" },
              title: `${documentTypeMap[wForm.documentType]} Form`,
              content: (
                <DownloadTaxDocumentationFormButton
                  documentType={wForm.documentType}
                />
              ),
            },
          ]
        : []),
      ...(selfCert
        ? [
            {
              key: `${selfCert.documentType}-form`,
              avatarProps: { iconName: "file-text" },
              title: `${documentTypeMap[selfCert.documentType]} Form`,
              content: (
                <DownloadTaxDocumentationFormButton
                  documentType={selfCert.documentType}
                />
              ),
            },
          ]
        : []),
    ];
  }, [taxDocumentations]);

  return (
    <Card title="Download Tax Documentation">
      {isLoading ? (
        <ContentSpinner
          trackingId={
            DownloadTaxDocumentationTrackingId.DownloadTaxDocumentationSpinner
          }
        />
      ) : kycTaxFormRows ? (
        <RowGroup rows={kycTaxFormRows} />
      ) : (
        <ContentErrorEmptyState entity="tax documentation" />
      )}
    </Card>
  );
};

export default DownloadTaxDocumentation;
