import {
  SearchHydratedAccountsParams,
  Payer,
  ExportHydratedAccountsParams,
  HydratedAccountTinStatus,
} from "@taxbit-dashboard/rest";
import {
  AccountsUrlParams,
  accountsUrlParamsSchema,
} from "@taxbit-dashboard/router";

import { AccountsView } from "./accountsView";

const expandTinStatuses = (
  statuses: AccountsUrlParams["tinStatuses"]
): HydratedAccountTinStatus[] | undefined => {
  if (!statuses || statuses.length === 0) {
    return undefined;
  }
  const tinStatuses: HydratedAccountTinStatus[] = [];
  for (const status of statuses) {
    if (status === "VALID_MATCH") {
      tinStatuses.push(
        "VALID_SSN_MATCH",
        "VALID_EIN_MATCH",
        "VALID_SSN_EIN_MATCH"
      );
    } else {
      tinStatuses.push(status);
    }
  }
  return tinStatuses;
};

const toHydratedAccountFilters = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): SearchHydratedAccountsParams["filters"] => {
  const defaultPayer = payers?.find((payer) => payer.isDefault);
  return {
    account: {
      name: AccountsView.includes("name", view) ? params.name : undefined,
      email: params.email,
      externalId: params.accountId,
      alternateExternalId: params.alternateAccountId,
      dateCreated: {
        gte: params.dateCreatedStart,
        lte: params.dateCreatedEnd,
      },
      tinStatuses: AccountsView.includes("tinStatus", view)
        ? expandTinStatuses(params.tinStatuses)
        : undefined,
      vatStatuses: AccountsView.includes("vatStatus", view)
        ? params.vatStatuses
        : undefined,
      types: AccountsView.includes("taxClassification", view)
        ? params.taxClassifications
        : undefined,
      taxCountryCodes: AccountsView.includes("taxCountryCodes", view)
        ? params.taxCountryCodes
        : undefined,
      filerIds: AccountsView.includes("filer", view)
        ? params.filerIds
        : undefined,
      matchMissingFiler:
        defaultPayer?.payerId &&
        params.filerIds?.includes(defaultPayer.payerId),
    },
    profile: {
      us: {
        name: AccountsView.includes("usProfileName", view)
          ? params.name
          : undefined,
        taxClassifications: AccountsView.includes(
          "usProfileTaxClassification",
          view
        )
          ? params.taxClassifications
          : undefined,
        taxCountryCodes: AccountsView.includes("usProfileTaxCountryCodes", view)
          ? params.taxCountryCodes
          : undefined,
        tinStatuses: AccountsView.includes("usProfileTinStatus", view)
          ? expandTinStatuses(params.tinStatuses)
          : undefined,
        taxDocumentationTypes: AccountsView.includes(
          "usProfileTaxDocumentationType",
          view
        )
          ? params.taxDocumentationTypes
          : undefined,
      },
      global: {
        name: AccountsView.includes("globalProfileName", view)
          ? params.name
          : undefined,
        vatStatuses: AccountsView.includes("globalProfileVatStatus", view)
          ? params.vatStatuses
          : undefined,
        taxClassifications: AccountsView.includes(
          "globalProfileTaxClassification",
          view
        )
          ? params.taxClassifications
          : undefined,
        taxCountryCodes: AccountsView.includes(
          "globalProfileTaxCountryCodes",
          view
        )
          ? params.taxCountryCodes
          : undefined,
        taxDocumentationTypes: AccountsView.includes(
          "globalProfileTaxDocumentationType",
          view
        )
          ? params.taxDocumentationTypes
          : undefined,
      },
    },
  };
};

const toHydratedAccountSort = (
  params: AccountsUrlParams
): SearchHydratedAccountsParams["sort"] => {
  const dir = params.sort.dir === "desc" ? "-" : ("" as const);
  return `${dir}account.${params.sort.field}` as const;
};

const toHydratedAccountPage = (
  params: AccountsUrlParams
): SearchHydratedAccountsParams["page"] => {
  return {
    limit: params.limit,
    offset: (params.page - 1) * params.limit,
  };
};

export const toSearchHydratedAccountsParams = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): SearchHydratedAccountsParams => {
  return {
    filters: toHydratedAccountFilters(params, view, payers),
    page: toHydratedAccountPage(params),
    sort: toHydratedAccountSort(params),
  };
};

export const toExportHydratedAccountsParams = (
  params: AccountsUrlParams,
  view: AccountsView,
  payers?: Payer[]
): ExportHydratedAccountsParams => {
  return {
    filters: toHydratedAccountFilters(params, view, payers),
    mappings: AccountsView.toExportMappings(view),
    sort: toHydratedAccountSort(params),
  };
};

export const DEFAULT_ACCOUNTS_URL_PARAMS = accountsUrlParamsSchema.parse({});
