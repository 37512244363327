import {
  AssetAmount,
  Transaction,
  transactionTypeLabelMap,
} from "@taxbit-dashboard/rest";
import { Flex, Icon, TableColumns, Tooltip } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import calculateFeeFiatValue from "../../../../../utils/calculateFeeFiatValue";
import calculateTransactionInFiatValue from "../../../../../utils/calculateTransactionInFiatValue";
import sumUpFees from "../../../../../utils/sumUpFees";
import useFormatAsset from "../../../../../utils/useFormatAsset";

export enum TransactionTableTrackingId {
  UserEnteredCostBasisTableInfoIcon = "userEnteredCostBasisTableInfoIconTrackingId",
  CosmicTable = "transactionTable",
}

export type TableTransaction = Transaction & {
  transactionFiatValue?: AssetAmount;
  feeFiatValue?: AssetAmount;
  hasTransferLotData?: boolean;
};

export const mapTransactionToTableTransaction = (
  transaction: Transaction
): TableTransaction => ({
  ...transaction,
  transactionFiatValue: calculateTransactionInFiatValue(transaction),
  feeFiatValue: calculateFeeFiatValue(transaction.fees),
  hasTransferLotData: transaction.metadata?.hasTransferLotData,
});

const useTransactionsTableColumns = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  return useMemo(
    (): TableColumns<TableTransaction> =>
      [
        {
          key: "datetime",
          label: "Date",
          renderCell: (date) => {
            return formatDateTime({ date, format: "DateTime" });
          },
        },
        {
          key: "type",
          label: "Type",
          isContentFullWidth: true,
          renderCell: (value, transaction: TableTransaction) => {
            const { hasTransferLotData } = transaction;
            const type = value && transactionTypeLabelMap[value];

            return (
              <Flex alignItems="center" gap="s">
                <span>{type}</span>
                {hasTransferLotData ? (
                  <Tooltip
                    content="This transaction includes user-provided cost basis information."
                    appendTo="body"
                    isInteractive={false}
                    target={
                      <Icon
                        name="info"
                        trackingId={
                          TransactionTableTrackingId.UserEnteredCostBasisTableInfoIcon
                        }
                      />
                    }
                  />
                ) : undefined}
              </Flex>
            );
          },
        },
        {
          key: "sent",
          label: "Out",
          textAlign: "right",
          renderCell: (value) =>
            formatAsset({ assetAmount: value?.[0]?.assetAmount }),
        },
        {
          key: "received",
          label: "In",
          textAlign: "right",
          renderCell: (value) =>
            formatAsset({ assetAmount: value?.[0]?.assetAmount }),
        },
        {
          key: "fees",
          label: "Fee",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: sumUpFees(value) }),
        },
        {
          key: "transactionFiatValue",
          label: "Transaction Value",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: value }),
        },
        {
          key: "feeFiatValue",
          label: "Fee Value",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: value }),
        },
      ] as const,
    [formatAsset, formatDateTime]
  );
};

export default useTransactionsTableColumns;
