import {
  hydratedAccountTaxClassificationSchema,
  hydratedAccountTaxDocumentationTypeSchema,
  hydratedAccountVatStatusSchema,
} from "@taxbit-dashboard/rest";
import { cosmicPaginationLimitSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import accountsSortSchema from "./accountsSortSchema";
import { accountsTinStatusFilterSchema } from "./accountsTinStatusFilterSchema";
import { accountsViewTypeSchema } from "./accountsViewTypeSchema";
import pageNumberSchema from "../../shared/pageNumberSchema";

const accountsUrlParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
  view: accountsViewTypeSchema,
  name: z.string().optional(),
  email: z.string().optional(),
  accountId: z.string().optional(),
  alternateAccountId: z.string().optional(),
  tinStatuses: accountsTinStatusFilterSchema.array().optional(),
  vatStatuses: hydratedAccountVatStatusSchema.array().optional(),
  taxClassifications: hydratedAccountTaxClassificationSchema.array().optional(),
  taxDocumentationTypes: hydratedAccountTaxDocumentationTypeSchema
    .array()
    .optional(),
  taxCountryCodes: z.string().array().optional(),
  dateCreatedStart: z.string().optional(),
  dateCreatedEnd: z.string().optional(),
  filerIds: z.string().array().optional(),
  sort: accountsSortSchema.catch({
    field: "dateCreated",
    dir: "desc",
  }),
});

export type AccountsUrlParams = z.infer<typeof accountsUrlParamsSchema>;

export default accountsUrlParamsSchema;
