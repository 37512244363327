import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";

import useFileUploader from "./useFileUploader";

const useIngestFileUploader = () => {
  return useFileUploader(FileAction.Ingest);
};

export default useIngestFileUploader;

export const {
  useContextHook: useIngestFileUploaderContext,
  Provider: IngestFileUploaderContextProvider,
} = createSingleInstanceHookContext(
  useIngestFileUploader,
  "useIngestFileUploader"
);
