import { createFileRoute } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-adapter";
import { z } from "zod";

import { RouteId } from "../types/RouteId";

/**
 * These params are present on the invitation link sent to the user's email
 * when they are added via the dashboard invitation flow.
 */
const auth0InvitationParamsSchema = z.object({
  organization: z.string().optional(),
  invitation: z.string().optional(),
  organization_name: z.string().optional(),
});

export const Route = createFileRoute(RouteId.Index)({
  validateSearch: zodValidator(auth0InvitationParamsSchema),
});
