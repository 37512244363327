import {
  AuthorizedView,
  DashboardNavigationItem,
  DashboardPrimaryTemplate,
  useDefinedCurrentCompanyUser,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import { OverviewDataContextProvider } from "./context/useOverviewData";
import Overview from "./Overview";

export const overviewNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.Overview,
  iconName: "home",
  label: "Home",
  trackingId: "sidebar-navigation-overview-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadAccounts],
} as const;

const OverviewPage = () => {
  const user = useDefinedCurrentCompanyUser();

  const [firstName] = user.name.split(" ");
  const nameOrEmptyString = firstName ? `, ${firstName}` : "";

  return (
    <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
      <DashboardPrimaryTemplate title={`Welcome${nameOrEmptyString}`}>
        <OverviewDataContextProvider>
          <Overview />
        </OverviewDataContextProvider>
      </DashboardPrimaryTemplate>
    </AuthorizedView>
  );
};

export default OverviewPage;
