import { Button, Chip, ChipGroup } from "@taxbit-private/cosmic";

import { useTransactionsTableContext } from "../useTransactionsTable";
import {
  TransactionsFilterChipsTrackingId,
  useTransactionsFilterChips,
} from "./useTransactionsFilterChips";

const TransactionsFilterChips: React.FC = () => {
  const { filterCount, clearAllFilters } = useTransactionsTableContext();

  const chipProps = useTransactionsFilterChips();

  return filterCount > 0 ? (
    <ChipGroup>
      {chipProps.map((props) => {
        if (props) {
          return <Chip key={props.trackingId} {...props} />;
        }
        return undefined;
      })}
      <Button
        trackingId={TransactionsFilterChipsTrackingId.ClearAllButton}
        label="Clear all filters"
        size="small"
        variant="anchor-primary-inline"
        onClick={() => {
          void clearAllFilters();
        }}
      />
    </ChipGroup>
  ) : undefined;
};

export default TransactionsFilterChips;
