import { cosmicPaginationLimitSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import pageNumberSchema from "./pageNumberSchema";

const pageLimitPaginationParamsSchema = z.object({
  page: pageNumberSchema,
  limit: cosmicPaginationLimitSchema,
});

export type PageLimitPaginationParams = z.infer<
  typeof pageLimitPaginationParamsSchema
>;

export const DEFAULT_PAGE_LIMIT_PARAMS = pageLimitPaginationParamsSchema.parse(
  {}
);

export default pageLimitPaginationParamsSchema;
