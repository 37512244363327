import { z } from "zod";

import { router } from "../router";
import getOrganizationPrefixedSubroute from "../utils/getOrganizationPrefixedSubroute";
import { isMigratedRoute } from "../utils/migratedRoutes";

export type DashboardPathSymbol = { readonly DashboardPath: unique symbol };

/**
 * A schema that validates a string as a valid dashboard path that can be used
 * in our `useAuthenticatedNavigation` hook.
 */
const dashboardPathSchema = z
  .string()
  .refine(
    (path) => {
      const allPaths = Object.keys(router.routesByPath);

      const isMigratedPath = isMigratedRoute(path);
      const isActivePath = allPaths.some((p) => {
        const subroute = getOrganizationPrefixedSubroute(p);
        return path === subroute;
      });

      return isActivePath || isMigratedPath;
    },
    { message: "Invalid path found when parsing with `dashboardPathSchema`" }
  )
  .transform((path) => path as string & DashboardPathSymbol);

export type DashboardPath = z.infer<typeof dashboardPathSchema>;

export default dashboardPathSchema;
