import { z } from "zod";

/**
 * This is a simplified set of the tin statuses that we have
 * at the API layer, which offers a better UX for filtering.
 */
export const ACCOUNTS_TIN_STATUS_FILTER_VALUES = [
  "PENDING",
  "FOREIGN",
  "INVALID_DATA",
  "VALID_MATCH",
  "MISMATCH",
  "TIN_NOT_ISSUED",
  "ERROR",
  "UNHANDLED",
] as const;

export const accountsTinStatusFilterSchema = z.enum(
  ACCOUNTS_TIN_STATUS_FILTER_VALUES
);
