import { irFormStatusSchema, irFormTypeSchema } from "@taxbit-dashboard/rest";
import { useMemo } from "react";
import { z } from "zod";

import { useCreateTextAreaFilterSchema } from "../../../../utils/useCreateTextAreaFilterSchema";
import { accountExternalIdsFilterSchema } from "../../shared/accountExternalIdsFilterSchema";

const formsFiltersFormFieldSchema = z.object({
  statuses: z.array(irFormStatusSchema),
  types: z.array(irFormTypeSchema),
  dateRange: z.tuple([z.date().optional(), z.date().optional()]),
  accountExternalIdsFilter: accountExternalIdsFilterSchema,
});

export type FormsFiltersFormFields = z.infer<
  typeof formsFiltersFormFieldSchema
>;

const useFormsFiltersFormFieldSchema = () => {
  const textAreaFilterSchema = useCreateTextAreaFilterSchema("Account IDs");
  return useMemo(
    () =>
      formsFiltersFormFieldSchema.extend({
        accountExternalIdsFilter: textAreaFilterSchema,
      }),
    [textAreaFilterSchema]
  );
};

export default useFormsFiltersFormFieldSchema;
