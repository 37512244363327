import {
  AppOutlet,
  DashboardNavigationItemWithChildren,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId, RouterMapForPrefix } from "@taxbit-dashboard/router";

import AccountSettingsPage, {
  accountSettingsNavigationItem,
} from "./src/pages/account-settings/AccountSettings.page";
import FilerSetupPage, {
  filerSetupNavigationItem,
} from "./src/pages/filer-setup/FilerSetup.page";
import UserManagementPage, {
  userManagementNavigationItem,
} from "./src/pages/org-settings/company-user-management/UserManagement.page";
import DeveloperSettingsPage, {
  developerSettingsNavigationItem,
} from "./src/pages/org-settings/developer-settings/DeveloperSettings.page";
import InvitationManagementPage, {
  invitationManagementNavigationItem,
} from "./src/pages/org-settings/invitation-management/InvitationManagement.page";

const orgSettingsNavigationItem: DashboardNavigationItemWithChildren = {
  iconName: "users",
  label: "My Organization",
  trackingId: "sidebar-navigation-my-organization-anchor",
  children: [
    userManagementNavigationItem,
    invitationManagementNavigationItem,
    developerSettingsNavigationItem,
  ],
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.ReadOrganizationMembers] ||
    permissions[UserPermission.ReadOrganizationInvitations] ||
    (flags.shouldShowDeveloperSettings && permissions[UserPermission.ReadTins]),
} as const;

export const routerMap = {
  [RouteId.Settings]: () => (
    <AppOutlet
      sections={[
        {
          items: [
            accountSettingsNavigationItem,
            orgSettingsNavigationItem,
            filerSetupNavigationItem,
          ],
        },
      ]}
    />
  ),
  [RouteId.UserManagement]: UserManagementPage,
  [RouteId.DeveloperSettings]: DeveloperSettingsPage,
  [RouteId.FilerSettings]: FilerSetupPage,
  [RouteId.MyAccount]: AccountSettingsPage,
  [RouteId.InvitationManagement]: InvitationManagementPage,
} as const satisfies RouterMapForPrefix<"/_authed/o/$organizationId/settings">;
