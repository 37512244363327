import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardMutationKey,
  createSingleInstanceHookContext,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import toFormCountBreakdownInformation from "./toFormCountBreakdownInformation";
import {
  useGetEligibilityCounts,
  useRecalculateEligibility,
} from "../../../api/information-reporting/eligible-users/eligibilityApi";
import { useGetFormsAggregates } from "../../../api/information-reporting/forms/formsApi";
import useLegacyFormFeatureFlagData from "../shared/form-year-dropdowns/useLegacyFormFeatureFlagData";
import useUrlParamsWithFormTypeAndYear from "../shared/form-year-dropdowns/useUrlParamsWithFormTypeAndYear";

const useIrSummaryData = () => {
  const { hasEligibilityPageAccess, hasRecalculateEligibilityAccess } =
    useDashboardFeatureFlags();

  const featureFlagData = useLegacyFormFeatureFlagData();

  const formDropdownData = useUrlParamsWithFormTypeAndYear({
    featureFlagData,
    routeId: RouteId.IrSummary,
  });

  const shouldFileForm = formDropdownData.urlParams.formType.startsWith("IRS");

  const {
    data: formsAggregates,
    isLoading: isFormAggregatesLoading,
    isError: isFormAggregatesError,
  } = useGetFormsAggregates(formDropdownData.urlParams);

  const {
    data: eligibilityCounts,
    isFetching: isEligibilityCountsLoading,
    isError: isEligibilityCountsError,
  } = useGetEligibilityCounts({
    ...formDropdownData.urlParams,
    isQueryEnabled: hasEligibilityPageAccess,
  });

  const { mutate: recalculateEligibility } = useRecalculateEligibility(
    formDropdownData.urlParams
  );

  const isRecalculatingEligibility =
    useIsMutating({
      mutationKey: [
        DashboardMutationKey.EligibilityRecalc,
        formDropdownData.urlParams,
      ],
    }) > 0;

  const shouldDisableControls = isRecalculatingEligibility;

  return {
    ...formDropdownData,
    eligibilityCounts,
    formMetadataCounts: toFormCountBreakdownInformation({
      formType: formDropdownData.urlParams.formType,
      formsAggregates,
      eligibilityCounts,
      shouldFileForm,
    }),
    hasEligibilityPageAccess,
    hasRecalculateEligibilityAccess,
    isEligibilityCountsError,
    isEligibilityCountsLoading,
    isFormAggregatesLoading,
    isFormAggregatesError,
    isLoading: isFormAggregatesLoading || isEligibilityCountsLoading,
    shouldDisableControls,
    shouldFileForm,
    recalculateEligibility,
  };
};

export const {
  useContextHook: useIrSummaryDataContext,
  Provider: IrSummaryDataContextProvider,
} = createSingleInstanceHookContext(useIrSummaryData, "useIrSummaryData");
