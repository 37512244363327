import {
  AuthorizedView,
  DashboardNavigationItem,
  DashboardPrimaryTemplate,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import Reports from "./Reports";
import { ReportsDataContextProvider } from "./useReportsData";

export const reportsNavigationItem: DashboardNavigationItem = {
  routeId: RouteId.Reports,
  iconName: "file",
  label: "Reports",
  trackingId: "sidebar-navigation-reports-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadTaxReports],
} as const;

const ReportsPage: React.FC = () => {
  return (
    <AuthorizedView permissions={[UserPermission.ReadTaxReports]}>
      <DashboardPrimaryTemplate title="Reports">
        <ReportsDataContextProvider>
          <Reports />
        </ReportsDataContextProvider>
      </DashboardPrimaryTemplate>
    </AuthorizedView>
  );
};

export default ReportsPage;
