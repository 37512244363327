import { utcDateStringSchema } from "@taxbit-private/cosmic";
import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import {
  assetAmountSchema,
  assetCodeSchema,
  lineItemSchema,
} from "./assetApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";
import { pageSchema } from "./sharedApiTypes";

export type TransactionId = StringType<"TransactionId">;
export const transactionIdSchema = z
  .string()
  .refine((transactionId) => uuidSchema.safeParse(transactionId).success)
  .transform((transactionId) => transactionId as TransactionId);

export type ExternalTransactionId = StringType<"ExternalId">;

export const externalIdSchema = z
  .string()
  .transform(
    (externalTransactionId) => externalTransactionId as ExternalTransactionId
  );

export enum TransactionType {
  Sell = "SELL",
  Buy = "BUY",
  Forex = "FOREX",
  TransferIn = "TRANSFER-IN",
  TransferOut = "TRANSFER-OUT",
  Transfer = "TRANSFER",
  GiftSent = "GIFT-SENT",
  GiftReceived = "GIFT-RECEIVED",
  Trade = "TRADE",
  Income = "INCOME",
  Expense = "EXPENSE",
  Reward = "REWARD",
  Acquisition = "ACQUISITION",
  Unknown = "UNKNOWN",
}

export enum TransactionSubtype {
  Royalties = "royalties",
  Rent = "rent",
  GrossProceedsPaidToAnAttorney = "gross-proceeds-paid-to-an-attorney",
  Nec = "nec",
  PaymentGoods = "payment-goods",
  PaymentServices = "payment-services",
  Other = "other",
  Airdrop = "airdrop",
  Reward = "reward",
  StakingReward = "staking-reward",
  MedicalPayment = "medical-payment",
  ReferralBonus = "referral-bonus",
  Debit = "debit",
  Ach = "ach",
  Blockchain = "blockchain",
  CardReward = "card-reward",
  Standard = "standard",
  Postponed = "postponed",
  Recharacterization = "recharacterization",
  Conversion = "conversion",
  Rollover = "rollover",
  LateRollover = "late-rollover",
  Gift = "gift",
  CostBasisFmv = "cost-basis-fmv",
  InterestFiatBacked = "interest-fiat-backed",
  InterestCryptoBacked = "interest-crypto-backed",
  InterestPayment = "interest-payment",
}

export const transactionSubtypeLabelMap: Record<TransactionSubtype, string> = {
  [TransactionSubtype.Royalties]: "Royalties",
  [TransactionSubtype.Rent]: "Rent",
  [TransactionSubtype.GrossProceedsPaidToAnAttorney]:
    "Gross Proceeds Paid to an Attorney",
  [TransactionSubtype.Nec]: "NEC",
  [TransactionSubtype.PaymentGoods]: "Payment Goods",
  [TransactionSubtype.PaymentServices]: "Payment Services",
  [TransactionSubtype.Other]: "Other",
  [TransactionSubtype.Airdrop]: "Airdrop",
  [TransactionSubtype.Reward]: "Reward",
  [TransactionSubtype.StakingReward]: "Staking Reward",
  [TransactionSubtype.MedicalPayment]: "Medical Payment",
  [TransactionSubtype.ReferralBonus]: "Referral Bonus",
  [TransactionSubtype.Debit]: "Debit",
  [TransactionSubtype.Ach]: "ACH",
  [TransactionSubtype.Blockchain]: "Blockchain",
  [TransactionSubtype.CardReward]: "Card Reward",
  [TransactionSubtype.Standard]: "Standard",
  [TransactionSubtype.Postponed]: "Postponed",
  [TransactionSubtype.Recharacterization]: "Recharacterization",
  [TransactionSubtype.Conversion]: "Conversion",
  [TransactionSubtype.Rollover]: "Rollover",
  [TransactionSubtype.LateRollover]: "Late Rollover",
  [TransactionSubtype.Gift]: "Gift",
  [TransactionSubtype.CostBasisFmv]: "Cost Basis FMV",
  [TransactionSubtype.InterestFiatBacked]: "Interest Fiat Backed",
  [TransactionSubtype.InterestCryptoBacked]: "Interest Crypto Backed",
  [TransactionSubtype.InterestPayment]: "Interest Payment",
};

export const transactionTypeLabelMap: Record<TransactionType, string> = {
  [TransactionType.Buy]: "Buy",
  [TransactionType.Income]: "Income",
  [TransactionType.TransferIn]: "Transfer In",
  [TransactionType.TransferOut]: "Transfer Out",
  [TransactionType.Transfer]: "Trade",
  [TransactionType.Sell]: "Sell",
  [TransactionType.Trade]: "Trade",
  [TransactionType.Expense]: "Expense",
  [TransactionType.Forex]: "Trade",
  [TransactionType.GiftReceived]: "Transfer In",
  [TransactionType.GiftSent]: "Transfer Out",
  [TransactionType.Reward]: "Card Reward",
  [TransactionType.Unknown]: "Unknown",
  [TransactionType.Acquisition]: "Transfer In",
};

export const platformSchema = z.object({
  transactionHash: z.string().optional(),
});

export const metaDataSchema = z.object({
  platform: platformSchema.optional(),
  hasTransferLotData: z.boolean().optional(),
});

export const transactionSchema = z.object({
  id: transactionIdSchema,
  accountId: uuidSchema,
  externalId: externalIdSchema,
  datetime: utcDateStringSchema,
  updatedDatetime: utcDateStringSchema.optional(),
  createdDatetime: utcDateStringSchema.optional(),
  type: z.nativeEnum(TransactionType).optional(),
  received: lineItemSchema.array().optional(),
  sent: lineItemSchema.array().optional(),
  fees: lineItemSchema.array().optional(),
  prices: assetAmountSchema.array().optional(),
  metadata: metaDataSchema.optional(),
});

export type Transaction = z.infer<typeof transactionSchema>;

export const getTransactionsSchema = getPublicApiSuccessSchema(
  transactionSchema.array()
);

export type GetTransactionsResponse = z.infer<typeof getTransactionsSchema>;

export const getTransactionSchema =
  getPublicApiSuccessSchema(transactionSchema);

export type GetTransactionResponse = z.infer<typeof getTransactionSchema>;

export const transactionsTableRowSchema = z.object({
  transactionUuid: uuidSchema,
  sourceTransactionId: z.string(),
  tenantId: uuidSchema,
  dateTime: utcDateStringSchema,
  accountId: z.string(),
  accountExternalId: z.string(),
  type: z.nativeEnum(TransactionType),
  subtype: z.nativeEnum(TransactionSubtype).optional(),
  sentQuantity: z.string().optional(),
  sentAsset: assetCodeSchema.optional(),
  receivedQuantity: z.string().optional(),
  receivedAsset: assetCodeSchema.optional(),
  feeQuantity: z.string().optional(),
  feeAsset: assetCodeSchema.optional(),
  feeValue: z.string().optional(),
  feeRateAsset: assetCodeSchema.optional(),
  transactionValue: z.string().optional(),
  transactionValueAsset: assetCodeSchema.optional(),
});

export const getTransactionsTableSchema = getPublicApiSuccessSchema(
  transactionsTableRowSchema.array()
);

export type TransactionsTableRow = z.infer<typeof transactionsTableRowSchema>;

export type GetTransactionsTableResponse = z.infer<
  typeof getTransactionsTableSchema
>;

export const getTransactionsTableRequestSchema = z.object({
  filters: z
    .object({
      startDate: utcDateStringSchema.optional(),
      endDate: utcDateStringSchema.optional(),
      accountIds: z.string().array().optional(),
      transactionIds: z.string().array().optional(),
      type: z.nativeEnum(TransactionType).array().optional(),
      assetCode: z.string().array().optional(),
    })
    .optional(),
  page: pageSchema,
});

export type GetTransactionsTableRequest = z.infer<
  typeof getTransactionsTableRequestSchema
>;

export const createTransactionsExportRequestSchema =
  getTransactionsTableRequestSchema.pick({ filters: true });

export type CreateTransactionsExportRequest = z.infer<
  typeof createTransactionsExportRequestSchema
>;
