import {
  RouteId,
  AccountsViewType,
  useUrlParams,
} from "@taxbit-dashboard/router";
import { useCallback } from "react";

export const useAccountsUrlParams = () => {
  const { urlParams, setUrlParams, updateUrlParams, updateFilterParams } =
    useUrlParams({
      routeId: RouteId.Accounts,
    });

  const clearFilters = useCallback(() => {
    const { view, sort, page, limit } = urlParams;

    return setUrlParams({
      view,
      sort,
      page,
      limit,
    });
  }, [setUrlParams, urlParams]);

  const setView = useCallback(
    (view: AccountsViewType) => {
      return updateUrlParams({
        ...urlParams,
        view,
        tinStatuses:
          view === AccountsViewType.Us ? urlParams.tinStatuses : undefined,
        vatStatuses:
          view === AccountsViewType.Global ? urlParams.vatStatuses : undefined,
      });
    },
    [updateUrlParams, urlParams]
  );

  return {
    urlParams,
    setFilters: updateFilterParams,
    clearFilters,
    setPageControls: updateUrlParams,
    setView,
  };
};
