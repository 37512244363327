import {
  FileType,
  DashboardFormType,
  AccountsTemplateType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";

import parseAsPromise from "../../../utils/parseAsPromise";
import { getCsvTemplateHeaders } from "../csvTemplateData";

export const MAX_FILE_SIZE = 5e9; // 5 GB
export const MAX_ACCOUNTS_FILE_SIZE = 12e7; // 120 MB
export const MAX_TRANSACTIONS_FILE_SIZE = 2e9; // 2 GB

const VALID_DELIMITERS = new Set([","]);

export const getFilenameParts = (filename: string) => {
  const parts = filename.split(".");

  // The filename can contain periods, so we need to rejoin all sections
  // up until the last split that delineates the extension.
  const prefix = parts.slice(0, -1).join("");

  return { prefix, extension: parts.pop() ?? "" };
};

export const FileUploadError = {
  TooLarge:
    "The file you tried to upload is too large. Please upload a file smaller than 5GB.",
  AccountsFileTooLarge:
    "The accounts file you tried to upload is too large. Please upload a file smaller than 120MB.",
  TransactionsFileTooLarge:
    "The transactions file you tried to upload is too large. Please upload a file smaller than 2GB.",
  NoFile: "You must upload a file to continue.",
  IncorrectHeaders:
    "The file you tried to upload has incorrect headers. Please upload your file again using the template.",
  InvalidExtension:
    "Files uploaded must be one of the following types: .csv, .jsonl",
  InvalidExtensionCsv: "File must be a .csv file.",
  MissingTemplate: "Please select a template.",
  IncorrectDelimiter:
    "The file you tried to upload uses the incorrect delimiter. Please use a comma (,) to separate values and try again.",
} as const;

const getTemplateHeadersError = (
  templateHeaders?: string[][],
  populatedHeaders?: string[]
): string | undefined => {
  if (templateHeaders) {
    for (const templateHeader of templateHeaders) {
      const doHeadersMatch =
        templateHeader.length === populatedHeaders?.length &&
        templateHeader.every((header, idx) => header === populatedHeaders[idx]);

      if (doHeadersMatch) {
        return undefined;
      }
    }
    return FileUploadError.IncorrectHeaders;
  } else {
    return FileUploadError.MissingTemplate;
  }
};

/**
 * Validates the given file according to the minimal requirements for the
 * MVP release of the transactions uploader. Namely, that the file is an
 * appropriate size and that the file's header row mirrors the generic CSV
 * template.
 *
 * This method returns an error message if a validation error exists, or undefined
 * if the file is valid.
 */
const validateFile = async ({
  shouldSkipFileHeaderValidation,
  fileType,
  file,
  templateType,
}: {
  shouldSkipFileHeaderValidation: boolean;
  fileType?: FileType;
  file?: File;
  templateType?:
    | DashboardFormType
    | AccountsTemplateType
    | TransactionsTemplateType;
}) => {
  // This case shouldn't be possible because we disable the upload button without a
  // file, but we need to account for it anyway to appease TypeScript.
  if (!file) {
    return FileUploadError.NoFile;
  }

  const { extension } = getFilenameParts(file.name);

  if (file.size > MAX_FILE_SIZE) {
    return FileUploadError.TooLarge;
  }

  if (fileType === FileType.Accounts && file.size > MAX_ACCOUNTS_FILE_SIZE) {
    return FileUploadError.AccountsFileTooLarge;
  }

  if (
    fileType === FileType.Transactions &&
    file.size > MAX_TRANSACTIONS_FILE_SIZE
  ) {
    return FileUploadError.TransactionsFileTooLarge;
  }

  if (extension === "csv") {
    /**
     * This is an internal flag that allows skipping header validation. This should only
     * be used by devs to unblock uploading new file types for testing in the backend.
     */
    if (shouldSkipFileHeaderValidation) {
      return undefined;
    }

    const results = await parseAsPromise(file, {
      preview: 1,
    });

    const {
      data: headers,
      meta: { delimiter },
    } = results;

    if (!VALID_DELIMITERS.has(delimiter)) {
      return FileUploadError.IncorrectDelimiter;
    }

    // Papaparse will read in additional columns as blank extra headers, but
    // these should be ignored for the purposes of validation.
    const populatedHeaders = headers?.filter((h) => !!h);
    const templateHeaders = fileType
      ? getCsvTemplateHeaders({ fileType, templateType })
      : [];

    return getTemplateHeadersError(templateHeaders, populatedHeaders);
  } else if (fileType === FileType.Transactions && extension === "jsonl") {
    return undefined;
  } else if (
    fileType === FileType.Forms ||
    fileType === FileType.Accounts ||
    fileType === FileType.DeleteTransactions ||
    fileType === FileType.DeleteAccounts ||
    fileType === FileType.DeleteAccountOwners ||
    fileType === FileType.DeleteForms
  ) {
    return FileUploadError.InvalidExtensionCsv;
  } else {
    // This case shouldn't be possible because we block the Uploader from allowing any files
    // but .csv and .jsonl, but we need to account for it anyway to appease Eslint.
    return FileUploadError.InvalidExtension;
  }
};

export default validateFile;
