import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { RouteId, useAuthenticatedNavigation } from "@taxbit-dashboard/router";
import { useCallback, useMemo } from "react";

/**
 * Returns a function that navigates to the appropriate initial tab of the account details page.
 * Even though the base page has a redirect to the same tab, an outstanding issue in Tanstack Router
 * causes scroll restoration to break intermitttently when this redirect is actually used. To ensure
 * consistent scroll restoration, we navigate to the tab directly.
 */
const useNavigateToAccountDetails = () => {
  const { authenticatedNavigate } = useAuthenticatedNavigation();

  const { hasReportingProfile, showAccountDetailsReportingProfile } =
    useDashboardFeatureFlags();

  const defaultRoute = useMemo(
    () =>
      hasReportingProfile && showAccountDetailsReportingProfile
        ? RouteId.AccountDetailsReportingProfileTab
        : RouteId.AccountDetailsDetailsTab,
    [hasReportingProfile, showAccountDetailsReportingProfile]
  );

  return useCallback(
    (accountId: string) => {
      void authenticatedNavigate({
        to: defaultRoute,
        params: { accountId },
      });
    },
    [authenticatedNavigate, defaultRoute]
  );
};

export default useNavigateToAccountDetails;
