import { utcDateStringSchema } from "@taxbit-private/cosmic";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { useMemo } from "react";
import { z } from "zod";

import useLegacyFormFeatureFlagData from "../../pages/information-reporting/shared/form-year-dropdowns/useLegacyFormFeatureFlagData";
import useFormItemYears from "../../pages/overview/context/useFormItemYears";
import getLastTaxYear from "../../utils/getLastTaxYear";

export const useOverviewUrlParamsSchema = () => {
  const { years: eligibilityYears } = useLegacyFormFeatureFlagData();
  const defaultEligibilityYear = eligibilityYears[0] ?? getLastTaxYear().year;

  const { formItemYears } = useFormItemYears();
  const defaultFormItemsYear = formItemYears[0] ?? getLastTaxYear().year;

  const { dateStrings: defaultTaxYearDates } = getLastTaxYear();

  return useMemo(
    () =>
      z.object({
        transactions: z
          .object({
            startDate: utcDateStringSchema.catch(defaultTaxYearDates.startDate),
            endDate: utcDateStringSchema.catch(defaultTaxYearDates.endDate),
          })
          .catch(defaultTaxYearDates),
        forms: z
          .object({
            year: fourDigitYearSchema.catch(defaultFormItemsYear),
          })
          .catch({
            year: defaultFormItemsYear,
          }),
        eligibility: z
          .object({
            year: fourDigitYearSchema.catch(defaultEligibilityYear),
          })
          .catch({
            year: defaultEligibilityYear,
          }),
      }),
    [defaultEligibilityYear, defaultFormItemsYear, defaultTaxYearDates]
  );
};

export type OverviewUrlParams = z.infer<
  ReturnType<typeof useOverviewUrlParamsSchema>
>;
