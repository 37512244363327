import {
  AuthorizedView,
  DashboardNavigationItemChild,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { RouteId } from "@taxbit-dashboard/router";

import CompanyUserManagement from "./company-user-table/CompanyUserManagement";
import { CompanyUserManagementDataProvider } from "./context/useCompanyUserManagementData";

export const userManagementNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.UserManagement,
  label: "User Management",
  trackingId: "sidebar-navigation-user-management",
  key: "user-manangement",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadOrganizationMembers],
} as const;

const UserManagementPage = () => (
  <AuthorizedView permissions={[UserPermission.ReadOrganizationMembers]}>
    <CompanyUserManagementDataProvider>
      <CompanyUserManagement />
    </CompanyUserManagementDataProvider>
  </AuthorizedView>
);

export default UserManagementPage;
