import {
  AuthorizedView,
  UrlParamPaginationControls,
  UserPermission,
} from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  Divider,
} from "@taxbit-private/cosmic";

import FormItemsTable from "../../../form-items/table/FormItemsTable";
import useFormItems from "../../../form-items/useFormItems";
import FormTypeAndYearDropdowns from "../../../information-reporting/shared/form-year-dropdowns/FormTypeAndYearDropdowns";

export enum FormDataTabTrackingId {
  LoadingSpinner = "form-data-tab-spinner",
  Pagination = "form-data-tab-pagination",
}

const FormDataTab: React.FC = () => {
  const {
    error,
    formItems,
    isError,
    isLoading,
    isNewDataLoading,
    shouldDisableControls,
    totalCount,
    urlParams,
    updateUrlParams,
    ...props
  } = useFormItems();

  const contents = (() => {
    if (isLoading || (isNewDataLoading && urlParams.page === 1)) {
      return (
        <ContentSpinner trackingId={FormDataTabTrackingId.LoadingSpinner} />
      );
    }

    if (isError && urlParams.page === 1) {
      return <ContentErrorEmptyState entity="form data" />;
    }

    if (!isError && formItems?.length === 0) {
      return <ContentEntityEmptyState entity="form data" />;
    }

    return (
      <>
        <FormItemsTable
          isLoading={isNewDataLoading}
          isError={isError}
          formItems={formItems ?? []}
          currentForm={urlParams.formType}
        />
        <Divider />
        <UrlParamPaginationControls
          isDisabled={shouldDisableControls}
          totalCount={totalCount}
          urlParams={urlParams}
          updateUrlParams={updateUrlParams}
          trackingId={FormDataTabTrackingId.Pagination}
        />
      </>
    );
  })();

  return (
    <AuthorizedView permissions={[UserPermission.ReadAccountDocuments]}>
      <Card
        title="Form Data"
        utilityElement={
          <FormTypeAndYearDropdowns
            {...props}
            urlParams={urlParams}
            isDisabled={isLoading}
            size="small"
          />
        }
      >
        {contents}
      </Card>
    </AuthorizedView>
  );
};

export default FormDataTab;
