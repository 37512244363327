import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

const exportAccountsPostSchema = z.object({
  exportId: z.string(),
});

export const getAccountsPostExportResponseSchema = getPublicApiSuccessSchema(
  exportAccountsPostSchema
);
