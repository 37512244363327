import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { IrEligibilityUrlParams } from "@taxbit-dashboard/router";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import useEligibilityFiltersFormFieldSchema, {
  EligibilityFiltersFormFields,
} from "./useEligibilityFiltersFormFieldSchema";
import { useEligibilityDataContext } from "../context/useEligibilityData";

const getFormDataFromUrlParams = ({
  statuses,
  accountValidationsEligibilityStatuses,
  formStatuses,
}: IrEligibilityUrlParams): Omit<
  EligibilityFiltersFormFields,
  "accountExternalIdsFilter"
> => ({
  statuses: statuses === "None" || !statuses ? [] : statuses,
  accountValidationsEligibilityStatuses:
    accountValidationsEligibilityStatuses ?? [],
  formStatuses: formStatuses ?? [],
});

const formDataEmptyValues: EligibilityFiltersFormFields = {
  statuses: [],
  accountValidationsEligibilityStatuses: [],
  formStatuses: [],
  accountExternalIdsFilter: "",
};

const useEligibilityFiltersDrawerForm = () => {
  const {
    urlParams,
    updateFilterParams,
    accountExternalIdsFilter,
    setAccountExternalIdsFilter,
  } = useEligibilityDataContext();

  const defaultValues = useMemo(
    () => ({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    }),
    [urlParams, accountExternalIdsFilter]
  );

  const eligibilityFiltersFormFieldSchema =
    useEligibilityFiltersFormFieldSchema();

  const formMethods = useForm<EligibilityFiltersFormFields>({
    resolver: zodResolver(eligibilityFiltersFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
      void formMethods.handleSubmit(
        ({
          accountExternalIdsFilter: newAccountExternalIdsFilter,
          statuses,
          ...newUrlParams
        }) => {
          updateFilterParams({
            ...newUrlParams,
            statuses: statuses.length > 0 ? statuses : "None",
          });
          setAccountExternalIdsFilter(newAccountExternalIdsFilter);
          callback();
        }
      )(e);
    },
    [formMethods, setAccountExternalIdsFilter, updateFilterParams]
  );

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset({
      ...getFormDataFromUrlParams(urlParams),
      accountExternalIdsFilter,
    });
  }, [accountExternalIdsFilter, urlParams, formMethods]);

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof EligibilityFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    formType: urlParams.formType,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useEligibilityFiltersDrawerForm;
