import { Button, Flex, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useState } from "react";
import styled from "styled-components";

import { useTransactionsTableContext } from "../useTransactionsTable";

export enum TransactionsTableSearchBarTrackingId {
  SearchBar = "transactions-table-search-bar",
  SearchButton = "transactions-table-search-button",
}

type SearchParamOption = {
  key: string;
  label: string;
};

type TransactionsTableSearchBarProps = {
  isDisabled: boolean;
};

const SEARCH_PARAM_OPTIONS = [
  { key: "transactionIds", label: "Transaction ID" },
  { key: "accountIds", label: "Account ID" },
] as const;

const TransactionsTableSearchBar: React.FC<TransactionsTableSearchBarProps> = ({
  isDisabled,
}) => {
  const { updateStateFilters } = useTransactionsTableContext();
  const [searchKeyOption, setSearchKeyOption] = useState<SearchParamOption>(
    SEARCH_PARAM_OPTIONS[0]
  );
  const [searchValue, setSearchValue] = useState("");

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateStateFilters({
      [searchKeyOption.key]: searchValue.replaceAll(",", "\n"),
    });
  };

  return (
    <FlexForm
      as="form"
      onSubmit={handleSearchSubmit}
      alignItems="center"
      gap="m"
    >
      <FullWidthScopedSearchBar
        trackingId={TransactionsTableSearchBarTrackingId.SearchBar}
        label="Search transactions"
        isLabelHidden={true}
        dropdownProps={{
          options: SEARCH_PARAM_OPTIONS,
          onChange: setSearchKeyOption,
          value: searchKeyOption,
          getOptionKey: ({ key }: SearchParamOption) => key,
          getOptionLabel: ({ label }: SearchParamOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={isDisabled}
        size="small"
      />
      <Button
        trackingId={TransactionsTableSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        size="small"
        isDisabled={!searchValue}
      />
    </FlexForm>
  );
};

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchParamOption>)`
  flex-grow: 1;
`;

const FlexForm = styled(Flex)`
  flex-grow: 1;
`;

export default TransactionsTableSearchBar;
