import {
  renderStringOrPlaceholder,
  GridRow,
  BaseGrid as Grid,
} from "@taxbit-dashboard/commons";
import {
  AssetAmount,
  AssetType,
  LineItem,
  Transaction,
  transactionTypeLabelMap,
} from "@taxbit-dashboard/rest";
import { Card, Flex, Divider, Tooltip, Icon } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import calculateAssetFiatValue from "../../../../../utils/calculateAssetFiatValue";
import sumUpFees from "../../../../../utils/sumUpFees";
import useFormatAsset from "../../../../../utils/useFormatAsset";

export enum TransactionDetailsInformationTrackingId {
  ToAccountLink = "transactionDetailsToAccountLink",
  UserEnteredCostBasisDetailsInfoIcon = "userEnteredCostBasisDetailsInfoIconTrackingId",
}

type TransactionDetailsInformationProps = {
  transaction: Transaction;
  costBasis?: AssetAmount;
  hasTransferLotData?: boolean;
};

const TransactionDetailsInformation: React.FC<
  TransactionDetailsInformationProps
> = ({ transaction }) => {
  const {
    datetime,
    externalId,
    fees,
    id,
    type,
    received,
    sent,
    createdDatetime,
    updatedDatetime,
    metadata,
  } = transaction;

  const receivedAsset = received?.[0];
  const sentAsset = sent?.[0];
  const transactionHash = metadata?.platform?.transactionHash;
  const hasTransferLotData = metadata?.hasTransferLotData;

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const formatAssetValue = useCallback(
    (lineItem?: LineItem) => {
      const formattedAsset = formatAsset({
        assetAmount: lineItem?.assetAmount,
      });
      if (lineItem && lineItem.assetAmount.asset.type === AssetType.Crypto) {
        const calculatedAssetFiatValue = calculateAssetFiatValue(lineItem);
        return `${formattedAsset} (${formatAsset({
          assetAmount: calculatedAssetFiatValue,
        })})`;
      } else {
        return formattedAsset;
      }
    },
    [formatAsset]
  );

  const renderTransferInTooltip = () => {
    return hasTransferLotData ? (
      <Tooltip
        content="This transaction includes user-provided cost basis information."
        appendTo="body"
        isInteractive={false}
        target={
          <Icon
            name="info"
            trackingId={
              TransactionDetailsInformationTrackingId.UserEnteredCostBasisDetailsInfoIcon
            }
          />
        }
      />
    ) : undefined;
  };

  return (
    <Card title="Details">
      <Flex direction="column" padding="l" gap="m">
        <Grid>
          <GridRow
            label="Type"
            content={
              type ? (
                <Flex gap="s" alignItems="center">
                  <span>{transactionTypeLabelMap[type]}</span>
                  {renderTransferInTooltip()}
                </Flex>
              ) : (
                COSMIC_VALUE_PLACEHOLDER
              )
            }
          />
          <GridRow
            label="Timestamp"
            content={formatDateTime({ date: datetime, format: "DateTime" })}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Amount In"
            content={formatAssetValue(receivedAsset)}
          />
          <GridRow label="Amount Out" content={formatAssetValue(sentAsset)} />
          <GridRow
            label="Fee"
            content={formatAsset({ assetAmount: sumUpFees(fees) })}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Transaction Hash"
            content={renderStringOrPlaceholder(transactionHash)}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Transaction ID"
            content={renderStringOrPlaceholder(externalId)}
          />
          <GridRow
            label="TaxBit Transaction ID"
            content={renderStringOrPlaceholder(id)}
          />
          <GridRow
            label="Ingested Timestamp"
            content={formatDateTime({
              date: createdDatetime,
              format: "DateTime",
            })}
          />
          <GridRow
            label="Modified Timestamp"
            content={formatDateTime({
              date: updatedDatetime,
              format: "DateTime",
            })}
          />
        </Grid>
      </Flex>
    </Card>
  );
};

export default TransactionDetailsInformation;
