import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { RhfTextArea } from "@taxbit-private/cosmic-react-hook-form";

import { getTextAreaFilterMaxItems } from "../../../../utils/useCreateTextAreaFilterSchema";

type TransactionsTextFilterProps = {
  name: string;
  label: string;
};

const TransactionIdsTextFilter: React.FC<TransactionsTextFilterProps> = ({
  name,
  label,
}) => {
  const { formatWholeQuantity } = useCosmicLocalizationContext();

  return (
    <RhfTextArea
      name={name}
      label={label}
      trackingId={`${name}-filter`}
      helpIconProps={{
        tooltipProps: {
          content: `Manually enter up to ${formatWholeQuantity(getTextAreaFilterMaxItems())} IDs, with each ID on a separate line.`,
        },
        trackingId: `${name}-help-icon`,
      }}
    />
  );
};

export default TransactionIdsTextFilter;
