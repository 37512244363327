import {
  hydratedAccountTinStatusSchema,
  hydratedAccountVatStatusSchema,
  hydratedAccountTaxClassificationSchema,
  HydratedAccount,
  Payer,
  payerIdSchema,
  hydratedAccountTaxDocumentationTypeSchema,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

const accountsTableRowSchema = z.object({
  id: z.string(),
  externalId: z.string(),
  alternateExternalId: z.string().optional(),
  name: z.string().optional(),
  dateCreated: z.string(),
  email: z.string().optional(),
  taxCountryCodes: z.string().array().optional(),
  tinStatus: hydratedAccountTinStatusSchema.optional(),
  vatStatus: hydratedAccountVatStatusSchema.optional(),
  taxClassification: hydratedAccountTaxClassificationSchema.optional(),
  filer: z.string().optional(),
  usProfileName: z.string().optional(),
  usProfileTinStatus: hydratedAccountTinStatusSchema.optional(),
  usProfileTaxClassification: hydratedAccountTaxClassificationSchema.optional(),
  usProfileTaxDocumentationType:
    hydratedAccountTaxDocumentationTypeSchema.optional(),
  usProfileTaxCountryCodes: z.string().array().optional(),
  globalProfileName: z.string().optional(),
  globalProfileVatStatus: hydratedAccountVatStatusSchema.optional(),
  globalProfileTaxClassification:
    hydratedAccountTaxClassificationSchema.optional(),
  globalProfileTaxDocumentationType:
    hydratedAccountTaxDocumentationTypeSchema.optional(),
  globalProfileTaxCountryCodes: z.string().array().optional(),
});
export type AccountsTableRow = z.infer<typeof accountsTableRowSchema>;

const fromHydratedAccount = (
  account: HydratedAccount,
  payers: Payer[]
): AccountsTableRow => {
  const payerMap = new Map(payers.map((payer) => [payer.payerId, payer]));

  const filer = account.filerId
    ? payerMap.get(payerIdSchema.parse(account.filerId))?.payerName
    : undefined;

  return {
    id: account.id,
    externalId: account.externalId,
    alternateExternalId: account.alternateExternalId,
    name: account.name,
    dateCreated: account.dateCreated,
    email: account.email,
    taxCountryCodes: account.taxCountryCodes,
    tinStatus: account.tinStatus,
    vatStatus: account.vatStatus,
    taxClassification: account.type,
    filer,
    usProfileName: account.profile?.us?.name,
    usProfileTinStatus: account.profile?.us?.tinStatus,
    usProfileTaxClassification: account.profile?.us?.taxClassification,
    usProfileTaxDocumentationType: account.profile?.us?.taxDocumentationType,
    usProfileTaxCountryCodes: account.profile?.us?.taxCountryCodes,
    globalProfileName: account.profile?.global?.name,
    globalProfileVatStatus: account.profile?.global?.vatStatus,
    globalProfileTaxClassification: account.profile?.global?.taxClassification,
    globalProfileTaxDocumentationType:
      account.profile?.global?.taxDocumentationType,
    globalProfileTaxCountryCodes: account.profile?.global?.taxCountryCodes,
  };
};

export const AccountsTableRow = {
  schema: accountsTableRowSchema,
  fromHydratedAccount,
};

type LabelableKeys = keyof Omit<AccountsTableRow, "id">;

export const ACCOUNTS_TABLE_ROW_LABELS: Record<
  "full" | "short",
  Record<LabelableKeys, string>
> = {
  full: {
    externalId: "Account ID",
    alternateExternalId: "Alternate Account ID",
    name: "Name",
    email: "Email",
    dateCreated: "Date Created",
    taxCountryCodes: "Country Code",
    tinStatus: "US TIN Status",
    vatStatus: "VAT Status",
    filer: "Filer",
    taxClassification: "Tax Classification",
    usProfileName: "Name",
    usProfileTinStatus: "US TIN Status",
    usProfileTaxClassification: "Tax Classification",
    usProfileTaxCountryCodes: "Country Code",
    usProfileTaxDocumentationType: "Documentation Type",
    globalProfileName: "Name",
    globalProfileTaxCountryCodes: "Country Code",
    globalProfileVatStatus: "VAT Status",
    globalProfileTaxClassification: "Tax Classification",
    globalProfileTaxDocumentationType: "Documentation Type",
  },
  short: {
    externalId: "Account ID",
    alternateExternalId: "Alternate Account ID",
    name: "Name",
    email: "Email",
    dateCreated: "Date Created",
    taxCountryCodes: "Country Code",
    tinStatus: "US TIN Status",
    vatStatus: "VAT Status",
    filer: "Filer",
    taxClassification: "Tax Class.",
    usProfileName: "Name",
    usProfileTaxCountryCodes: "Country Code",
    usProfileTinStatus: "US TIN Status",
    usProfileTaxClassification: "Tax Class.",
    usProfileTaxDocumentationType: "Doc. Type",
    globalProfileName: "Name",
    globalProfileTaxCountryCodes: "Country Code",
    globalProfileVatStatus: "VAT Status",
    globalProfileTaxClassification: "Tax Class.",
    globalProfileTaxDocumentationType: "Doc. Type",
  },
};
