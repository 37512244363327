import { AuthorizedView, UserPermission } from "@taxbit-dashboard/commons";

import AccountDetails from "./AccountDetails";
import { AccountOwnerTaxDocumentProvider } from "../../../hooks/useGetAccountOwnerTaxDocumentData";

const AccountDetailsPage: React.FC = () => {
  return (
    <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
      <AccountOwnerTaxDocumentProvider>
        <AccountDetails />
      </AccountOwnerTaxDocumentProvider>
    </AuthorizedView>
  );
};

export default AccountDetailsPage;
