import { z } from "zod";

import {
  irsTinMatchValidationResultSchema,
  W_FORM_TAX_CLASSIFICATION,
  accountIdSchema,
  accountOwnerIdSchema,
  accountTypeSchema,
  addressSchema,
  organizationIdSchema,
  payerIdSchema,
  vatValidationResultSchema,
  yearEndFairMarketValueSchema,
} from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const PROFILE_TYPE = ["US", "GLOBAL"] as const;

const profileTypeSchema = z.enum(PROFILE_TYPE);

export const TIN_SOURCE = ["ACCOUNT_DATA", "KYC_DATA"] as const;

const tinSourceSchema = z.enum(TIN_SOURCE);

export const TIN_TYPE = ["SSN", "EIN", "ITIN", "ATIN", "OTHER"] as const;

const tinTypeSchema = z.enum(TIN_TYPE);

export type ReportingProfileTinType = z.infer<typeof tinTypeSchema>;

const residencyAndTinSchema = z.object({
  taxCountryCode: z.string().optional(),
  tin: z.string().optional(),
  maskedTin: z.string().optional(),
  tinType: tinTypeSchema.optional(),
  isValidTin: z.boolean().optional(),
  tinSource: tinSourceSchema,
  tinNotRequired: z.boolean().optional(),
});

const taxClassificationTypeSchema = z.enum(W_FORM_TAX_CLASSIFICATION);

export const CONTROLLING_PERSON_ROLE = [
  "SETTLOR",
  "TRUSTEE",
  "PROTECTOR",
  "BENEFICIARY",
  "OTHER",
  "SETTLOR_EQUIVALENT",
  "TRUSTEE_EQUIVALENT",
  "PROTECTOR_EQUIVALENT",
  "BENEFICIARY_EQUIVALENT",
  "OTHER_EQUIVALENT",
  "OWNER",
  "OTHER_MEANS",
  "SENIOR_MANAGING_OFFICIAL",
] as const;

export const controllingPersonSchema = z.object({
  name: z.string().optional(),
  role: z.enum(CONTROLLING_PERSON_ROLE).optional(),
  birthDate: z.string().optional(),
  birthCity: z.string().optional(),
  birthCountry: z.string().optional(),
  address: addressSchema.optional(),
  mailingAddressIsDifferent: z.boolean().optional(),
  mailingAddress: addressSchema.optional(),
  residenciesAndTins: z.array(residencyAndTinSchema).optional(),
});

const reportingProfileDataSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  birthDate: z.string().optional(),
  birthCity: z.string().optional(),
  birthCountry: z.string().optional(),
  dbaName: z.string().optional(),
  residenciesAndTins: z.array(residencyAndTinSchema).optional(),
  taxClassification: taxClassificationTypeSchema.optional(),
  carfClassification: z.string().optional(),
  address: addressSchema.optional(),
  mailingAddress: addressSchema.optional(),
  permanentAddress: addressSchema.optional(),
  mailingAddressIsDifferent: z.boolean().optional(),
  vatId: z.string().optional(),
  vatIdMasked: z.string().optional(),
  vatStatus: vatValidationResultSchema.optional(), // stubbed for downstream implementation - not yet set
  vatCountry: z.string().optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationNumberMasked: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
  financialAccountId: z.string().optional(),
  financialAccountIdMasked: z.string().optional(),
  financialAccountHolderName: z.string().optional(),
  irsTinMatchResult: irsTinMatchValidationResultSchema.optional(),
  irsTinMatchResultDate: z.string().optional(),
  exemptFatcaCode: z.string().optional(),
  exemptPayeeCode: z.string().optional(),
  vatinNotRequired: z.boolean().optional(),
  hasFatcaFilingRequirement: z.boolean().optional(),
  hasSecondTinNotice: z.boolean().optional(),
  isNotSubjectToBackupWithholding: z.boolean().optional(),
  isTaxExempt: z.boolean().optional(),
  prefersPhysicalMail: z.boolean().optional(),
  referenceNumbers: z.string().optional(),
  establishmentDate: z.string().optional(),
  controllingPersons: controllingPersonSchema.array().optional(),
});

export const reportingProfileSchema = z.object({
  organizationId: organizationIdSchema,
  accountId: accountIdSchema,
  accountExternalId: z.string(),
  accountType: accountTypeSchema.optional(),
  filerId: payerIdSchema.optional(),
  accountOwnerId: accountOwnerIdSchema,
  accountOwnerExternalId: z.string(),
  secondaryAccountOwnerNames: z.string().array().optional(),
  yearEndFairMarketValue: yearEndFairMarketValueSchema.array().optional(),
  accountDataUpdateDate: z.string(),
  kycDataUpdateDate: z.string().optional(),
  taxDocumentationType: z.string().optional(),
  isTaxDocumentationValid: z.boolean().optional(),
  profileType: profileTypeSchema,
  profileData: reportingProfileDataSchema,
});

const reportingProfilesSchema = z.object({
  reportingProfiles: reportingProfileSchema.array(),
});

export type ReportingProfile = z.infer<typeof reportingProfileSchema>;

export const getAccountReportingProfilesResponseSchema =
  getPublicApiSuccessSchema(reportingProfilesSchema);

export type GetAccountReportingProfilesResponse = z.infer<
  typeof getAccountReportingProfilesResponseSchema
>;
