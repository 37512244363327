import { OffsetPaginationApiParams } from "@taxbit-dashboard/rest";
import { PageLimitPaginationParams } from "@taxbit-dashboard/router";

import getOffsetFromPage from "./getOffsetFromPage";

const getPageLimitApiParams = ({
  page,
  limit,
}: PageLimitPaginationParams): OffsetPaginationApiParams => {
  return {
    "page[limit]": limit,
    "page[offset]": getOffsetFromPage({ page, limit }),
  };
};

export default getPageLimitApiParams;
