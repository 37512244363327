import { DashboardFormType } from "@taxbit-dashboard/rest";
import {
  ParamsWithDefinedFormTypeAndDate,
  RouteId,
  UrlParams,
  useUrlParams,
  UseUrlParamsReturn,
} from "@taxbit-dashboard/router";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useCallback, useEffect, useMemo } from "react";

import { FormFeatureFlagData } from "./useFormFeatureFlagData";
import {
  supportedFormItemTypesFromDashboardFormTypes,
  toUpdatedDashboardFormType,
} from "../../../../api/form-items/formItemsApiTypes";

export type FormTypeAndYearRouteId =
  | typeof RouteId.AccountDetailsFormDataTab
  | typeof RouteId.IrSummary
  | typeof RouteId.IrEligibility
  | typeof RouteId.IrForms;

export type UseUrlParamsWithFormTypeAndYearParams<
  TRouteId extends FormTypeAndYearRouteId,
> = {
  shouldUseFormItemsForms?: boolean;
  featureFlagData: FormFeatureFlagData;
  onFormChangeCallback?: (form: DashboardFormType) => void;
  onYearChangeCallback?: (year: FourDigitYear) => void;
  routeId: TRouteId;
};

type UseUrlParamsReturnWithFormTypeAndYear<
  TRouteId extends FormTypeAndYearRouteId,
> = UseUrlParamsReturn<UrlParams<TRouteId>> & {
  urlParams: ParamsWithDefinedFormTypeAndDate<UrlParams<TRouteId>>;
};

type UseUrlParamsWithFormTypeAndYearReturn<
  TRouteId extends FormTypeAndYearRouteId,
> = UseUrlParamsReturnWithFormTypeAndYear<TRouteId> & {
  forms: DashboardFormType[];
  onFormChange: (form: DashboardFormType) => Promise<void>;
  years: FourDigitYear[];
  onYearChange: (year: FourDigitYear) => Promise<void>;
};

/**
 * Wraps the standard shared URL param infrastructure with an additional layer of logic to handle
 * defaulting the shared form year / form type dropdown values appropriately based on feature flag data.
 * This hook should be used in conjunction instead of the standard `useUrlParams` hook when handling
 * url params for a page that leverages the shared dropdowns.
 *
 * TODO: Incorporate feature flag data directly into this hook when all pages are moved over to
 * the format of feature flags expected in `useFormFeatureFlagData`.
 * https://linear.app/taxbit/issue/DASH-56/deprecate-uselegacyformfeatureflagdata-hook
 */
const useUrlParamsWithFormTypeAndYear = <
  TRouteId extends FormTypeAndYearRouteId,
>({
  featureFlagData: { forms, getYearsForForm, hasYearForForm },
  onFormChangeCallback,
  onYearChangeCallback,
  shouldUseFormItemsForms = false,
  routeId,
}: UseUrlParamsWithFormTypeAndYearParams<TRouteId>): UseUrlParamsWithFormTypeAndYearReturn<TRouteId> => {
  const {
    urlParams: { formType, formYear, ...restParams },
    updateUrlParams,
    ...restUrlParamsHelpers
  } = useUrlParams({
    routeId: routeId satisfies FormTypeAndYearRouteId,
  });

  /**
   * This validation logic maintains the current URL params if both params are valid and if they are combined
   * correctly per feature flag data (i.e. the selected year is available for the selected form). Otherwise,
   * we default to the first available form and the most recent available year for that form.
   */
  const { validatedFormType, validatedFormYear } = useMemo(() => {
    if (
      formType &&
      formYear &&
      hasYearForForm(formType, formYear) &&
      (!shouldUseFormItemsForms ||
        supportedFormItemTypesFromDashboardFormTypes.has(formType))
    ) {
      return {
        validatedFormType: shouldUseFormItemsForms
          ? toUpdatedDashboardFormType(formType)
          : formType,
        validatedFormYear: formYear,
      };
    } else {
      const defaultForm = forms[0];
      const defaultYear = defaultForm && getYearsForForm(defaultForm)[0];

      if (!defaultForm || !defaultYear) {
        throw new Error(`No tax forms configured for route: ${routeId}`);
      }

      /** We show a top-level error state if there are no tax forms / years configured on any page that
       * requires form/year feature flag data, so the additional fallbacks for `validatedForm` and `validatedFormYear`
       * are just to appease Typescript and should never be reached.
       */
      return {
        validatedFormType: defaultForm,
        validatedFormYear: defaultYear,
      };
    }
  }, [
    formType,
    formYear,
    hasYearForForm,
    shouldUseFormItemsForms,
    forms,
    getYearsForForm,
    routeId,
  ]);

  /**
   * If the validated params don't match the actual URL params, update the URL params.
   */
  useEffect(() => {
    if (validatedFormType !== formType || validatedFormYear !== formYear) {
      void updateUrlParams({
        formYear: validatedFormYear,
        formType: validatedFormType,
      });
    }
  }, [
    validatedFormType,
    validatedFormYear,
    updateUrlParams,
    formYear,
    formType,
  ]);

  const years = useMemo(
    () => getYearsForForm(validatedFormType),
    [validatedFormType, getYearsForForm]
  );

  const onFormChange = useCallback(
    async (form: DashboardFormType) => {
      const formYear = hasYearForForm(form, validatedFormYear)
        ? validatedFormYear
        : getYearsForForm(form)[0];

      await updateUrlParams({
        formType: form,
        formYear,
      });

      onFormChangeCallback?.(form);
    },
    [
      validatedFormYear,
      getYearsForForm,
      hasYearForForm,
      onFormChangeCallback,
      updateUrlParams,
    ]
  );

  const onYearChange = useCallback(
    async (year: FourDigitYear) => {
      await updateUrlParams({
        formYear: year,
      });
      onYearChangeCallback?.(year);
    },
    [onYearChangeCallback, updateUrlParams]
  );

  // The typing is getting thrown off by the "satisfies" that we use above when
  // accessing the urlParams from the useUrlParams hook. The inferred types are correct
  // when this hook is being used, so we can safely cast this object.
  const urlParamsReturn = {
    urlParams: {
      formType: validatedFormType,
      formYear: validatedFormYear,
      ...restParams,
    },
    updateUrlParams,
    ...restUrlParamsHelpers,
  } as unknown as UseUrlParamsReturnWithFormTypeAndYear<TRouteId>;

  return {
    forms,
    onFormChange,
    years,
    onYearChange,
    ...urlParamsReturn,
  };
};

export default useUrlParamsWithFormTypeAndYear;
