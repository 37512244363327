import {
  createSingleInstanceHookContext,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { TransactionsTableRow } from "@taxbit-dashboard/rest";
import {
  getEnUsErrorEmptyStateProps,
  useCosmicTable,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useMemo, useCallback, useState } from "react";

import {
  TransactionsTableTrackingId,
  useTransactionsTableColumns,
} from "./useTransactionsTableColumns";
import {
  useCreateTransactionsExport,
  useGetTransactionsForTable,
} from "../../../api/transactionsApi";
import { useTransactionsParams } from "../useTransactionsParams";

const useTransactionsTable = () => {
  const {
    urlParams,
    requestParams,
    updateUrlParams,
    updateStateFilters,
    stateFilters,
    updateFilterParams,
    clearAllFilters,
  } = useTransactionsParams();

  const { data, meta, isLoading, isError, isFetching } =
    useGetTransactionsForTable(requestParams);

  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);

  const { mutate: triggerTransactionsExport, isLoading: isCreatingExport } =
    useCreateTransactionsExport();

  const columns = useTransactionsTableColumns();

  const rows = useMemo(() => data ?? [], [data]);

  const memoizedGetRowKey = useCallback(
    (row: TransactionsTableRow) => row.transactionUuid,
    []
  );

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const tableProps = useCosmicTable({
    trackingId: TransactionsTableTrackingId.CosmicTable,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    density: "compact",
    rows,
    columns,
    isLoading: isLoading || isFetching,
    emptyStateProps: isError
      ? getEnUsErrorEmptyStateProps({ entity: "transactions" })
      : searchEmptyStateProps,
  });

  const shouldDisableControls = isLoading || isError;

  const addToast = useDashboardStore((store) => store.addToast);

  const createTransactionsExport = useCallback(() => {
    triggerTransactionsExport(
      { filters: requestParams.filters },
      {
        onError: () => {
          addToast({
            message: "Failed to create transactions export, please try again.",
            trackingId: TransactionsTableTrackingId.ExportFailureToast,
            variant: "danger",
            timeoutMs: 5000,
          });
        },
        onSuccess: () => {
          addToast({
            message:
              "Transactions export triggered successfully. It will be available in the notifications panel shortly.",
            trackingId: TransactionsTableTrackingId.ExportSuccessToast,
            timeoutMs: 5000,
          });
        },
      }
    );
  }, [triggerTransactionsExport, requestParams, addToast]);

  const filterCount = useMemo(() => {
    const { page, limit, ...urlParamFilters } = urlParams;
    const urlParamCount = Object.values(urlParamFilters).filter(Boolean).length;
    const localFilterCount = Object.values(stateFilters).filter(
      (value) => !!value.trim()
    ).length;
    return urlParamCount + localFilterCount;
  }, [urlParams, stateFilters]);

  return {
    tableProps,
    shouldDisableControls,
    totalCount: meta?.page?.totalCount ?? 0,
    urlParams,
    requestParams,
    updateUrlParams,
    updateStateFilters,
    stateFilters,
    updateFilterParams,
    clearAllFilters,
    createTransactionsExport,
    isCreatingExport,
    isWarningModalOpen,
    setIsWarningModalOpen,
    filterCount,
  };
};

export const {
  Provider: TransactionsTableProvider,
  useContextHook: useTransactionsTableContext,
} = createSingleInstanceHookContext(
  useTransactionsTable,
  "useTransactionsTableContext"
);
