/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/root'
import { Route as CallbackImport } from './routes/callback'
import { Route as AuthedImport } from './routes/authed'
import { Route as IndexImport } from './routes/index'
import { Route as NotificationsImport } from './routes/notifications'
import { Route as IrwLayoutImport } from './routes/irwLayout'
import { Route as SettingsUserManagementImport } from './routes/settings/userManagement'
import { Route as SettingsMyAccountImport } from './routes/settings/myAccount'
import { Route as SettingsInvitationManagementImport } from './routes/settings/invitationManagement'
import { Route as SettingsFilerSettingsImport } from './routes/settings/filerSettings'
import { Route as SettingsDeveloperSettingsImport } from './routes/settings/developerSettings'
import { Route as IrwReportsImport } from './routes/irw/reports'
import { Route as IrwOverviewImport } from './routes/irw/overview'
import { Route as IrwIrSummaryImport } from './routes/irw/irSummary'
import { Route as IrwIrFormsImport } from './routes/irw/irForms'
import { Route as IrwIrEligibilityImport } from './routes/irw/irEligibility'
import { Route as IrwIngestDataImport } from './routes/irw/ingestData'
import { Route as IrwDeleteDataImport } from './routes/irw/deleteData'
import { Route as IrwTransactionsIndexImport } from './routes/irw/transactions/index'
import { Route as IrwAccountsIndexImport } from './routes/irw/accounts/index'
import { Route as IrwTransactionsTransactionDetailsImport } from './routes/irw/transactions/transactionDetails'
import { Route as IrwAccountsAccountDetailsTransactionsImport } from './routes/irw/accounts/account-details/transactions'
import { Route as IrwAccountsAccountDetailsTaxFormsImport } from './routes/irw/accounts/account-details/taxForms'
import { Route as IrwAccountsAccountDetailsTaxDocumentationImport } from './routes/irw/accounts/account-details/taxDocumentation'
import { Route as IrwAccountsAccountDetailsReportingProfileImport } from './routes/irw/accounts/account-details/reportingProfile'
import { Route as IrwAccountsAccountDetailsInventoryImport } from './routes/irw/accounts/account-details/inventory'
import { Route as IrwAccountsAccountDetailsFormDataImport } from './routes/irw/accounts/account-details/formData'
import { Route as IrwAccountsAccountDetailsDetailsImport } from './routes/irw/accounts/account-details/details'
import { Route as IrwAccountsAccountDetailsAccountDetailsImport } from './routes/irw/accounts/account-details/accountDetails'
import { Route as IrwAccountsAccountDetailsTransactionsDetailsImport } from './routes/irw/accounts/account-details/transactionsDetails'

// Create Virtual Routes

const AuthedOOrganizationIdImport = createFileRoute(
  '/_authed/o/$organizationId',
)()
const AuthedOOrganizationIdSettingsImport = createFileRoute(
  '/_authed/o/$organizationId/settings',
)()
const AuthedOOrganizationIdIrwLayoutTransactionsImport = createFileRoute(
  '/_authed/o/$organizationId/_irwLayout/transactions',
)()
const AuthedOOrganizationIdIrwLayoutAccountsImport = createFileRoute(
  '/_authed/o/$organizationId/_irwLayout/accounts',
)()
const AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport = createFileRoute(
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId',
)()

// Create/Update Routes

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthedRoute = AuthedImport.update({
  id: '/_authed',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthedOOrganizationIdRoute = AuthedOOrganizationIdImport.update({
  id: '/o/$organizationId',
  path: '/o/$organizationId',
  getParentRoute: () => AuthedRoute,
} as any)

const AuthedOOrganizationIdSettingsRoute =
  AuthedOOrganizationIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AuthedOOrganizationIdRoute,
  } as any)

const NotificationsRoute = NotificationsImport.update({
  id: '/notifications',
  path: '/notifications',
  getParentRoute: () => AuthedOOrganizationIdRoute,
} as any)

const IrwLayoutRoute = IrwLayoutImport.update({
  id: '/_irwLayout',
  getParentRoute: () => AuthedOOrganizationIdRoute,
} as any)

const SettingsUserManagementRoute = SettingsUserManagementImport.update({
  id: '/user-management',
  path: '/user-management',
  getParentRoute: () => AuthedOOrganizationIdSettingsRoute,
} as any)

const SettingsMyAccountRoute = SettingsMyAccountImport.update({
  id: '/my-account',
  path: '/my-account',
  getParentRoute: () => AuthedOOrganizationIdSettingsRoute,
} as any)

const SettingsInvitationManagementRoute =
  SettingsInvitationManagementImport.update({
    id: '/invitation-management',
    path: '/invitation-management',
    getParentRoute: () => AuthedOOrganizationIdSettingsRoute,
  } as any)

const SettingsFilerSettingsRoute = SettingsFilerSettingsImport.update({
  id: '/filer-settings',
  path: '/filer-settings',
  getParentRoute: () => AuthedOOrganizationIdSettingsRoute,
} as any)

const SettingsDeveloperSettingsRoute = SettingsDeveloperSettingsImport.update({
  id: '/developer-settings',
  path: '/developer-settings',
  getParentRoute: () => AuthedOOrganizationIdSettingsRoute,
} as any)

const AuthedOOrganizationIdIrwLayoutTransactionsRoute =
  AuthedOOrganizationIdIrwLayoutTransactionsImport.update({
    id: '/transactions',
    path: '/transactions',
    getParentRoute: () => IrwLayoutRoute,
  } as any)

const IrwReportsRoute = IrwReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwOverviewRoute = IrwOverviewImport.update({
  id: '/overview',
  path: '/overview',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwIrSummaryRoute = IrwIrSummaryImport.update({
  id: '/ir-summary',
  path: '/ir-summary',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwIrFormsRoute = IrwIrFormsImport.update({
  id: '/ir-forms',
  path: '/ir-forms',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwIrEligibilityRoute = IrwIrEligibilityImport.update({
  id: '/ir-eligibility',
  path: '/ir-eligibility',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwIngestDataRoute = IrwIngestDataImport.update({
  id: '/ingest-data',
  path: '/ingest-data',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const IrwDeleteDataRoute = IrwDeleteDataImport.update({
  id: '/delete-data',
  path: '/delete-data',
  getParentRoute: () => IrwLayoutRoute,
} as any)

const AuthedOOrganizationIdIrwLayoutAccountsRoute =
  AuthedOOrganizationIdIrwLayoutAccountsImport.update({
    id: '/accounts',
    path: '/accounts',
    getParentRoute: () => IrwLayoutRoute,
  } as any)

const IrwTransactionsIndexRoute = IrwTransactionsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedOOrganizationIdIrwLayoutTransactionsRoute,
} as any)

const IrwAccountsIndexRoute = IrwAccountsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsRoute,
} as any)

const AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute =
  AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport.update({
    id: '/$accountId',
    path: '/$accountId',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsRoute,
  } as any)

const IrwTransactionsTransactionDetailsRoute =
  IrwTransactionsTransactionDetailsImport.update({
    id: '/$accountId/$transactionId',
    path: '/$accountId/$transactionId',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutTransactionsRoute,
  } as any)

const IrwAccountsAccountDetailsTransactionsRoute =
  IrwAccountsAccountDetailsTransactionsImport.update({
    id: '/transactions',
    path: '/transactions',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsTaxFormsRoute =
  IrwAccountsAccountDetailsTaxFormsImport.update({
    id: '/tax-forms',
    path: '/tax-forms',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsTaxDocumentationRoute =
  IrwAccountsAccountDetailsTaxDocumentationImport.update({
    id: '/tax-documentation',
    path: '/tax-documentation',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsReportingProfileRoute =
  IrwAccountsAccountDetailsReportingProfileImport.update({
    id: '/reporting-profile',
    path: '/reporting-profile',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsInventoryRoute =
  IrwAccountsAccountDetailsInventoryImport.update({
    id: '/inventory',
    path: '/inventory',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsFormDataRoute =
  IrwAccountsAccountDetailsFormDataImport.update({
    id: '/form-data',
    path: '/form-data',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsDetailsRoute =
  IrwAccountsAccountDetailsDetailsImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsAccountDetailsRoute =
  IrwAccountsAccountDetailsAccountDetailsImport.update({
    id: '/account-details',
    path: '/account-details',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute,
  } as any)

const IrwAccountsAccountDetailsTransactionsDetailsRoute =
  IrwAccountsAccountDetailsTransactionsDetailsImport.update({
    id: '/$accountId_/transactions_/$transactionId',
    path: '/$accountId/transactions/$transactionId',
    getParentRoute: () => AuthedOOrganizationIdIrwLayoutAccountsRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authed': {
      id: '/_authed'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthedImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/_authed/o/$organizationId': {
      id: '/_authed/o/$organizationId'
      path: '/o/$organizationId'
      fullPath: '/o/$organizationId'
      preLoaderRoute: typeof AuthedOOrganizationIdImport
      parentRoute: typeof AuthedImport
    }
    '/_authed/o/$organizationId/_irwLayout': {
      id: '/_authed/o/$organizationId/_irwLayout'
      path: ''
      fullPath: '/o/$organizationId'
      preLoaderRoute: typeof IrwLayoutImport
      parentRoute: typeof AuthedOOrganizationIdImport
    }
    '/_authed/o/$organizationId/notifications': {
      id: '/_authed/o/$organizationId/notifications'
      path: '/notifications'
      fullPath: '/o/$organizationId/notifications'
      preLoaderRoute: typeof NotificationsImport
      parentRoute: typeof AuthedOOrganizationIdImport
    }
    '/_authed/o/$organizationId/settings': {
      id: '/_authed/o/$organizationId/settings'
      path: '/settings'
      fullPath: '/o/$organizationId/settings'
      preLoaderRoute: typeof AuthedOOrganizationIdSettingsImport
      parentRoute: typeof AuthedOOrganizationIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts'
      path: '/accounts'
      fullPath: '/o/$organizationId/accounts'
      preLoaderRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/delete-data': {
      id: '/_authed/o/$organizationId/_irwLayout/delete-data'
      path: '/delete-data'
      fullPath: '/o/$organizationId/delete-data'
      preLoaderRoute: typeof IrwDeleteDataImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/ingest-data': {
      id: '/_authed/o/$organizationId/_irwLayout/ingest-data'
      path: '/ingest-data'
      fullPath: '/o/$organizationId/ingest-data'
      preLoaderRoute: typeof IrwIngestDataImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/ir-eligibility': {
      id: '/_authed/o/$organizationId/_irwLayout/ir-eligibility'
      path: '/ir-eligibility'
      fullPath: '/o/$organizationId/ir-eligibility'
      preLoaderRoute: typeof IrwIrEligibilityImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/ir-forms': {
      id: '/_authed/o/$organizationId/_irwLayout/ir-forms'
      path: '/ir-forms'
      fullPath: '/o/$organizationId/ir-forms'
      preLoaderRoute: typeof IrwIrFormsImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/ir-summary': {
      id: '/_authed/o/$organizationId/_irwLayout/ir-summary'
      path: '/ir-summary'
      fullPath: '/o/$organizationId/ir-summary'
      preLoaderRoute: typeof IrwIrSummaryImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/overview': {
      id: '/_authed/o/$organizationId/_irwLayout/overview'
      path: '/overview'
      fullPath: '/o/$organizationId/overview'
      preLoaderRoute: typeof IrwOverviewImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/reports': {
      id: '/_authed/o/$organizationId/_irwLayout/reports'
      path: '/reports'
      fullPath: '/o/$organizationId/reports'
      preLoaderRoute: typeof IrwReportsImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/_irwLayout/transactions': {
      id: '/_authed/o/$organizationId/_irwLayout/transactions'
      path: '/transactions'
      fullPath: '/o/$organizationId/transactions'
      preLoaderRoute: typeof AuthedOOrganizationIdIrwLayoutTransactionsImport
      parentRoute: typeof IrwLayoutImport
    }
    '/_authed/o/$organizationId/settings/developer-settings': {
      id: '/_authed/o/$organizationId/settings/developer-settings'
      path: '/developer-settings'
      fullPath: '/o/$organizationId/settings/developer-settings'
      preLoaderRoute: typeof SettingsDeveloperSettingsImport
      parentRoute: typeof AuthedOOrganizationIdSettingsImport
    }
    '/_authed/o/$organizationId/settings/filer-settings': {
      id: '/_authed/o/$organizationId/settings/filer-settings'
      path: '/filer-settings'
      fullPath: '/o/$organizationId/settings/filer-settings'
      preLoaderRoute: typeof SettingsFilerSettingsImport
      parentRoute: typeof AuthedOOrganizationIdSettingsImport
    }
    '/_authed/o/$organizationId/settings/invitation-management': {
      id: '/_authed/o/$organizationId/settings/invitation-management'
      path: '/invitation-management'
      fullPath: '/o/$organizationId/settings/invitation-management'
      preLoaderRoute: typeof SettingsInvitationManagementImport
      parentRoute: typeof AuthedOOrganizationIdSettingsImport
    }
    '/_authed/o/$organizationId/settings/my-account': {
      id: '/_authed/o/$organizationId/settings/my-account'
      path: '/my-account'
      fullPath: '/o/$organizationId/settings/my-account'
      preLoaderRoute: typeof SettingsMyAccountImport
      parentRoute: typeof AuthedOOrganizationIdSettingsImport
    }
    '/_authed/o/$organizationId/settings/user-management': {
      id: '/_authed/o/$organizationId/settings/user-management'
      path: '/user-management'
      fullPath: '/o/$organizationId/settings/user-management'
      preLoaderRoute: typeof SettingsUserManagementImport
      parentRoute: typeof AuthedOOrganizationIdSettingsImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId'
      path: '/$accountId'
      fullPath: '/o/$organizationId/accounts/$accountId'
      preLoaderRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/'
      path: '/'
      fullPath: '/o/$organizationId/accounts/'
      preLoaderRoute: typeof IrwAccountsIndexImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsImport
    }
    '/_authed/o/$organizationId/_irwLayout/transactions/': {
      id: '/_authed/o/$organizationId/_irwLayout/transactions/'
      path: '/'
      fullPath: '/o/$organizationId/transactions/'
      preLoaderRoute: typeof IrwTransactionsIndexImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutTransactionsImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details'
      path: '/account-details'
      fullPath: '/o/$organizationId/accounts/$accountId/account-details'
      preLoaderRoute: typeof IrwAccountsAccountDetailsAccountDetailsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details'
      path: '/details'
      fullPath: '/o/$organizationId/accounts/$accountId/details'
      preLoaderRoute: typeof IrwAccountsAccountDetailsDetailsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data'
      path: '/form-data'
      fullPath: '/o/$organizationId/accounts/$accountId/form-data'
      preLoaderRoute: typeof IrwAccountsAccountDetailsFormDataImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory'
      path: '/inventory'
      fullPath: '/o/$organizationId/accounts/$accountId/inventory'
      preLoaderRoute: typeof IrwAccountsAccountDetailsInventoryImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile'
      path: '/reporting-profile'
      fullPath: '/o/$organizationId/accounts/$accountId/reporting-profile'
      preLoaderRoute: typeof IrwAccountsAccountDetailsReportingProfileImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation'
      path: '/tax-documentation'
      fullPath: '/o/$organizationId/accounts/$accountId/tax-documentation'
      preLoaderRoute: typeof IrwAccountsAccountDetailsTaxDocumentationImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms'
      path: '/tax-forms'
      fullPath: '/o/$organizationId/accounts/$accountId/tax-forms'
      preLoaderRoute: typeof IrwAccountsAccountDetailsTaxFormsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions'
      path: '/transactions'
      fullPath: '/o/$organizationId/accounts/$accountId/transactions'
      preLoaderRoute: typeof IrwAccountsAccountDetailsTransactionsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdImport
    }
    '/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId': {
      id: '/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId'
      path: '/$accountId/$transactionId'
      fullPath: '/o/$organizationId/transactions/$accountId/$transactionId'
      preLoaderRoute: typeof IrwTransactionsTransactionDetailsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutTransactionsImport
    }
    '/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId': {
      id: '/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId'
      path: '/$accountId/transactions/$transactionId'
      fullPath: '/o/$organizationId/accounts/$accountId/transactions/$transactionId'
      preLoaderRoute: typeof IrwAccountsAccountDetailsTransactionsDetailsImport
      parentRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsImport
    }
  }
}

// Create and export the route tree

interface AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteChildren {
  IrwAccountsAccountDetailsAccountDetailsRoute: typeof IrwAccountsAccountDetailsAccountDetailsRoute
  IrwAccountsAccountDetailsDetailsRoute: typeof IrwAccountsAccountDetailsDetailsRoute
  IrwAccountsAccountDetailsFormDataRoute: typeof IrwAccountsAccountDetailsFormDataRoute
  IrwAccountsAccountDetailsInventoryRoute: typeof IrwAccountsAccountDetailsInventoryRoute
  IrwAccountsAccountDetailsReportingProfileRoute: typeof IrwAccountsAccountDetailsReportingProfileRoute
  IrwAccountsAccountDetailsTaxDocumentationRoute: typeof IrwAccountsAccountDetailsTaxDocumentationRoute
  IrwAccountsAccountDetailsTaxFormsRoute: typeof IrwAccountsAccountDetailsTaxFormsRoute
  IrwAccountsAccountDetailsTransactionsRoute: typeof IrwAccountsAccountDetailsTransactionsRoute
}

const AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteChildren: AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteChildren =
  {
    IrwAccountsAccountDetailsAccountDetailsRoute:
      IrwAccountsAccountDetailsAccountDetailsRoute,
    IrwAccountsAccountDetailsDetailsRoute:
      IrwAccountsAccountDetailsDetailsRoute,
    IrwAccountsAccountDetailsFormDataRoute:
      IrwAccountsAccountDetailsFormDataRoute,
    IrwAccountsAccountDetailsInventoryRoute:
      IrwAccountsAccountDetailsInventoryRoute,
    IrwAccountsAccountDetailsReportingProfileRoute:
      IrwAccountsAccountDetailsReportingProfileRoute,
    IrwAccountsAccountDetailsTaxDocumentationRoute:
      IrwAccountsAccountDetailsTaxDocumentationRoute,
    IrwAccountsAccountDetailsTaxFormsRoute:
      IrwAccountsAccountDetailsTaxFormsRoute,
    IrwAccountsAccountDetailsTransactionsRoute:
      IrwAccountsAccountDetailsTransactionsRoute,
  }

const AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren =
  AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute._addFileChildren(
    AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteChildren,
  )

interface AuthedOOrganizationIdIrwLayoutAccountsRouteChildren {
  AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren
  IrwAccountsIndexRoute: typeof IrwAccountsIndexRoute
  IrwAccountsAccountDetailsTransactionsDetailsRoute: typeof IrwAccountsAccountDetailsTransactionsDetailsRoute
}

const AuthedOOrganizationIdIrwLayoutAccountsRouteChildren: AuthedOOrganizationIdIrwLayoutAccountsRouteChildren =
  {
    AuthedOOrganizationIdIrwLayoutAccountsAccountIdRoute:
      AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren,
    IrwAccountsIndexRoute: IrwAccountsIndexRoute,
    IrwAccountsAccountDetailsTransactionsDetailsRoute:
      IrwAccountsAccountDetailsTransactionsDetailsRoute,
  }

const AuthedOOrganizationIdIrwLayoutAccountsRouteWithChildren =
  AuthedOOrganizationIdIrwLayoutAccountsRoute._addFileChildren(
    AuthedOOrganizationIdIrwLayoutAccountsRouteChildren,
  )

interface AuthedOOrganizationIdIrwLayoutTransactionsRouteChildren {
  IrwTransactionsIndexRoute: typeof IrwTransactionsIndexRoute
  IrwTransactionsTransactionDetailsRoute: typeof IrwTransactionsTransactionDetailsRoute
}

const AuthedOOrganizationIdIrwLayoutTransactionsRouteChildren: AuthedOOrganizationIdIrwLayoutTransactionsRouteChildren =
  {
    IrwTransactionsIndexRoute: IrwTransactionsIndexRoute,
    IrwTransactionsTransactionDetailsRoute:
      IrwTransactionsTransactionDetailsRoute,
  }

const AuthedOOrganizationIdIrwLayoutTransactionsRouteWithChildren =
  AuthedOOrganizationIdIrwLayoutTransactionsRoute._addFileChildren(
    AuthedOOrganizationIdIrwLayoutTransactionsRouteChildren,
  )

interface IrwLayoutRouteChildren {
  AuthedOOrganizationIdIrwLayoutAccountsRoute: typeof AuthedOOrganizationIdIrwLayoutAccountsRouteWithChildren
  IrwDeleteDataRoute: typeof IrwDeleteDataRoute
  IrwIngestDataRoute: typeof IrwIngestDataRoute
  IrwIrEligibilityRoute: typeof IrwIrEligibilityRoute
  IrwIrFormsRoute: typeof IrwIrFormsRoute
  IrwIrSummaryRoute: typeof IrwIrSummaryRoute
  IrwOverviewRoute: typeof IrwOverviewRoute
  IrwReportsRoute: typeof IrwReportsRoute
  AuthedOOrganizationIdIrwLayoutTransactionsRoute: typeof AuthedOOrganizationIdIrwLayoutTransactionsRouteWithChildren
}

const IrwLayoutRouteChildren: IrwLayoutRouteChildren = {
  AuthedOOrganizationIdIrwLayoutAccountsRoute:
    AuthedOOrganizationIdIrwLayoutAccountsRouteWithChildren,
  IrwDeleteDataRoute: IrwDeleteDataRoute,
  IrwIngestDataRoute: IrwIngestDataRoute,
  IrwIrEligibilityRoute: IrwIrEligibilityRoute,
  IrwIrFormsRoute: IrwIrFormsRoute,
  IrwIrSummaryRoute: IrwIrSummaryRoute,
  IrwOverviewRoute: IrwOverviewRoute,
  IrwReportsRoute: IrwReportsRoute,
  AuthedOOrganizationIdIrwLayoutTransactionsRoute:
    AuthedOOrganizationIdIrwLayoutTransactionsRouteWithChildren,
}

const IrwLayoutRouteWithChildren = IrwLayoutRoute._addFileChildren(
  IrwLayoutRouteChildren,
)

interface AuthedOOrganizationIdSettingsRouteChildren {
  SettingsDeveloperSettingsRoute: typeof SettingsDeveloperSettingsRoute
  SettingsFilerSettingsRoute: typeof SettingsFilerSettingsRoute
  SettingsInvitationManagementRoute: typeof SettingsInvitationManagementRoute
  SettingsMyAccountRoute: typeof SettingsMyAccountRoute
  SettingsUserManagementRoute: typeof SettingsUserManagementRoute
}

const AuthedOOrganizationIdSettingsRouteChildren: AuthedOOrganizationIdSettingsRouteChildren =
  {
    SettingsDeveloperSettingsRoute: SettingsDeveloperSettingsRoute,
    SettingsFilerSettingsRoute: SettingsFilerSettingsRoute,
    SettingsInvitationManagementRoute: SettingsInvitationManagementRoute,
    SettingsMyAccountRoute: SettingsMyAccountRoute,
    SettingsUserManagementRoute: SettingsUserManagementRoute,
  }

const AuthedOOrganizationIdSettingsRouteWithChildren =
  AuthedOOrganizationIdSettingsRoute._addFileChildren(
    AuthedOOrganizationIdSettingsRouteChildren,
  )

interface AuthedOOrganizationIdRouteChildren {
  IrwLayoutRoute: typeof IrwLayoutRouteWithChildren
  NotificationsRoute: typeof NotificationsRoute
  AuthedOOrganizationIdSettingsRoute: typeof AuthedOOrganizationIdSettingsRouteWithChildren
}

const AuthedOOrganizationIdRouteChildren: AuthedOOrganizationIdRouteChildren = {
  IrwLayoutRoute: IrwLayoutRouteWithChildren,
  NotificationsRoute: NotificationsRoute,
  AuthedOOrganizationIdSettingsRoute:
    AuthedOOrganizationIdSettingsRouteWithChildren,
}

const AuthedOOrganizationIdRouteWithChildren =
  AuthedOOrganizationIdRoute._addFileChildren(
    AuthedOOrganizationIdRouteChildren,
  )

interface AuthedRouteChildren {
  AuthedOOrganizationIdRoute: typeof AuthedOOrganizationIdRouteWithChildren
}

const AuthedRouteChildren: AuthedRouteChildren = {
  AuthedOOrganizationIdRoute: AuthedOOrganizationIdRouteWithChildren,
}

const AuthedRouteWithChildren =
  AuthedRoute._addFileChildren(AuthedRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/o/$organizationId': typeof IrwLayoutRouteWithChildren
  '/o/$organizationId/notifications': typeof NotificationsRoute
  '/o/$organizationId/settings': typeof AuthedOOrganizationIdSettingsRouteWithChildren
  '/o/$organizationId/accounts': typeof AuthedOOrganizationIdIrwLayoutAccountsRouteWithChildren
  '/o/$organizationId/delete-data': typeof IrwDeleteDataRoute
  '/o/$organizationId/ingest-data': typeof IrwIngestDataRoute
  '/o/$organizationId/ir-eligibility': typeof IrwIrEligibilityRoute
  '/o/$organizationId/ir-forms': typeof IrwIrFormsRoute
  '/o/$organizationId/ir-summary': typeof IrwIrSummaryRoute
  '/o/$organizationId/overview': typeof IrwOverviewRoute
  '/o/$organizationId/reports': typeof IrwReportsRoute
  '/o/$organizationId/transactions': typeof AuthedOOrganizationIdIrwLayoutTransactionsRouteWithChildren
  '/o/$organizationId/settings/developer-settings': typeof SettingsDeveloperSettingsRoute
  '/o/$organizationId/settings/filer-settings': typeof SettingsFilerSettingsRoute
  '/o/$organizationId/settings/invitation-management': typeof SettingsInvitationManagementRoute
  '/o/$organizationId/settings/my-account': typeof SettingsMyAccountRoute
  '/o/$organizationId/settings/user-management': typeof SettingsUserManagementRoute
  '/o/$organizationId/accounts/$accountId': typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren
  '/o/$organizationId/accounts/': typeof IrwAccountsIndexRoute
  '/o/$organizationId/transactions/': typeof IrwTransactionsIndexRoute
  '/o/$organizationId/accounts/$accountId/account-details': typeof IrwAccountsAccountDetailsAccountDetailsRoute
  '/o/$organizationId/accounts/$accountId/details': typeof IrwAccountsAccountDetailsDetailsRoute
  '/o/$organizationId/accounts/$accountId/form-data': typeof IrwAccountsAccountDetailsFormDataRoute
  '/o/$organizationId/accounts/$accountId/inventory': typeof IrwAccountsAccountDetailsInventoryRoute
  '/o/$organizationId/accounts/$accountId/reporting-profile': typeof IrwAccountsAccountDetailsReportingProfileRoute
  '/o/$organizationId/accounts/$accountId/tax-documentation': typeof IrwAccountsAccountDetailsTaxDocumentationRoute
  '/o/$organizationId/accounts/$accountId/tax-forms': typeof IrwAccountsAccountDetailsTaxFormsRoute
  '/o/$organizationId/accounts/$accountId/transactions': typeof IrwAccountsAccountDetailsTransactionsRoute
  '/o/$organizationId/transactions/$accountId/$transactionId': typeof IrwTransactionsTransactionDetailsRoute
  '/o/$organizationId/accounts/$accountId/transactions/$transactionId': typeof IrwAccountsAccountDetailsTransactionsDetailsRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/o/$organizationId': typeof IrwLayoutRouteWithChildren
  '/o/$organizationId/notifications': typeof NotificationsRoute
  '/o/$organizationId/settings': typeof AuthedOOrganizationIdSettingsRouteWithChildren
  '/o/$organizationId/delete-data': typeof IrwDeleteDataRoute
  '/o/$organizationId/ingest-data': typeof IrwIngestDataRoute
  '/o/$organizationId/ir-eligibility': typeof IrwIrEligibilityRoute
  '/o/$organizationId/ir-forms': typeof IrwIrFormsRoute
  '/o/$organizationId/ir-summary': typeof IrwIrSummaryRoute
  '/o/$organizationId/overview': typeof IrwOverviewRoute
  '/o/$organizationId/reports': typeof IrwReportsRoute
  '/o/$organizationId/settings/developer-settings': typeof SettingsDeveloperSettingsRoute
  '/o/$organizationId/settings/filer-settings': typeof SettingsFilerSettingsRoute
  '/o/$organizationId/settings/invitation-management': typeof SettingsInvitationManagementRoute
  '/o/$organizationId/settings/my-account': typeof SettingsMyAccountRoute
  '/o/$organizationId/settings/user-management': typeof SettingsUserManagementRoute
  '/o/$organizationId/accounts/$accountId': typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren
  '/o/$organizationId/accounts': typeof IrwAccountsIndexRoute
  '/o/$organizationId/transactions': typeof IrwTransactionsIndexRoute
  '/o/$organizationId/accounts/$accountId/account-details': typeof IrwAccountsAccountDetailsAccountDetailsRoute
  '/o/$organizationId/accounts/$accountId/details': typeof IrwAccountsAccountDetailsDetailsRoute
  '/o/$organizationId/accounts/$accountId/form-data': typeof IrwAccountsAccountDetailsFormDataRoute
  '/o/$organizationId/accounts/$accountId/inventory': typeof IrwAccountsAccountDetailsInventoryRoute
  '/o/$organizationId/accounts/$accountId/reporting-profile': typeof IrwAccountsAccountDetailsReportingProfileRoute
  '/o/$organizationId/accounts/$accountId/tax-documentation': typeof IrwAccountsAccountDetailsTaxDocumentationRoute
  '/o/$organizationId/accounts/$accountId/tax-forms': typeof IrwAccountsAccountDetailsTaxFormsRoute
  '/o/$organizationId/accounts/$accountId/transactions': typeof IrwAccountsAccountDetailsTransactionsRoute
  '/o/$organizationId/transactions/$accountId/$transactionId': typeof IrwTransactionsTransactionDetailsRoute
  '/o/$organizationId/accounts/$accountId/transactions/$transactionId': typeof IrwAccountsAccountDetailsTransactionsDetailsRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authed': typeof AuthedRouteWithChildren
  '/callback': typeof CallbackRoute
  '/_authed/o/$organizationId': typeof AuthedOOrganizationIdRouteWithChildren
  '/_authed/o/$organizationId/_irwLayout': typeof IrwLayoutRouteWithChildren
  '/_authed/o/$organizationId/notifications': typeof NotificationsRoute
  '/_authed/o/$organizationId/settings': typeof AuthedOOrganizationIdSettingsRouteWithChildren
  '/_authed/o/$organizationId/_irwLayout/accounts': typeof AuthedOOrganizationIdIrwLayoutAccountsRouteWithChildren
  '/_authed/o/$organizationId/_irwLayout/delete-data': typeof IrwDeleteDataRoute
  '/_authed/o/$organizationId/_irwLayout/ingest-data': typeof IrwIngestDataRoute
  '/_authed/o/$organizationId/_irwLayout/ir-eligibility': typeof IrwIrEligibilityRoute
  '/_authed/o/$organizationId/_irwLayout/ir-forms': typeof IrwIrFormsRoute
  '/_authed/o/$organizationId/_irwLayout/ir-summary': typeof IrwIrSummaryRoute
  '/_authed/o/$organizationId/_irwLayout/overview': typeof IrwOverviewRoute
  '/_authed/o/$organizationId/_irwLayout/reports': typeof IrwReportsRoute
  '/_authed/o/$organizationId/_irwLayout/transactions': typeof AuthedOOrganizationIdIrwLayoutTransactionsRouteWithChildren
  '/_authed/o/$organizationId/settings/developer-settings': typeof SettingsDeveloperSettingsRoute
  '/_authed/o/$organizationId/settings/filer-settings': typeof SettingsFilerSettingsRoute
  '/_authed/o/$organizationId/settings/invitation-management': typeof SettingsInvitationManagementRoute
  '/_authed/o/$organizationId/settings/my-account': typeof SettingsMyAccountRoute
  '/_authed/o/$organizationId/settings/user-management': typeof SettingsUserManagementRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId': typeof AuthedOOrganizationIdIrwLayoutAccountsAccountIdRouteWithChildren
  '/_authed/o/$organizationId/_irwLayout/accounts/': typeof IrwAccountsIndexRoute
  '/_authed/o/$organizationId/_irwLayout/transactions/': typeof IrwTransactionsIndexRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details': typeof IrwAccountsAccountDetailsAccountDetailsRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details': typeof IrwAccountsAccountDetailsDetailsRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data': typeof IrwAccountsAccountDetailsFormDataRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory': typeof IrwAccountsAccountDetailsInventoryRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile': typeof IrwAccountsAccountDetailsReportingProfileRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation': typeof IrwAccountsAccountDetailsTaxDocumentationRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms': typeof IrwAccountsAccountDetailsTaxFormsRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions': typeof IrwAccountsAccountDetailsTransactionsRoute
  '/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId': typeof IrwTransactionsTransactionDetailsRoute
  '/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId': typeof IrwAccountsAccountDetailsTransactionsDetailsRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/callback'
    | '/o/$organizationId'
    | '/o/$organizationId/notifications'
    | '/o/$organizationId/settings'
    | '/o/$organizationId/accounts'
    | '/o/$organizationId/delete-data'
    | '/o/$organizationId/ingest-data'
    | '/o/$organizationId/ir-eligibility'
    | '/o/$organizationId/ir-forms'
    | '/o/$organizationId/ir-summary'
    | '/o/$organizationId/overview'
    | '/o/$organizationId/reports'
    | '/o/$organizationId/transactions'
    | '/o/$organizationId/settings/developer-settings'
    | '/o/$organizationId/settings/filer-settings'
    | '/o/$organizationId/settings/invitation-management'
    | '/o/$organizationId/settings/my-account'
    | '/o/$organizationId/settings/user-management'
    | '/o/$organizationId/accounts/$accountId'
    | '/o/$organizationId/accounts/'
    | '/o/$organizationId/transactions/'
    | '/o/$organizationId/accounts/$accountId/account-details'
    | '/o/$organizationId/accounts/$accountId/details'
    | '/o/$organizationId/accounts/$accountId/form-data'
    | '/o/$organizationId/accounts/$accountId/inventory'
    | '/o/$organizationId/accounts/$accountId/reporting-profile'
    | '/o/$organizationId/accounts/$accountId/tax-documentation'
    | '/o/$organizationId/accounts/$accountId/tax-forms'
    | '/o/$organizationId/accounts/$accountId/transactions'
    | '/o/$organizationId/transactions/$accountId/$transactionId'
    | '/o/$organizationId/accounts/$accountId/transactions/$transactionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/callback'
    | '/o/$organizationId'
    | '/o/$organizationId/notifications'
    | '/o/$organizationId/settings'
    | '/o/$organizationId/delete-data'
    | '/o/$organizationId/ingest-data'
    | '/o/$organizationId/ir-eligibility'
    | '/o/$organizationId/ir-forms'
    | '/o/$organizationId/ir-summary'
    | '/o/$organizationId/overview'
    | '/o/$organizationId/reports'
    | '/o/$organizationId/settings/developer-settings'
    | '/o/$organizationId/settings/filer-settings'
    | '/o/$organizationId/settings/invitation-management'
    | '/o/$organizationId/settings/my-account'
    | '/o/$organizationId/settings/user-management'
    | '/o/$organizationId/accounts/$accountId'
    | '/o/$organizationId/accounts'
    | '/o/$organizationId/transactions'
    | '/o/$organizationId/accounts/$accountId/account-details'
    | '/o/$organizationId/accounts/$accountId/details'
    | '/o/$organizationId/accounts/$accountId/form-data'
    | '/o/$organizationId/accounts/$accountId/inventory'
    | '/o/$organizationId/accounts/$accountId/reporting-profile'
    | '/o/$organizationId/accounts/$accountId/tax-documentation'
    | '/o/$organizationId/accounts/$accountId/tax-forms'
    | '/o/$organizationId/accounts/$accountId/transactions'
    | '/o/$organizationId/transactions/$accountId/$transactionId'
    | '/o/$organizationId/accounts/$accountId/transactions/$transactionId'
  id:
    | '__root__'
    | '/'
    | '/_authed'
    | '/callback'
    | '/_authed/o/$organizationId'
    | '/_authed/o/$organizationId/_irwLayout'
    | '/_authed/o/$organizationId/notifications'
    | '/_authed/o/$organizationId/settings'
    | '/_authed/o/$organizationId/_irwLayout/accounts'
    | '/_authed/o/$organizationId/_irwLayout/delete-data'
    | '/_authed/o/$organizationId/_irwLayout/ingest-data'
    | '/_authed/o/$organizationId/_irwLayout/ir-eligibility'
    | '/_authed/o/$organizationId/_irwLayout/ir-forms'
    | '/_authed/o/$organizationId/_irwLayout/ir-summary'
    | '/_authed/o/$organizationId/_irwLayout/overview'
    | '/_authed/o/$organizationId/_irwLayout/reports'
    | '/_authed/o/$organizationId/_irwLayout/transactions'
    | '/_authed/o/$organizationId/settings/developer-settings'
    | '/_authed/o/$organizationId/settings/filer-settings'
    | '/_authed/o/$organizationId/settings/invitation-management'
    | '/_authed/o/$organizationId/settings/my-account'
    | '/_authed/o/$organizationId/settings/user-management'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId'
    | '/_authed/o/$organizationId/_irwLayout/accounts/'
    | '/_authed/o/$organizationId/_irwLayout/transactions/'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions'
    | '/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId'
    | '/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthedRoute: typeof AuthedRouteWithChildren
  CallbackRoute: typeof CallbackRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthedRoute: AuthedRouteWithChildren,
  CallbackRoute: CallbackRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "./root.tsx",
      "children": [
        "/",
        "/_authed",
        "/callback"
      ]
    },
    "/": {
      "filePath": "./index.ts"
    },
    "/_authed": {
      "filePath": "./authed.ts",
      "children": [
        "/_authed/o/$organizationId"
      ]
    },
    "/callback": {
      "filePath": "./callback.ts"
    },
    "/_authed/o/$organizationId": {
      "filePath": "",
      "parent": "/_authed",
      "children": [
        "/_authed/o/$organizationId/_irwLayout",
        "/_authed/o/$organizationId/notifications",
        "/_authed/o/$organizationId/settings"
      ]
    },
    "/_authed/o/$organizationId/_irwLayout": {
      "filePath": "./irwLayout.ts",
      "parent": "/_authed/o/$organizationId",
      "children": [
        "/_authed/o/$organizationId/_irwLayout/accounts",
        "/_authed/o/$organizationId/_irwLayout/delete-data",
        "/_authed/o/$organizationId/_irwLayout/ingest-data",
        "/_authed/o/$organizationId/_irwLayout/ir-eligibility",
        "/_authed/o/$organizationId/_irwLayout/ir-forms",
        "/_authed/o/$organizationId/_irwLayout/ir-summary",
        "/_authed/o/$organizationId/_irwLayout/overview",
        "/_authed/o/$organizationId/_irwLayout/reports",
        "/_authed/o/$organizationId/_irwLayout/transactions"
      ]
    },
    "/_authed/o/$organizationId/notifications": {
      "filePath": "./notifications.ts",
      "parent": "/_authed/o/$organizationId"
    },
    "/_authed/o/$organizationId/settings": {
      "filePath": "",
      "parent": "/_authed/o/$organizationId",
      "children": [
        "/_authed/o/$organizationId/settings/developer-settings",
        "/_authed/o/$organizationId/settings/filer-settings",
        "/_authed/o/$organizationId/settings/invitation-management",
        "/_authed/o/$organizationId/settings/my-account",
        "/_authed/o/$organizationId/settings/user-management"
      ]
    },
    "/_authed/o/$organizationId/_irwLayout/accounts": {
      "filePath": "",
      "parent": "/_authed/o/$organizationId/_irwLayout",
      "children": [
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId",
        "/_authed/o/$organizationId/_irwLayout/accounts/",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId"
      ]
    },
    "/_authed/o/$organizationId/_irwLayout/delete-data": {
      "filePath": "./irw/deleteData.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/ingest-data": {
      "filePath": "./irw/ingestData.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/ir-eligibility": {
      "filePath": "./irw/irEligibility.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/ir-forms": {
      "filePath": "./irw/irForms.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/ir-summary": {
      "filePath": "./irw/irSummary.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/overview": {
      "filePath": "./irw/overview.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/reports": {
      "filePath": "./irw/reports.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout"
    },
    "/_authed/o/$organizationId/_irwLayout/transactions": {
      "filePath": "",
      "parent": "/_authed/o/$organizationId/_irwLayout",
      "children": [
        "/_authed/o/$organizationId/_irwLayout/transactions/",
        "/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId"
      ]
    },
    "/_authed/o/$organizationId/settings/developer-settings": {
      "filePath": "./settings/developerSettings.ts",
      "parent": "/_authed/o/$organizationId/settings"
    },
    "/_authed/o/$organizationId/settings/filer-settings": {
      "filePath": "./settings/filerSettings.ts",
      "parent": "/_authed/o/$organizationId/settings"
    },
    "/_authed/o/$organizationId/settings/invitation-management": {
      "filePath": "./settings/invitationManagement.ts",
      "parent": "/_authed/o/$organizationId/settings"
    },
    "/_authed/o/$organizationId/settings/my-account": {
      "filePath": "./settings/myAccount.ts",
      "parent": "/_authed/o/$organizationId/settings"
    },
    "/_authed/o/$organizationId/settings/user-management": {
      "filePath": "./settings/userManagement.ts",
      "parent": "/_authed/o/$organizationId/settings"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId": {
      "filePath": "",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts",
      "children": [
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms",
        "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions"
      ]
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/": {
      "filePath": "./irw/accounts/index.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts"
    },
    "/_authed/o/$organizationId/_irwLayout/transactions/": {
      "filePath": "./irw/transactions/index.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/transactions"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/account-details": {
      "filePath": "./irw/accounts/account-details/accountDetails.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/details": {
      "filePath": "./irw/accounts/account-details/details.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/form-data": {
      "filePath": "./irw/accounts/account-details/formData.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/inventory": {
      "filePath": "./irw/accounts/account-details/inventory.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/reporting-profile": {
      "filePath": "./irw/accounts/account-details/reportingProfile.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-documentation": {
      "filePath": "./irw/accounts/account-details/taxDocumentation.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/tax-forms": {
      "filePath": "./irw/accounts/account-details/taxForms.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId/transactions": {
      "filePath": "./irw/accounts/account-details/transactions.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts/$accountId"
    },
    "/_authed/o/$organizationId/_irwLayout/transactions/$accountId/$transactionId": {
      "filePath": "./irw/transactions/transactionDetails.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/transactions"
    },
    "/_authed/o/$organizationId/_irwLayout/accounts/$accountId_/transactions_/$transactionId": {
      "filePath": "./irw/accounts/account-details/transactionsDetails.ts",
      "parent": "/_authed/o/$organizationId/_irwLayout/accounts"
    }
  }
}
ROUTE_MANIFEST_END */
