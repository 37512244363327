import { isDefined } from "@taxbit-dashboard/commons";
import {
  Card,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
  ErrorEmptyState,
  Flex,
  InlineSpinner,
} from "@taxbit-private/cosmic";

import useGetTransactionsTab from "./useGetTransactionsTab";
import EntityNotFound from "../../EntityNotFound";
import DownloadTransactionsCsvButton from "./csv/DownloadTransactionsCsvButton";
import TransactionsTable from "./table/TransactionsTable";

export enum TransactionsTabTrackingId {
  LoadingSpinner = "transactions-tab-loading-spinner",
  NextPageLoadingSpinner = "transactions-tab-next-page-loading-spinner",
}

const TransactionsTab = () => {
  const {
    isLoading,
    transactions,
    isAccountNotFoundError,
    sentryRef,
    isError,
    accountId,
    isFetchingNextPage,
  } = useGetTransactionsTab();

  return (
    <Card
      title="Transactions"
      {...(!isError &&
        isDefined(transactions) &&
        transactions.length > 0 && {
          utilityElement: <DownloadTransactionsCsvButton />,
        })}
    >
      {isLoading ? (
        <ContentSpinner trackingId={TransactionsTabTrackingId.LoadingSpinner} />
      ) : isError && isAccountNotFoundError ? (
        <EntityNotFound entity="Account" />
      ) : isError && !transactions ? (
        <ContentErrorEmptyState entity="transactions" />
      ) : !transactions || transactions.length === 0 ? (
        <ContentEntityEmptyState entity="transactions" />
      ) : (
        <Flex direction="column">
          <TransactionsTable accountId={accountId} rows={transactions} />
          {isFetchingNextPage && (
            <Flex padding="contentPadding" justifyContent="center">
              <InlineSpinner
                trackingId={TransactionsTabTrackingId.NextPageLoadingSpinner}
              />
            </Flex>
          )}
          {isError && !isFetchingNextPage && (
            <ErrorEmptyState entity="transactions data" />
          )}
          <div ref={sentryRef} />
        </Flex>
      )}
    </Card>
  );
};

export default TransactionsTab;
