import { zodResolver } from "@hookform/resolvers/zod";
import {
  doesResponseErrorMatchStatusCode,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGetAccountByExternalId } from "../../../api/account/accountApi";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import useNavigateToAccountDetails from "../../accounts/account-details/useNavigateToAccountDetails";

export enum AccountLookupTrackingId {
  ErrorToast = "account-lookup-error-toast",
  Input = "account-lookup-input",
  SubmitButton = "account-lookup-submit-button",
}

const accountLookupFormFieldSchema = z.object({
  externalId: z.string().min(1, { message: "Please enter an account ID." }),
});

export type AccountLookupFormFields = z.infer<
  typeof accountLookupFormFieldSchema
>;

const useAccountLookupForm = () => {
  const [isAccountNotFoundModalOpen, setIsAccountNotFoundModalOpen] =
    useState(false);

  const addToast = useDashboardStore((store) => store.addToast);

  const navigateToAccountDetails = useNavigateToAccountDetails();

  const { mutate: getAccountByExternalId, isLoading } =
    useGetAccountByExternalId();

  const formMethods = useForm({
    resolver: zodResolver(accountLookupFormFieldSchema),
    defaultValues: {
      externalId: "",
    },
  });

  const onSubmit = useCallback(
    (e: React.FormEvent) => {
      void formMethods.handleSubmit(({ externalId }) => {
        getAccountByExternalId(externalId, {
          onSuccess: ({ data }) => {
            const internalId = data.accountId;
            navigateToAccountDetails(internalId);
          },
          onError: (error) => {
            const isAccountNotFoundError = doesResponseErrorMatchStatusCode(
              error,
              404
            );

            if (isAccountNotFoundError) {
              setIsAccountNotFoundModalOpen(true);
              formMethods.setError("externalId", { type: "custom" });
            } else {
              addToast({
                message:
                  "Something went wrong when fetching that ID. Please try again.",
                timeoutMs: TOAST_TIMEOUT,
                trackingId: AccountLookupTrackingId.ErrorToast,
                variant: "danger",
              });
            }
          },
        });
      })(e);
    },
    [addToast, navigateToAccountDetails, formMethods, getAccountByExternalId]
  );

  return {
    formMethods,
    isAccountNotFoundModalOpen,
    setIsAccountNotFoundModalOpen,
    isLoading,
    onSubmit,
  };
};

export default useAccountLookupForm;
