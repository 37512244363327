import { createSingleInstanceHookContext } from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";

import useFileUploader from "./useFileUploader";

const useDeleteFileUploader = () => {
  return useFileUploader(FileAction.Delete);
};

export default useDeleteFileUploader;

export const {
  useContextHook: useDeleteFileUploaderContext,
  Provider: DeleteFileUploaderContextProvider,
} = createSingleInstanceHookContext(
  useDeleteFileUploader,
  "useDeleteFileUploader"
);
