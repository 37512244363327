import { useEffect } from "react";

import Router from "./routing/Router";

const startMockWorker = async () => {
  if (import.meta.env.DEV) {
    const { setupWorker } = await import("msw");
    const { runtimeHandlers } = await import("@taxbit-dashboard/mocks");
    const MockWorker = setupWorker(...runtimeHandlers);
    await MockWorker.start({
      onUnhandledRequest: "bypass",
    });
  }
};

const App: React.FC = () => {
  useEffect(() => {
    void startMockWorker();

    // window.location.assign triggers beforeunload
    // this causes worker to cancel and unregister client
    // we need to start it once again
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener("beforeunload", startMockWorker, true);
    return () =>
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.removeEventListener("beforeunload", startMockWorker, true);
  }, []);

  return <Router />;
};

export default App;
