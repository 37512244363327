import {
  AuthorizedView,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  DashboardPrimaryTemplate,
  MissingDashboardConfigEmptyState,
  UserPermission,
} from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";
import { RouteId } from "@taxbit-dashboard/router";
import { Flex } from "@taxbit-private/cosmic";

import { useIngestFileUploaderContext } from "./context/useIngestFileUploader";
import { deleteDataNavigationItem } from "./DeleteData.page";
import FilesTableCard from "./file-uploader/files-table-card/FilesTableCard";
import FileUploaderFormCard from "./file-uploader/FileUploaderFormCard";

const ingestDataNavigationItem: DashboardNavigationItemChild = {
  routeId: RouteId.IngestData,
  label: "Ingest",
  trackingId: "sidebar-navigation-ingest-file-uploader-anchor",
  key: "ingest-file-uploader",
} as const;

/**
 * This parent item will only live here temporarily, as the refreshed
 * navigation will have only top-level navigation items.
 */
export const manageDataNavigationItem: DashboardNavigationItemWithChildren = {
  iconName: "settings",
  label: "Manage Data",
  trackingId: "sidebar-navigation-manage-data-anchor",
  shouldShowRoute: ({ permissions, flags }) =>
    permissions[UserPermission.UploadFiles] && flags.hasIngestionModuleAccess,
  children: [ingestDataNavigationItem, deleteDataNavigationItem],
} as const;

const IngestFileUploader = () => {
  const { hasIngestionTemplates } = useIngestFileUploaderContext();

  return (
    <AuthorizedView
      flags={["hasIngestionModuleAccess"]}
      permissions={[UserPermission.UploadFiles]}
    >
      <DashboardPrimaryTemplate title="Ingest Data">
        {hasIngestionTemplates ? (
          <Flex direction="column" gap="l">
            <FileUploaderFormCard action={FileAction.Ingest} />
            <FilesTableCard action={FileAction.Ingest} />
          </Flex>
        ) : (
          <MissingDashboardConfigEmptyState />
        )}
      </DashboardPrimaryTemplate>
    </AuthorizedView>
  );
};

export default IngestFileUploader;
