import {
  useUserPermission,
  UserPermission,
  useDashboardStore,
  NavigationHistoryContextProvider,
} from "@taxbit-dashboard/commons";
import {
  DeleteFileUploaderContextProvider,
  IngestFileUploaderContextProvider,
} from "@taxbit-dashboard/irw";
import {
  Navigate,
  Outlet,
  RouteId,
  useAuthenticatedNavigation,
  useIsIndexRoute,
} from "@taxbit-dashboard/router";

import useIdentifyUserInVendors from "./useIdentifyUserInVendors";
import { NotificationsContextProvider } from "../../notifications/useNotificationsContext";
import AppLevelSpinner from "../navigation/AppLevelSpinner";
import TopNavigation from "../navigation/top-navigation/TopNavigation";
import { useUpdateZendeskUrl } from "../vendors/zendesk";

/**
 * This component is the main entry point for the authenticated user experience. It must always be
 * rendered inside of the `AuthedRouteWrapper` to ensure that none of the hooks triggered by this
 * component are called before the user is authenticated via Auth0. This component is responsible
 * for intializing vendors with the authenticated user info as well as redirecting the user to
 * an appropriate landing page based on their permissions if they are not directly navigating to a
 * particular page.
 */
const AuthedRoute = () => {
  const isUserIdentified = useIdentifyUserInVendors();
  const organizationId = useDashboardStore((store) => store.authOrganizationId);

  const { getAuthenticatedNavigateProps } = useAuthenticatedNavigation({
    organizationId,
  });
  const hasReadAccountsPermission = useUserPermission(
    UserPermission.ReadAccounts
  );

  const isIndexRoute = useIsIndexRoute();

  /**
   * Ensures we are passing page URL changes to the Zendesk widget for help
   * articles to be properly contextualized.
   */
  useUpdateZendeskUrl();

  if (!isUserIdentified) {
    return <AppLevelSpinner trackingId="authed-route-spinner" />;
  }

  /**
   * If we've navigated to a route that has children, display the top navigation and the child
   * route along with all of the necessary providers.
   *
   * Note: The Ingest/Delete context providers live at this level so that large file uploads
   * are not interrupted by route changes. Generally, page-specific context providers
   * should be wrapped around the top-level component for each page.
   */
  if (!isIndexRoute) {
    return (
      <>
        <NotificationsContextProvider>
          <TopNavigation />
          <IngestFileUploaderContextProvider>
            <DeleteFileUploaderContextProvider>
              <NavigationHistoryContextProvider>
                <Outlet />
              </NavigationHistoryContextProvider>
            </DeleteFileUploaderContextProvider>
          </IngestFileUploaderContextProvider>
        </NotificationsContextProvider>
      </>
    );
  } else if (hasReadAccountsPermission) {
    return (
      <Navigate {...getAuthenticatedNavigateProps({ to: RouteId.Overview })} />
    );
  } else {
    return (
      <Navigate
        {...getAuthenticatedNavigateProps({ to: RouteId.UserManagement })}
      />
    );
  }
};

export default AuthedRoute;
