import { utcDateStringSchema } from "@taxbit-private/cosmic";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const transferInLotItemSchema = z.object({
  costBasis: z.string(),
  createdDateTime: utcDateStringSchema.optional(),
  acquisitionTransactionDatetime: utcDateStringSchema.optional(),
  modifiedDateTime: utcDateStringSchema.optional(),
  quantity: z.string().optional(),
});

export type TransferInLotItem = z.infer<typeof transferInLotItemSchema>;

export const getTransferInLotsSchema = getPublicApiSuccessSchema(
  transferInLotItemSchema.array()
);

export type GetTransferInLots = z.infer<typeof getTransferInLotsSchema>;
