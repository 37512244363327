import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { FileType } from "@taxbit-dashboard/rest";
import { useCallback } from "react";

import useFormFeatureFlagData from "../../information-reporting/shared/form-year-dropdowns/useFormFeatureFlagData";

const useTemplatesFeatureFlagData = () => {
  const formData = useFormFeatureFlagData({
    featureFlag: "ingestionModuleYearsByFormTemplate",
  });
  const {
    ingestionModuleAccountsTemplates: accountsTemplates,
    ingestionModuleTransactionsTemplates: transactionsTemplates,
  } = useDashboardFeatureFlags();

  const getTemplatesForFileType = useCallback(
    (fileType: FileType) => {
      switch (fileType) {
        case FileType.Accounts: {
          return accountsTemplates;
        }
        case FileType.Forms:
        case FileType.DeleteForms: {
          return formData.forms;
        }
        case FileType.Transactions: {
          return transactionsTemplates;
        }
        default: {
          return [];
        }
      }
    },
    [accountsTemplates, formData.forms, transactionsTemplates]
  );

  const hasMultipleTemplatesForFileType = useCallback(
    (fileType: FileType) => getTemplatesForFileType(fileType).length > 1,
    [getTemplatesForFileType]
  );

  return {
    ...formData,
    accountsTemplates,
    transactionsTemplates,
    getTemplatesForFileType,
    hasMultipleTemplatesForFileType,
  };
};

export default useTemplatesFeatureFlagData;
