import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { MAX_SEARCH_VALUE_LENGTH } from "@taxbit-dashboard/router";
import { Button, ScopedSearchBar } from "@taxbit-private/cosmic";
import { useMemo, useState } from "react";
import styled from "styled-components";

import {
  FormsSearchParamKey,
  formsSearchParamKeyLabelMap,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../context/useIrForms";

export enum FormsScopedSearchBarTrackingId {
  SearchBar = "ir-forms-scoped-search-bar",
  SearchButton = "ir-forms-scoped-search-button",
}

const searchKeyToTooltipContentMap: Record<FormsSearchParamKey, string> = {
  name: "name",
  accountExternalId: "account ID",
  accountAlternateExternalId: "alternate account ID",
};

type SearchKeyOption = {
  key: FormsSearchParamKey;
  label: string;
};

const DEFAULT_OPTIONS = [
  {
    key: "accountExternalId",
    label: formsSearchParamKeyLabelMap.accountExternalId,
  },
  { key: "name", label: formsSearchParamKeyLabelMap.name },
] as const;

const ALT_ID_OPTION: SearchKeyOption = {
  key: "accountAlternateExternalId",
  label: formsSearchParamKeyLabelMap.accountAlternateExternalId,
};

const FormsScopedSearchBar: React.FC = () => {
  const { updateFilterParams, urlParams, shouldDisableControls } =
    useIrFormsContext();

  const { showAlternateExternalId } = useDashboardFeatureFlags();

  const [searchKey, setSearchKey] = useState<SearchKeyOption>(
    DEFAULT_OPTIONS[0]
  );

  const [searchValue, setSearchValue] = useState("");

  const trimmedValue = searchValue.trim();
  const isValueEmpty = !trimmedValue;
  const isValueTooLong = trimmedValue.length > MAX_SEARCH_VALUE_LENGTH;
  const hasAlreadyAddedFilter = !!urlParams[searchKey.key];

  const isSubmitDisabled =
    isValueEmpty ||
    hasAlreadyAddedFilter ||
    shouldDisableControls ||
    isValueTooLong;

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    void updateFilterParams({
      [searchKey.key]: trimmedValue,
    });

    setSearchValue("");
  };

  const options = useMemo(
    () =>
      showAlternateExternalId
        ? [...DEFAULT_OPTIONS, ALT_ID_OPTION]
        : DEFAULT_OPTIONS,
    [showAlternateExternalId]
  );

  const tooltipProps = (() => {
    const searchKeyForTooltip = searchKeyToTooltipContentMap[searchKey.key];

    if (hasAlreadyAddedFilter) {
      return {
        content: `You can only search for one ${searchKeyForTooltip} at a time. Please clear your existing search to search by another ${searchKeyForTooltip}.`,
      };
    } else if (isValueEmpty) {
      return {
        content: `The ${searchKeyForTooltip} should not be empty.`,
      };
    } else if (isValueTooLong) {
      return {
        content: `The ${searchKeyForTooltip} should not exceed ${MAX_SEARCH_VALUE_LENGTH} characters.`,
      };
    } else {
      return undefined;
    }
  })();

  return (
    <FlexForm onSubmit={handleSearchSubmit}>
      <FullWidthScopedSearchBar
        trackingId={FormsScopedSearchBarTrackingId.SearchBar}
        label="Search by name or account ID"
        isLabelHidden={true}
        size="small"
        dropdownProps={{
          options,
          onChange: setSearchKey,
          value: searchKey,
          getOptionKey: ({ key }: SearchKeyOption) => key,
          getOptionLabel: ({ label }: SearchKeyOption) => label,
        }}
        inputProps={{
          value: searchValue,
          onTextChange: setSearchValue,
        }}
        isDisabled={shouldDisableControls}
      />
      <Button
        size="small"
        trackingId={FormsScopedSearchBarTrackingId.SearchButton}
        label="Search"
        type="submit"
        isDisabled={isSubmitDisabled}
        tooltipProps={tooltipProps}
      />
    </FlexForm>
  );
};

const FlexForm = styled.form(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.m};
  align-items: center;
  flex-grow: 1;
`
);

const FullWidthScopedSearchBar = styled(ScopedSearchBar<SearchKeyOption>)`
  flex-grow: 1;
`;

export default FormsScopedSearchBar;
