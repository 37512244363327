import { zodResolver } from "@hookform/resolvers/zod";
import {
  ToastConfig,
  hasDefinedValues,
  irFormTypeToDisplayNameMap,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { useDownloadIrForms } from "../../../../../api/information-reporting/forms/formsApi";
import { toBaseApiFormsFilters } from "../../../../../api/information-reporting/forms/formsApiTypes";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import {
  downloadFormsModalFormDefaultValues,
  downloadFormsModalFormSchema,
} from "../../context/downloadFormsModalFormTypes";
import { useIrFormsContext } from "../../context/useIrForms";

export enum IrFormsDownloadActionTrackingId {
  ErrorToast = "ir-forms-download-error-toast",
  SuccessToast = "ir-forms-download-success-toast",
  FilteredPopulationOption = "ir-forms-download-confirmation-modal-filtered-population-option",
  AllPopulationOption = "ir-forms-download-confirmation-modal-all-population-option",
}

const ALLOWED_FORMS_TO_DOWNLOAD = 100_000;

export const downloadSuccessToast: ToastConfig = {
  message:
    "Forms are being prepared. Track the status in the Active Jobs tab, and the zip file will appear in your Notifications when ready to download.",
  trackingId: IrFormsDownloadActionTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const downloadErrorToast: ToastConfig = {
  message: "Forms failed to download. Please try again.",
  trackingId: IrFormsDownloadActionTrackingId.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
  variant: "danger",
};

const useDownloadFormsConfirmationModal = ({
  onClose,
  isBulkDownload = false,
}: {
  onClose: () => void;
  isBulkDownload?: boolean;
}) => {
  const addToast = useDashboardStore((store) => store.addToast);
  const {
    currentForm,
    currentYear,
    accountExternalIdsFilter,
    urlParams: { formYear, formType, page, limit, sort, ...filterParams },
    selectedForms,
    toggleAllSelectedForms,
    aggregates,
    totalCount: filteredCount,
  } = useIrFormsContext();

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const downloadFormsModalFormMethods = useForm({
    resolver: zodResolver(downloadFormsModalFormSchema),
    defaultValues: downloadFormsModalFormDefaultValues,
  });

  const { mutate: downloadForms } = useDownloadIrForms({
    onError: () => {
      addToast(downloadErrorToast);
    },
    onSuccess: () => {
      addToast(downloadSuccessToast);
    },
  });

  const hasFiltersApplied =
    hasDefinedValues(filterParams) || accountExternalIdsFilter.length > 0;

  const handleDownloadForms = useCallback(
    (e: React.FormEvent) => {
      void downloadFormsModalFormMethods.handleSubmit(({ population }) => {
        toggleAllSelectedForms(false);

        downloadForms({
          taxFormKeys: isBulkDownload ? undefined : selectedForms,
          documentType: currentForm,
          documentYear: currentYear,
          filters:
            isBulkDownload && hasFiltersApplied && population === "filtered"
              ? toBaseApiFormsFilters({
                  ...filterParams,
                  formType,
                  formYear,
                  accountExternalIdsFilter,
                })
              : undefined,
        });
      })(e);

      onClose();
    },
    [
      accountExternalIdsFilter,
      currentForm,
      currentYear,
      downloadForms,
      downloadFormsModalFormMethods,
      filterParams,
      formYear,
      formType,
      hasFiltersApplied,
      isBulkDownload,
      onClose,
      selectedForms,
      toggleAllSelectedForms,
    ]
  );

  const totalCount = isBulkDownload
    ? (aggregates?.total ?? 0)
    : selectedForms.length;

  const formattedAllowedFormsToDownload = formatWholeQuantity(
    ALLOWED_FORMS_TO_DOWNLOAD
  );

  const shouldShowRadioGroup = isBulkDownload && hasFiltersApplied;

  const radioGroupLabel = `All ${irFormTypeToDisplayNameMap[currentForm]} forms will be downloaded into a zip file. Which forms would you like to download?`;

  const modalContent = (() => {
    if (totalCount > ALLOWED_FORMS_TO_DOWNLOAD) {
      return `The first ${formattedAllowedFormsToDownload} forms will be downloaded into a zip file.`;
    }

    return `All ${irFormTypeToDisplayNameMap[currentForm]} ${pluralize("form", totalCount)} will be downloaded into a zip file (${formatWholeQuantity(totalCount)} ${pluralize("form", totalCount)}).`;
  })();

  const modalNote = (() => {
    if (totalCount > ALLOWED_FORMS_TO_DOWNLOAD) {
      return (
        <div>
          <BoldSpan>Note:</BoldSpan> For populations exceeding{" "}
          {formattedAllowedFormsToDownload} forms, only the first{" "}
          {formattedAllowedFormsToDownload} will be downloaded. Please contact
          support if you need assistance with larger populations.
        </div>
      );
    }

    return undefined;
  })();

  const getDownloadFormsModalPopulationOptions = useCallback(
    () => [
      {
        value: "filtered",
        label: `Filtered population (${formatWholeQuantity(filteredCount)} ${pluralize("form", filteredCount)})`,
        trackingId: IrFormsDownloadActionTrackingId.FilteredPopulationOption,
      },
      {
        value: "all",
        label:
          totalCount > ALLOWED_FORMS_TO_DOWNLOAD
            ? `The first ${formattedAllowedFormsToDownload} forms`
            : `Entire population (${formatWholeQuantity(totalCount)} ${pluralize("form", totalCount)})`,
        trackingId: IrFormsDownloadActionTrackingId.AllPopulationOption,
      },
    ],
    [
      filteredCount,
      formattedAllowedFormsToDownload,
      formatWholeQuantity,
      totalCount,
    ]
  );

  return {
    downloadFormsModalFormMethods,
    getDownloadFormsModalPopulationOptions,
    handleDownloadForms,
    modalContent,
    modalNote,
    radioGroupLabel,
    shouldShowRadioGroup,
  };
};

export default useDownloadFormsConfirmationModal;

const BoldSpan = styled.span(
  ({ theme }) => `
  font-weight: ${theme.fontWeight.bold};`
);
