export enum HelpArticleLinkTrackingId {
  CuringDocumentation = "help-article-link-curing-documentation",
  TreatyCountryMismatchCuringDocumentation = "help-article-link-treaty-country-mismatch-curing-documentation",
  WithholdingDocumentationCuringDocumentationLink = "help-article-link-withholding-documentation-curing-documentation",
}

export const curingDocumentationLink =
  "https://taxbitsupport.zendesk.com/hc/en-us/articles/28876526910861-Curing-Documentation-Review";

export const treatyCountryMismatchCuringDocumentationLink =
  "https://taxbitsupport.zendesk.com/hc/en-us/articles/33287740947853-Treaty-Country-Mismatch";

export const withholdingDocumentationCuringDocumentationLink = "#";
