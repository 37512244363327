import { isDefined, renderExcludedSpan } from "@taxbit-dashboard/commons";
import {
  AccountOwnerDetailsTaxDocumentationIssueType,
  AccountOwnerIssue,
} from "@taxbit-dashboard/rest";
import { Flex } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { isAccountOwnerDetailsCuringIssueType } from "../../../../../api/account-owners/accountOwnersApiTypes";
import {
  TaxDocDetails,
  taxClassificationMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { IssueDetails } from "../../kyc-tax-documentation/IssueDetails";
import KycBadge from "../../KycBadge";

const getAddressField = (field?: string, hasTrailingSpace: boolean = true) =>
  field ? `${field}${hasTrailingSpace ? " " : ""}` : "";

const useFormDetailsRows = ({
  formDetails,
}: {
  formDetails?: TaxDocDetails;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();

  return useMemo(() => {
    if (!formDetails) {
      return undefined;
    }

    const topRows = {
      key: "top-rows",
      rows: [
        {
          label: "Documentation Status",
          content: <KycBadge taxStatus={formDetails.documentStatus} />,
          key: "documentation-status",
        },
        {
          label: "Name",
          content: renderExcludedSpan(formDetails.name),
          key: "name",
        },
        {
          label: "Tax Classification",
          content: formDetails.taxClassification
            ? taxClassificationMap[formDetails.taxClassification] ?? "Other"
            : undefined,
          key: "tax-classification",
        },
        ...(formDetails.withholdingDocumentationStatus
          ? [
              {
                label: "Withholding Documentation Status",
                content: (
                  <KycBadge
                    taxStatus={formDetails.withholdingDocumentationStatus}
                  />
                ),
                key: "withholding-documentation-status",
              },
            ]
          : []),
        ...(formDetails.treatyClaimStatus
          ? [
              {
                label: "Treaty Claim Status",
                content: <KycBadge taxStatus={formDetails.treatyClaimStatus} />,
                key: "treaty-claim-status",
              },
            ]
          : []),
        {
          label: "Submission Date",
          content: formatDateTime({
            date: formDetails.submissionDate,
          }),
          key: "submission-date",
        },
        ...(formDetails.expirationDate
          ? [
              {
                label: "Expiration Date",
                content: formatDateTime({
                  date: formDetails.expirationDate,
                }),
                key: "expiration-date",
              },
            ]
          : []),
        ...(isDefined(formDetails.subjectToBackupWithholding)
          ? [
              {
                label: "Subject to Backup Withholding",
                content: formatBoolean(formDetails.subjectToBackupWithholding),
                key: "subject-to-backup-withholding",
              },
            ]
          : []),
      ],
    };

    const getTargetString = (
      issueType: AccountOwnerDetailsTaxDocumentationIssueType
    ) => {
      if (isAccountOwnerDetailsCuringIssueType(issueType)) {
        return issueType ===
          AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
          ? "US"
          : issueType ===
              AccountOwnerDetailsTaxDocumentationIssueType.TreatyCountryMismatch
            ? formDetails.treatyCountry
            : formDetails.permanentAddress
              ? `${getAddressField(formDetails.permanentAddress.firstLine)}${getAddressField(formDetails.permanentAddress.secondLine)}${getAddressField(formDetails.permanentAddress.city)}${getAddressField(formDetails.permanentAddress.stateOrProvince)}${getAddressField(formDetails.permanentAddress.postalCode)}${getAddressField(formDetails.permanentAddress.country, false)}`
              : undefined;
      }

      return undefined;
    };

    const hasValidIssue = isDefined(formDetails.permanentAddress);

    const getIssueContent = (issues?: AccountOwnerIssue[]) =>
      isDefined(issues) && issues.length > 0 && hasValidIssue ? (
        <Flex direction="column" gap="s">
          {issues.map((issue) => {
            return (
              <IssueDetails
                issue={issue}
                taxDocumentType={formDetails.documentType}
                key={`issue-${issue.issueType}`}
                targetString={getTargetString(issue.issueType)}
              />
            );
          })}
        </Flex>
      ) : (
        COSMIC_VALUE_PLACEHOLDER
      );

    const middleRows = {
      key: "middle-rows",
      rows: [
        {
          label: "Issues",
          content: getIssueContent(formDetails.issues),
          key: "issues",
        },
      ],
    };

    const warningRows =
      formDetails.warnings && formDetails.warnings.length > 0
        ? [
            {
              key: "warning-rows",
              rows: [
                {
                  label: "Warnings",
                  content: getIssueContent(formDetails.warnings),
                  key: "warnings",
                },
              ],
            },
          ]
        : [];

    return [topRows, middleRows, ...warningRows];
  }, [formDetails, formatBoolean, formatDateTime]);
};

export default useFormDetailsRows;
